import {
  Dispatch,
  MutableRefObject,
  ReactElement,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ReactComponent as ChevronUpIcon } from '../../assets/svg/ChevronUpIcon.svg';
import { ReactComponent as CloseSmallIcon } from '../../assets/svg/CloseSmallIcon.svg';
import ActionIcon from '../ActionIcon/ActionIcon';

import './FullSizeModal.scss';

interface FullSizeModalProps {
  title: string;
  children: ReactElement;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  className?: string;
  withSideBar?: boolean;
}

const FullSizeModal = ({
  title,
  children,
  show,
  setShow,
  className,
  withSideBar,
}: FullSizeModalProps): ReactElement => {
  const defaultHeight = window.innerHeight / 2;
  const minHeight = 150;
  const [expanded, setExpanded] = useState(false);
  const refModal = useRef(null) as MutableRefObject<HTMLDivElement | null>;
  const refResizer = useRef(null) as MutableRefObject<HTMLDivElement | null>;
  const refResizerLayout = useRef(
    null
  ) as MutableRefObject<HTMLDivElement | null>;
  const [currentHeight, setCurrentHeight] = useState(defaultHeight);

  useEffect(() => {
    const element = refModal.current;
    const layout = refResizerLayout.current;
    if (!element || !layout) {
      return (): void => {};
    }
    const styles = window.getComputedStyle(element);

    let height = parseInt(styles.height, 10);
    let yCord = 0;

    const onMouseMoveTopResize = (event: MouseEvent): void => {
      const dy = event.clientY - yCord;
      const newHeight = height - dy;

      // Ensure it doesn't get too small or off screen
      if (newHeight < minHeight || event.clientY <= 0) {
        return;
      }

      height -= dy;
      yCord = event.clientY;
      // Make the layout follow the mouse vertically
      layout.style.top = `${event.clientY}px`;
    };

    const onMouseUpTopResize = (): void => {
      const heightValue = `${height}px`;
      setCurrentHeight(height);

      element.style.height = heightValue;
      // When release the handle, hide the layout
      layout.style.display = 'none';
      document.removeEventListener('mousemove', onMouseMoveTopResize);
    };

    const onMouseDownTopResize = (event: MouseEvent): void => {
      yCord = event.clientY;
      element.style.bottom = styles.bottom;
      element.style.top = '';
      document.addEventListener('mousemove', onMouseMoveTopResize);
      document.addEventListener('mouseup', onMouseUpTopResize);
      layout.style.display = 'block';
    };

    const resizer = refResizer.current;
    resizer?.addEventListener('mousedown', onMouseDownTopResize);

    return (): void => {
      resizer?.removeEventListener('mousedown', onMouseDownTopResize);
    };
  }, [expanded, refModal, refResizer, refResizerLayout, setCurrentHeight]);

  if (!show) {
    return <div />;
  }

  return (
    <div
      ref={refModal}
      style={{ height: expanded ? '100%' : `${currentHeight}px` }}
      className={`full-size-modal-component ${className} ${
        expanded ? 'expanded' : 'collapsed'
      } ${withSideBar ? 'with-sidebar' : ''}`}
    >
      <div ref={refResizer} className="resizer" />
      <div ref={refResizerLayout} className="resizer-layout" />
      <div className="header">
        <div className="title">{title}</div>
        <div className="actions">
          <ActionIcon
            className={`collapser ${expanded ? 'reverse' : ''}`}
            Icon={ChevronUpIcon}
            onClick={(): void => {
              setExpanded((value) => !value);
            }}
          />
          <ActionIcon
            Icon={CloseSmallIcon}
            onClick={(): void => {
              setExpanded(false);
              setShow((value) => !value);
            }}
          />
        </div>
      </div>
      <div className="table-container">{children}</div>
    </div>
  );
};

export default FullSizeModal;

FullSizeModal.defaultProps = {
  className: '',
  withSideBar: false,
};
