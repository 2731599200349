import { CSSProperties, ReactElement } from 'react';
import './UserInitial.scss';

interface UserInitialProps {
  displayName: string;
  color?: string;
  isInvitationPending: boolean;
}

const getInitials = (
  displayName: string,
  isInvitationPending: boolean
): string => {
  if (isInvitationPending) return '';

  if (!displayName) {
    return '';
  }

  const parts = displayName.split(' ');
  const characterLimit = 2;
  let initials = '';

  for (let i = 0; i < parts.length; i += 1) {
    if (initials.length >= characterLimit) break;

    const [part] = parts[i];
    if (part) {
      const [firstPart] = part;
      initials += firstPart.toUpperCase();
    }
  }

  return initials;
};

const getInitialClassName = (
  color: string | undefined,
  isInvitationPending: boolean
): string => {
  if (isInvitationPending) return 'user-initials pending';
  return `user-initials ${color ? 'random' : ''}`;
};

const getBackgroundColor = (
  color: string | undefined,
  isInvitationPending: boolean
): CSSProperties => {
  if (isInvitationPending) return { background: 'white' };
  return { background: color ? `#${color}` : 'white' };
};

const UserInitial = ({
  displayName,
  color,
  isInvitationPending,
}: UserInitialProps): ReactElement => (
  <span
    data-hj-suppress
    className={getInitialClassName(color, isInvitationPending)}
    style={getBackgroundColor(color, isInvitationPending)}
  >
    {getInitials(displayName, isInvitationPending)}
  </span>
);

UserInitial.defaultProps = {
  color: undefined,
};

export default UserInitial;
