/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { MutableRefObject, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SortArrowAtoZ } from '../../assets/svg/SortArrowAtoZ.svg';
import { ReactComponent as SortArrowDefault } from '../../assets/svg/SortArrowDefault.svg';
import { ReactComponent as SortArrowZtoA } from '../../assets/svg/SortArrowZtoA.svg';
import SortDirection from '../../common/types/sortDirection';

interface GridHeaderCellProps {
  element: MutableRefObject<any>;
  text: string;
  className: string;
  cellIndex: number;
  onSort?: (headerName: string) => void;
  sortDirection: {
    [key: string]: SortDirection;
  };
  sortableColumnKeys: string[];
}

const GridHeaderCell = ({
  element,
  text,
  className,
  cellIndex,
  onSort,
  sortDirection,
  sortableColumnKeys,
}: GridHeaderCellProps): ReactElement => {
  const { t } = useTranslation();

  const getHeaderSortState = (): null | ReactElement => {
    if (onSort === undefined) {
      return null;
    }
    if (!sortableColumnKeys.includes(text)) {
      return null;
    }

    if (sortDirection[text] === SortDirection.Descending) {
      return (
        <SortArrowZtoA className="ms-2" onClick={(): void => onSort(text)} />
      );
    }
    if (sortDirection[text] === SortDirection.Ascending) {
      return (
        <SortArrowAtoZ className="ms-2" onClick={(): void => onSort(text)} />
      );
    }

    return (
      <SortArrowDefault className="ms-2" onClick={(): void => onSort(text)} />
    );
  };

  return (
    <div ref={element} className={className || ''}>
      <span>
        {t(text)} <i className="clickable">{getHeaderSortState()}</i>
      </span>
      <div tabIndex={cellIndex} role="link" />
    </div>
  );
};

GridHeaderCell.defaultProps = {
  onSort: (): void => {},
};

export default GridHeaderCell;
