/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ReactElement, useState } from 'react';
import { ReactComponent as LockIcon } from '../../assets/svg/LockIcon.svg';
import { ReactComponent as UploadCloudWhiteSmallIcon } from '../../assets/svg/UploadCloudWhiteSmallIcon.svg';
import KeyPairItem from '../../common/models/keypairItem';
import MaskOptions from '../../common/models/maskOptions';
import RadioDropdownItem from '../../common/models/radioDropdownItem';
import TabItem from '../../common/models/tabItem';
import DataType from '../../common/types/dataType';
import DropdownItemRole from '../../common/types/dropdownItemRole';
import NotificationVariant from '../../common/types/notificationVariant';
import SDCButtonIconPosition from '../../common/types/sdcButtonIconPosition';
import SDCButtonSize from '../../common/types/sdcButtonSize';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import SDCDropdownSize from '../../common/types/sdcDropdownSize';
import SDCLinkButtonVariant from '../../common/types/sdcLinkButtonVariant';
import SDCTextFieldSize from '../../common/types/sdcTextFieldSize';
import { RowCollection, RowData } from '../../common/types/types';
import BadgePill from '../../components/BadgePill/BadgePill';
import DataTable from '../../components/DataTable/DataTable';
import NotificationAlert from '../../components/NotificationAlert/NotifcationAlert';
import RadioDropdown from '../../components/RadioDropdown/RadioDropdown';
import SDCButton from '../../components/SDCButton/SDCButton';
import SDCCheckbox from '../../components/SDCCheckbox/SDCCheckbox';
import SDCDropdown from '../../components/SDCDropdown/SDCDropdown';
import SDCLinkButton from '../../components/SDCLinkButton/SDCLinkButton';
import SDCTextField from '../../components/SDCTextField/SDCTextField';
import Tab from '../../components/Tab/Tab';
import TableRowDropdownMenu from '../../components/TableRowDropdownMenu/TableRowDropdownMenu';
import TagItem from '../../components/Tag/TagItem';
import Toaster from '../../components/Toaster/Toaster';
import showToaster from '../../components/Toaster/ToasterProvider';
import MetadataDetail from '../../services/capture/models/metadataDetail';
import MetadataTemplateItem from '../../services/documentType/models/metadataTemplateItem';
import UserSettings from '../../services/user/models/userSettings';
import './StyleSheetPage.scss';

const StyleSheetPage = (): ReactElement => {
  const classNameSet: Array<string> = [
    'heading-title-40-bold',
    'heading-title-40',
    'heading-title-36-bold',
    'heading-title-36',
    'heading-title-32-bold',
    'heading-title-32',
    'heading-title-28-bold',
    'heading-title-28',
    'heading-title-24-bold',
    'heading-title-24',
    'heading-title-20-bold',
    'heading-title-20',
    'heading-title-18-bold',
    'heading-title-18',
    'body-underline-bold',
    'body-underline',
    'body-bold',
    'body-regular',
    'body-italic',
    'body-small-bold',
    'body-small-underline',
    'body-small',
    'body-small-italic',
    'body-extra-small-bold',
    'body-extra-small-underline',
    'body-extra-small',
    'body-extra-small-italic',
  ];
  const colorSet: object = {
    black: '#0d1423',
    white: '#fff',
    'grey-100': '#f5f7fb',
    'grey-200': '#eff3fa',
    'grey-300': '#e3eaf7',
    'grey-400': '#d3ddee',
    'grey-500': '#b2bfd2',
    'grey-600': '#8b98ad',
    'grey-700': '#656e85',
    'grey-800': '#42495e',
    'grey-900': '#1c2136',
    'success-100': '#eafde8',
    'success-200': '#b8f9b9',
    'success-300': '#76ec82',
    'success-400': '#51d96d',
    'success-500': '#20c151',
    'success-600': '#17a550',
    'success-700': '#108a4d',
    'success-800': '#0a6f47',
    'success-900': '#065c42',
    'danger-100': '#fde7e7',
    'danger-200': '#f3caca',
    'danger-300': '#eaa4a4',
    'danger-400': '#da6060',
    'danger-500': '#c91b1b',
    'danger-600': '#b80000',
    'danger-700': '#a60000',
    'danger-800': '#860000',
    'danger-900': '#5b0000',
    'info-100': '#f0f8fd',
    'info-200': '#d4eafc',
    'info-300': '#a0cdfa',
    'info-400': '#5c9cef',
    'info-500': '#1f6beb',
    'info-600': '#1b55d6',
    'info-700': '#153eb9',
    'info-800': '#112a9c',
    'info-900': '#0c1a7e',
    'warning-100': '#fbf6dc',
    'warning-200': '#faedb7',
    'warning-300': '#f6dd8a',
    'warning-400': '#f0c346',
    'warning-500': '#eea909',
    'warning-600': '#d18500',
    'warning-700': '#b56800',
    'warning-800': '#984e00',
    'warning-900': '#753500',
    'branding-sdc-100': '#f2f6f8',
    'branding-sdc-200': '#c2ecf0',
    'branding-sdc-300': '#5ac3d6',
    'branding-sdc-400': '#2d96b4',
    'branding-sdc-500': '#005d83',
    'branding-sdc-600': '#004870',
    'branding-sdc-700': '#00365e',
    'branding-sdc-800': '#00264b',
    'branding-sdc-900': '#001b3e',
    'product-mustard-100': '#fdfaf4',
    'product-mustard-200': '#fff1da',
    'product-mustard-300': '#fee3b5',
    'product-mustard-400': '#f7c36b',
    'product-mustard-500': '#eea320',
    'product-mustard-600': '#d7931d',
    'product-mustard-700': '#c1831a',
    'product-mustard-800': '#aa7416',
    'product-mustard-900': '#936413',
    'product-purple-100': '#faf8fd',
    'product-purple-200': '#dfd4ed',
    'product-purple-300': '#c5b3de',
    'product-purple-400': '#af99ce',
    'product-purple-500': '#9e85bf',
    'product-purple-600': '#917baf',
    'product-purple-700': '#8470a0',
    'product-purple-800': '#776590',
    'product-purple-900': '#67577c',
    'product-salmon-100': '#fff5f4',
    'product-salmon-200': '#fad7d2',
    'product-salmon-300': '#f3b9b0',
    'product-salmon-400': '#ee9d91',
    'product-salmon-500': '#e88273',
    'product-salmon-600': '#da7a6c',
    'product-salmon-700': '#c97063',
    'product-salmon-800': '#b7675b',
    'product-salmon-900': '#a65e53',
    'product-orange-100': '#fff9f6',
    'product-orange-200': '#f6d5c5',
    'product-orange-300': '#f2b79b',
    'product-orange-400': '#eda17d',
    'product-orange-500': '#e86e34',
    'product-orange-600': '#d96731',
    'product-orange-700': '#cb602d',
    'product-orange-800': '#bc592a',
    'product-orange-900': '#ad5125',
  };
  const dropdownItems: KeyPairItem[] = [
    {
      Name: 'Apple',
      Value: '1',
    },
    {
      Name: 'Banana',
      Value: '2',
    },
    {
      Name: 'Orange',
      Value: '3',
    },
    {
      Name: 'Watermelon',
      Value: '4',
    },
  ];

  const tabItems: TabItem[] = [
    {
      text: 'Tab 1',
      value: '1',
      badgeText: '55',
      Icon: LockIcon,
    },
    {
      text: 'Tab 2',
      value: '2',
      badgeText: '2000',
      Icon: LockIcon,
    },
    {
      text: 'Tab 3',
      value: '3',
      badgeText: '0',
      Icon: LockIcon,
    },
  ];
  const radioDropdownItems: RadioDropdownItem[] = [
    {
      name: '1 year',
      value: '1',
      dataType: DataType.Decimal,
      role: DropdownItemRole.Button,
    },
    {
      name: '3 years',
      value: '3',
      dataType: DataType.Decimal,
      role: DropdownItemRole.Button,
    },
    {
      name: '7 years',
      value: '7',
      dataType: DataType.Decimal,
      role: DropdownItemRole.Button,
    },
    {
      name: 'Custom',
      value: '10',
      dataType: DataType.YearTimespan,
      role: DropdownItemRole.Button,
      editable: true,
    },
    {
      name: 'Do not protect',
      value: 'unprotected',
      dataType: DataType.String,
      role: DropdownItemRole.Button,
    },
  ];
  const settings: UserSettings = {
    dateFormat: 'DD/MM/YY',
    dateTimeFormat: 'DD/MM/YY hh:mm:ss',
  };

  const columnTemplate: MetadataTemplateItem[] = [
    {
      name: 'name',
      displayName: 'Movie Name',
      dataType: DataType.String,
      priority: 0,
      isRequired: true,
      isSystemDefined: false,
      isHidden: false,
      isReadOnly: false,
      template: [],
    },
    {
      name: 'release_date',
      displayName: 'Release Date',
      dataType: DataType.Date,
      priority: 1,
      isRequired: true,
      isSystemDefined: false,
      isHidden: false,
      isReadOnly: false,
      template: [],
    },
    {
      name: 'box_office',
      displayName: 'Box Office (Million USD)',
      dataType: DataType.Decimal,
      priority: 2,
      isRequired: true,
      isSystemDefined: false,
      isHidden: false,
      isReadOnly: false,
      template: [],
    },
  ];

  const [tableRows, setTableRows] = useState<RowCollection>([
    [
      {
        key: 'name',
        value: 'Iron Man',
        type: DataType.String,
        displayName: '',
        isHidden: false,
      },
      {
        key: 'box_office',
        value: '858.4',
        type: DataType.String,
        displayName: '',
        isHidden: false,
      },
      {
        key: 'release_date',
        value: '2008-05-02',
        type: DataType.Date,
        displayName: '',
        isHidden: false,
      },
    ],
    [
      {
        key: 'name',
        value: 'Thor',
        type: DataType.String,
        displayName: '',
        isHidden: false,
      },
      {
        key: 'box_office',
        value: '858.4',
        type: DataType.String,
        displayName: '',
        isHidden: false,
      },
      {
        key: 'release_date',
        value: '2011-05-06',
        type: DataType.Date,
        displayName: '',
        isHidden: false,
      },
    ],
    [
      {
        key: 'name',
        value:
          'Captain America: The First Avenger (This is back to American Civil War)',
        type: DataType.String,
        displayName: '',
        isHidden: false,
      },
      {
        key: 'box_office',
        value: '858.4',
        type: DataType.String,
        displayName: '',
        isHidden: false,
      },
      {
        key: 'release_date',
        value: '2011-07-22',
        type: DataType.Date,
        displayName: '',
        isHidden: false,
      },
    ],
    [
      {
        key: 'name',
        value: 'The Avengers',
        type: DataType.String,
        displayName: '',
        isHidden: false,
      },
      {
        key: 'box_office',
        value: '858.4',
        type: DataType.String,
        displayName: '',
        isHidden: false,
      },
      {
        key: 'release_date',
        value: '2012-05-04',
        type: DataType.Date,
        displayName: '',
        isHidden: false,
      },
    ],
    [
      {
        key: 'name',
        value: 'Iron Man 3',
        type: DataType.String,
        displayName: '',
        isHidden: false,
      },
      {
        key: 'box_office',
        value: '858.4',
        type: DataType.String,
        displayName: '',
        isHidden: false,
      },
      {
        key: 'release_date',
        value: '2013-05-03',
        type: DataType.Date,
        displayName: '',
        isHidden: false,
      },
    ],
    [
      {
        key: 'name',
        value: 'Guardians of the Galaxy',
        type: DataType.String,
        displayName: '',
        isHidden: false,
      },
      {
        key: 'box_office',
        value: '858.4',
        type: DataType.String,
        displayName: '',
        isHidden: false,
      },
      {
        key: 'release_date',
        value: '2014-08-01',
        type: DataType.Date,
        displayName: '',
        isHidden: false,
      },
    ],
    [
      {
        key: 'name',
        value: 'Ant-Man',
        type: DataType.String,
        displayName: '',
        isHidden: false,
      },
      {
        key: 'box_office',
        value: '858.4',
        type: DataType.String,
        displayName: '',
        isHidden: false,
      },
      {
        key: 'release_date',
        value: '2015-07-17',
        type: DataType.Date,
        displayName: '',
        isHidden: false,
      },
    ],
    [
      {
        key: 'name',
        value: 'Doctor Strange',
        type: DataType.String,
        displayName: '',
        isHidden: false,
      },
      {
        key: 'box_office',
        value: '858.4',
        type: DataType.String,
        displayName: '',
        isHidden: false,
      },
      {
        key: 'release_date',
        value: '2016-12-04',
        type: DataType.Date,
        displayName: '',
        isHidden: false,
      },
    ],
    [
      {
        key: 'name',
        value: 'Spider-Man: Homecoming',
        type: DataType.String,
        displayName: '',
        isHidden: false,
      },
      {
        key: 'box_office',
        value: '858.4',
        type: DataType.String,
        displayName: '',
        isHidden: false,
      },
      {
        key: 'release_date',
        value: '2017-07-07',
        type: DataType.Date,
        displayName: '',
        isHidden: false,
      },
    ],
    [
      {
        key: 'name',
        value: 'Black Panther',
        type: DataType.String,
        displayName: '',
        isHidden: false,
      },
      {
        key: 'box_office',
        value: '858.4',
        type: DataType.String,
        displayName: '',
        isHidden: false,
      },
      {
        key: 'release_date',
        value: '2018-02-16',
        type: DataType.Date,
        displayName: '',
        isHidden: false,
      },
    ],
    [
      {
        key: 'name',
        value: 'Avengers: Endgame',
        type: DataType.String,
        displayName: '',
        isHidden: false,
      },
      {
        key: 'box_office',
        value: '858.4',
        type: DataType.String,
        displayName: '',
        isHidden: false,
      },
      {
        key: 'release_date',
        value: '2019-04-26',
        type: DataType.Date,
        displayName: '',
        isHidden: false,
      },
    ],
  ]);

  const addTableRow = (index: number): void => {
    const emptyRow: RowData = [];
    columnTemplate.forEach((value: MetadataTemplateItem) => {
      const item: MetadataDetail = {
        key: value.name,
        value: '',
        type: value.dataType,
        displayName: value.displayName,
        isHidden: false,
      };
      emptyRow.push(item);
    });

    const temp = [...tableRows];
    temp.splice(index, 0, emptyRow);

    setTableRows(temp);
  };

  return (
    <>
      <Toaster />
      <div className="stylesheet-page-container">
        <h1>Typography</h1>
        <div className="typo-container">
          {classNameSet.map((className: string) => (
            <div key={className} className={className}>
              {className}
            </div>
          ))}
        </div>
        <h1>Colors</h1>
        <div className="color-container">
          {Object.entries(colorSet).map(([colorKey, colorCode]) => (
            <div key={colorKey} className="colorSet">
              <h5>{`${colorKey} : ${colorCode}`}</h5>
              <div
                className="sample-color"
                style={{ color: '#8b98ad', background: colorCode }}
              />
            </div>
          ))}
        </div>
        <h1>Links</h1>
        <div className="button-container">
          <h3>Standard</h3>
          <div>
            <SDCLinkButton onClick={(): boolean => false} text="Go back" />
          </div>
          <div />
          <h3>Dark</h3>
          <div style={{ background: 'black' }}>
            <SDCLinkButton
              onClick={(): boolean => false}
              text="Go back"
              variant={SDCLinkButtonVariant.Dark}
            />
          </div>
          <div />
        </div>
        <h1>Buttons</h1>
        <div className="button-container">
          <h3>Default</h3>
          <div>
            <SDCButton
              Icon={UploadCloudWhiteSmallIcon}
              iconPosition={SDCButtonIconPosition.Right}
              onClick={(): boolean => false}
              text="Sample Default Button"
            />
          </div>
          <div>
            <SDCButton
              Icon={UploadCloudWhiteSmallIcon}
              iconPosition={SDCButtonIconPosition.Right}
              size={SDCButtonSize.Small}
              onClick={(): boolean => false}
              text="Sample Default Button"
            />
          </div>
          <h3>Primary</h3>
          <div>
            <SDCButton
              text="Sample Button"
              variant={SDCButtonVariant.Primary}
              onClick={(): boolean => false}
            />
          </div>
          <div>
            <SDCButton
              text="Sample Button"
              variant={SDCButtonVariant.Primary}
              size={SDCButtonSize.Small}
              onClick={(): boolean => false}
            />
          </div>
          <h3>Secondary</h3>
          <div>
            <SDCButton
              variant={SDCButtonVariant.Secondary}
              onClick={(): boolean => false}
              text="Sample Secondary Button"
            />
          </div>
          <div>
            <SDCButton
              variant={SDCButtonVariant.Secondary}
              size={SDCButtonSize.Small}
              onClick={(): boolean => false}
              text="Sample Secondary Button"
            />
          </div>
          <h3>Disabled</h3>
          <div>
            <SDCButton
              text="Sample Disabled Button"
              onClick={(): boolean => false}
              disabled
            />
          </div>
          <div>
            <SDCButton
              text="Sample Disabled Button"
              size={SDCButtonSize.Small}
              onClick={(): boolean => false}
              disabled
            />
          </div>
          <h3>Primary Left Icon</h3>
          <div>
            <SDCButton
              Icon={UploadCloudWhiteSmallIcon}
              variant={SDCButtonVariant.Primary}
              onClick={(): boolean => false}
              text="Sample Primary Small Icon Left Button"
            />
          </div>
          <div>
            <SDCButton
              Icon={UploadCloudWhiteSmallIcon}
              variant={SDCButtonVariant.Primary}
              size={SDCButtonSize.Small}
              onClick={(): boolean => false}
              text="Sample Primary Small Icon Left Button"
            />
          </div>
          <h3>Secondary Left Icon</h3>
          <div>
            <SDCButton
              Icon={UploadCloudWhiteSmallIcon}
              variant={SDCButtonVariant.Secondary}
              onClick={(): boolean => false}
              text="Sample Secondary Small Icon Left Button"
            />
          </div>
          <div>
            <SDCButton
              Icon={UploadCloudWhiteSmallIcon}
              variant={SDCButtonVariant.Secondary}
              size={SDCButtonSize.Small}
              onClick={(): boolean => false}
              text="Sample Secondary Small Icon Left Button"
            />
          </div>
          <h3>Primary Right Icon</h3>
          <div>
            <SDCButton
              Icon={UploadCloudWhiteSmallIcon}
              iconPosition={SDCButtonIconPosition.Right}
              variant={SDCButtonVariant.Primary}
              onClick={(): boolean => false}
              text="Sample Primary Small Icon RIght Button"
            />
          </div>
          <div>
            <SDCButton
              Icon={UploadCloudWhiteSmallIcon}
              iconPosition={SDCButtonIconPosition.Right}
              variant={SDCButtonVariant.Primary}
              size={SDCButtonSize.Small}
              onClick={(): boolean => false}
              text="Sample Primary Small Icon RIght Button"
            />
          </div>
          <h3>Outlined</h3>
          <div>
            <SDCButton
              Icon={UploadCloudWhiteSmallIcon}
              iconPosition={SDCButtonIconPosition.Right}
              variant={SDCButtonVariant.Outlined}
              onClick={(): boolean => false}
              text="Sample Outlined Button"
            />
          </div>
          <div>
            <SDCButton
              Icon={UploadCloudWhiteSmallIcon}
              iconPosition={SDCButtonIconPosition.Right}
              variant={SDCButtonVariant.Outlined}
              size={SDCButtonSize.Small}
              onClick={(): boolean => false}
              text="Sample Outlined Button"
            />
          </div>
          <h3>Outlined Dark</h3>
          <div style={{ background: 'black', padding: '1rem' }}>
            <SDCButton
              Icon={UploadCloudWhiteSmallIcon}
              iconPosition={SDCButtonIconPosition.Right}
              variant={SDCButtonVariant.OutlinedDark}
              onClick={(): boolean => false}
              text="Sample Outlined Dark Button"
            />
          </div>
          <div style={{ background: 'black', padding: '1rem' }}>
            <SDCButton
              Icon={UploadCloudWhiteSmallIcon}
              iconPosition={SDCButtonIconPosition.Right}
              variant={SDCButtonVariant.OutlinedDark}
              size={SDCButtonSize.Small}
              onClick={(): boolean => false}
              text="Sample Outlined Dark Button"
            />
          </div>
          <h3>Outlined Dark Disabled</h3>
          <div style={{ background: 'black', padding: '1rem' }}>
            <SDCButton
              Icon={UploadCloudWhiteSmallIcon}
              iconPosition={SDCButtonIconPosition.Right}
              variant={SDCButtonVariant.OutlinedDark}
              onClick={(): boolean => false}
              text="Sample Outlined Dark Disables Button"
              disabled
            />
          </div>
          <div style={{ background: 'black', padding: '1rem' }}>
            <SDCButton
              Icon={UploadCloudWhiteSmallIcon}
              iconPosition={SDCButtonIconPosition.Right}
              variant={SDCButtonVariant.OutlinedDark}
              size={SDCButtonSize.Small}
              onClick={(): boolean => false}
              text="Sample Outlined Dark Disables Button"
              disabled
            />
          </div>
          <h3>Text Button with Icon</h3>
          <div>
            <SDCButton
              Icon={UploadCloudWhiteSmallIcon}
              iconPosition={SDCButtonIconPosition.Left}
              variant={SDCButtonVariant.Text}
              onClick={(): boolean => false}
              text="Text Button"
            />
          </div>
          <div>
            <SDCButton
              Icon={UploadCloudWhiteSmallIcon}
              iconPosition={SDCButtonIconPosition.Left}
              variant={SDCButtonVariant.Text}
              size={SDCButtonSize.Small}
              onClick={(): boolean => false}
              text="Text Button"
            />
          </div>
          <h3>Badges</h3>
          <div style={{ padding: '6rem 0 2rem 0' }}>
            <BadgePill text="pdf" themeClass="file-type pdf" />
          </div>
          <div style={{ padding: '6rem 0 2rem 0' }}>
            <BadgePill text="exe" themeClass="file-type exe" />
          </div>
          <h3>Tag Item</h3>
          <div style={{ padding: '1rem' }}>
            <TagItem text="test" onRemove={(): boolean => true} />
          </div>
          <div style={{ padding: '1rem' }}>
            <TagItem text="test" onRemove={(): boolean => true} />
          </div>
        </div>
        <h1>Text Fields</h1>
        <div className="button-container">
          <h3>Default</h3>
          <div style={{ padding: '1rem' }}>
            <SDCTextField
              placeholder="Type something"
              onChange={(): boolean => true}
            />
          </div>
          <div style={{ padding: '1rem' }}>
            <SDCTextField
              placeholder="Type something"
              onChange={(): boolean => true}
              fieldSize={SDCTextFieldSize.Small}
            />
          </div>
          <h3>Icon</h3>
          <div style={{ padding: '1rem' }}>
            <SDCTextField
              placeholder="Type something"
              onChange={(): boolean => true}
              Icon={UploadCloudWhiteSmallIcon}
            />
          </div>
          <div style={{ padding: '1rem' }}>
            <SDCTextField
              placeholder="Type something"
              onChange={(): boolean => true}
              fieldSize={SDCTextFieldSize.Small}
              Icon={UploadCloudWhiteSmallIcon}
            />
          </div>
          <h3>Help Text</h3>
          <div style={{ display: 'block', padding: '1rem' }}>
            <SDCTextField
              placeholder="Type something"
              onChange={(): boolean => true}
              helpText="This should be a really long help text. This thing can be an essay LOL. It should support a long text here. Btw, it's safe to type something here!!"
              helpTextId="text-box-default"
            />
          </div>
          <div style={{ display: 'block', padding: '1rem' }}>
            <SDCTextField
              placeholder="Type something"
              onChange={(): boolean => true}
              fieldSize={SDCTextFieldSize.Small}
              helpText="It's safe to type something here!!"
              helpTextId="text-box-small"
            />
          </div>
          <h3>Help Text and icon</h3>
          <div style={{ display: 'block', padding: '1rem' }}>
            <SDCTextField
              placeholder="Type something"
              onChange={(): boolean => true}
              helpText="This should be a really long help text. This thing can be an essay LOL. It should support a long text here. Btw, it's safe to type something here!!"
              helpTextId="text-box-default"
              Icon={UploadCloudWhiteSmallIcon}
            />
          </div>
          <div style={{ display: 'block', padding: '1rem' }}>
            <SDCTextField
              placeholder="Type something"
              onChange={(): boolean => true}
              fieldSize={SDCTextFieldSize.Small}
              helpText="It's safe to type something here!!"
              helpTextId="text-box-small"
              Icon={UploadCloudWhiteSmallIcon}
            />
          </div>
          <h3>Disabled</h3>
          <div style={{ padding: '1rem' }}>
            <SDCTextField
              placeholder="Type something"
              onChange={(): boolean => true}
              disabled
            />
          </div>
          <div style={{ padding: '1rem' }}>
            <SDCTextField
              placeholder="Type something"
              onChange={(): boolean => true}
              fieldSize={SDCTextFieldSize.Small}
              disabled
            />
          </div>
          <h3>Masking</h3>
          <div style={{ display: 'block', padding: '1rem' }}>
            <SDCTextField
              placeholder="DD/MM/YYYY hh:mm:ss"
              onChange={(): boolean => true}
              maskOptions={
                {
                  maskText: 'DD/MM/YYYY hh:mm:ss',
                  maskTextMapping: {
                    D: /\d/,
                    M: /\d/,
                    Y: /\d/,
                    m: /\d/,
                    s: /\d/,
                    h: /\d/,
                  },
                } as MaskOptions
              }
            />
          </div>
          <div style={{ display: 'block', padding: '1rem' }}>
            <SDCTextField
              placeholder="Only alphanumeric"
              onChange={(): boolean => true}
              maskOptions={
                {
                  maskFilter: /^[\w\s]*$/,
                } as MaskOptions
              }
            />
          </div>
          <h3>Floating Label</h3>
          <div style={{ display: 'block', padding: '1rem' }}>
            <SDCTextField
              placeholder="Type your name"
              onChange={(): boolean => true}
              labelText="Type your name"
            />
          </div>
          <div />
        </div>
        <h1>Checkboxes</h1>
        <div className="button-container">
          <h3>Default</h3>
          <div>
            <SDCCheckbox
              labelText="Banana"
              onCheckChange={(): boolean => true}
              checked
            />
          </div>
          <div>
            <SDCCheckbox
              labelText="Apple"
              onCheckChange={(): boolean => true}
              checked={false}
            />
          </div>
        </div>
        <div className="button-container">
          <h3>Disabled</h3>
          <div>
            <SDCCheckbox
              labelText="Banana"
              onCheckChange={(): boolean => true}
              checked
              disabled
            />
          </div>
          <div>
            <SDCCheckbox
              labelText="Apple"
              onCheckChange={(): boolean => true}
              checked={false}
              disabled
            />
          </div>
        </div>
        <h1>Dropdowns</h1>
        <div className="button-container">
          <h3>Default</h3>
          <div style={{ padding: '1rem' }}>
            <SDCDropdown
              values={dropdownItems}
              onSelect={(): boolean => true}
            />
          </div>
          <div style={{ padding: '1rem' }}>
            <SDCDropdown
              values={dropdownItems}
              onSelect={(): boolean => true}
              size={SDCDropdownSize.Small}
            />
          </div>
          <h3>Dark</h3>
          <div style={{ background: 'black', padding: '1rem' }}>
            <SDCDropdown
              values={dropdownItems}
              onSelect={(): boolean => true}
              darkMode
            />
          </div>
          <div style={{ background: 'black', padding: '1rem' }}>
            <SDCDropdown
              values={dropdownItems}
              onSelect={(): boolean => true}
              size={SDCDropdownSize.Small}
              darkMode
            />
          </div>
        </div>
        <h1>Radio Dropdown</h1>
        <div className="button-container">
          <h3>Default</h3>
          <div style={{ padding: '1rem' }}>
            <RadioDropdown
              list={radioDropdownItems}
              onApply={(): void => {
                // Empty
              }}
              settings={settings}
              text="Please select"
            />
          </div>
          <div />
          <h3>Pre-selected</h3>
          <div style={{ padding: '1rem' }}>
            <RadioDropdown
              initialSelectedValue={radioDropdownItems[1].value}
              list={radioDropdownItems}
              onApply={(): void => {
                // Empty
              }}
              settings={settings}
              text="Please select"
            />
          </div>
          <div />
          <h3>Custom pre-selected</h3>
          <div style={{ padding: '1rem' }}>
            <RadioDropdown
              initialSelectedValue={radioDropdownItems[3].value}
              list={radioDropdownItems}
              onApply={(): void => {
                // Empty
              }}
              settings={settings}
              text="Please select"
            />
          </div>
          <div />
          <h3>With description</h3>
          <div style={{ padding: '1rem' }}>
            <RadioDropdown
              description="Please choose one of these options"
              list={radioDropdownItems}
              onApply={(): void => {
                // Empty
              }}
              settings={settings}
              text="Please select"
            />
          </div>
          <div />
        </div>
        <h1>Table Row Dropdown Menu</h1>
        <div className="button-container">
          <h3>Default</h3>
          <div style={{ padding: '1rem' }}>
            <TableRowDropdownMenu
              values={dropdownItems}
              onSelect={(): boolean => true}
            />
          </div>
        </div>
        <h1>Tabs</h1>
        <div className="button-container">
          <h3>Default</h3>
          <div>
            <Tab
              items={tabItems}
              currentTabValue={undefined}
              onChange={(): boolean => true}
            />
          </div>
          <div />
        </div>
        <h1>Toasters</h1>
        <div className="button-container">
          <h3>Primary</h3>
          <div>
            <button
              type="button"
              onClick={(): void => {
                showToaster(
                  'This toaster has just the title',
                  '',
                  NotificationVariant.PrimarySuccess
                );
              }}
            >
              Title Only
            </button>
          </div>
          <div style={{ display: 'grid-row', gap: '5px' }}>
            <button
              type="button"
              onClick={(): void => {
                showToaster(
                  'Test',
                  'Something went successfully',
                  NotificationVariant.PrimarySuccess
                );
              }}
            >
              Success
            </button>
            <button
              type="button"
              onClick={(): void => {
                showToaster(
                  'Test',
                  'Something went successfully',
                  NotificationVariant.PrimaryInfo
                );
              }}
            >
              Info
            </button>
            <button
              type="button"
              onClick={(): void => {
                showToaster(
                  'Test',
                  'Something went successfully',
                  NotificationVariant.PrimaryWarning
                );
              }}
            >
              Warning
            </button>
            <button
              type="button"
              onClick={(): void => {
                showToaster(
                  'Test',
                  'Something went successfully',
                  NotificationVariant.PrimaryDanger
                );
              }}
            >
              Danger
            </button>
            <button
              type="button"
              onClick={(): void => {
                showToaster(
                  'Test',
                  'Something went successfully',
                  NotificationVariant.PrimaryBasic
                );
              }}
            >
              Basic
            </button>
          </div>
        </div>
        <div className="button-container">
          <h3>Secondary</h3>
          <div>
            <button
              type="button"
              onClick={(): void => {
                showToaster(
                  'This toaster has just the title',
                  '',
                  NotificationVariant.SecondarySuccess
                );
              }}
            >
              Title Only
            </button>
          </div>
          <div style={{ display: 'grid-row', gap: '5px' }}>
            <button
              type="button"
              onClick={(): void => {
                showToaster(
                  'Test',
                  'Something went successfully',
                  NotificationVariant.SecondarySuccess
                );
              }}
            >
              Success
            </button>
            <button
              type="button"
              onClick={(): void => {
                showToaster(
                  'Test',
                  'Something went successfully',
                  NotificationVariant.SecondaryInfo
                );
              }}
            >
              Info
            </button>
            <button
              type="button"
              onClick={(): void => {
                showToaster(
                  'Test',
                  'Something went successfully',
                  NotificationVariant.SecondaryWarning
                );
              }}
            >
              Warning
            </button>
            <button
              type="button"
              onClick={(): void => {
                showToaster(
                  'Test',
                  'Something went successfully',
                  NotificationVariant.SecondaryDanger
                );
              }}
            >
              Danger
            </button>
            <button
              type="button"
              onClick={(): void => {
                showToaster(
                  'Test',
                  'Something went successfully',
                  NotificationVariant.SecondaryBasic
                );
              }}
            >
              Basic
            </button>
          </div>
        </div>
        <div className="button-container">
          <h3>Soft</h3>
          <div>
            <button
              type="button"
              onClick={(): void => {
                showToaster(
                  'This toaster has just the title',
                  '',
                  NotificationVariant.SoftSuccess
                );
              }}
            >
              Title Only
            </button>
          </div>
          <div style={{ display: 'grid-row', gap: '5px' }}>
            <button
              type="button"
              onClick={(): void => {
                showToaster(
                  'Test',
                  'Something went successfully',
                  NotificationVariant.SoftSuccess
                );
              }}
            >
              Success
            </button>
            <button
              type="button"
              onClick={(): void => {
                showToaster(
                  'Test',
                  'Something went successfully',
                  NotificationVariant.SoftInfo
                );
              }}
            >
              Info
            </button>
            <button
              type="button"
              onClick={(): void => {
                showToaster(
                  'Test',
                  'Something went successfully',
                  NotificationVariant.SoftWarning
                );
              }}
            >
              Warning
            </button>
            <button
              type="button"
              onClick={(): void => {
                showToaster(
                  'Test',
                  'Something went successfully',
                  NotificationVariant.SoftDanger
                );
              }}
            >
              Danger
            </button>
            <button
              type="button"
              onClick={(): void => {
                showToaster(
                  'Test',
                  'Something went successfully',
                  NotificationVariant.SoftBasic
                );
              }}
            >
              Basic
            </button>
          </div>
        </div>
        <h1>Alerts</h1>
        <div className="button-container">
          <h3>Primary</h3>
          <div style={{ display: 'grid', gap: '10px' }}>
            <NotificationAlert
              title="Some title"
              message="Message description goes here"
              variant={NotificationVariant.PrimarySuccess}
            />
            <NotificationAlert
              title="Some title"
              message="Message description goes here"
              variant={NotificationVariant.PrimaryInfo}
            />
            <NotificationAlert
              title="Some title"
              message="Message description goes here"
              variant={NotificationVariant.PrimaryWarning}
            />
            <NotificationAlert
              title="Some title"
              message="Message description goes here"
              variant={NotificationVariant.PrimaryDanger}
            />
            <NotificationAlert
              title="Some title"
              message="Message description goes here"
              variant={NotificationVariant.PrimaryBasic}
            />
          </div>
          <div />

          <h3>Secondary</h3>
          <div style={{ display: 'grid', gap: '10px' }}>
            <NotificationAlert
              title="Some title"
              message="Message description goes here"
              variant={NotificationVariant.SecondarySuccess}
            />
            <NotificationAlert
              title="Some title"
              message="Message description goes here"
              variant={NotificationVariant.SecondaryInfo}
            />
            <NotificationAlert
              title="Some title"
              message="Message description goes here"
              variant={NotificationVariant.SecondaryWarning}
            />
            <NotificationAlert
              title="Some title"
              message="Message description goes here"
              variant={NotificationVariant.SecondaryDanger}
            />
            <NotificationAlert
              title="Some title"
              message="Message description goes here"
              variant={NotificationVariant.SecondaryBasic}
            />
          </div>
          <div />

          <h3>Soft</h3>
          <div style={{ display: 'grid', gap: '10px' }}>
            <NotificationAlert
              title="Some title"
              message="Message description goes here"
              variant={NotificationVariant.SoftSuccess}
            />
            <NotificationAlert
              title="Some title"
              message="Message description goes here"
              variant={NotificationVariant.SoftInfo}
            />
            <NotificationAlert
              title="Some title"
              message="Message description goes here"
              variant={NotificationVariant.SoftWarning}
            />
            <NotificationAlert
              title="Some title"
              message="Message description goes here"
              variant={NotificationVariant.SoftDanger}
            />
            <NotificationAlert
              title="Some title"
              message="Message description goes here"
              variant={NotificationVariant.SoftBasic}
            />
          </div>
          <div />
        </div>
        <h1>Table</h1>
        <div className="button-container">
          <h3>Default</h3>
          <div>
            <DataTable
              template={columnTemplate}
              rows={tableRows}
              onEdit={(index: number, column: string, value: string): void => {
                // Modify table data
                const temp = [...tableRows];
                const item = temp[index].find((x) => x.key === column); // = value;
                if (item) {
                  item.value = value;
                }
                setTableRows(temp);
              }}
              onRowAdd={addTableRow}
              onRowDelete={(index: number): void => {
                const temp = [...tableRows];
                temp.splice(index, 1);
                setTableRows(temp);
              }}
              settings={settings}
            />
          </div>

          <div>
            <DataTable
              template={columnTemplate}
              rows={tableRows}
              onEdit={(): void => {
                //
              }}
              onRowAdd={(): void => {
                //
              }}
              onRowDelete={(): void => {
                //
              }}
              settings={settings}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StyleSheetPage;
