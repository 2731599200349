enum UserPermission {
  AccessPendingTray = 'AccessPendingTray',
  BillingManagement = 'BillingManagement',
  CaptureDocuments = 'CaptureDocuments',
  ConfigureSystemSettings = 'ConfigureSystemSettings',
  DeleteDocuments = 'DeleteDocuments',
  RecoverDeletedDocuments = 'RecoverDeletedDocuments',
  SearchArchivedDocuments = 'SearchArchivedDocuments',
  ViewAuditLogs = 'ViewAuditLogs',
  ViewDocuments = 'ViewDocuments',
  AccessTrash = 'AccessTrash',
  ViewAllTrashDocuments = 'ViewAllTrashDocuments',
  MigrateData = 'MigrateData',
}

export default UserPermission;
