import { useTranslation } from 'react-i18next';
import KeyPairItem from '../../common/models/keypairItem';
import Tray from '../../common/types/tray';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';

const useDefaultDestinationUser = (currentTab: string): KeyPairItem => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();

  return currentTab === Tray.Public
    ? {
        Value: currentUser.id,
        Name: currentUser.name,
      }
    : {
        Value: 'public',
        Name: t('pendingTrayPage.publicTrayOption'),
      };
};

export default useDefaultDestinationUser;
