import { ReactElement, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import lengths from '../../common/constants/lengths.constant';
import SDCTextField from '../SDCTextField/SDCTextField';
import './EditProfileSidePanel.scss';

interface EditProfileSidePanelProps {
  fullName: string;
  onEditUserProfile: (name: string, hasError: boolean) => void;
}

const EditProfileSidePanel = ({
  fullName,
  onEditUserProfile,
}: EditProfileSidePanelProps): ReactElement => {
  const { t } = useTranslation();
  const [error, setError] = useState(undefined);

  return (
    <div className="edit-profile-side-panel-container">
      <Row>
        <Col md="12" className="title">
          {t('teamManagementScreen.sidePanelProfileTitle')}
        </Col>
        <Col md="12" className="label">
          <span> {t('teamManagementScreen.sidePanelNameTitle')}</span>
        </Col>
        <Col md="12">
          <SDCTextField
            value={fullName}
            onChange={(e): void => {
              const { value } = e.target;
              const hasLengthError = value.length > lengths.name;
              if (hasLengthError) {
                setError(
                  t('validation.textLengthError', { count: lengths.name })
                );
                onEditUserProfile(value, true);
                return;
              }
              const hasMissingError = value?.trim() === '';
              if (hasMissingError) {
                setError(t('validation.textMissingError'));
                onEditUserProfile(value, true);
                return;
              }

              setError(undefined);
              onEditUserProfile(value, false);
            }}
            hasError={error}
            errorMessage={error}
          />
        </Col>
      </Row>
    </div>
  );
};

export default EditProfileSidePanel;
