import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { useAuth } from '../../context/Auth/AuthContext';
import { TenantContext } from '../../context/Tenant/TenantContext';
import { getTenantSetting } from '../../services/tenant/tenant.service';

interface TenantProviderProps {
  children: ReactNode;
}

const TenantProvider = ({ children }: TenantProviderProps): ReactElement => {
  const { authService } = useAuth();
  const [maxFileSizeInBytes, setmaxFileSizeInBytes] = useState<number>(0);
  const [supportedFileTypes, setSupportedFileTypes] = useState<string[]>([]);
  const [accountName, setAccountName] = useState('');

  const getTenant = async (): Promise<void> => {
    const tenantSetting = await getTenantSetting();
    setmaxFileSizeInBytes(tenantSetting.maxFileSizeInBytes);
    setSupportedFileTypes(tenantSetting.supportedFileTypes);
    setAccountName(tenantSetting.accountName);
  };

  useEffect(() => {
    if (!authService.isPending()) getTenant();
  }, [authService]);

  return (
    <TenantContext.Provider
      value={{
        maxFileSizeInBytes,
        supportedFileTypes,
        accountName,
      }}
    >
      {children}
    </TenantContext.Provider>
  );
};

export default TenantProvider;
