import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ReactElement, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../assets/svg/CloseIcon.svg';
import { ReactComponent as DatePickerIcon } from '../../assets/svg/DatePickerIcon.svg';
import accessKeyConstants from '../../common/constants/accessKey.constant';
import KeyPairItem from '../../common/models/keypairItem';
import NotificationVariant from '../../common/types/notificationVariant';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import { createAccessKey } from '../../services/accessKey/accessKey.service';
import CreateAccessKeyRequest from '../../services/accessKey/models/createAccessKeyRequest';
import CreateAccessKeyResponse from '../../services/accessKey/models/createAccessKeyResponse';
import UserSettings from '../../services/user/models/userSettings';
import CopyTextField from '../CopyTextField/CopyTextField';
import LoadingSet from '../LoadingSet/LoadingSet';
import NotificationAlert from '../NotificationAlert/NotifcationAlert';
import SDCButton from '../SDCButton/SDCButton';
import SDCDropdown from '../SDCDropdown/SDCDropdown';
import SDCTextField from '../SDCTextField/SDCTextField';
import './NewAccessKeyModal.scss';

dayjs.extend(utc);

interface NewAccessKeyModalProps {
  show: boolean;
  settings: UserSettings;
  onClose: (id?: string) => void;
}

const NewAccessKeyModal = ({
  show,
  settings,
  onClose,
}: NewAccessKeyModalProps): ReactElement => {
  const { t } = useTranslation();
  const request = {
    displayName: '',
    validUntil: accessKeyConstants.DefaultExpiry,
  } as CreateAccessKeyRequest;

  const expiryOptions: KeyPairItem[] = [
    {
      Name: t('accessKeyPage.sidePanelExpireAfterYear', {
        count: accessKeyConstants.DefaultExpiryPeriod,
      }),
      Value: accessKeyConstants.OptionExpireDefault,
    },
    {
      Name: t('accessKeyPage.optionExpireAfter'),
      Value: accessKeyConstants.OptionExpire,
    },
    {
      Name: t('accessKeyPage.optionNeverExpire'),
      Value: accessKeyConstants.OptionNonExpire,
    },
  ];
  const [selectedOption, setSelectedOption] = useState(expiryOptions[0]);
  const [createResponse, setCreateResponse] =
    useState<CreateAccessKeyResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState<string>();
  const [createDisabled, setCreateDisabled] = useState(true);
  const [modalSize, setModalSize] = useState<'lg' | 'sm' | 'xl' | undefined>();

  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CreateAccessKeyRequest>({ defaultValues: request });

  useEffect(() => {
    register('displayName', {
      required: t('accessKeyPage.errorMissingDisplayName', {
        length: accessKeyConstants.MaxDisplayNameLength,
      }),
      maxLength: {
        value: accessKeyConstants.MaxDisplayNameLength,
        message: t('accessKeyPage.errorTooLong', {
          length: accessKeyConstants.MaxDisplayNameLength,
        }),
      },
    });
    register('validUntil', {
      required: t('accessKeyPage.errorInvalidDate'),
    });
  }, [register, t]);

  const handleButtonSubmit = (item: CreateAccessKeyRequest): void => {
    setIsLoading(true);
    createAccessKey(item)
      .then((response) => {
        setCreateResponse(response);
        setModalSize('lg');
        setId(response.id);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getDefaultDate = (): Date =>
    selectedOption.Value === accessKeyConstants.OptionExpireDefault
      ? accessKeyConstants.DefaultExpiry
      : dayjs().toDate();

  const renderForm = (): ReactElement => {
    if (isLoading) {
      return <LoadingSet size="sm" />;
    }

    return (
      <>
        <SDCTextField
          onTextChange={(value: string): void => {
            setValue('displayName', value);
            setCreateDisabled(value.trim() === '');
          }}
          value={request.displayName}
          hasError={errors.displayName !== undefined}
          errorMessage={errors.displayName?.message}
          errorMessageId="request-display-name"
          placeholder={t('accessKeyPage.placeholderName')}
          labelText={t('accessKeyPage.displayNameLabel')}
        />
        <div className="pb-4" />

        <SDCDropdown
          labelText={t('accessKeyPage.validUntilLabel')}
          currentItem={selectedOption}
          values={expiryOptions}
          onSelect={(item: KeyPairItem): void => {
            setSelectedOption(item);

            if (item.Value === accessKeyConstants.OptionNonExpire) {
              setValue('validUntil', null);
            } else {
              setValue('validUntil', accessKeyConstants.DefaultExpiry);
            }
          }}
        />
        <div className="pb-2" />
        {selectedOption.Value !== accessKeyConstants.OptionNonExpire ? (
          <Controller
            control={control}
            name="validUntil"
            render={({ field }): ReactElement => (
              <div className="date-picker-container">
                {' '}
                <ReactDatePicker
                  className="text-field-input"
                  placeholderText={settings.dateFormat}
                  dateFormat={settings.dateFormat
                    .replace(/D/g, 'd')
                    .replace(/Y/g, 'y')}
                  onChange={(date: Date | null, e: any): void => {
                    field.onChange(e);
                    // Ensure it always gives a date value when set to non-expiring
                    setValue('validUntil', date ?? getDefaultDate());
                  }}
                  selected={field.value ?? getDefaultDate()}
                  filterDate={(date: Date): boolean =>
                    date.getTime() > new Date().getTime()
                  }
                  disabled={
                    selectedOption.Value ===
                    accessKeyConstants.OptionExpireDefault
                  }
                />
                <div className="calendar-icon">
                  <DatePickerIcon />
                </div>
              </div>
            )}
          />
        ) : (
          <NotificationAlert
            title=""
            message={t('accessKeyPage.expiryWarningMessage')}
            variant={NotificationVariant.SecondaryWarning}
          />
        )}
      </>
    );
  };

  const renderAccessKey = (): ReactElement => (
    <>
      <CopyTextField text={createResponse?.accessKey ?? '...'} />
      <div className="pb-4" />
      <NotificationAlert
        title={t('accessKeyPage.accessKeyWarningTitle')}
        message={t('accessKeyPage.accessKeyWarningMessage')}
        variant={NotificationVariant.SecondaryWarning}
      />
      <div className="pb-3" />
      <NotificationAlert
        title={t('accessKeyPage.accessKeyInfoTitle')}
        message={t('accessKeyPage.accessKeyInfoMessage')}
        variant={NotificationVariant.SecondaryInfo}
      />
    </>
  );

  const renderFooter = (): ReactElement => {
    if (isLoading) {
      return <div />;
    }

    if (createResponse === undefined) {
      return (
        <SDCButton
          text={t('accessKeyPage.createButton')}
          onClick={handleSubmit(handleButtonSubmit)}
          variant={SDCButtonVariant.Primary}
          disabled={createDisabled}
        />
      );
    }

    return (
      <div className="pb-2 pt-2">
        <SDCButton
          text={t('accessKeyPage.doneButton')}
          onClick={(): void => onClose(id)}
          variant={SDCButtonVariant.Link}
        />
      </div>
    );
  };

  return (
    <Modal
      dialogClassName="new-access-key-modal"
      show={show}
      size={modalSize}
      onHide={(): void => onClose(id)}
      centered
    >
      <Modal.Header className={`${createResponse ? 'success' : ''}`}>
        <Modal.Title className="user-select-none">
          {createResponse
            ? createResponse.displayName
            : t('accessKeyPage.createModalTitle')}
        </Modal.Title>
        <button
          type="button"
          className="close-button"
          onClick={(): void => onClose(id)}
        >
          <CloseIcon width={22} height={22} />
        </button>
      </Modal.Header>

      <Modal.Body>
        {createResponse === undefined ? renderForm() : renderAccessKey()}
      </Modal.Body>
      <Modal.Footer>{renderFooter()}</Modal.Footer>
    </Modal>
  );
};

export default NewAccessKeyModal;
