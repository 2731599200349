import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import SearchGuideImage from '../../assets/img/search-guide.png';
import { ReactComponent as KeyIcon } from '../../assets/svg/KeyIcon.svg';
import accessKeyConstants from '../../common/constants/accessKey.constant';
import AccessKeyEditType from '../../common/types/accessKeyEditType';
import AllPermissionAccessStatus from '../../common/types/allPermissionAccessStatus';
import CollapserPositionVariant from '../../common/types/collapserPositionVariant';
import NotificationVariant from '../../common/types/notificationVariant';
import PermissionAccessStatus from '../../common/types/permissionAccessStatus';
import PermissionStatus from '../../common/types/permissionStatus';
import SDCButtonSize from '../../common/types/sdcButtonSize';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import UserPermission from '../../common/types/userPermission';
import AccessKeyTable from '../../components/DataGrid/AccessKeyTable/AccessKeyTable';
import DefaultLoading from '../../components/DefaultLoading/DefaultLoading';
import NewAccessKeyModal from '../../components/DefaultModal/NewAccessKeyModal';
import LoadingSet from '../../components/LoadingSet/LoadingSet';
import PaginationNavigator from '../../components/Pagination/PaginationNavigator';
import RestrictedArea from '../../components/RestrictedArea/RestrictedArea';
import SDCButton from '../../components/SDCButton/SDCButton';
import EditAccessKeyDisplayNameSidePanel from '../../components/SidePanel/EditAccessKeyDisplayNameSidePanel';
import EditAccessKeyExpirySidePanel from '../../components/SidePanel/EditAccessKeyExpirySidePanel';
import EditContainerAccessSidePanel from '../../components/SidePanel/EditContainerAccessSidePanel';
import EditDocumentTypeAccessSidePanel from '../../components/SidePanel/EditDocumentTypeAccessSidePanel';
import EditPermissionSidePanel from '../../components/SidePanel/EditPermissionSidePanel';
import SidePanel from '../../components/SidePanel/SidePanel';
import Toaster from '../../components/Toaster/Toaster';
import showToaster from '../../components/Toaster/ToasterProvider';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import {
  getAccessKeyContainerAccess,
  getAccessKeyDocumentTypeAccess,
  getAccessKeyPermissions,
  getAccessKeys,
  updateAccessKey,
  updateAccessKeyContainerAccess,
  updateAccessKeyDocumentTypeAccess,
  updateAccessKeyExpiry,
  updateAccessKeyPermissions,
} from '../../services/accessKey/accessKey.service';
import AccessKey from '../../services/accessKey/models/accessKey';
import AccessKeyAccess from '../../services/accessKey/models/accessKeyAccess';
import { getContainersAsync } from '../../services/container/container.service';
import Container from '../../services/container/models/container';
import { getAllDocumentTypes } from '../../services/documentType/documentType.service';
import DocumentType from '../../services/documentType/models/documentType';
import { AccessDetail } from '../../services/teamManagement/models/userAccessItem';
import userHasPermission from '../../utils/userUtil';
import './AccessKeyPage.scss';

const AccessKeyPage = (): ReactElement => {
  const { t } = useTranslation();
  const handleError = useErrorHandler();
  const { currentUser } = useCurrentUser();
  // Loading control
  const [showLoading, setShowLoading] = useState(true);
  const [showLoadingSidePanel, setShowLoadingSidePanel] = useState(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isShowSidePanel, setIsShowSidePanel] = useState(false);
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [accessKeys, setAccessKeys] = useState<AccessKey[]>([]);
  // Edit Type // Profile, Role, Access, DocumentType
  const [selectedType, setSelectedType] = useState<AccessKeyEditType>(
    AccessKeyEditType.Name
  );
  const [newId, setNewId] = useState<string>();
  const [selectedItem, setSelectedItem] = useState<AccessKey>();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [displayNameToUpdate, setDisplayNameToUpdate] = useState<string>('');
  const [expiryToUpdate, setExpiryToUpdate] = useState<Date | null | undefined>(
    undefined
  );
  // Container
  const [containers, setContainers] = useState<Container[]>([]);
  const [containerAccesses, setContainerAccesses] = useState<AccessDetail[]>(
    []
  );
  const [selectAllContainer, setSelectAllContainer] = useState(false);

  // Document Type
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
  const [documentTypeAccesses, setDocumentTypeAccesses] = useState<
    AccessDetail[]
  >([]);
  const [selectAllDocumentType, setSelectAllDocumentType] = useState(true);

  // Permissions
  const [permissions, setPermissions] = useState<string[]>([]);
  const [showNewAccessKeyModal, setShowNewAccessKeyModal] = useState(false);

  // Original information
  const [originalPermissions, setOriginalPermissions] = useState<string[]>([]);
  let originalContainerStatuses: PermissionAccessStatus[] = [];
  let originalDocumentTypeStatuses: PermissionAccessStatus[] = [];

  const setPermissionStatus = (items: AccessKey[]): AccessKey[] => {
    const allPermissionCount = accessKeyConstants.Permissions.length;
    const getPermissionStatus = (item: AccessKey): PermissionStatus => {
      if (item.permissions.length === allPermissionCount) {
        return PermissionStatus.All;
      }
      if (item.permissions.length === 0) {
        return PermissionStatus.None;
      }
      return PermissionStatus.Restricted;
    };

    return items.map(
      (item: AccessKey): AccessKey => ({
        ...item,
        permissionStatus: getPermissionStatus(item),
      })
    );
  };

  const fetchAccessKeys = useCallback(async () => {
    setShowLoading(true);
    const response = await getAccessKeys(handleError);
    if (response != null) {
      const accessKeysWithStatus = setPermissionStatus(response);
      setAccessKeys(accessKeysWithStatus);
    }
    setShowLoading(false);
  }, [handleError]);

  // Get selected user containers
  const fetchContainerAccess = async (id: string): Promise<void> => {
    setShowLoadingSidePanel(true);
    getAccessKeyContainerAccess(id).then((response) => {
      const { accesses } = response;
      setSelectAllContainer(
        response.permission === AllPermissionAccessStatus.All
      );
      setContainerAccesses(accesses);
      originalContainerStatuses = accesses.map(
        (acc: AccessDetail) => acc.permission
      );
      setShowLoadingSidePanel(false);
    });
  };

  // Get selected access key's document types
  const fetchDocumentTypeAccess = async (id: string): Promise<void> => {
    setShowLoadingSidePanel(true);
    getAccessKeyDocumentTypeAccess(id).then((response) => {
      setSelectAllDocumentType(
        response.permission === AllPermissionAccessStatus.All
      );
      setDocumentTypeAccesses(response.accesses);
      originalDocumentTypeStatuses = response.accesses.map(
        (x: AccessDetail) => x.permission
      );
      setShowLoadingSidePanel(false);
    });
  };

  // Get selected access key's permissions
  const fetchPermissions = async (id: string): Promise<void> => {
    setShowLoadingSidePanel(true);
    getAccessKeyPermissions(id).then((response) => {
      setPermissions(response);
      setOriginalPermissions(response);
      setShowLoadingSidePanel(false);
    });
  };

  const isAccessEqual = (
    hasAccess: AccessDetail[],
    oriHasAccess: PermissionAccessStatus[]
  ): boolean => {
    let checkIsEqual = true;
    hasAccess.forEach((acc, i) => {
      if (acc.permission !== oriHasAccess[i]) {
        checkIsEqual = false;
      }
    });
    return checkIsEqual;
  };

  const checkIfFormIsDirty = (
    status: PermissionStatus,
    selectAll: boolean,
    currentData: AccessDetail[],
    originalData: PermissionAccessStatus[]
  ): void => {
    if (status === PermissionStatus.All) {
      setIsDirty(!selectAll);
    } else {
      setIsDirty(!isAccessEqual(currentData, originalData));
    }
  };

  const onSave = async (): Promise<void> => {
    if (!selectedItem) {
      return;
    }

    setIsProcessing(true);

    let message = '';
    if (selectedType === AccessKeyEditType.Name && displayNameToUpdate) {
      await updateAccessKey({
        accessKeyId: selectedItem.id,
        displayName: displayNameToUpdate,
      });
      message = t('accessKeyPage.successUpdateNotificationMessage');
    } else if (
      selectedType === AccessKeyEditType.Expiry &&
      expiryToUpdate !== undefined
    ) {
      await updateAccessKeyExpiry({
        accessKeyId: selectedItem.id,
        validUntil: expiryToUpdate,
      });
      message = t('accessKeyPage.successUpdateNotificationMessage');
    } else if (selectedType === AccessKeyEditType.Container) {
      const containerAccess: AccessKeyAccess = {
        permission: selectAllContainer
          ? AllPermissionAccessStatus.All
          : AllPermissionAccessStatus.Restricted,
        accesses: containerAccesses,
        accessKeyId: selectedItem.id,
      };
      await updateAccessKeyContainerAccess(containerAccess);
      message = t(
        'accessKeyPage.successUpdateContainerAccessNotificationMessage'
      );
    } else if (selectedType === AccessKeyEditType.DocumentType) {
      const documentTypeAccess: AccessKeyAccess = {
        permission: selectAllDocumentType
          ? AllPermissionAccessStatus.All
          : AllPermissionAccessStatus.Restricted,
        accesses: documentTypeAccesses,
        accessKeyId: selectedItem.id,
      };
      await updateAccessKeyDocumentTypeAccess(documentTypeAccess);
      message = t(
        'accessKeyPage.successUpdateDocumentTypeAccessNotificationMessage'
      );
    } else if (selectedType === AccessKeyEditType.Permission) {
      const updatedPermissions = await updateAccessKeyPermissions(
        selectedItem.id,
        permissions
      );
      setPermissions(updatedPermissions);
      setOriginalPermissions(updatedPermissions);
      message = t('accessKeyPage.successUpdatePermissionNotificationMessage');
    }

    // Post update
    setIsProcessing(false);
    setIsDirty(false);
    fetchAccessKeys();
    showToaster(
      t('accessKeyPage.successUpdateNotificationTitle'),
      message,
      NotificationVariant.PrimarySuccess
    );
  };

  useEffect(() => {
    getContainersAsync(1, 100).then((response) => {
      setContainers(response.results);
    });
    getAllDocumentTypes().then((response) => {
      setDocumentTypes(response);
    });
    fetchAccessKeys();
  }, [fetchAccessKeys]);

  const onSetContainer = (id: string): void => {
    if (!selectedItem) {
      return;
    }

    const newContainers = [...containerAccesses].map((container) => {
      if (container.referenceId === id) {
        // eslint-disable-next-line no-param-reassign
        container.permission =
          container.permission === PermissionAccessStatus.Granted
            ? PermissionAccessStatus.Denied
            : PermissionAccessStatus.Granted;
      }
      return container;
    });
    checkIfFormIsDirty(
      selectedItem.containerPermissionStatus,
      selectAllContainer,
      newContainers,
      originalContainerStatuses
    );
    setContainerAccesses(newContainers);
  };

  const onSetDocumentType = (id: string): void => {
    if (!selectedItem) {
      return;
    }

    const newDocumentTypes = [...documentTypeAccesses].map((docType) => {
      if (docType.referenceId === id) {
        // eslint-disable-next-line no-param-reassign
        docType.permission =
          docType.permission === PermissionAccessStatus.Granted
            ? PermissionAccessStatus.Denied
            : PermissionAccessStatus.Granted;
      }
      return docType;
    });
    checkIfFormIsDirty(
      selectedItem.documentTypePermissionStatus,
      selectAllDocumentType,
      newDocumentTypes,
      originalDocumentTypeStatuses
    );
    setDocumentTypeAccesses(newDocumentTypes);
  };

  const onSelectAllDocumentType = (selectAll: boolean): void => {
    if (!selectedItem) {
      return;
    }

    checkIfFormIsDirty(
      selectedItem.documentTypePermissionStatus,
      selectAll,
      documentTypeAccesses,
      originalDocumentTypeStatuses
    );
    setSelectAllDocumentType(selectAll);
  };

  const onSelectAllContainer = (selectAll: boolean): void => {
    if (!selectedItem) {
      return;
    }

    checkIfFormIsDirty(
      selectedItem.containerPermissionStatus,
      selectAll,
      containerAccesses,
      originalContainerStatuses
    );
    setSelectAllContainer(selectAll);
  };

  const handleDisplaynameChange = (text: string, hasError: boolean): void => {
    if (!selectedItem) {
      return;
    }

    const isChanged = selectedItem.displayName !== text;
    setIsDirty(isChanged && !hasError);
    setDisplayNameToUpdate(text);
  };

  // ----------- Side panel ------------ //
  const sidePanelLoading = (): ReactElement => (
    <div className="loader text-center">
      <LoadingSet size="sm" />
    </div>
  );

  const setSidePanelContent = (): ReactElement => {
    if (!selectedItem) {
      return <></>;
    }

    if (selectedType === AccessKeyEditType.Container) {
      if (showLoadingSidePanel) {
        return sidePanelLoading();
      }
      return (
        <EditContainerAccessSidePanel
          key={selectedItem.id}
          containers={containers}
          userContainers={containerAccesses}
          selectAllContainer={selectAllContainer}
          onSelectAllContainer={onSelectAllContainer}
          onSetContainer={onSetContainer}
        />
      );
    }
    if (selectedType === AccessKeyEditType.DocumentType) {
      if (showLoadingSidePanel) {
        return sidePanelLoading();
      }
      return (
        <EditDocumentTypeAccessSidePanel
          key={selectedItem.id}
          userDocumentTypes={documentTypeAccesses}
          documentTypes={documentTypes}
          selectAllDocumentType={selectAllDocumentType}
          onSelectAllDocumentType={onSelectAllDocumentType}
          onSetDocumentType={onSetDocumentType}
        />
      );
    }
    if (selectedType === AccessKeyEditType.Expiry) {
      return (
        <EditAccessKeyExpirySidePanel
          key={selectedItem.id}
          validUntil={selectedItem.validUntil}
          settings={currentUser.settings}
          onChange={(value: Date | null): void => {
            setExpiryToUpdate(value);
            setIsDirty(selectedItem.validUntil !== value);
          }}
        />
      );
    }
    if (selectedType === AccessKeyEditType.Permission) {
      if (showLoadingSidePanel) {
        return sidePanelLoading();
      }
      return (
        <EditPermissionSidePanel
          key={selectedItem.id}
          permissions={permissions}
          onChange={(values: string[]): void => {
            const before = originalPermissions.sort().join(',');
            const after = values.sort().join(',');
            setIsDirty(before !== after);
            setPermissions(values);
          }}
        />
      );
    }
    return (
      <EditAccessKeyDisplayNameSidePanel
        key={selectedItem.id}
        displayName={selectedItem?.displayName}
        onChange={handleDisplaynameChange}
      />
    );
  };

  const setSidePanel = (
    type: AccessKeyEditType,
    accessKey: AccessKey
  ): void => {
    setSelectedType(type);
    setSelectedItem(accessKey);
    setIsDirty(false);
    setIsShowSidePanel(true);
    setOpenSidePanel(true);
    if (type === AccessKeyEditType.Container) {
      fetchContainerAccess(accessKey.id);
    }
    if (type === AccessKeyEditType.DocumentType) {
      fetchDocumentTypeAccess(accessKey.id);
    }
    if (type === AccessKeyEditType.Permission) {
      fetchPermissions(accessKey.id);
    }
    if (type === AccessKeyEditType.Name || type === AccessKeyEditType.Expiry) {
      setAccessKeys((model) => model);
    }
  };

  const renderNewAccessKeyModal = (): JSX.Element | null => {
    if (!showNewAccessKeyModal) {
      return null;
    }
    return (
      <NewAccessKeyModal
        show={showNewAccessKeyModal}
        onClose={(id?: string): void => {
          setNewId(id);
          setShowNewAccessKeyModal(false);

          if (id !== undefined) {
            fetchAccessKeys();
          }
        }}
        settings={currentUser.settings}
      />
    );
  };

  if (!userHasPermission(currentUser, UserPermission.ConfigureSystemSettings)) {
    return (
      <div className="access-key-page">
        <RestrictedArea />
      </div>
    );
  }

  const renderResults = (): ReactElement => {
    if (!accessKeys.length) {
      return (
        <div className="result-placeholder-container">
          <img src={SearchGuideImage} alt="" />
          <div className="guide-content">
            <div>{t('accessKeyPage.welcomeText')}</div>
            <div className="pt-4">
              <SDCButton
                className="ps-5 pe-5"
                onClick={(): void => {
                  setShowNewAccessKeyModal(true);
                }}
                variant={SDCButtonVariant.Primary}
                text={t('accessKeyPage.createNewAccessKeyButton')}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="pb-2">
          <SDCButton
            onClick={(): void => {
              setShowNewAccessKeyModal(true);
            }}
            variant={SDCButtonVariant.Default}
            size={SDCButtonSize.Small}
            text={t('accessKeyPage.createNewAccessKeyButton')}
            Icon={KeyIcon}
          />
        </div>
        <AccessKeyTable
          items={accessKeys}
          newItemId={newId}
          setSidePanel={setSidePanel}
          onDeletionCompleted={(): void => {
            setShowLoading(false);
            showToaster(
              t('accessKeyPage.successDeleteNotificationTitle'),
              t('accessKeyPage.successDeleteNotificationMessage'),
              NotificationVariant.PrimarySuccess
            );

            fetchAccessKeys();
          }}
          onLoading={(): void => {
            setShowLoading(true);
          }}
        />
        <PaginationNavigator
          pageSize={accessKeys.length}
          lastPage={1}
          currentPage={1}
          totalCount={accessKeys.length}
          onPageChange={(): void => {
            // Do nothing. We show the control but display all items in one page.
          }}
          descriptionTranslationKey="accessKeyPage.paginationDescription"
        />
      </>
    );
  };

  const getSidePanelTitle = (): string => {
    if (selectedType === AccessKeyEditType.Container) {
      return t('accessKeyPage.containerColHeader');
    }
    if (selectedType === AccessKeyEditType.DocumentType) {
      return t('accessKeyPage.documentTypeColHeader');
    }
    if (selectedType === AccessKeyEditType.Permission) {
      return t('accessKeyPage.permissionColHeader');
    }
    if (selectedType === AccessKeyEditType.Expiry) {
      return t('accessKeyPage.expiryColHeader');
    }
    if (selectedType === AccessKeyEditType.Name) {
      return t('accessKeyPage.nameColHeader');
    }
    return t('accessKeyPage.sidePanelTitle');
  };

  if (showLoading) {
    return <DefaultLoading />;
  }

  return (
    <>
      <Toaster />
      {renderNewAccessKeyModal()}
      <div className="access-key-page">
        <div className="access-key-body">{renderResults()}</div>
        {isShowSidePanel && (
          <div className="access-key-side-panel">
            <SidePanel
              collapserPosition={CollapserPositionVariant.WithTitleBar}
              title={`${t(
                'accessKeyPage.sidePanelEdit'
              )} ${getSidePanelTitle()}`}
              footerActionItem={{
                text: t('accessKeyPage.sidePanelSaveButton'),
                onClick: onSave,
                isProcessing,
                show: true,
                disabled: !isDirty,
              }}
              showSidePanel={openSidePanel}
              setShowSidePanel={setOpenSidePanel}
            >
              {setSidePanelContent()}
            </SidePanel>
          </div>
        )}
      </div>
    </>
  );
};

export default AccessKeyPage;
