import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FileIcon } from '../../assets/svg/FileIcon.svg';
import { ReactComponent as ProtectionIcon } from '../../assets/svg/ProtectionIcon.svg';
import getDefaultPageSize from '../../common/configurations/pageConfig';
import KeyPairItem from '../../common/models/keypairItem';
import TrashSearchOptions from '../../common/models/trashSearchOptions';
import CollapserPositionVariant from '../../common/types/collapserPositionVariant';
import ConfirmationModalVariant from '../../common/types/confirmationModalVariant';
import NotificationVariant from '../../common/types/notificationVariant';
import ProtectionStatus from '../../common/types/protectionStatus';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import TaskBarSize from '../../common/types/taskBarSize';
import UserPermission from '../../common/types/userPermission';
import DefaultLoading from '../../components/DefaultLoading/DefaultLoading';
import ConfirmationModal from '../../components/DefaultModal/ConfirmationModal';
import LoadingSet from '../../components/LoadingSet/LoadingSet';
import PaginationNavigator from '../../components/Pagination/PaginationNavigator';
import RestrictedArea from '../../components/RestrictedArea/RestrictedArea';
import SearchBox from '../../components/SearchBox/SearchBox';
import DocumentReadonlySidePanel from '../../components/SidePanel/DocumentReadonlySidePanel';
import Tab from '../../components/Tab/Tab';
import TaskBar from '../../components/TaskBar/TaskBar';
import Toaster from '../../components/Toaster/Toaster';
import showToaster from '../../components/Toaster/ToasterProvider';
import TrashTable from '../../components/TrashTable/TrashTable';
import { useContainer } from '../../context/Container/ContainerContext';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import Metadata from '../../services/capture/models/metadata';
import { downloadArchivedDocuments } from '../../services/document/document.service';
import { getAllDocumentTypes } from '../../services/documentType/documentType.service';
import DocumentType from '../../services/documentType/models/documentType';
import PaginationResponse from '../../services/paginationResponse';
import TrashItem from '../../services/trash/models/trashItem';
import {
  deleteTrashDocuments,
  restoreTrashDocuments,
  searchTrashDocuments,
} from '../../services/trash/trash.service';
import UserFullNameResponse from '../../services/user/models/userFullNameResponse';
import { getUserFullNames } from '../../services/user/user.service';
import buildDocumentTypeDropdownListItems from '../../utils/documentTypeUtil';
import userHasPermission from '../../utils/userUtil';
import './TrashPage.scss';

const TrashPage = (): ReactElement => {
  const { t } = useTranslation();
  const { containerKey } = useContainer();
  const errorHandler = useErrorHandler();
  const { currentUser } = useCurrentUser();
  const defaultPageSize = getDefaultPageSize();
  const firstPage = 1;
  const anyUserId = 'any-user';
  const defaultUserItem: KeyPairItem = {
    Name: t('trashPage.userDropdownTitle'),
    Value: anyUserId,
  };

  // Action trigger
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isDeletingFile, setIsDeletingFile] = useState<boolean>(false);
  const [isRestoringFile, setIsRestoringFile] = useState<boolean>(false);

  // Visible control
  const [showDeletionConfirmationDialog, setShowDeletionConfirmationDialog] =
    useState(false);
  const [
    showRestorationConfirmationDialog,
    setShowRestorationConfirmationDialog,
  ] = useState(false);

  const [trashResponse, setTrashResponse] = useState<
    PaginationResponse<TrashItem>
  >({} as PaginationResponse<TrashItem>);
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
  const documentTypeDropdownDefault = t(
    'trashPage.documentTypeDropdownDefault'
  );
  const deletedDocumentTypeState = t('trashPage.deletedDocumentTypeState');
  const documentTypeDropdownItems: KeyPairItem[] =
    buildDocumentTypeDropdownListItems(
      documentTypeDropdownDefault,
      documentTypes,
      deletedDocumentTypeState
    );

  const [selectedItem, setSelectedItem] = React.useState<File>();
  const [selectedMetadata, setSelectedMetadata] = React.useState<Metadata>({
    templateName: '',
    templateGroupName: '',
    data: [],
  });
  const [document, setDocument] = useState<TrashItem | undefined>(undefined);

  const [documentIdsToDelete, setDocumentIdsToDelete] = useState<string[]>([]);
  const [documentIdsToRestore, setDocumentIdsToRestore] = useState<string[]>(
    []
  );

  const [currentTab, setCurrentTab] = useState<string>(
    ProtectionStatus.Unprotected
  );
  const [unprotectedFileCount, setUnprotectedFileCount] = useState<number>(0);
  const [protectedFileCount, setProtectedFileCount] = useState<number>(0);

  const [users, setUsers] = useState<UserFullNameResponse[]>([]);
  const [searchOptions, setSearchOptions] = useState<TrashSearchOptions>({
    page: firstPage,
    pageSize: defaultPageSize,
    protection: currentTab,
  });
  const [selectedUserItem, setSelectedUserItem] = useState(defaultUserItem);

  // For display total document items count for Unprotected/Protected tab
  const getTotalDocumentCount = useCallback((): void => {
    // Get Unprotected item counts
    searchTrashDocuments(
      {
        protection: ProtectionStatus.Unprotected,
        page: firstPage,
        pageSize: 1,
      },
      containerKey
    ).then((response: PaginationResponse<TrashItem>) => {
      setUnprotectedFileCount(response.totalCount);
    });

    // Get Protected item counts
    searchTrashDocuments(
      {
        protection: ProtectionStatus.Protected,
        page: firstPage,
        pageSize: 1,
      },
      containerKey
    ).then((response: PaginationResponse<TrashItem>) => {
      setProtectedFileCount(response.totalCount);
    });
  }, [containerKey]);

  // Select item for display in side panel
  const handleItemSelect = async (documentItem: TrashItem): Promise<void> => {
    // If select the same one, do nothing
    if (document === documentItem) {
      return;
    }

    if (documentItem.metadata) {
      setSelectedMetadata(documentItem.metadata);
    }
    setDocument(documentItem);
    setSelectedItem(undefined);
  };

  // Content display inside side panel
  const loadFileContent = async (): Promise<void> => {
    if (!document) return;
    setSelectedItem(undefined);
    const fileContent = await downloadArchivedDocuments(
      [document.id],
      errorHandler
    );
    if (fileContent) {
      setSelectedItem(fileContent);
    }
  };

  // Restore documents
  const handleRestoreSelectedDocuments = (): void => {
    setIsRestoringFile(true);

    async function restoreItems(): Promise<void> {
      await restoreTrashDocuments(documentIdsToRestore);
      showToaster(
        t('trashPage.toasterRestoreSuccess'),
        '',
        NotificationVariant.SecondarySuccess
      );
      setSelectedMetadata({} as Metadata);
      setDocument(undefined);
      setSelectedItem(undefined);
      setIsRestoringFile(false);
      setTrashResponse((prev) => ({
        ...prev,
        results: prev.results.filter(
          (x) => !documentIdsToRestore.find((y) => y === x.id)
        ),
        totalCount: prev.totalCount - documentIdsToRestore.length,
      }));
      if (currentTab === ProtectionStatus.Unprotected)
        setUnprotectedFileCount((prev) => prev - documentIdsToRestore.length);
      else setProtectedFileCount((prev) => prev - documentIdsToRestore.length);
      setDocumentIdsToRestore([]);
    }
    restoreItems();
  };

  // Delete documents forever
  const handleDeleteSelectedDocuments = (): void => {
    setIsDeletingFile(true);
    deleteTrashDocuments(documentIdsToDelete)
      .then(() => {
        showToaster(
          t('trashPage.toasterDeleteSuccess'),
          '',
          NotificationVariant.SecondaryInfo
        );
        setSelectedMetadata({} as Metadata);
        setDocument(undefined);
        setSelectedItem(undefined);
        setTrashResponse((prev) => ({
          ...prev,
          results: prev.results.filter(
            (x) => !documentIdsToDelete.find((y) => y === x.id)
          ),
          totalCount: prev.totalCount - documentIdsToDelete.length,
        }));
        if (currentTab === ProtectionStatus.Unprotected)
          setUnprotectedFileCount((prev) => prev - documentIdsToDelete.length);
        else setProtectedFileCount((prev) => prev - documentIdsToDelete.length);
      })
      .finally(() => {
        setIsDeletingFile(false);
        setDocumentIdsToDelete([]);
      });
  };

  // On tab change Unprotected/Protected
  const handleTabChange = (tabName: string): void => {
    if (isSearching) {
      return;
    }
    const newOptions: TrashSearchOptions = {
      ...searchOptions,
      protection: tabName,
    };
    setCurrentTab(tabName);
    setDocument(undefined);
    setSearchOptions(newOptions);
  };

  useEffect(() => {
    let ignore = false;
    const abortController = new AbortController();

    setIsSearching(true);

    searchTrashDocuments(
      searchOptions,
      containerKey,
      abortController.signal,
      errorHandler
    )
      .then((response) => {
        if (response && !ignore) {
          setTrashResponse(response);
        }
      })
      .finally(() => {
        setIsSearching(false);
      });

    getTotalDocumentCount();

    return (): void => {
      abortController.abort();
      ignore = true;
    };
  }, [errorHandler, getTotalDocumentCount, searchOptions, containerKey]);

  useEffect(() => {
    // Load document types
    getAllDocumentTypes().then((response) => {
      setDocumentTypes(response);
    });
    // Load users
    getUserFullNames().then((response) => {
      setUsers(response);
    });
  }, [documentTypeDropdownDefault]);

  const renderTable = (): ReactElement => (
    <>
      <TrashTable
        documentTypes={documentTypes}
        settings={currentUser.settings}
        trashItems={trashResponse.results || []}
        users={users}
        selectedUserItem={selectedUserItem}
        setSelectedUserItem={setSelectedUserItem}
        isLoading={isSearching}
        onDelete={(ids: string[]): void => {
          if (ids.length === 0) {
            return;
          }
          setDocumentIdsToDelete(ids);
          setShowDeletionConfirmationDialog(true);
        }}
        protection={currentTab as ProtectionStatus}
        onRestore={(ids: string[]): void => {
          if (ids.length === 0) {
            return;
          }
          setDocumentIdsToRestore(ids);
          setShowRestorationConfirmationDialog(true);
        }}
        onUserFilterChange={(userId: string): void => {
          const options: TrashSearchOptions = {
            ...searchOptions,
            deletedByUserId: userId === anyUserId ? undefined : userId,
          };
          setSearchOptions(options);
        }}
        onItemSelect={handleItemSelect}
      />
      <PaginationNavigator
        currentPage={searchOptions.page}
        lastPage={trashResponse.lastPage}
        totalCount={trashResponse.totalCount}
        pageSize={searchOptions.pageSize}
        onPageChange={(page: number): void => {
          const newOptions = { ...searchOptions, page };
          setSearchOptions(newOptions);
        }}
        descriptionTranslationKey="trashPage.paginationDescription"
      />
    </>
  );

  if (
    !userHasPermission(currentUser, UserPermission.DeleteDocuments) &&
    !userHasPermission(currentUser, UserPermission.RecoverDeletedDocuments)
  ) {
    return (
      <div className="trash-container">
        <RestrictedArea />
      </div>
    );
  }

  return (
    <>
      {isDeletingFile && (
        <DefaultLoading
          progressTranslationKey="defaultLoadingComponent.deleting"
          successTranslationKey="defaultLoadingComponent.deleteSuccess"
          errorTranslationKey="defaultLoadingComponent.deleteError"
        />
      )}
      {isRestoringFile && (
        <DefaultLoading
          progressTranslationKey="defaultLoadingComponent.restoring"
          successTranslationKey="defaultLoadingComponent.restoreSuccess"
          errorTranslationKey="defaultLoadingComponent.restoreError"
        />
      )}
      <Toaster />
      <div className="trash-container">
        <TaskBar
          headerPrefix=""
          size={TaskBarSize.Default}
          isShowButton={SDCButtonVariant.Secondary}
          isHaveSideNavBar
          isSubNavbar
        >
          <Tab
            items={[
              {
                text: t('trashPage.tabUnprotected'),
                value: ProtectionStatus.Unprotected,
                badgeText: `${unprotectedFileCount}`,
                Icon: FileIcon,
              },
              {
                text: t('trashPage.tabProtected'),
                value: ProtectionStatus.Protected,
                badgeText: `${protectedFileCount}`,
                Icon: ProtectionIcon,
              },
            ]}
            currentTabValue={currentTab}
            onChange={(tab: string | undefined): void => {
              if (tab) {
                handleTabChange(tab);
              }
            }}
          />
        </TaskBar>
        <div className="view-container">
          <div className="main-content">
            <div className="result-container">
              <SearchBox
                documentTypeItems={documentTypeDropdownItems}
                onSearch={(
                  searchText: string,
                  option: KeyPairItem | undefined
                ): void => {
                  const newOptions = {
                    ...searchOptions,
                    searchText,
                    documentType: option?.Value,
                  };
                  setSearchOptions(newOptions);
                }}
              />
              {!isSearching && (
                <div className="result-description">
                  {trashResponse.totalCount === 0
                    ? t('trashPage.resultDescriptionNotFound')
                    : t('trashPage.resultDescription', {
                        count: trashResponse.totalCount,
                      })}
                </div>
              )}
              {isSearching ? <LoadingSet /> : renderTable()}
            </div>
            <DocumentReadonlySidePanel
              loadFileContent={loadFileContent}
              file={selectedItem}
              id={document ? document.id : ''}
              document={document}
              documentTypes={documentTypes}
              metadata={selectedMetadata}
              settings={currentUser.settings}
              collapserPosition={
                CollapserPositionVariant.WithTitleBarAndTaskBar
              }
            />
          </div>
        </div>
      </div>
      <ConfirmationModal
        title={t('confirmationModal.deleteDocumentForeverDialogTitle')}
        bodyText={t('confirmationModal.deleteDocumentForeverDialogBody')}
        show={showDeletionConfirmationDialog}
        setShow={setShowDeletionConfirmationDialog}
        onAnswer={(isConfimred: boolean): void => {
          if (!isConfimred) {
            return;
          }
          handleDeleteSelectedDocuments();
        }}
        primaryButtonText={t('confirmationModal.yesDeleteButton')}
        dismissButtonText={t('confirmationModal.NoButton')}
        variant={ConfirmationModalVariant.SecondaryWarning}
      />
      <ConfirmationModal
        title={t('confirmationModal.restoreDocumentDialogTitle')}
        bodyText=""
        show={showRestorationConfirmationDialog}
        setShow={setShowRestorationConfirmationDialog}
        onAnswer={(isConfimred: boolean): void => {
          if (!isConfimred) {
            return;
          }
          handleRestoreSelectedDocuments();
        }}
        primaryButtonText={t('confirmationModal.yesRestoreButton')}
        dismissButtonText={t('confirmationModal.NoButton')}
        variant={ConfirmationModalVariant.SecondaryWarning}
      />
    </>
  );
};

export default TrashPage;
