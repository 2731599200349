/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import SearchNotFoundImage from '../../assets/img/search-not-found.png';
import { ReactComponent as UploadCloudIcon } from '../../assets/svg/UploadCloudIcon.svg';
import DocumentSearchOptions from '../../common/models/documentSearchOptions';
import KeyPairItem from '../../common/models/keypairItem';
import UploadFileWithStatus from '../../common/models/uploadFileWithStatus';
import NotificationVariant from '../../common/types/notificationVariant';
import PendingTrayFileProgressTracking from '../../common/types/pendingTrayFileProgressTracking';
import PendingTrayFileState from '../../common/types/pendingTrayFileState';
import PendingTrayFileStatus from '../../common/types/pendingTrayFileStatus';
import { useTenant } from '../../context/Tenant/TenantContext';
import DocumentType from '../../services/documentType/models/documentType';
import PaginationResponse from '../../services/paginationResponse';
import PendingTrayItemResponse from '../../services/pendingTray/models/pendingTrayItemResponse';
import {
  formatFileSetToHaveStatus,
  getFileExtension,
} from '../../utils/fileUtil';
import BlankDropzone from '../BlankDropzone/BlankDropzone';
import LoadingSet from '../LoadingSet/LoadingSet';
import PendingTrayActionPane from '../PendingTrayActionPane/PendingTrayActionPane';
import PendingTrayDeletionModal from '../PendingTrayDeletionModal/PendingTrayDeletionModal';
import PendingTrayDocumentMovingModal from '../PendingTrayDocumentMovingModal/PendingTrayDocumentMovingModal';
import PendingTrayArchivedStatusCard from '../PendingTrayStatusCard/PendingTrayArchivedStatusCard';
import PendingTrayProcessingStatusCard from '../PendingTrayStatusCard/PendingTrayProcessingStatusCard';
import PendingTrayTimeoutStatusCard from '../PendingTrayStatusCard/PendingTrayTimeoutStatusCard';
import ResultPendingTrayListItem from '../ResultPendingTrayListItem/ResultPendingTrayListItem';
import SearchBox from '../SearchBox/SearchBox';
import showToaster from '../Toaster/ToasterProvider';
import './ResultPendingTrayList.scss';

interface ResultPendingTrayListProps {
  itemResult: PaginationResponse<PendingTrayItemResponse>;
  onItemClick: (item: PendingTrayItemResponse) => void;
  documentTypes: DocumentType[];
  searchOptions: DocumentSearchOptions;
  setSearchOptions: React.Dispatch<React.SetStateAction<DocumentSearchOptions>>;
  isSearchingItem: boolean;
  scope: string;
  loadPendingTray: (withLoadingScreen: boolean) => void;
  setIsDeletingFile: (isDeletingFile: boolean) => void;
  setIsMovingFile: (isMovingFile: boolean) => void;
  checkedDocumentIds: Array<string>;
  setCheckedDocumentIds: (checkedDocumentIds: Array<string>) => void;
  selectedDocumentId: string;
  setSelectedDocumentId: (selectedDocumentId: string) => void;
  fileProgressTrackings: Array<PendingTrayFileProgressTracking>;
  setFileProgressTrackings: React.Dispatch<
    React.SetStateAction<Array<PendingTrayFileProgressTracking>>
  >;
  selectedUserDestination: KeyPairItem;
  setSelectedUserDestination: React.Dispatch<React.SetStateAction<KeyPairItem>>;
  setFiles: React.Dispatch<React.SetStateAction<Array<UploadFileWithStatus>>>;
}

const ResultPendingTrayList = ({
  itemResult,
  onItemClick,
  documentTypes,
  searchOptions,
  setSearchOptions,
  isSearchingItem,
  scope,
  loadPendingTray,
  setIsDeletingFile,
  setIsMovingFile,
  checkedDocumentIds,
  setCheckedDocumentIds,
  selectedDocumentId,
  setSelectedDocumentId,
  fileProgressTrackings,
  setFileProgressTrackings,
  selectedUserDestination,
  setSelectedUserDestination,
  setFiles,
}: ResultPendingTrayListProps): ReactElement => {
  const { t } = useTranslation();
  const allDocumentType = t('documentsViewScreen.documentTypeDropDownDefault');
  const [documentTypeItems, setDocumentTypeItems] = useState<KeyPairItem[]>([]);
  const [searched, setSearched] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [idsToDelete, setIdsToDelete] = useState<string[]>([]);
  const [isDocumentMovingModalVisible, setIsDocumentMovingModalVisible] =
    useState(false);

  const { supportedFileTypes } = useTenant();

  const {
    getRootProps,
    getInputProps,
    open,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: supportedFileTypes,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length) {
        setFiles((currentFiles) =>
          formatFileSetToHaveStatus(acceptedFiles, currentFiles, false)
        );
      }
    },
    // Ignore rejected files and notify the user about them
    onDropRejected: (rejectedFile: FileRejection[]) => {
      const extensions = rejectedFile.map((x: FileRejection) =>
        getFileExtension(x.file.name)
      );
      const uniques = [...new Set(extensions)];
      showToaster(
        t('dropzoneComponent.rejectFileTitle'),
        t('dropzoneComponent.rejectFileMessage', {
          count: uniques.length,
          extension: uniques.join(', '),
        }),
        NotificationVariant.SoftDanger
      );
    },
  });

  useEffect(() => {
    if (documentTypes) {
      const defaultDocumentType: KeyPairItem = {
        Name: allDocumentType,
        Value: '',
      };
      const listItems = documentTypes.map((item: DocumentType) => ({
        Name: item.displayName,
        Value: item.name,
      }));
      setDocumentTypeItems([defaultDocumentType, ...listItems]);
    }
  }, [documentTypes, allDocumentType]);

  const handleCheckChange = (document: PendingTrayItemResponse): void => {
    const { id } = document;
    const foundIndex = checkedDocumentIds.indexOf(id);
    const unchecked = foundIndex === -1;

    if (unchecked) {
      setCheckedDocumentIds([...checkedDocumentIds, id]);
    } else {
      const temp = [...checkedDocumentIds];
      temp.splice(foundIndex, 1);
      setCheckedDocumentIds(temp);
    }
  };

  const handleItemClick = (document: PendingTrayItemResponse): void => {
    onItemClick(document);
  };

  const renderStatusCard = (
    item: PendingTrayFileProgressTracking
  ): ReactElement => {
    const MaximumTrackingCounter = 20;

    if (item.scope.toLowerCase() !== scope.toLowerCase()) return <></>;

    if (item.counter > MaximumTrackingCounter)
      return (
        <PendingTrayTimeoutStatusCard
          key={item.id}
          id={item.id}
          fileName={item.name}
          setFileProgressTrackings={setFileProgressTrackings}
        />
      );

    if (
      item.status === PendingTrayFileStatus.Done &&
      item.state === PendingTrayFileState.Archived
    ) {
      return (
        <PendingTrayArchivedStatusCard
          key={item.id}
          id={item.id}
          fileName={item.name}
          setFileProgressTrackings={setFileProgressTrackings}
        />
      );
    }
    return (
      <PendingTrayProcessingStatusCard key={item.id} fileName={item.name} />
    );
  };

  const renderItems = (): JSX.Element => {
    if (isDragAccept || isDragReject) {
      return (
        <BlankDropzone open={open} isFocused={isDragAccept || isDragReject} />
      );
    }
    if (itemResult.totalCount === 0 && fileProgressTrackings.length === 0) {
      if (searched) {
        return (
          <>
            <div className="result-placeholder-container">
              <img src={SearchNotFoundImage} alt="" />
              <div className="not-found-content">
                {t('pendingTrayPage.noSearchResults')}
              </div>
            </div>
            <div className="simple-result-placeholder-container">
              {t('pendingTrayPage.noSearchResults')}
            </div>
          </>
        );
      }

      return (
        <>
          <div className="result-placeholder-container">
            <UploadCloudIcon />
            <div className="guide-content">
              {t('pendingTrayPage.noResults')}
            </div>
          </div>
          <div className="simple-result-placeholder-container">
            {t('pendingTrayPage.noResults')}
          </div>
        </>
      );
    }

    return (
      <>
        {fileProgressTrackings.map((item: PendingTrayFileProgressTracking) =>
          renderStatusCard(item)
        )}
        {itemResult.results?.map((item: PendingTrayItemResponse) => (
          <ResultPendingTrayListItem
            key={item.id}
            date={item.modifiedOn}
            subtitle={
              documentTypes.find((x) => x.name === item.documentType)
                ?.displayName
            }
            title={item.fileName}
            authorName={item.createdBy}
            onClick={(): void => handleItemClick(item)}
            onCheckChange={(): void => handleCheckChange(item)}
            selected={item.id === selectedDocumentId}
            checked={checkedDocumentIds.includes(item.id)}
          />
        ))}
      </>
    );
  };

  return (
    <>
      <div className="result-pending-tray-container user-select-none">
        <div className="mb-3">
          <SearchBox
            documentTypeItems={documentTypeItems}
            onSearch={(text: string, item: KeyPairItem | undefined): void => {
              setSearchOptions((prevOptions) => ({
                ...prevOptions,
                text,
                scope,
                documentType:
                  item?.Name === allDocumentType ? undefined : item?.Value,
              }));
              setSelectedDocumentId('');
              setSearched(true);
            }}
          />
        </div>
        <PendingTrayActionPane
          itemResult={itemResult}
          searchOptions={searchOptions}
          setSearchOptions={setSearchOptions}
          checkedDocumentIds={checkedDocumentIds}
          setCheckedDocumentIds={setCheckedDocumentIds}
          setSelectedDocumentId={setSelectedDocumentId}
          setIsDocumentMovingModalVisible={(value: boolean): void =>
            setIsDocumentMovingModalVisible(value)
          }
          setShowDeleteConfirmationModal={(value: boolean): void =>
            setShowDeleteConfirmationModal(value)
          }
          setIdsToDelete={(value: Array<string>): void => setIdsToDelete(value)}
        />
        <div className="dropzone-container">
          <div
            {...getRootProps({
              className: `dropzone${
                isFocused || isDragAccept || isDragReject ? ' drag-over' : ''
              }`,
            })}
          >
            {isSearchingItem ? <LoadingSet /> : renderItems()}
            <input
              data-testid="file-uploader"
              {...getInputProps({
                accept: supportedFileTypes.join(','),
              })}
            />
          </div>
        </div>
      </div>
      <PendingTrayDocumentMovingModal
        show={isDocumentMovingModalVisible}
        setShow={setIsDocumentMovingModalVisible}
        selectedUserDestination={selectedUserDestination}
        setSelectedUserDestination={setSelectedUserDestination}
        scope={scope}
        checkedDocumentIds={checkedDocumentIds}
        setCheckedDocumentIds={setCheckedDocumentIds}
        setIsMovingFile={setIsMovingFile}
        loadPendingTray={loadPendingTray}
      />
      <PendingTrayDeletionModal
        idsToDelete={idsToDelete}
        setCheckedDocumentIds={setCheckedDocumentIds}
        showDeleteConfirmationModal={showDeleteConfirmationModal}
        setShowDeleteConfirmationModal={setShowDeleteConfirmationModal}
        loadPendingTray={loadPendingTray}
        setIsDeletingFile={setIsDeletingFile}
      />
    </>
  );
};

export default ResultPendingTrayList;
