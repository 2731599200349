import { ReactElement, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ThumbnailPlaceholderIcon } from '../../assets/svg/ThumbnailPlaceholderIcon.svg';
import './DocumentFilePreviewer.scss';

interface DocumentFilePreviewerProps {
  imageData: string;
  onFullViewClick: () => void;
}

const DocumentFilePreviewer = ({
  imageData,
  onFullViewClick,
}: DocumentFilePreviewerProps): ReactElement => {
  const { t } = useTranslation();

  const [width, setWidth] = useState(0);
  const divRef = useRef(null);

  useEffect(() => {
    if (divRef.current) {
      const observer = new ResizeObserver((entries) => {
        const newWidth = entries[0].contentRect.width;
        if (newWidth !== width) {
          setWidth(newWidth);
        }
      });
      observer.observe(divRef.current);
      return (): void => observer.disconnect();
    }

    return (): void => {};
  }, [divRef, width]);

  const renderPreview = (): ReactElement =>
    imageData ? (
      <div
        className="previewer"
        style={{
          height: `${width}px`,
          width: `${width}px`,
        }}
        data-hj-suppress
      >
        <img
          style={{
            maxHeight: `${width}px`,
            maxWidth: `${width}px`,
          }}
          width="auto"
          height="auto"
          src={imageData}
          alt=""
        />
      </div>
    ) : (
      <div className="previewer">
        <ThumbnailPlaceholderIcon width={width} height={width} />
      </div>
    );

  return (
    <div className="document-file-previewer-container" ref={divRef}>
      {renderPreview()}
      <div className="actions">
        <Button
          className="shadow-none"
          variant="link"
          onClick={(e): void => {
            e.preventDefault();
            onFullViewClick();
          }}
        >
          {t('documentFilePreviewer.viewButton')}
        </Button>
      </div>
    </div>
  );
};

export default DocumentFilePreviewer;
