import { ReactElement } from 'react';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/svg/Logo.svg';
import { ReactComponent as LogoSmall } from '../../../assets/svg/LogoSmall.svg';
import isTabletSize from '../../../utils/responsiveUtil';
import './BrandLogo.scss';

const BrandLogo = (): ReactElement => (
  <Navbar.Brand as={Link} to="/" className="logo m-0">
    {isTabletSize() ? <LogoSmall /> : <Logo />}
  </Navbar.Brand>
);

export default BrandLogo;
