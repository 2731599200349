import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import KeyPairItem from '../../common/models/keypairItem';
import CollapserPositionVariant from '../../common/types/collapserPositionVariant';
import UserPermission from '../../common/types/userPermission';
import './AccessDescriptionSidePanel.scss';
import SidePanel from './SidePanel';

const permissions = Object.values(UserPermission);

interface AccessDescriptionSidePanelProps {
  collapserPosition: CollapserPositionVariant;
  userPermissions: UserPermission[];
  userRole: string;
}

const AccessDescriptionSidePanel = ({
  collapserPosition,
  userPermissions,
  userRole,
}: AccessDescriptionSidePanelProps): ReactElement => {
  const { t } = useTranslation();
  const granted = permissions.filter((x) => userPermissions.includes(x));
  const denied = permissions.filter((x) => !userPermissions.includes(x));
  const [show, setShow] = useState(true);

  const getTranslation = (value: UserPermission): string =>
    t(`accessDescription.permission${value}`);
  const rolesTranslation: KeyPairItem[] = [
    { Value: 'Administrator', Name: t('teamManagementScreen.roleAdmin') },
    { Value: 'SuperUser', Name: t('teamManagementScreen.roleSuperUser') },
    {
      Value: 'CaptureAndView',
      Name: t('teamManagementScreen.roleCaptureAndView'),
    },
    { Value: 'View', Name: t('teamManagementScreen.roleView') },
    { Value: 'Owner', Name: t('teamManagementScreen.roleOwner') },
  ];

  return (
    <SidePanel
      collapserPosition={collapserPosition}
      showSidePanel={show}
      setShowSidePanel={setShow}
    >
      <div className="access-description-side-panel">
        <div className="role">
          {t('accessDescription.role', {
            role: rolesTranslation.find((x) => x.Value === userRole)?.Name,
          })}
        </div>
        {granted.length > 0 ? (
          <>
            <div>{t('accessDescription.can')}</div>
            <ul>
              {granted.map((value: UserPermission) => (
                <li key={value}>{getTranslation(value)}</li>
              ))}
            </ul>
          </>
        ) : null}

        {denied.length > 0 ? (
          <>
            <div>{t('accessDescription.cannot')}</div>
            <ul>
              {denied.map((value: UserPermission) => (
                <li key={value}>{getTranslation(value)}</li>
              ))}
            </ul>
          </>
        ) : null}
      </div>
    </SidePanel>
  );
};

export default AccessDescriptionSidePanel;
