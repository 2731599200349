import { ReactElement } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../assets/svg/BuildingIcon.svg';
import { ReactComponent as CloudCheckIcon } from '../../assets/svg/CloudCheckIcon.svg';
import { ReactComponent as DocumentIcon } from '../../assets/svg/DocumentIcon.svg';
import { ReactComponent as ExitIcon } from '../../assets/svg/ExitIcon.svg';
import { ReactComponent as KeyLargeIcon } from '../../assets/svg/KeyLargeIcon.svg';
import { ReactComponent as ListIcon } from '../../assets/svg/ListIcon.svg';
import { ReactComponent as PendingTrayIcon } from '../../assets/svg/PendingTrayIcon.svg';
import { ReactComponent as ProtectionIcon } from '../../assets/svg/ProtectionIcon.svg';
import { ReactComponent as SearchMenuIcon } from '../../assets/svg/SearchMenuIcon.svg';
import LocationProvider from '../../common/providers/locationProvider';
import UserPermission from '../../common/types/userPermission';
import { useAuth } from '../../context/Auth/AuthContext';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import { useTenant } from '../../context/Tenant/TenantContext';
import userHasPermission from '../../utils/userUtil';
import './MenuOptions.scss';

interface MenuOptionsProps {
  displayName: string;
  userRole: string;
}

const MenuOptions = ({
  displayName,
  userRole,
}: MenuOptionsProps): ReactElement => {
  const locationProvider = new LocationProvider();
  const { currentUser } = useCurrentUser();
  const { t } = useTranslation();
  const { authService } = useAuth();
  const { accountName } = useTenant();

  const logout = async (): Promise<void> => {
    const redirectUrl = await authService.logout();
    locationProvider.replace(redirectUrl);
  };

  return (
    <span>
      <NavDropdown
        title={
          <>
            <ListIcon /> Menu
          </>
        }
        className="menu-options"
      >
        <NavDropdown.Item className="dropdown-section">
          <div>
            {displayName} <div className="role">{userRole}</div>
          </div>
        </NavDropdown.Item>
        <NavDropdown.Item className="dropdown-section">
          <BuildingIcon />
          {accountName}
        </NavDropdown.Item>
        <NavDropdown.Divider />
        {userHasPermission(
          currentUser,
          UserPermission.SearchArchivedDocuments
        ) ? (
          <NavDropdown.Item eventKey="/search" href="/search">
            <SearchMenuIcon />
            {t('mainMenu.documentSearchMenuItem')}
          </NavDropdown.Item>
        ) : null}
        {userHasPermission(currentUser, UserPermission.AccessPendingTray) ? (
          <NavDropdown.Item eventKey="/pending-tray" href="/pending-tray">
            <PendingTrayIcon />
            {t('mainMenu.pendingTrayMenuItem')}
          </NavDropdown.Item>
        ) : null}
        <NavDropdown.Item className="dropdown-section">
          {t('mainMenu.settingMenuSection')}
        </NavDropdown.Item>
        <NavDropdown.Divider />
        {userHasPermission(
          currentUser,
          UserPermission.ConfigureSystemSettings
        ) ? (
          <NavDropdown.Item href="/document-protection">
            <ProtectionIcon />
            {t('mainMenu.documentProtectionMenuItem')}
          </NavDropdown.Item>
        ) : null}
        {userHasPermission(
          currentUser,
          UserPermission.ConfigureSystemSettings
        ) ? (
          <NavDropdown.Item href="/team-management">
            <CloudCheckIcon />
            {t('mainMenu.teamManagementMenuItem')}
          </NavDropdown.Item>
        ) : null}
        {userHasPermission(currentUser, UserPermission.DeleteDocuments) ||
        userHasPermission(
          currentUser,
          UserPermission.RecoverDeletedDocuments
        ) ? (
          <NavDropdown.Item href="/trash">
            <CloudCheckIcon />
            {t('mainMenu.trashMenuItem')}
          </NavDropdown.Item>
        ) : null}
        {userHasPermission(
          currentUser,
          UserPermission.ConfigureSystemSettings
        ) ? (
          <NavDropdown.Item href="/container-management">
            <CloudCheckIcon />
            {t('mainMenu.containerManagementMenuItem')}
          </NavDropdown.Item>
        ) : null}
        {userHasPermission(
          currentUser,
          UserPermission.ConfigureSystemSettings
        ) ? (
          <NavDropdown.Item href="/api-access">
            <KeyLargeIcon />
            {t('mainMenu.apiAccessMenuItem')}
          </NavDropdown.Item>
        ) : null}
        <NavDropdown.Divider />
        <NavDropdown.Item>
          <DocumentIcon />
          {t('mainMenu.terms')}
        </NavDropdown.Item>
        <NavDropdown.Item>
          <DocumentIcon />
          {t('mainMenu.privacy')}
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={logout}>
          <ExitIcon />
          {t('mainMenu.signOutMenuItem')}
        </NavDropdown.Item>
      </NavDropdown>
    </span>
  );
};

export default MenuOptions;
