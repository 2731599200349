import { QRCodeSVG } from 'qrcode.react';
import { MutableRefObject, ReactElement, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Code from '../../common/models/code';
import CodeSetting from '../../common/models/codeSetting';
import './QRCodePreviewer.scss';

interface QRCodePreviewerProps {
  qrCode: Code | undefined;
  qrCodeRef: MutableRefObject<HTMLDivElement | null>;
  setting: CodeSetting;
}

const QRCodePreviewer = ({
  qrCode,
  qrCodeRef,
  setting,
}: QRCodePreviewerProps): ReactElement => {
  const defaultSize = 200;
  const xmlns = 'http://www.w3.org/2000/svg';
  const descriptionFontStyle = 'font:bold 20px monospace';
  const descriptionTextAnchor = 'middle';
  const { t } = useTranslation();
  const qrCodeValue = qrCode?.value ?? '';
  const qrCodeDescription = qrCode?.description ?? '';
  const descriptionCharactersCountLimitPerLine = 17;
  const descriptionCharactersCountTotalLimit = 34;

  const createTextElement = useCallback(
    (textContent: string, x: number, y: number): SVGTextElement => {
      const descriptionElement = document.createElementNS(xmlns, 'text');
      descriptionElement.setAttribute('style', descriptionFontStyle);
      descriptionElement.setAttribute('text-anchor', descriptionTextAnchor);
      descriptionElement.setAttribute('x', x.toString());
      descriptionElement.setAttribute('y', y.toString());
      descriptionElement.textContent = textContent;
      return descriptionElement;
    },
    []
  );

  const elementExists = useCallback(
    (
      parent: SVGGraphicsElement,
      searchTag: string,
      searchInnerText: string | undefined
    ): boolean => {
      if (searchInnerText)
        return (
          [...parent.getElementsByTagName(searchTag)].filter(
            (a) => a.textContent && a.textContent.includes(searchInnerText)
          ).length > 0
        );
      return parent.getElementsByTagName(searchTag).length > 0;
    },
    []
  );

  const removeElement = useCallback(
    (
      parent: SVGGraphicsElement,
      removedTag: string,
      innerText: string
    ): void => {
      [...parent.getElementsByTagName(removedTag)]
        .filter((a) => a.textContent && a.textContent.includes(innerText))
        .forEach((a) => parent.removeChild(a));
    },
    []
  );

  const getFirstLineOfQRCodeDescription = useCallback(
    (): string =>
      qrCodeDescription.substring(0, descriptionCharactersCountLimitPerLine),
    [qrCodeDescription]
  );

  const getSecondLineOfQRCodeDescription = useCallback(
    (): string =>
      qrCodeDescription.substring(
        descriptionCharactersCountLimitPerLine,
        descriptionCharactersCountTotalLimit
      ),
    [qrCodeDescription]
  );

  const addLabelTexts = useCallback((): void => {
    const divElement = qrCodeRef.current as HTMLDivElement;
    const svgElement = divElement.getElementsByTagName(
      'svg'
    )[0] as SVGGraphicsElement;
    if (svgElement) {
      if (
        setting.isDisplayCodeText &&
        !elementExists(svgElement, 'text', qrCodeValue)
      ) {
        const qrCodeValueElement = createTextElement(qrCodeValue, 100, 195);
        svgElement.appendChild(qrCodeValueElement);
      } else if (!setting.isDisplayCodeText) {
        removeElement(svgElement, 'text', qrCodeValue);
      }
      const firstLineOfQRCodeDescription = getFirstLineOfQRCodeDescription();
      const secondLineOfQRCodeDescription = getSecondLineOfQRCodeDescription();
      if (
        setting.isDisplayCodeDescription &&
        !elementExists(svgElement, 'text', firstLineOfQRCodeDescription)
      ) {
        const firstQRCodeDescriptionElement = createTextElement(
          firstLineOfQRCodeDescription,
          100,
          15
        );
        svgElement.appendChild(firstQRCodeDescriptionElement);
      } else if (!setting.isDisplayCodeDescription) {
        removeElement(svgElement, 'text', firstLineOfQRCodeDescription);
      }

      if (
        setting.isDisplayCodeDescription &&
        !elementExists(svgElement, 'text', secondLineOfQRCodeDescription)
      ) {
        const secondQRCodeDescriptionElement = createTextElement(
          secondLineOfQRCodeDescription,
          100,
          30
        );
        svgElement.appendChild(secondQRCodeDescriptionElement);
      } else if (!setting.isDisplayCodeDescription) {
        removeElement(svgElement, 'text', secondLineOfQRCodeDescription);
      }
    }
  }, [
    qrCodeRef,
    setting.isDisplayCodeText,
    setting.isDisplayCodeDescription,
    elementExists,
    qrCodeValue,
    getFirstLineOfQRCodeDescription,
    getSecondLineOfQRCodeDescription,
    createTextElement,
    removeElement,
  ]);

  const initialTransformQRCode = useCallback((): void => {
    const divElement = qrCodeRef.current as HTMLDivElement;
    const svgElement = divElement.getElementsByTagName(
      'svg'
    )[0] as SVGGraphicsElement;
    let gElement: SVGGElement | null = null;
    svgElement.setAttribute('height', defaultSize.toString());
    svgElement.setAttribute(
      'viewBox',
      `0 0 ${defaultSize.toString()} ${defaultSize.toString()}`
    );
    if (!elementExists(svgElement, 'g', undefined)) {
      gElement = document.createElementNS(xmlns, 'g');
      svgElement.appendChild(gElement);

      const pathElements = svgElement.getElementsByTagName('path');
      gElement.appendChild(pathElements[0]);
      gElement.appendChild(pathElements[0]);
    } else {
      [gElement] = svgElement.getElementsByTagName('g');
    }

    if (setting.isDisplayCodeText && !setting.isDisplayCodeDescription) {
      // show footer only
      gElement.setAttribute('transform', 'translate(15, 10) scale(8)');
    } else if (!setting.isDisplayCodeText && setting.isDisplayCodeDescription) {
      // show header only
      gElement.setAttribute('transform', 'translate(25, 40) scale(7)');
    } else if (setting.isDisplayCodeText && setting.isDisplayCodeDescription) {
      // show both header and footer
      gElement.setAttribute('transform', 'translate(35, 45) scale(6)');
    } else {
      // hide both header and footer
      gElement.setAttribute('transform', 'translate(5, 5) scale(9)');
    }
  }, [
    elementExists,
    qrCodeRef,
    setting.isDisplayCodeDescription,
    setting.isDisplayCodeText,
  ]);

  useEffect(() => {
    initialTransformQRCode();
  }, [initialTransformQRCode]);

  useEffect(() => {
    if (qrCodeValue) {
      addLabelTexts();
    }
  }, [
    addLabelTexts,
    qrCodeValue,
    setting.isDisplayCodeDescription,
    setting.isDisplayCodeText,
  ]);

  return (
    <div className="qr-code-previewer-container">
      <div className="section-label">{t('qrCodePreviewer.header')}</div>
      <div className="qr-code-printing" ref={qrCodeRef}>
        <QRCodeSVG
          bgColor="rgba(255,255,255,0)"
          fgColor="rgba(0,0,0,1)"
          value={qrCodeValue}
          size={defaultSize}
        />
      </div>
    </div>
  );
};

export default QRCodePreviewer;
