import { DataGridRow } from '../../components/DataGrid/GridRowCollection';

export default class DataGridRowSortFunctionProvider {
  private key: string | undefined;

  constructor(key: string) {
    this.key = key;
  }

  public sortDataRow = (colA: DataGridRow, colB: DataGridRow): number => {
    const cellA = colA.cells.find((cell) => cell.dataKey === this.key);
    const cellB = colB.cells.find((cell) => cell.dataKey === this.key);
    const a = cellA?.value ? cellA.value : '';
    const b = cellB?.value ? cellB.value : '';
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }

    return 0;
  };
}
