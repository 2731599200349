import { getSdcClient } from '../../common/apiClients/sdcClient.instance';
import ArgumentError from '../../common/error/argumentError';
import handleApplicationError from '../../utils/errorUtil';
import { BarcodeRequest } from './models/barcodeRequest';
import CodeResponse from './models/codeResponse';
import CodeSeparatorResponse from './models/codeSeparatorResponse';

export const getCode = async (
  id: string,
  errorHandler?: Function | undefined
): Promise<CodeResponse> => {
  if (!id) throw new ArgumentError('id');

  const sdcClient = getSdcClient();
  const uri: string = `./api/v1/barcodes/${id}`;
  try {
    const response = await sdcClient.get<CodeResponse>(uri);
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }
  return {} as CodeResponse;
};

export const createBarcode = async (
  request: BarcodeRequest,
  errorHandler?: Function | undefined
): Promise<CodeResponse> => {
  const sdcClient = getSdcClient();
  const uri: string = './api/v1/barcodes';
  try {
    const response = await sdcClient.post<CodeResponse>(uri, request);
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }
  return {} as CodeResponse;
};

export const getCodeSeparator = async (
  errorHandler?: Function | undefined
): Promise<CodeSeparatorResponse> => {
  const sdcClient = getSdcClient();
  const uri: string = `./api/v1/barcodes/separator`;
  try {
    const response = await sdcClient.get<CodeResponse>(uri);
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }
  return {} as CodeResponse;
};
