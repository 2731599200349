import { ReactElement } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CrossEyeIcon } from '../../assets/svg/CrossEyeIcon.svg';
import './UnsupportedFilePreviewer.scss';

interface UnsupportedFilePreviewerProps {
  onDownloadClick: () => void;
}

const UnsupportedFilePreviewer = ({
  onDownloadClick,
}: UnsupportedFilePreviewerProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="unsupported-file-previewer-container">
      <div className="unsupported-preview">
        <CrossEyeIcon width={120} />
        <span>{t('unsupportedFilePreviewer.description')}</span>
      </div>
      <div className="actions">
        <span>{t('unsupportedFilePreviewer.downloadAction')}</span>
        <Button
          className="shadow-none"
          variant="link"
          onClick={(e): void => {
            e.preventDefault();
            onDownloadClick();
          }}
        >
          {t('unsupportedFilePreviewer.downloadButton')}
        </Button>
      </div>
    </div>
  );
};

export default UnsupportedFilePreviewer;
