/* eslint-disable jsx-a11y/anchor-is-valid */
import { MutableRefObject, ReactElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from '../../assets/svg/PlusIcon.svg';
import { ReactComponent as SearchIcon } from '../../assets/svg/SearchIcon.svg';
import DataTypeItem from '../../common/models/dataTypeItem';
import FilterConditionItem from '../../common/models/filterConditionItem';
import DropdownItemRole from '../../common/types/dropdownItemRole';
import SDCTextFieldSize from '../../common/types/sdcTextFieldSize';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import useClickOutside from '../../hooks/useClickOutside/useClickOutside';
import SDCTextField from '../SDCTextField/SDCTextField';
import './FilterDropdown.scss';
import FilterOperatorSelector from './FilterOperatorSelector';

interface FilterDropdownProps {
  list: DataTypeItem[];
  onApplyFilter: (filterItem: FilterConditionItem) => void;
  disabled?: boolean;
}

/**
 * Credit: Code translated from javascript
 * https://codepen.io/ayanna/pen/ObLowr
 */
const FilterDropdown = ({
  list,
  onApplyFilter,
  disabled,
}: FilterDropdownProps): ReactElement => {
  const { currentUser } = useCurrentUser();
  const [isOpen, setIsOpen] = useState(false);
  const [fieldFilterText, setFieldFilterText] = useState<string>('');
  const [selectedField, setSelectedField] = useState<DataTypeItem | undefined>(
    undefined
  );
  const menuRef = useRef(null) as MutableRefObject<any>;
  const { t } = useTranslation();

  const toggleDropdown = (): void => {
    setIsOpen((value) => !value);
    setFieldFilterText('');
    setSelectedField(undefined);
  };

  const closeDropdownMenu = (): void => {
    setIsOpen(false);
    setFieldFilterText('');
    setSelectedField(undefined);
  };

  useClickOutside(menuRef, closeDropdownMenu);

  const selectField = (item: DataTypeItem): void => {
    if (selectedField === item) {
      return;
    }
    setSelectedField(item);
  };

  const renderDropdownField = (
    item: DataTypeItem,
    index: number
  ): ReactElement => {
    if (item.role === DropdownItemRole.Divider) {
      return (
        <div className="separator" key={`item-${index}`}>
          {item.name}
        </div>
      );
    }

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
      <li
        key={`item-${index}`}
        value={item.value}
        onClick={(): void => {
          selectField(item);
        }}
      >
        <a href="#">{item.name}</a>
      </li>
    );
  };

  const getFilteredFields = (): DataTypeItem[] => {
    if (!fieldFilterText) {
      return list;
    }

    let result: DataTypeItem[] = [];
    const dividers = list.filter((x) => x.role === DropdownItemRole.Divider);

    const systemResult = list.filter(
      (x) => x.isSystemDefined && x.name.toLowerCase().includes(fieldFilterText)
    );

    const templateResult = list.filter(
      (x) =>
        !x.isSystemDefined && x.name.toLowerCase().includes(fieldFilterText)
    );

    if (systemResult.length !== 0) {
      result = [dividers[0], ...systemResult];
    }
    if (templateResult.length !== 0) {
      result = [...result, dividers[1], ...templateResult];
    }

    return result;
  };

  const renderDropdownMenuContent = (): ReactElement => {
    if (selectedField) {
      return (
        <FilterOperatorSelector
          field={selectedField}
          onApplyFilter={(filterCondition: FilterConditionItem): void => {
            onApplyFilter(filterCondition);
            toggleDropdown();
          }}
          settings={currentUser.settings}
        />
      );
    }

    return (
      <>
        <div className="field-filter">
          <SDCTextField
            Icon={SearchIcon}
            placeholder={t('searchFilterComponent.placeholderFilter')}
            fieldSize={SDCTextFieldSize.Small}
            onTextChange={(text: string): void => {
              setFieldFilterText(text.toLocaleLowerCase());
            }}
          />
        </div>
        <ul className="filter-dropdown-item-container">
          {getFilteredFields().map(renderDropdownField)}
        </ul>
      </>
    );
  };

  return (
    <div className="filter-dropdown-container" data-hj-suppress>
      <div className={`filter-dropdown ${isOpen ? 'open' : ''} `} ref={menuRef}>
        <button
          disabled={disabled}
          className="filter-dropdown-toggle"
          type="button"
          onClick={toggleDropdown}
        >
          {selectedField === undefined ? (
            <PlusIcon className="icon" width={16} height={16} />
          ) : null}
          {selectedField?.name ?? t('searchFilterComponent.addFilterButton')}
        </button>
        <div className="filter-dropdown-menu">
          {renderDropdownMenuContent()}
        </div>
      </div>
    </div>
  );
};

export default FilterDropdown;

FilterDropdown.defaultProps = {
  disabled: false,
};
