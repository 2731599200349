const invokeWithIgnoredResult = async (
  connection: signalR.HubConnection | undefined,
  methodName: string,
  ...args: any[]
): Promise<any> => {
  try {
    if (connection) {
      await connection.invoke(methodName, ...args);
    }
  } catch (e) {
    // Ignore error after invoke server event
  }
  return Promise.resolve();
};

export default invokeWithIgnoredResult;
