import { AxiosProgressEvent } from 'axios';
import { getSdcClient } from '../../common/apiClients/sdcClient.instance';
import ArgumentError from '../../common/error/argumentError';
import handleApplicationError from '../../utils/errorUtil';
import { CaptureObjectResponse } from './models/captureObjectResponse';
import { UploadContentResponse } from './models/uploadContentResponse';

export const getCaptureData = async (
  captureInfoId: string,
  errorHandler?: any
): Promise<CaptureObjectResponse> => {
  if (!captureInfoId) throw new ArgumentError('captureInfoId');

  const sdcClient = getSdcClient();
  const url: string = `./api/v1/captures/${captureInfoId}`;

  try {
    const response = await sdcClient.get<CaptureObjectResponse>(url);
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return {} as CaptureObjectResponse;
};

export const postUploadContent = async (
  captureInfoId: string,
  uploadContentRequest: FormData,
  updateProgress: React.Dispatch<React.SetStateAction<number>>,
  signal: AbortSignal,
  errorHandler?: any
): Promise<UploadContentResponse> => {
  if (!captureInfoId) throw new ArgumentError('captureInfoId');
  if (!uploadContentRequest) throw new ArgumentError('uploadContentRequest');

  const sdcClient = getSdcClient();
  const url: string = `./api/v1/captures/${captureInfoId}/content`;

  try {
    const response = await sdcClient.post<UploadContentResponse>(
      url,
      uploadContentRequest,
      {
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const percentComplete: number =
            (progressEvent.loaded / (progressEvent.total ?? 1)) * 100;
          updateProgress(percentComplete);
        },
        signal,
      }
    );

    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return {} as UploadContentResponse;
};

export const getUploadedDocumentPreviewImage = async (
  uploadedObjectInfoId: string,
  signal: AbortSignal,
  errorHandler?: Function | undefined
): Promise<File> => {
  if (!uploadedObjectInfoId) throw new ArgumentError('uploadedObjectInfoId');

  const sdcClient = getSdcClient();
  const url: string = `./api/v1/captures/files/${uploadedObjectInfoId}/thumbnails/default`;

  try {
    const response = await sdcClient.get<any>(url, {
      responseType: 'blob',
      signal,
    });
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return {} as any;
};
