import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CopyIcon } from '../../assets/svg/CopyIcon.svg';
import SDCButtonIconPosition from '../../common/types/sdcButtonIconPosition';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import SDCButton from '../SDCButton/SDCButton';
import SDCTextField from '../SDCTextField/SDCTextField';
import './CopyTextField.scss';

interface CopyTextFieldProps {
  text: string;
}

const CopyTextField = ({ text }: CopyTextFieldProps): ReactElement => {
  const { t } = useTranslation();
  const [buttonText, setButtonText] = useState<string>(t('copyTextField.copy'));
  const [isCopied, setIsCopied] = useState(false);

  return (
    <div className="copy-text-field-component">
      <SDCTextField value={text} disabled />
      <SDCButton
        className="copy-button"
        text={buttonText}
        Icon={CopyIcon}
        iconPosition={SDCButtonIconPosition.Left}
        variant={SDCButtonVariant.Link}
        disabled={isCopied}
        onClick={(): void => {
          window.navigator.clipboard.writeText(text);
          setButtonText(t('copyTextField.copied'));
          setIsCopied(true);
          setTimeout(() => {
            setButtonText(t('copyTextField.copy'));
            setIsCopied(false);
          }, 2000);
        }}
      />
    </div>
  );
};

export default CopyTextField;
