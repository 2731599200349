import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CollapserPositionVariant from '../../common/types/collapserPositionVariant';
import { RowCollection } from '../../common/types/types';
import MetadataDetail from '../../services/capture/models/metadataDetail';
import ArchivedDocumentWithMetadata from '../../services/document/models/archivedDocumentWithMetadata';
import DocumentType from '../../services/documentType/models/documentType';
import UserSettings from '../../services/user/models/userSettings';
import FullSizeModal from '../DefaultModal/FullSizeModal';
import FileInformation from '../FileInformation/FileInformation';
import ReadonlyDataTable from '../ReadonlyDataTable/ReadonlyDataTable';
import ReadonlyDocumentMetadataTable from '../ReadonlyDocumentMetadataTable/ReadonlyDocumentMetadataTable';
import TableMetadataSection from '../TableMetadataSection/TableMetadataSection';
import './DocumentReadonlyNoThumbnailSidePanel.scss';
import SidePanel from './SidePanel';

interface DocumentReadonlyNoThumbnailSidePanelProps {
  document: ArchivedDocumentWithMetadata;
  documentTypes: DocumentType[];
  settings: UserSettings;
  collapserPosition: CollapserPositionVariant;
}

const DocumentReadonlyNoThumbnailSidePanel = ({
  document,
  documentTypes,
  settings,
  collapserPosition,
}: DocumentReadonlyNoThumbnailSidePanelProps): ReactElement => {
  const { t } = useTranslation();

  const [selectedTable, setSelectedTable] = useState<
    MetadataDetail | undefined
  >(undefined);
  const [showTable, setShowTable] = useState(false);

  const findDocumentTypeDisplayNameByName = (
    name: string
  ): string | undefined =>
    documentTypes.find((x) => x.name.toLowerCase() === name?.toLowerCase())
      ?.displayName;

  const renderContent = (): ReactElement => {
    if (!document) {
      return (
        <div className="nothing-selected">
          <div className="title">{t('sidePanel.noSelectionTitle')}</div>
          <div>{t('sidePanel.noSelectionDescription')}</div>
        </div>
      );
    }

    return (
      <div className="document-readonly-no-thumbnail-side-panel">
        <div className="section-label">{t('sidePanel.viewLabel')}</div>
        {document?.metadata?.templateName && (
          <>
            <div className="section-label">
              {t('sidePanel.documentTypeLabel')}
            </div>
            <div className="document-type">
              {findDocumentTypeDisplayNameByName(
                document.metadata.templateName
              )}
            </div>
          </>
        )}
        {document?.metadata?.data && document?.metadata.data.length > 0 && (
          <>
            <div className="section-divider" />
            <div className="section-label">{t('sidePanel.metadataLabel')}</div>
            <ReadonlyDocumentMetadataTable
              metadata={document.metadata.data}
              settings={settings}
            />
            <TableMetadataSection
              title={t('sidePanel.tableMetadataLabel')}
              data={document.metadata.data}
              onSelect={(item: MetadataDetail): void => {
                setSelectedTable(item);
                setShowTable(true);
              }}
            />
          </>
        )}
        {document && (
          <>
            <div className="section-divider" />
            <div className="section-label">
              {t('sidePanel.fileInformationLabel')}
            </div>
            <FileInformation document={document} />
            <div className="end-of-data">
              <div className="separator" />
              <div>{t('sidePanel.end')}</div>
            </div>
          </>
        )}
      </div>
    );
  };
  return (
    <>
      <SidePanel collapserPosition={collapserPosition} resizable>
        {renderContent()}
      </SidePanel>

      {selectedTable !== undefined ? (
        <FullSizeModal
          className="data-table"
          title={selectedTable.displayName ?? selectedTable.key}
          setShow={setShowTable}
          show={showTable}
          withSideBar={false}
        >
          <ReadonlyDataTable rows={selectedTable.value as RowCollection} />
        </FullSizeModal>
      ) : null}
    </>
  );
};

export default DocumentReadonlyNoThumbnailSidePanel;
