import { ReactElement } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as ExclamationMarkIcon } from '../../assets/svg/ExclamationMarkIcon.svg';
import KeyPairItem from '../../common/models/keypairItem';
import SDCDropdownSize from '../../common/types/sdcDropdownSize';
import './SDCDropdown.scss';

interface SDCDropdownProps {
  title?: string;
  labelText?: string;
  values: KeyPairItem[];
  onSelect: (value: KeyPairItem) => void;
  currentItem?: KeyPairItem;
  size?: SDCDropdownSize;
  darkMode?: boolean;
  noOutline?: boolean;
  validateError?: string;
  disabled?: boolean;
}

const SDCDropdown = ({
  title,
  labelText,
  values,
  onSelect,
  currentItem,
  size,
  darkMode,
  noOutline,
  validateError,
  disabled,
}: SDCDropdownProps): ReactElement => {
  const selectEventHandler = (item: KeyPairItem): void => {
    onSelect(item);
  };

  // Returns the current item value if specified. Otherwise the first item or default value.
  const getCurrentValue = (): string => {
    if (currentItem) {
      return currentItem.Name;
    }

    if (values.length > 0 && values[0]) {
      return values[0].Name;
    }
    return '...';
  };

  return (
    <>
      <Dropdown
        data-hj-suppress
        title={title}
        className={`sdc-dropdown ${darkMode ? 'dark' : 'light'}`}
      >
        <Dropdown.Toggle
          variant="none"
          className={`shadow-none  
        ${size === SDCDropdownSize.Small ? 'small' : 'default'}  ${
            validateError ? 'error' : ''
          }  ${noOutline ? 'no-outline' : ''} ${
            labelText ? 'button-with-label' : ''
          }`}
          disabled={disabled}
        >
          <span className={`${labelText ? 'text-with-label' : ''}`}>
            {getCurrentValue()}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="m-0 shadow-none">
          {values.map((item: KeyPairItem) => (
            <Dropdown.Item
              key={item.Value}
              eventKey={item.Value}
              onClick={(): void => selectEventHandler(item)}
              disabled={item.Value === ''}
            >
              {item.Name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
        {labelText && <div className="label-text">{labelText}</div>}
      </Dropdown>
      {validateError && (
        <div className="validation-error-message">
          <ExclamationMarkIcon /> {validateError}
        </div>
      )}
    </>
  );
};

SDCDropdown.defaultProps = {
  title: '',
  darkMode: false,
  noOutline: false,
  size: SDCDropdownSize.Default,
  currentItem: null,
  validateError: false,
  disabled: false,
  labelText: undefined,
};

export default SDCDropdown;
