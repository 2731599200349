import dayjs from 'dayjs';
import { ReactElement, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DatePickerIcon } from '../../assets/svg/DatePickerIcon.svg';
import accessKeyConstants from '../../common/constants/accessKey.constant';
import KeyPairItem from '../../common/models/keypairItem';
import NotificationVariant from '../../common/types/notificationVariant';
import UserSettings from '../../services/user/models/userSettings';
import NotificationAlert from '../NotificationAlert/NotifcationAlert';
import SDCDropdown from '../SDCDropdown/SDCDropdown';
import './EditAccessKeyExpirySidePanel.scss';

interface EditAccessKeyExpirySidePanelProps {
  validUntil: Date | null | undefined;
  settings: UserSettings;
  onChange: (value: Date | null) => void;
}

const EditAccessKeyExpirySidePanel = ({
  validUntil,
  settings,
  onChange,
}: EditAccessKeyExpirySidePanelProps): ReactElement => {
  const { t } = useTranslation();
  const [inputDate, setInputDate] = useState<Date | null>(
    validUntil ? dayjs(validUntil).toDate() : accessKeyConstants.DefaultExpiry
  );

  const expiryOptions: KeyPairItem[] = [
    {
      Name: t('accessKeyPage.sidePanelExpireAfterYear', {
        count: accessKeyConstants.DefaultExpiryPeriod,
      }),
      Value: accessKeyConstants.OptionExpireDefault,
    },
    {
      Name: t('accessKeyPage.optionExpireAfter'),
      Value: accessKeyConstants.OptionExpire,
    },
    {
      Name: t('accessKeyPage.optionNeverExpire'),
      Value: accessKeyConstants.OptionNonExpire,
    },
  ];
  const [selectedOption, setSelectedOption] = useState(
    validUntil ? expiryOptions[0] : expiryOptions[1]
  );
  const getDefaultDate = (): Date =>
    selectedOption.Value === accessKeyConstants.OptionExpireDefault
      ? accessKeyConstants.DefaultExpiry
      : dayjs().toDate();

  return (
    <div className="edit-access-key-expiry-side-panel-component">
      <div className="section-label clear">
        {t('accessKeyPage.expirySectionLabel')}
      </div>
      <SDCDropdown
        labelText={t('accessKeyPage.validUntilLabel')}
        currentItem={selectedOption}
        values={expiryOptions}
        onSelect={(item: KeyPairItem): void => {
          setSelectedOption(item);

          if (item.Value === accessKeyConstants.OptionNonExpire) {
            setInputDate(null);
            onChange(null);
          } else {
            const dateValue = validUntil ?? accessKeyConstants.DefaultExpiry;
            setInputDate(dayjs(dateValue).toDate());
            if (dateValue !== validUntil) {
              onChange(dateValue);
            }
          }
        }}
      />
      <div className="pb-3" />
      {selectedOption.Value !== accessKeyConstants.OptionNonExpire ? (
        <div className="date-picker-container">
          {' '}
          <ReactDatePicker
            className="text-field-input"
            placeholderText={settings.dateFormat}
            dateFormat={settings.dateFormat
              .replace(/D/g, 'd')
              .replace(/Y/g, 'y')}
            onChange={(date: Date | null): void => {
              // Ensure it always gives a date value when set to non-expiring
              setInputDate(date ?? getDefaultDate());
            }}
            selected={inputDate ?? getDefaultDate()}
            filterDate={(date: Date): boolean =>
              date.getTime() > new Date().getTime()
            }
            disabled={
              selectedOption.Value === accessKeyConstants.OptionExpireDefault
            }
          />
          <div className="calendar-icon">
            <DatePickerIcon />
          </div>
        </div>
      ) : (
        <NotificationAlert
          title=""
          message={t('accessKeyPage.expiryWarningMessage')}
          variant={NotificationVariant.SecondaryInfo}
        />
      )}
    </div>
  );
};

export default EditAccessKeyExpirySidePanel;
