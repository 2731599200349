import UserPermission from '../common/types/userPermission';
import CurrentUserResponse from '../services/user/models/currentUserResponse';

const userHasPermission = (
  user: CurrentUserResponse,
  permission: UserPermission
): boolean => {
  const { permissions } = user;
  return permissions.includes(permission);
};

export default userHasPermission;
