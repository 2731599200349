/* eslint-disable react/no-unknown-property */
import JsBarcode from 'jsbarcode';
import { MutableRefObject, ReactElement, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Code from '../../common/models/code';
import CodeSetting from '../../common/models/codeSetting';
import './BarcodePreviewer.scss';

interface BarcodePreviewerProps {
  barcode: Code | undefined;
  barcodeRef: MutableRefObject<HTMLDivElement | null>;
  setting: CodeSetting;
}

const BarcodePreviewer = ({
  barcode,
  barcodeRef,
  setting,
}: BarcodePreviewerProps): ReactElement => {
  const xmlns = 'http://www.w3.org/2000/svg';
  const plainBarcodeHeight = 120;
  const barcodeFormat = 'code39';
  const fontOptions = 'bold';
  const textMargin = '0';
  const descriptionFontStyle = 'font:bold 20px monospace';
  const descriptionTextAnchor = 'middle';
  const barcodeMargin = 10;
  const barcodeMarginTop = 50;
  const barcodeWidth = 372;
  const descriptionLineHeight = 20;
  const { t } = useTranslation();
  const barcodeValue = barcode?.value ?? '';
  const barcodeDescription = barcode?.description ?? '';
  const descriptionCharactersCountLimitPerLine = 30;
  const descriptionCharactersCountTotalLimit = 60;
  const isSecondLineOfDescriptionNeeded =
    barcodeDescription.length > descriptionCharactersCountLimitPerLine;

  const createTextElement = useCallback(
    (description: string, x: number, y: number): SVGTextElement => {
      const descriptionElement = document.createElementNS(xmlns, 'text');
      descriptionElement.setAttribute('style', descriptionFontStyle);
      descriptionElement.setAttribute('text-anchor', descriptionTextAnchor);
      descriptionElement.setAttribute('x', x.toString());
      descriptionElement.setAttribute('y', y.toString());
      descriptionElement.textContent = description;
      return descriptionElement;
    },
    []
  );

  const addBarcodeDescription = useCallback(
    (svgElement: SVGGraphicsElement, description: string): void => {
      const g = svgElement.getElementsByTagName('g')[0];
      g.setAttribute(
        'transform',
        `translate(10, ${
          10 +
          descriptionLineHeight +
          (isSecondLineOfDescriptionNeeded ? descriptionLineHeight : 0)
        })`
      );
      const bBox = svgElement.getBBox();
      const x = (bBox.width - barcodeMargin * 2) / 2;
      const y =
        -barcodeMargin -
        (isSecondLineOfDescriptionNeeded ? descriptionLineHeight : 0);

      const firstLineOfDescriptionElement = createTextElement(
        description.substring(0, descriptionCharactersCountLimitPerLine - 1),
        x,
        y
      );

      if (isSecondLineOfDescriptionNeeded) {
        const secondLineOfDescriptionElement = createTextElement(
          description.substring(
            descriptionCharactersCountLimitPerLine,
            descriptionCharactersCountTotalLimit - 1
          ),
          x,
          y + descriptionLineHeight
        );
        g.prepend(secondLineOfDescriptionElement);
      }
      g.prepend(firstLineOfDescriptionElement);
    },
    [createTextElement, isSecondLineOfDescriptionNeeded]
  );

  const modifyBarcode = useCallback((): void => {
    const divElement = barcodeRef.current as HTMLDivElement;
    const svgElement = divElement.getElementsByTagName(
      'svg'
    )[0] as SVGGraphicsElement;
    if (svgElement && barcodeDescription) {
      const newHeight =
        plainBarcodeHeight +
        (setting.isDisplayCodeDescription ? descriptionLineHeight : 0) +
        (setting.isDisplayCodeText ? descriptionLineHeight : 0) +
        (isSecondLineOfDescriptionNeeded ? descriptionLineHeight : 0);

      svgElement.setAttribute('height', `${newHeight}px`);
      svgElement.setAttribute('viewBox', `0 0 ${barcodeWidth} ${newHeight}`);

      const rect = svgElement.getElementsByTagName('rect')[0];
      rect.setAttribute('height', `${newHeight}`);

      if (setting.isDisplayCodeDescription) {
        addBarcodeDescription(svgElement, barcodeDescription);
      }
    }
  }, [
    addBarcodeDescription,
    barcodeDescription,
    barcodeRef,
    isSecondLineOfDescriptionNeeded,
    setting.isDisplayCodeDescription,
    setting.isDisplayCodeText,
  ]);

  useEffect(() => {
    if (barcodeValue) {
      JsBarcode('#barcode').init();
      if (setting.isDisplayCodeDescription) modifyBarcode();
    }
  }, [
    modifyBarcode,
    barcodeValue,
    setting.isDisplayCodeDescription,
    setting.isDisplayCodeText,
  ]);

  return (
    <div className="barcode-previewer-container">
      <div className="section-label">{t('barcodePreviewer.header')}</div>
      <div className="barcode-printing" ref={barcodeRef}>
        <svg
          id="barcode"
          className="barcode"
          jsbarcode-format={barcodeFormat}
          jsbarcode-value={barcodeValue}
          jsbarcode-displayvalue={setting.isDisplayCodeText.toString()}
          jsbarcode-textmargin={textMargin}
          jsbarcode-fontoptions={fontOptions}
          jsbarcode-marginright={barcodeMargin}
          jsbarcode-marginleft={barcodeMargin}
          jsbarcode-margintop={
            setting.isDisplayCodeDescription ? barcodeMarginTop : barcodeMargin
          }
          jsbarcode-marginbottom={barcodeMargin}
        />
      </div>
    </div>
  );
};

export default BarcodePreviewer;
