import { ReactElement } from 'react';

export interface DataGridCell {
  dataKey: string;
  value: string | Date | number;
  template?: ReactElement;
  columnExtraWidth?: boolean;
}

interface GridCellProps {
  id: string;
  cell: DataGridCell;
}

const GridCell = ({ id, cell }: GridCellProps): ReactElement => {
  const content = cell.template ? cell.template : <span>{cell.value}</span>;

  return (
    <div
      key={`${id}-${cell.dataKey}`}
      className={
        cell.dataKey !== 'dropdown-menu'
          ? `data-col ${cell.columnExtraWidth ? 'f-2' : ''}`
          : 'data-menu'
      }
      data-hj-suppress
    >
      {content}
    </div>
  );
};

export default GridCell;
