import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import UnsupportedFileIcon from '../../assets/img/unsupported-file.png';
import { ReactComponent as DownloadIcon } from '../../assets/svg/DownloadIcon.svg';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import SDCButton from '../SDCButton/SDCButton';
import './UnsupportedFileViewer.scss';

interface UnsupportedFileViewerProps {
  onDownloadClick: () => void;
  archived: boolean;
}

const UnsupportedFileViewer = ({
  onDownloadClick,
  archived,
}: UnsupportedFileViewerProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <div className="unsupported-file-viewer-container">
      <div>
        <img src={UnsupportedFileIcon} alt="" />
      </div>
      <div className="title">{t('unsupportedFileViewer.title')}</div>
      {!archived && <div>{t('unsupportedFileViewer.message')}</div>}
      <div className="download-section">
        <div>{t('unsupportedFileViewer.downloadMessage')}</div>
        <div>
          <SDCButton
            text={t('unsupportedFileViewer.downloadButton')}
            variant={SDCButtonVariant.OutlinedDark}
            Icon={DownloadIcon}
            onClick={onDownloadClick}
          />
        </div>
      </div>
    </div>
  );
};

export default UnsupportedFileViewer;
