import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloudDarkIcon } from '../../../assets/svg/CloudCheckDarkIcon.svg';
import { ReactComponent as PenOutlinedIcon } from '../../../assets/svg/PenOutlinedIcon.svg';
import Container from '../../../services/container/models/container';
import DateTimeFormatter from '../../DateTime/DateTimeFormatter';
import DataGrid, { DataGridHeaderDefinition } from '../DataGrid';
import './ContainerManagementDataGrid.scss';

export interface ContainerManagementDataGridProps {
  containers: Array<Container>;
  onUpdate: (item: Container) => void;
}

const ContainerManagementDataGrid = ({
  containers,
  onUpdate,
}: ContainerManagementDataGridProps): ReactElement => {
  const [selectedItem, setSelectedItem] = useState<Container | undefined>(
    undefined
  );
  const extraWidth = true;
  const editContainer = (item: Container): void => {
    setSelectedItem(item);
    onUpdate(item);
  };
  const { t } = useTranslation();
  const rows = containers.map((item: Container) => ({
    id: item.key,
    rowClass:
      item.key === selectedItem?.key
        ? 'selected user-select-none'
        : 'user-select-none',
    cells: [
      {
        columnKey: 'containerPage.nameColumnHeader',
        dataKey: 'containerPage.nameColumnHeader',
        value: item.name,
        text: item.name,
        template: (
          <div className="table-column">
            <CloudDarkIcon className="me-2 pe-none" />
            <span className="truncate">{item.name}</span>
            {item.isSystemDefined ? null : (
              <PenOutlinedIcon
                className="ms-2 clickable"
                onClick={(): void => editContainer(item)}
              />
            )}
          </div>
        ),
        columnExtraWidth: true,
      },
      {
        columnKey: 'containerPage.dateColumnHeader',
        dataKey: 'containerPage.dateColumnHeader',
        value: item.createdOn,
        template: (
          <div className="d-flex name">
            <DateTimeFormatter date={item.createdOn} format="D MMMM YYYY" />
          </div>
        ),
      },
    ],
    clickable: false,
  }));

  const headers: Array<DataGridHeaderDefinition> = [
    {
      columnKey: 'containerPage.nameColumnHeader',
      translationKey: 'containerPage.nameColumnHeader',
      columnExtraWidth: extraWidth,
    },
    {
      columnKey: 'containerPage.dateColumnHeader',
      translationKey: 'containerPage.dateColumnHeader',
    },
  ];

  return (
    <div className="container-management-data-grid">
      <DataGrid
        headerDefinitions={headers}
        tableContent={rows}
        selectable={false}
        setSelectableStates={(): void => {}}
        sortableColumnKeys={[
          'containerPage.nameColumnHeader',
          'containerPage.dateColumnHeader',
        ]}
        noResultsMessage={t('containerPage.noResultsMessage')}
        isLoading={containers.length === 0}
      />
    </div>
  );
};

export default ContainerManagementDataGrid;
