import { getSdcClient } from '../../common/apiClients/sdcClient.instance';
import handleApplicationError from '../../utils/errorUtil';
import { EntityResponse } from './models/entityResponse';

const findEntity = async (
  data: { [key: string]: string },
  templateName: string | null,
  errorHandler?: Function | undefined
): Promise<EntityResponse> => {
  const sdcClient = getSdcClient();
  const url: string = `./api/v1/entities/exact-match${
    templateName ? `/${templateName}` : ''
  }`;

  try {
    const response = await sdcClient.get<EntityResponse>(url, {
      params: data,
    });
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return {} as EntityResponse;
};

export default findEntity;
