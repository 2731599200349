enum ConfirmationModalVariant {
  PrimaryDefault = 'primary-default',
  PrimaryInfo = 'primary-info',
  PrimaryWarning = 'primary-warning',
  PrimaryDanger = 'primary-danger',

  SecondaryDefault = 'secondary-default',
  SecondaryInfo = 'secondary-info',
  SecondaryWarning = 'secondary-warning',
  SecondaryDanger = 'secondary-danger',
}

export default ConfirmationModalVariant;
