import { ReactElement } from 'react';
import { Spinner } from 'react-bootstrap';
import './LoadingSet.scss';

interface LoadingSetProps {
  size?: 'sm' | undefined;
}

const LoadingSet = ({ size }: LoadingSetProps): ReactElement => (
  <div className="loading-set">
    <Spinner animation="grow" variant="primary" size={size} />
    <Spinner animation="grow" variant="primary" size={size} />
    <Spinner animation="grow" variant="primary" size={size} />
  </div>
);

export default LoadingSet;

LoadingSet.defaultProps = {
  size: undefined,
};
