import { AxiosError } from 'axios';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import barcode from '../../common/constants/barcode.constant';
import Code from '../../common/models/code';
import CodeSetting from '../../common/models/codeSetting';
import Entity from '../../common/models/entity';
import ErrorDetails from '../../common/models/errorDetails';
import CollapserPositionVariant from '../../common/types/collapserPositionVariant';
import ErrorCode from '../../common/types/errorCode';
import NotificationVariant from '../../common/types/notificationVariant';
import QueryStringKey from '../../common/types/queryStringKey';
import SizeUnit from '../../common/types/sizeUnit';
import UserPermission from '../../common/types/userPermission';
import BarcodePreviewer from '../../components/BarcodePreviewer/BarcodePreviewer';
import QRCodePreviewer from '../../components/BarcodePreviewer/QRCodePreviewer';
import CodePageFooter from '../../components/CodePageFooter/CodePageFooter';
import CodeSettingPanel from '../../components/CodeSettingPanel/CodeSettingPanel';
import DefaultLoading from '../../components/DefaultLoading/DefaultLoading';
import RestrictedArea from '../../components/RestrictedArea/RestrictedArea';
import CaptureSidePanel from '../../components/SidePanel/CaptureSidePanel';
import TaskBar from '../../components/TaskBar/TaskBar';
import TaskDescription from '../../components/TaskBar/TaskDescription';
import Toaster from '../../components/Toaster/Toaster';
import showToaster from '../../components/Toaster/ToasterProvider';
import { useContainer } from '../../context/Container/ContainerContext';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import useQueryString from '../../hooks/useQueryString/useQueryString';
import Metadata from '../../services/capture/models/metadata';
import MetadataDetail from '../../services/capture/models/metadataDetail';
import { createBarcode, getCode } from '../../services/code/code.service';
import { BarcodeRequest } from '../../services/code/models/barcodeRequest';
import {
  getActiveDocumentTypes,
  getDocumentTemplate,
} from '../../services/documentType/documentType.service';
import DocumentTemplateResponse from '../../services/documentType/models/documentTemplateResponse';
import DocumentType from '../../services/documentType/models/documentType';
import MetadataTemplateItem from '../../services/documentType/models/metadataTemplateItem';
import { getCodeSetting } from '../../services/userSetting/userSetting.service';
import {
  buildMetadataRequest,
  setMissingStatus,
  validateMetadataRequest,
} from '../../utils/metadataUtil';
import { generateBarcode } from '../../utils/stringUtil';
import userHasPermission from '../../utils/userUtil';
import './CodePage.scss';

interface CodePageParams {
  id: string;
}

const CodePage = (): ReactElement => {
  const barcodeLength = 10;
  const errorHandler = useErrorHandler();
  const { t } = useTranslation();
  const queryString = useQueryString();
  const { currentUser } = useCurrentUser();
  const { containerKey, setContainerKey } = useContainer();

  const [showLoading, setShowLoading] = useState(false);
  const [showSaving, setShowSaving] = useState(false);
  const { id } = useParams<CodePageParams>();
  const [code, setCode] = useState<Code | undefined>();
  const displayName = ((): string => {
    const value = queryString.get(QueryStringKey.DisplayName);
    if (value) return value;
    return id.toString();
  })();
  const [needRenewBarcode, setNeedRenewBarcode] = useState(false);

  const codeRef = useRef(null);
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [entities, setEntities] = useState<Entity[]>([]);
  const [metadata, setMetadata] = useState<Metadata>({
    templateName: '',
    templateGroupName: '',
    data: [],
  });
  const [originalMetadata, setOriginalMetadata] = useState<
    Metadata | undefined
  >();
  const [modifiedMetadata, setModifiedMetadata] = useState<
    MetadataDetail[] | undefined
  >(undefined);
  const [selectedDocumentType, setSelectedDocumentType] = useState('');
  const [documentTypeValidateError, setDocumentTypeValidateError] =
    useState<string>('');
  const [selectedTemplate, setSelectedTemplate] = useState<
    MetadataTemplateItem[]
  >([]);
  const [codeSetting, setCodeSetting] = useState<CodeSetting>(
    (): CodeSetting => {
      const settings = getCodeSetting();
      // Query param overrides the settings
      const value = queryString.get(QueryStringKey.Type);
      // Redirect to error if barcode type is invalid
      if (value && value !== barcode.CODE_39 && value !== barcode.QR_CODE) {
        window.location.replace('/error/404');
      }

      if (value) {
        settings.type = value;
      }

      return settings;
    }
  );
  const createPageStyle = useCallback((): string => {
    const unit = codeSetting.sizeUnit === SizeUnit.Inch ? 'in' : 'mm';
    const width = `${codeSetting.width}${unit}`;
    const height = `${codeSetting.height}${unit}`;
    const pageStyle = `
      @media print {
        @page {
          background-color: white;
        }        
      }
      svg {
        width: ${width};
        height: ${height};
      }
    `;
    return pageStyle;
  }, [codeSetting]);
  const [printingPageStyle, setPrintingPageStyle] = useState<string>(() =>
    createPageStyle()
  );
  const getCodeTypeDescription = (): string =>
    codeSetting.type === barcode.CODE_39 ? 'barcode' : 'QR code';

  useEffect(() => {
    if (!userHasPermission(currentUser, UserPermission.CaptureDocuments)) {
      return;
    }

    const init = async (): Promise<void> => {
      const documentTypesResponse = await getActiveDocumentTypes();
      if (documentTypesResponse) {
        setDocumentTypes(documentTypesResponse);
      }

      const response = await getCode(id, errorHandler);
      if (response) {
        const template = documentTypesResponse.find(
          (x) => x.name === response.metadata.templateName
        );

        let isValid = true;
        if (template) {
          const metadataTemplate = await getDocumentTemplate(template.id);
          isValid = validateMetadataRequest(
            response.metadata.data,
            metadataTemplate.template
          );
          setNeedRenewBarcode(!isValid);
          setSelectedTemplate(metadataTemplate.template);
        }
        setContainerKey(response.containerKey);

        setCode({
          value: isValid ? response.barcode : generateBarcode(barcodeLength),
          description: response.barcodeDescription,
        });

        setMissingStatus(response.metadata.data);
        setMetadata(response.metadata);
        setOriginalMetadata(response.metadata);
        setEntities(response.entities);
        setShowLoading(false);
      }
    };

    setShowLoading(true);
    init();
  }, [currentUser, id, setContainerKey, errorHandler]);

  useEffect(() => {
    setPrintingPageStyle(createPageStyle());
  }, [codeSetting, createPageStyle]);

  const handleDocumentTypeChange = (
    response: DocumentTemplateResponse | undefined
  ): void => {
    if (response) {
      setSelectedDocumentType(response.documentType.name);
      setSelectedTemplate(response.template);
      setDocumentTypeValidateError('');
      setCode((previous) => ({
        value: generateBarcode(barcodeLength),
        description: previous?.description ?? '',
      }));

      // If the selected document type is original, clear out metadata request.
      // Otherwise, build new metada request for the document from the original metadata against the new document type.
      const useOriginalTemplate =
        response.documentType.name?.toLowerCase() ===
        originalMetadata?.templateName?.toLowerCase();

      if (useOriginalTemplate) {
        if (originalMetadata) {
          setMetadata(originalMetadata);
          setModifiedMetadata(originalMetadata.data);
        }
      } else if (modifiedMetadata) {
        setMetadata({
          templateName: response.documentType.name,
          templateGroupName: '',
          data: modifiedMetadata,
        });
      }
    } else {
      setSelectedDocumentType('');
    }
  };

  const validateDataBeforePrint = async (): Promise<boolean> => {
    if (!selectedDocumentType) {
      setDocumentTypeValidateError(
        t('archiveDocumentSectionComponent.validateDocumentTypeRequiredLabel')
      );
      return false;
    }
    setDocumentTypeValidateError('');

    // Only validate if the user changes the metadata
    const metadataToValidate = modifiedMetadata ?? metadata.data;
    const isValidMetadata = validateMetadataRequest(
      metadataToValidate,
      selectedTemplate
    );
    if (!isValidMetadata) {
      showToaster(
        t('codeScreen.invalidMetadataInputErrorTitle'),
        t('codeScreen.invalidMetadataInputErrorMessage'),
        NotificationVariant.SoftDanger
      );
      return false;
    }

    // Create new barcode if needs to generate new barcode
    const data = buildMetadataRequest(
      modifiedMetadata ?? metadata.data,
      selectedDocumentType
    );
    if (needRenewBarcode && code && data) {
      setShowSaving(true);

      try {
        const request: BarcodeRequest = {
          entities,
          metadata: data,
          containerKey,
          barcode: code.value,
        };
        await createBarcode(request);
      } catch (error) {
        const axiosError = error as AxiosError;
        const detail = axiosError.response?.data as ErrorDetails;
        showToaster(
          t('codeScreen.genericErrorTitle'),
          t(`errorCode.${detail.errorCode}`),
          NotificationVariant.SoftDanger
        );

        // Upon conflict error, generate a new code
        if (detail.errorCode === ErrorCode.BarcodeAlreadyExists) {
          setCode((previous) => ({
            value: generateBarcode(barcodeLength),
            description: previous?.description ?? '',
          }));
        }

        return false;
      } finally {
        setShowSaving(false);
      }
    }

    return true;
  };

  const handleAfterPrint = async (): Promise<void> => {
    const documentType = documentTypes.find(
      (x) => x.name === metadata.templateName
    );

    // If there's no need to renew barcode, return.
    if (!documentType || !needRenewBarcode) {
      return;
    }

    const response = await getCode(id, errorHandler);
    setCode({
      value: generateBarcode(barcodeLength),
      description: response.barcodeDescription,
    });

    // Reset to orignal
    setSelectedDocumentType(response.metadata.templateName);
  };

  const renderBarcodePreviewer = (): ReactElement | null => {
    if (!code) {
      return null;
    }

    if (codeSetting.type === barcode.CODE_39) {
      return (
        <BarcodePreviewer
          key={code.value}
          barcode={code}
          barcodeRef={codeRef}
          setting={codeSetting}
        />
      );
    }
    return (
      <QRCodePreviewer
        key={code.value}
        qrCode={code}
        qrCodeRef={codeRef}
        setting={codeSetting}
      />
    );
  };

  if (!userHasPermission(currentUser, UserPermission.CaptureDocuments)) {
    return <RestrictedArea />;
  }

  return (
    <>
      <Toaster />
      {showLoading && <DefaultLoading />}
      {showSaving && (
        <DefaultLoading
          progressTranslationKey="defaultLoadingComponent.saving"
          successTranslationKey="defaultLoadingComponent.saveSuccess"
          errorTranslationKey="defaultLoadingComponent.saveError"
        />
      )}
      <div className="code-page-container">
        <TaskBar
          headerPrefix={t('taskBar.capturePrefix')}
          actionText={t('taskBar.done')}
          actionOnClick={(): boolean => true}
          isSubNavbar
        >
          <TaskDescription
            descriptionText={t('taskBar.barcodeTaskDescription', {
              type: getCodeTypeDescription(),
            })}
            documentDisplayName={displayName}
          />
        </TaskBar>
        <div className="code-container">
          <div className="code-preview-container">
            <div className="code-screen-header">
              {t('codeScreen.header', {
                type: getCodeTypeDescription(),
              })}
            </div>
            <div className="code-screen-subheader">
              {t('codeScreen.subheader', {
                type: getCodeTypeDescription(),
              })}
            </div>
            {renderBarcodePreviewer()}
            <CodeSettingPanel
              setting={codeSetting}
              setSetting={setCodeSetting}
            />
          </div>
          <CaptureSidePanel
            onDocumentTypeChange={handleDocumentTypeChange}
            documentTypeValidateError={documentTypeValidateError}
            onMetadataChange={setModifiedMetadata}
            settings={currentUser.settings}
            documentTypes={documentTypes}
            metadata={metadata}
            collapserPosition={CollapserPositionVariant.WithTitleBarAndTaskBar}
          />
          <CodePageFooter
            componentToPrintRef={codeRef}
            validateData={validateDataBeforePrint}
            printingPageStyle={printingPageStyle}
            onAfterPrint={handleAfterPrint}
          />
        </div>
      </div>
    </>
  );
};

export default CodePage;
