import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UserInviteIcon } from '../../assets/svg/UserInviteIcon.svg';
import userConst from '../../common/constants/user.constant';
import AllPermissionAccessStatus from '../../common/types/allPermissionAccessStatus';
import CollapserPositionVariant from '../../common/types/collapserPositionVariant';
import ConfirmationModalVariant from '../../common/types/confirmationModalVariant';
import NotificationVariant from '../../common/types/notificationVariant';
import PermissionAccessStatus from '../../common/types/permissionAccessStatus';
import PermissionStatus from '../../common/types/permissionStatus';
import SDCButtonSize from '../../common/types/sdcButtonSize';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import UserPermission from '../../common/types/userPermission';
import UserPermissionEditType from '../../common/types/userPermissionEditType';
import TeamManagementDataGrid from '../../components/DataGrid/TeamManagementDataGrid/TeamManagementDataGrid';
import DefaultLoading from '../../components/DefaultLoading/DefaultLoading';
import ConfirmationModal from '../../components/DefaultModal/ConfirmationModal';
import UserInvitationModal from '../../components/DefaultModal/UserInvitationModal';
import LoadingSet from '../../components/LoadingSet/LoadingSet';
import PaginationNavigator from '../../components/Pagination/PaginationNavigator';
import RestrictedArea from '../../components/RestrictedArea/RestrictedArea';
import SDCButton from '../../components/SDCButton/SDCButton';
import EditContainerAccessSidePanel from '../../components/SidePanel/EditContainerAccessSidePanel';
import EditDocumentTypeAccessSidePanel from '../../components/SidePanel/EditDocumentTypeAccessSidePanel';
import EditProfileSidePanel from '../../components/SidePanel/EditProfileSidePanel';
import SidePanel from '../../components/SidePanel/SidePanel';
import Toaster from '../../components/Toaster/Toaster';
import showToaster from '../../components/Toaster/ToasterProvider';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import { getContainersAsync } from '../../services/container/container.service';
import Container from '../../services/container/models/container';
import DocumentType from '../../services/documentType/models/documentType';
import PaginationResponse from '../../services/paginationResponse';
import TeamManagementDisplay from '../../services/teamManagement/models/teamManagementDisplay';
import TeamManagementResponse from '../../services/teamManagement/models/teamManagementResponse';
import UserAccessItem, {
  AccessDetail,
} from '../../services/teamManagement/models/userAccessItem';
import {
  createUser,
  getDocumentTypes,
  getRoles,
  getUserContainers,
  getUserDocumentTypes,
  getUsers,
  saveUserContainers,
  saveUserDocumentTypes,
  saveUserProfile,
  saveUserRoles,
} from '../../services/teamManagement/teamManagement.service';
import { getRandomColorCode } from '../../utils/stringUtil';
import userHasPermission from '../../utils/userUtil';
import './TeamManagementPage.scss';

const TeamManagementPage = (): ReactElement => {
  const OWNER = 'Owner';
  const defaultPageNumber: number = 1;
  const defaultPageSize: number = 20;
  const defaultResponse: PaginationResponse<TeamManagementDisplay> = {
    results: [],
    page: defaultPageNumber,
    pageSize: defaultPageSize,
    lastPage: 0,
    totalCount: 0,
  };

  const { t } = useTranslation();
  const handleError = useErrorHandler();
  const { currentUser } = useCurrentUser();
  // Loading control
  const [showLoading, setShowLoading] = useState(false);
  const [showLoadingSidePanel, setShowLoadingSidePanel] = useState(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  // Visible control
  const [isShowUserInvitationModal, setIsShowUserInvitationModal] =
    useState<boolean>(false);
  const [showConfirmationModalChangeRole, setShowConfirmationModalChangeRole] =
    useState(false);
  const [isShowSidePanel, setIsShowSidePanel] = useState(false);
  const [openSidePanel, setOpenSidePanel] = useState(false);
  // User PerMission
  const [usersResult, setUsersResult] =
    useState<PaginationResponse<TeamManagementDisplay>>(defaultResponse);
  // Edit Type // Profile, Role, Access, DocumentType
  const [selectedType, setSelectedType] = useState<UserPermissionEditType>(
    UserPermissionEditType.Profile
  );
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const selectedUserInfo = usersResult.results.find(
    (user: TeamManagementDisplay) => user.id === selectedUserId
  );

  const [fullName, setFullName] = useState<string>('');
  const [roles, setRoles] = useState<string[]>([]);
  const [selectedRole, setSelectedRole] = useState<string>('');
  // Container
  const [containers, setContainers] = useState<Container[]>([]);
  const [oriContainers, setOriContainers] = useState<PermissionAccessStatus[]>(
    []
  );
  const [userContainers, setUserContainers] = useState<AccessDetail[]>([]);
  const [selectAllContainer, setSelectAllContainer] = useState(false);
  // Document Type
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
  const [oriDocumentTypes, setOriDocumentTypes] = useState<
    PermissionAccessStatus[]
  >([]);
  const [userDocumentTypes, setUserDocumentTypes] = useState<AccessDetail[]>(
    []
  );
  const [selectAllDocumentType, setSelectAllDocumentType] = useState(true);

  const isEqual = (
    hasAccess: AccessDetail[],
    oriHasAccess: PermissionAccessStatus[]
  ): boolean => {
    let checkIsEqual = true;
    hasAccess.forEach((acc, i) => {
      if (acc.permission !== oriHasAccess[i]) {
        checkIsEqual = false;
      }
    });
    return checkIsEqual;
  };

  const isEditedInfo = ((): boolean => {
    if (!selectedUserInfo) {
      return false;
    }

    if (selectedType === UserPermissionEditType.Profile) {
      if (selectedUserInfo.name !== fullName) return true;
      return false;
    }

    if (selectedType === UserPermissionEditType.Access) {
      if (selectedUserInfo.containerPermissionStatus === PermissionStatus.All) {
        return !selectAllContainer;
      }
      if (selectAllContainer) {
        return true;
      }
      return !isEqual(userContainers, oriContainers);
    }

    if (selectedType === UserPermissionEditType.DocumentType) {
      if (
        selectedUserInfo.documentTypePermissionStatus === PermissionStatus.All
      ) {
        return !selectAllDocumentType;
      }
      if (selectAllDocumentType) {
        return true;
      }
      return !isEqual(userDocumentTypes, oriDocumentTypes);
    }

    return false;
  })();

  // Get user data
  const fetchUserData = useCallback(
    async (newPageNumber: number) => {
      setShowLoading(true);
      const response: PaginationResponse<TeamManagementResponse> =
        await getUsers(newPageNumber, defaultPageSize, handleError);
      if (response != null) {
        response.results.forEach((user) => {
          const randomColor = getRandomColorCode();
          const newUser: TeamManagementDisplay = user as TeamManagementDisplay;
          newUser.userColor = randomColor;
          if (currentUser.id === newUser.id) {
            newUser.userColor = userConst.color;
          }
          return newUser;
        });
        setUsersResult(response as PaginationResponse<TeamManagementDisplay>);
      }
      setShowLoading(false);
    },
    [currentUser.id, handleError]
  );

  // Get roles
  const fetchRoles = useCallback(async () => {
    const response = await getRoles(handleError);
    if (response != null) {
      setRoles(response);
    }
  }, [handleError]);

  // Get containers
  const fetchContainers = useCallback(async () => {
    const response = await getContainersAsync(1, 100, handleError);
    if (response != null) {
      setContainers(response.results);
    }
  }, [handleError]);

  // Get documnet types
  const fetchDocumentTypes = useCallback(async () => {
    const response = await getDocumentTypes(handleError);
    if (response != null) {
      setDocumentTypes(response);
    }
  }, [handleError]);

  // Get selected user containers
  const fetchUserContainers = async (id: string): Promise<void> => {
    setShowLoadingSidePanel(true);
    const response: UserAccessItem = await getUserContainers(id);
    if (response) {
      const { accesses } = response;
      setSelectAllContainer(
        response.permission === AllPermissionAccessStatus.All
      );
      setUserContainers(accesses);
      setOriContainers(accesses.map((acc: AccessDetail) => acc.permission));
      setShowLoadingSidePanel(false);
    }
  };

  // Get selected user document types
  const fetchUserDocumentTypes = async (id: string): Promise<void> => {
    setShowLoadingSidePanel(true);
    const response = await getUserDocumentTypes(id);
    if (response) {
      const docType = response.accesses;
      setSelectAllDocumentType(
        response.permission === AllPermissionAccessStatus.All
      );
      setUserDocumentTypes(docType);
      setOriDocumentTypes(docType.map((doc: AccessDetail) => doc.permission));
      setShowLoadingSidePanel(false);
    }
  };

  const onSaveUserRole = useCallback(
    async (id, role) => {
      setShowLoading(true);
      const newUserRoles = [role];
      await saveUserRoles(id, newUserRoles);
      setShowLoading(false);
      fetchUserData(usersResult?.page || 1);

      showToaster(
        t('teamManagementScreen.successUpdateNotificationTitle'),
        t('teamManagementScreen.successUpdateRoleNotificationMessage'),
        NotificationVariant.PrimarySuccess
      );

      // If it's ownership transfer, refresh the page to reload the current user context
      // because the current user's role now is inaccurate
      if (role === OWNER) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    },

    [fetchUserData, t, usersResult.page]
  );

  const onSaveUserInfo = async (): Promise<void> => {
    setShowLoading(true);
    setIsProcessing(true);

    let message = '';
    if (selectedType === UserPermissionEditType.Profile && selectedUserInfo) {
      await saveUserProfile(selectedUserInfo.id, fullName);
      message = t('teamManagementScreen.successUpdateNotificationMessage');
    }

    if (selectedType === UserPermissionEditType.Access && selectedUserInfo) {
      const newUserContainers: UserAccessItem = {
        permission: selectAllContainer
          ? AllPermissionAccessStatus.All
          : AllPermissionAccessStatus.Restricted,
        accesses: userContainers,
      };
      await saveUserContainers(selectedUserInfo.id, newUserContainers);
      message = t(
        'teamManagementScreen.successUpdateContainerAccessNotificationMessage'
      );
    }

    if (
      selectedType === UserPermissionEditType.DocumentType &&
      selectedUserInfo
    ) {
      const newUserDOcumentTypes: UserAccessItem = {
        permission: selectAllDocumentType
          ? AllPermissionAccessStatus.All
          : AllPermissionAccessStatus.Restricted,
        accesses: userDocumentTypes,
      };
      await saveUserDocumentTypes(selectedUserInfo.id, newUserDOcumentTypes);
      message = t(
        'teamManagementScreen.successUpdateDocumentTypeAccessNotificationMessage'
      );
    }

    setIsProcessing(false);
    setShowLoading(false);
    fetchUserData(usersResult?.page || 1);
    showToaster(
      t('teamManagementScreen.successUpdateNotificationTitle'),
      message,
      NotificationVariant.PrimarySuccess
    );
  };

  // ----------- End On SAVE ------------ //

  // ----------- UseEffect ------------ //
  useEffect(() => {
    if (
      !userHasPermission(currentUser, UserPermission.ConfigureSystemSettings)
    ) {
      return;
    }

    fetchUserData(defaultPageNumber);
  }, [fetchUserData, currentUser]);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  useEffect(() => {
    fetchContainers();
  }, [fetchContainers]);

  useEffect(() => {
    fetchDocumentTypes();
  }, [fetchDocumentTypes]);

  // ----------- End UseEffect ------------ //

  // ----------- On Invite user ------------ //

  const onSubmitInviteUser = async (
    inviteEmail: string,
    inviteRole: string[]
  ): Promise<void> => {
    setShowLoading(true);
    await createUser(inviteEmail, inviteRole);
    setShowLoading(false);
    setIsShowUserInvitationModal(false);
    fetchUserData(usersResult?.page || 1);
  };

  // ----------- End Invite user ------------ //

  // ----------- On EDIT User Info ------------ //

  const onConfirmChangeRole = (answer: boolean): void => {
    if (answer && selectedUserInfo)
      onSaveUserRole(selectedUserInfo.id, selectedRole);
  };

  const onSetContainer = (id: string): void => {
    const newContainers = [...userContainers].map((container) => {
      if (container.referenceId === id) {
        // eslint-disable-next-line no-param-reassign
        container.permission =
          container.permission === PermissionAccessStatus.Granted
            ? PermissionAccessStatus.Denied
            : PermissionAccessStatus.Granted;
      }
      return container;
    });
    setUserContainers(newContainers);
  };

  const onSetDocumentType = (id: string): void => {
    const newDocumentTypes = [...userDocumentTypes].map((docType) => {
      if (docType.referenceId === id) {
        // eslint-disable-next-line no-param-reassign
        docType.permission =
          docType.permission === PermissionAccessStatus.Granted
            ? PermissionAccessStatus.Denied
            : PermissionAccessStatus.Granted;
      }
      return docType;
    });
    setUserDocumentTypes(newDocumentTypes);
  };

  const onSelectAllDocumentType = (selectAll: boolean): void => {
    setSelectAllDocumentType(selectAll);
  };

  const onSelectAllContainer = (selectAll: boolean): void => {
    setSelectAllContainer(selectAll);
  };

  const onEditUserProfile = (text: string): void => {
    setFullName(text);
  };
  // ----------- End On EDIT User Info ------------ //

  // ----------- Side panel ------------ //
  const sidePanelLoading = (): ReactElement => (
    <div className="loader text-center">
      <LoadingSet size="sm" />
    </div>
  );

  const setSidePanelContent = (): ReactElement => {
    if (selectedType === UserPermissionEditType.Access) {
      if (showLoadingSidePanel) {
        return sidePanelLoading();
      }
      return (
        <EditContainerAccessSidePanel
          containers={containers}
          userContainers={userContainers}
          selectAllContainer={selectAllContainer}
          onSelectAllContainer={onSelectAllContainer}
          onSetContainer={onSetContainer}
        />
      );
    }
    if (selectedType === UserPermissionEditType.DocumentType) {
      if (showLoadingSidePanel) {
        return sidePanelLoading();
      }
      return (
        <EditDocumentTypeAccessSidePanel
          userDocumentTypes={userDocumentTypes}
          documentTypes={documentTypes}
          selectAllDocumentType={selectAllDocumentType}
          onSelectAllDocumentType={onSelectAllDocumentType}
          onSetDocumentType={onSetDocumentType}
        />
      );
    }
    return (
      <EditProfileSidePanel
        fullName={fullName || (selectedUserInfo?.name ?? '')}
        onEditUserProfile={onEditUserProfile}
      />
    );
  };

  const setSidePanel = (
    type: UserPermissionEditType,
    userInfo: TeamManagementResponse,
    role?: string
  ): void => {
    setSelectedType(type);
    setSelectedUserId(userInfo.id);
    if (type === UserPermissionEditType.Role) {
      if (role) {
        setSelectedRole(role);
        setShowConfirmationModalChangeRole(true);
      }
    } else {
      setIsShowSidePanel(true);
      setOpenSidePanel(true);
      if (type === UserPermissionEditType.Access)
        fetchUserContainers(userInfo.id);
      if (type === UserPermissionEditType.DocumentType)
        fetchUserDocumentTypes(userInfo.id);
      if (type === UserPermissionEditType.Profile) setFullName(userInfo.name);
    }
  };

  // ----------- End side panel ------------ //

  const renderOwnerRoleChange = (): ReactElement | undefined => {
    if (selectedRole === OWNER) {
      return (
        <Modal.Body className="to-owner-modal">
          <span>{t('teamManagementScreen.changeRoleOwnerDescription')}</span>
          <div className="user-block" data-hj-suppress>
            <div className="name">{selectedUserInfo?.name ?? ''}</div>
            <div>{selectedUserInfo?.email ?? ''}</div>
          </div>
          <div className="description">
            {t('teamManagementScreen.changeRoleOwnerToSuperUser')}
          </div>
          <div className="learn-more">
            {t('teamManagementScreen.changeRoleOwnerLearnMore')}
          </div>
        </Modal.Body>
      );
    }
    return undefined;
  };

  if (!userHasPermission(currentUser, UserPermission.ConfigureSystemSettings)) {
    return (
      <div className="user-permission-page">
        <RestrictedArea />
      </div>
    );
  }

  return (
    <>
      {showLoading && <DefaultLoading />}
      <Toaster data-hj-suppress />
      <div className="user-permission-page">
        <div className="user-permission-body">
          <div className="pb-2">
            <SDCButton
              Icon={UserInviteIcon}
              text={t('teamManagementScreen.inviteButtonText')}
              onClick={(): void => setIsShowUserInvitationModal(true)}
              variant={SDCButtonVariant.Warning}
              size={SDCButtonSize.Small}
            />
          </div>
          <TeamManagementDataGrid
            users={usersResult.results}
            setSidePanel={setSidePanel}
            allRoles={roles}
            isLoading={showLoading}
            onResendInvitationCompleted={(invitedEmail: string): void => {
              setShowLoading(false);
              showToaster(
                t(
                  'teamManagementScreen.successResendInvitationNotificationTitle'
                ),
                t(
                  'teamManagementScreen.successResendInvitationNotificationMessage',
                  { email: invitedEmail }
                ),
                NotificationVariant.PrimarySuccess
              );
            }}
            onDeleteUserCompleted={(user: TeamManagementDisplay): void => {
              setShowLoading(false);
              const title = user.isInvitationPending
                ? 'teamManagementScreen.successDeleteInvitationNotificationTitle'
                : 'teamManagementScreen.successDeleteUserNotificationTitle';
              const message = user.isInvitationPending
                ? 'teamManagementScreen.successDeleteInvitationNotificationMessage'
                : 'teamManagementScreen.successDeleteUserNotificationMessage';

              showToaster(
                t(title),
                t(message, { name: user.name || user.username }),
                NotificationVariant.PrimarySuccess
              );

              fetchUserData(usersResult?.page || 1);
            }}
            onLoading={(): void => {
              setShowLoading(true);
            }}
          />
          <PaginationNavigator
            pageSize={usersResult?.pageSize || 0}
            lastPage={usersResult?.lastPage || 0}
            currentPage={usersResult?.page || 1}
            totalCount={usersResult?.totalCount || 0}
            onPageChange={fetchUserData}
            descriptionTranslationKey={
              usersResult?.totalCount > usersResult?.pageSize
                ? 'teamManagementScreen.paginationDescription_moreThanPageSize'
                : 'teamManagementScreen.paginationDescription_withinPageSize'
            }
          />
        </div>
        {isShowSidePanel && (
          <div className="user-permission-side-panel">
            <SidePanel
              collapserPosition={CollapserPositionVariant.WithTitleBar}
              title={t('teamManagementScreen.sidePanelTitle')}
              footerActionItem={{
                text: t('teamManagementScreen.sidePanelSaveButton'),
                onClick: onSaveUserInfo,
                isProcessing,
                show: true,
                disabled: !isEditedInfo,
              }}
              showSidePanel={openSidePanel}
              setShowSidePanel={setOpenSidePanel}
            >
              {setSidePanelContent()}
            </SidePanel>
          </div>
        )}
      </div>
      {isShowUserInvitationModal ? (
        <UserInvitationModal
          show={isShowUserInvitationModal}
          setShow={setIsShowUserInvitationModal}
          onSubmit={onSubmitInviteUser}
          roles={roles.filter((r) => r !== OWNER)}
        />
      ) : null}
      {selectedRole === OWNER ? (
        <ConfirmationModal
          title={t('teamManagementScreen.changeRoleOwnerTitle')}
          show={showConfirmationModalChangeRole}
          setShow={setShowConfirmationModalChangeRole}
          onAnswer={onConfirmChangeRole}
          primaryButtonText={t('teamManagementScreen.changeRoleOwnerButton')}
          dismissButtonText={t('confirmationModal.cancelButton')}
          variant={ConfirmationModalVariant.PrimaryWarning}
        >
          {renderOwnerRoleChange()}
        </ConfirmationModal>
      ) : (
        <ConfirmationModal
          title={t('teamManagementScreen.changeRoleConfirmationTitle')}
          show={showConfirmationModalChangeRole}
          setShow={setShowConfirmationModalChangeRole}
          onAnswer={onConfirmChangeRole}
          primaryButtonText={t('confirmationModal.changeButton')}
          dismissButtonText={t('confirmationModal.cancelButton')}
          variant={ConfirmationModalVariant.PrimaryWarning}
        />
      )}
    </>
  );
};

export default TeamManagementPage;
