import { HubConnectionState } from '@microsoft/signalr';
import { createContext, useContext } from 'react';

export type SignalRContextProps = {
  connection: signalR.HubConnection;
  connectionState: HubConnectionState;
};

export const SignalRContext = createContext<SignalRContextProps | undefined>(
  undefined
);

export const useSignalR = (): SignalRContextProps => {
  const context = useContext(SignalRContext);
  if (context === undefined) {
    throw new Error('useSignalR must be used within a SignalRProvider');
  }
  return context;
};
