import { MutableRefObject, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as CloseIcon } from '../../assets/svg/CloseIcon.svg';
import { ReactComponent as SearchIcon } from '../../assets/svg/SearchIcon.svg';
import getDefaultPageSize from '../../common/configurations/pageConfig';
import DataTypeItem from '../../common/models/dataTypeItem';
import DocumentSearchOptions from '../../common/models/documentSearchOptions';
import FilterConditionItem from '../../common/models/filterConditionItem';
import KeyPairItem from '../../common/models/keypairItem';
import DataType from '../../common/types/dataType';
import DropdownItemRole from '../../common/types/dropdownItemRole';
import KeyCode from '../../common/types/keyCode';
import SDCButtonSize from '../../common/types/sdcButtonSize';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import SDCTextFieldSize from '../../common/types/sdcTextFieldSize';
import { useContainer } from '../../context/Container/ContainerContext';
import { getDocumentTemplate } from '../../services/documentType/documentType.service';
import DocumentType from '../../services/documentType/models/documentType';
import MetadataTemplateItem from '../../services/documentType/models/metadataTemplateItem';
import FilterDropdown from '../FilterDropdown/FilterDropdown';
import SDCButton from '../SDCButton/SDCButton';
import SDCTextField from '../SDCTextField/SDCTextField';
import SecondaryDropdown from '../SecondaryDropdown/SecondaryDropdown';
import FilterTag from './FilterTag';
import './SearchFilter.scss';

interface SearchFilterProps {
  documentTypeItems: KeyPairItem[];
  documentTypes: DocumentType[];
  onSearch: (options: DocumentSearchOptions) => void;
  filterDisabled?: boolean;
  inputRef?: MutableRefObject<any>;
}

const tagValueMaxLength = 20;
const metadataFieldPrefix = 'metadata_';

const SearchFilter = ({
  onSearch,
  documentTypeItems,
  documentTypes,
  filterDisabled,
  inputRef,
}: SearchFilterProps): ReactElement => {
  const { t } = useTranslation();
  const { containerKey } = useContainer();
  const tableSectionPrefix = t(
    'searchFilterComponent.tableColumnSectionDividerPrefix'
  );
  const templateDropdownDivider: DataTypeItem = {
    name: t('searchFilterComponent.templateSectionDivider'),
    value: '',
    dataType: DataType.String,
    role: DropdownItemRole.Divider,
  };
  const defaultSearchDocumentFields: DataTypeItem[] = [
    {
      name: t('searchFilterComponent.systemSectionDivider'),
      value: '',
      dataType: DataType.String,
      role: DropdownItemRole.Divider,
    },
    {
      name: t('searchFilterComponent.systemFieldDocumentName'),
      value: 'document_display_name',
      dataType: DataType.String,
      isSystemDefined: true,
      role: DropdownItemRole.Button,
    },
    {
      name: t('searchFilterComponent.systemFieldFileName'),
      value: 'file_name',
      isSystemDefined: true,
      role: DropdownItemRole.Button,
      dataType: DataType.String,
    },
    {
      name: t('searchFilterComponent.systemFieldCreatedBy'),
      value: 'created_by',
      isSystemDefined: true,
      role: DropdownItemRole.Button,
      dataType: DataType.String,
    },
    {
      name: t('searchFilterComponent.systemFieldCreatedOn'),
      value: 'created_on',
      isSystemDefined: true,
      role: DropdownItemRole.Button,
      dataType: DataType.Date,
    },
  ];

  const [searchText, setSearchText] = useState('');
  const [selectedItem, setSelectedItem] = useState<KeyPairItem | undefined>(
    undefined
  );
  const [fields, setFields] = useState<DataTypeItem[]>(
    defaultSearchDocumentFields
  );
  const [firstLoad, setFirstLoad] = useState(true);
  const [filterItems, setFilterItems] = useState<FilterConditionItem[]>([]);
  const allDocumentType = t('documentsViewScreen.documentTypeDropDownDefault');
  const betweenConjunction = t('searchFilterComponent.betweenConjunction');

  const keyUpHandler = (value: string): void => {
    setSearchText(value);
  };

  const buildFilterQuery = (
    filters: FilterConditionItem[]
  ): string | undefined => {
    if (filters.length === 0) {
      return undefined;
    }

    return filters.map((x) => x.query).join(` ${betweenConjunction} `);
  };

  const getTemplateName = (): string | undefined =>
    selectedItem?.Name === allDocumentType ? undefined : selectedItem?.Value;

  const handleOnSearch = (): void => {
    const filterQuey = buildFilterQuery(filterItems);

    onSearch({
      text: searchText,
      page: 1,
      pageSize: getDefaultPageSize(),
      filter: filterQuey,
      documentType: getTemplateName(),
      // Default sorting. This gets overwritten by other compoenet
      sortBy: undefined,
    });
  };

  useEffect(() => {
    if (!firstLoad) {
      const filterQuey = buildFilterQuery(filterItems);

      onSearch({
        text: searchText,
        page: 1,
        pageSize: getDefaultPageSize(),
        filter: filterQuey,
        documentType: getTemplateName(),
        // Default sorting. This gets overwritten by other compoenet
        sortBy: undefined,
      });
    } else {
      setFirstLoad(false);
    }
    // IMPORTANT: Do not remove this eslint-disable.
    // Only want to trigger containerKey state variable change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerKey]);

  const handleApplyFilter = (filter: FilterConditionItem): void => {
    const newFilterItems = [...filterItems, filter];
    setFilterItems(newFilterItems);

    const filterQuey = buildFilterQuery(newFilterItems);

    onSearch({
      text: searchText,
      page: 1,
      pageSize: getDefaultPageSize(),
      filter: filterQuey,
      documentType: getTemplateName(),
      // Default sorting. This gets overwritten by any parent
      sortBy: undefined,
    });
  };

  const handleRemoveFilter = (filter: FilterConditionItem): void => {
    const newFilterItems = [...filterItems];
    const index = newFilterItems.indexOf(filter);
    if (index !== -1) {
      newFilterItems.splice(index, 1);
      setFilterItems(newFilterItems);
    }

    const filterQuey = buildFilterQuery(newFilterItems);

    onSearch({
      text: searchText,
      page: 1,
      pageSize: getDefaultPageSize(),
      filter: filterQuey,
      documentType: getTemplateName(),
      // Default sorting. This gets overwritten by any parent
      sortBy: undefined,
    });
  };

  const handleClearFilters = (): void => {
    setFilterItems([]);
    onSearch({
      text: searchText,
      page: 1,
      pageSize: getDefaultPageSize(),
      filter: undefined,
      documentType: getTemplateName(),
      // Default sorting. This gets overwritten by any parent
      sortBy: undefined,
    });
  };

  const handleKeyPress = (key: string): void => {
    if (key === KeyCode.KeyEnter) {
      handleOnSearch();
    }
  };

  const prefixMetadataField = (fieldName: string): string =>
    `${metadataFieldPrefix}${fieldName}`;

  const prefixTableMetadataField = (
    metadataKey: string,
    fieldName: string
  ): string => `${metadataFieldPrefix}${metadataKey}.${fieldName}`;

  const prefixTableSectionDivider = (name: string): string =>
    `${tableSectionPrefix}${name}`;

  const handleDocumentTypeSelect = (item: KeyPairItem): void => {
    setSelectedItem(item);

    // Get the ID to load the template
    const documentType = documentTypes.find((x) => x.name === item.Value);
    if (!documentType) {
      setFields(defaultSearchDocumentFields);
      return;
    }
    getDocumentTemplate(documentType?.id)
      .then((response) => {
        const templateItems = response.template.map(
          (templateItem: MetadataTemplateItem) =>
            ({
              name: templateItem.displayName,
              value: prefixMetadataField(templateItem.name),
              dataType: templateItem.dataType,
              role: DropdownItemRole.Button,
            } as DataTypeItem)
        );

        let allFields = [...defaultSearchDocumentFields].concat(
          templateDropdownDivider,
          templateItems.filter((x) => x.dataType !== DataType.Table)
        );

        // Add table template
        response.template.forEach((metadataTemplate) => {
          if (metadataTemplate.template.length) {
            const divider: DataTypeItem = {
              name: prefixTableSectionDivider(metadataTemplate.displayName),
              value: '',
              dataType: DataType.String,
              role: DropdownItemRole.Divider,
            };
            const tableTemplateItems = metadataTemplate.template.map(
              (tableTemplate: MetadataTemplateItem) =>
                ({
                  name: tableTemplate.displayName,
                  value: prefixTableMetadataField(
                    metadataTemplate.name,
                    tableTemplate.name
                  ),
                  dataType: tableTemplate.dataType,
                  role: DropdownItemRole.Button,
                } as DataTypeItem)
            );

            allFields = [...allFields].concat(divider, tableTemplateItems);
          }
        });

        setFields(allFields);
      })
      .catch(() => {
        setFields(defaultSearchDocumentFields);
      });
  };

  useEffect(() => {
    if (selectedItem === undefined) {
      setSelectedItem(documentTypeItems[0]);
    }
  }, [documentTypeItems, selectedItem]);

  const renderSearchBox = (): ReactElement => (
    <div className="search-box-container">
      <SDCTextField
        inputRef={inputRef}
        value={searchText}
        Icon={SearchIcon}
        fieldSize={SDCTextFieldSize.Small}
        placeholder={t('searchFilterComponent.placeholderSearch')}
        onChange={(e): void => keyUpHandler(e.target.value)}
        onKeyPress={(e): void => handleKeyPress(e.key)}
      />
      <div className="dropdown-container">
        <SecondaryDropdown
          selectedValue={selectedItem?.Value ?? ''}
          items={documentTypeItems}
          onSelect={handleDocumentTypeSelect}
        />
      </div>
    </div>
  );

  const renderTagItem = (item: FilterConditionItem): ReactElement => {
    const value = item.values
      .map((x: string) => `“${x}”`)
      .join(` ${betweenConjunction} `);

    const getTagContent = (filterItem: FilterConditionItem): ReactElement => (
      <span className="condition-description" data-tip={value}>
        <span className="field">{filterItem.fieldName}</span>
        <span className="operator">{filterItem.operatorDescription}</span>
        <span className="value">{value}</span>
        {value.length > tagValueMaxLength ? (
          <ReactTooltip effect="solid" />
        ) : null}
      </span>
    );

    return getTagContent(item);
  };

  const renderFilterTags = (): ReactElement | null => {
    if (filterItems.length === 0) {
      return null;
    }
    return (
      <>
        {filterItems.map((item: FilterConditionItem) => (
          <FilterTag
            key={`filter-tag-${item.fieldName}-${
              item.values.length > 0 ? item.values[0] : 'value'
            }`}
            onRemove={(): void => {
              handleRemoveFilter(item);
            }}
          >
            {renderTagItem(item)}
          </FilterTag>
        ))}
      </>
    );
  };

  return (
    <div className="search-filter-container">
      <div className="search-control-container">
        {renderSearchBox()}
        <SDCButton
          size={SDCButtonSize.Small}
          variant={SDCButtonVariant.Primary}
          onClick={(): void => handleOnSearch()}
          text={t('searchFilterComponent.searchButton')}
        />
      </div>

      <div className="filter-container">
        {renderFilterTags()}
        <FilterDropdown
          list={fields}
          disabled={filterDisabled}
          onApplyFilter={handleApplyFilter}
        />
        {filterItems.length !== 0 ? (
          <button
            title={t('searchFilterComponent.titleOnClearButton')}
            className="clear-filter-button"
            type="button"
            onClick={(): void => {
              handleClearFilters();
            }}
          >
            <CloseIcon />
          </button>
        ) : null}
      </div>
    </div>
  );
};

SearchFilter.defaultProps = {
  filterDisabled: false,
  inputRef: undefined,
};

export default SearchFilter;
