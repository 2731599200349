import React, { ReactElement, useEffect, useState } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MenuOptionsIcon } from '../../../assets/svg/MenuOptionsIcon.svg';
import { ReactComponent as PenOutlinedIcon } from '../../../assets/svg/PenOutlinedIcon.svg';
import KeyPairItem from '../../../common/models/keypairItem';
import ConfirmationModalVariant from '../../../common/types/confirmationModalVariant';
import PermissionStatus from '../../../common/types/permissionStatus';
import UserPermissionEditType from '../../../common/types/userPermissionEditType';
import { useCurrentUser } from '../../../context/CurrentUser/CurrentUserContext';
import TeamManagementDisplay from '../../../services/teamManagement/models/teamManagementDisplay';
import {
  createUser,
  deleteUser,
} from '../../../services/teamManagement/teamManagement.service';
import ConfirmationModal from '../../DefaultModal/ConfirmationModal';
import SDCDropdown from '../../SDCDropdown/SDCDropdown';
import UserInitial from '../../UserInitial/UserInitial';
import DataGrid, { DataGridHeaderDefinition } from '../DataGrid';
import { DataGridRow } from '../GridRowCollection';
import './TeamManagementDataGrid.scss';

export interface TeamManagementDataGridProps {
  users: Array<TeamManagementDisplay>;
  setSidePanel: Function;
  allRoles: Array<string>;
  isLoading: boolean;
  onResendInvitationCompleted: (email: string) => void;
  onDeleteUserCompleted: (user: TeamManagementDisplay) => void;
  onLoading: () => void;
}

const TeamManagementDataGrid = ({
  users,
  setSidePanel,
  allRoles,
  isLoading,
  onResendInvitationCompleted,
  onDeleteUserCompleted,
  onLoading,
}: TeamManagementDataGridProps): ReactElement => {
  const OWNER = 'Owner';
  const { t } = useTranslation();
  const [showConfirmationModalRemoveUser, setShowConfirmationModalRemoveUser] =
    useState(false);
  const { currentUser } = useCurrentUser();
  const [selectedRow, setSelectedRow] = useState<TeamManagementDisplay>();
  const [rolesKeyPair, setRolesKeyPair] = useState<KeyPairItem[]>([]);
  const headers: Array<DataGridHeaderDefinition> = [
    {
      columnKey: 'teamManagementScreen.membersColHeader',
      translationKey: 'teamManagementScreen.membersColHeader',
      columnExtraWidth: true,
    },
    {
      columnKey: 'teamManagementScreen.roleColHeader',
      translationKey: 'teamManagementScreen.roleColHeader',
    },
    {
      columnKey: 'teamManagementScreen.containerColHeader',
      translationKey: 'teamManagementScreen.containerColHeader',
    },
    {
      columnKey: 'teamManagementScreen.documentTypeColHeader',
      translationKey: 'teamManagementScreen.documentTypeColHeader',
    },
    {
      columnKey: '',
      translationKey: '',
    },
  ];

  const getCurrentRoleItem = (role: string): undefined | KeyPairItem => {
    if (rolesKeyPair) return rolesKeyPair.find((r) => r.Value === role);
    return undefined;
  };

  const editUser = (
    type: UserPermissionEditType,
    row: TeamManagementDisplay,
    selectedRole?: string
  ): void => {
    setSelectedRow(row);
    setSidePanel(type, row, selectedRole);
  };

  useEffect(() => {
    if (allRoles && rolesKeyPair.length === 0) {
      const rolesTranslation: KeyPairItem[] = [
        { Value: 'Administrator', Name: t('teamManagementScreen.roleAdmin') },
        { Value: 'SuperUser', Name: t('teamManagementScreen.roleSuperUser') },
        {
          Value: 'CaptureAndView',
          Name: t('teamManagementScreen.roleCaptureAndView'),
        },
        { Value: 'View', Name: t('teamManagementScreen.roleView') },
        { Value: 'Owner', Name: t('teamManagementScreen.roleOwner') },
      ];
      const toKeyPair: KeyPairItem[] = allRoles.map(
        (r) =>
          ({
            Name: rolesTranslation.find((x) => x.Value === r)?.Name,
            Value: r,
          } as KeyPairItem)
      );
      if (currentUser.roles && currentUser.roles[0] !== OWNER) {
        setRolesKeyPair(toKeyPair.filter((r) => r.Name !== OWNER));
      } else {
        setRolesKeyPair(toKeyPair);
      }
    }
  }, [allRoles, currentUser.roles, rolesKeyPair.length, t]);

  // Dropdown needs access to the DOM node in order to position the Menu
  // eslint-disable-next-line react/prop-types
  const CustomToggle = React.forwardRef(
    (
      { children, onClick }: any,
      ref: React.LegacyRef<HTMLAnchorElement> | undefined
    ) => (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        ref={ref}
        href=""
        onClick={(e): void => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {/* Render custom icon here */}
        <MenuOptionsIcon />
        {children}
      </a>
    )
  );

  const resendInvitation = async (
    invitedEmail: string,
    newRoles: string[]
  ): Promise<void> => {
    onLoading();
    await createUser(invitedEmail, newRoles);
    onResendInvitationCompleted(invitedEmail);
  };

  const removeUser = async (confirm: boolean): Promise<void> => {
    if (selectedRow && confirm) {
      onLoading();
      await deleteUser(selectedRow.id);
      onDeleteUserCompleted(selectedRow);
      setSelectedRow(undefined);
    }
  };

  const renderDeleteConfirmation = (): ReactElement | undefined => {
    const title = selectedRow?.isInvitationPending
      ? 'teamManagementScreen.deleteUserInvitationConfirmationTitle'
      : 'teamManagementScreen.deleteUserConfirmationTitle';
    const button = selectedRow?.isInvitationPending
      ? 'teamManagementScreen.deleteUserInvitationButton'
      : 'teamManagementScreen.deleteUserButton';
    const description = selectedRow?.isInvitationPending
      ? 'teamManagementScreen.deleteUserInvitationDescription'
      : 'teamManagementScreen.deleteUserDescription';
    return (
      <ConfirmationModal
        title={t(title)}
        show={showConfirmationModalRemoveUser}
        setShow={setShowConfirmationModalRemoveUser}
        onAnswer={(isYes: boolean): Promise<void> => removeUser(isYes)}
        primaryButtonText={t(button)}
        dismissButtonText={t('confirmationModal.cancelButton')}
        variant={ConfirmationModalVariant.PrimaryWarning}
      >
        <Modal.Body className="to-owner-modal">
          <span>{t(description)}</span>
          <div className="user-block">
            <div className="name">{selectedRow?.name}</div>
            <div>{selectedRow?.email}</div>
          </div>
        </Modal.Body>
      </ConfirmationModal>
    );
  };

  const renderDropdownMenu = (element: TeamManagementDisplay): ReactElement => {
    // Disable options for self and owner. Cannot delete self and etc.
    if (currentUser?.id === element.id || element.roles.includes(OWNER)) {
      return <></>;
    }

    if (element.isInvitationPending) {
      return (
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
          <Dropdown.Menu>
            <Dropdown.Item
              eventKey="1"
              onClick={(): Promise<void> =>
                resendInvitation(element.email, element.roles)
              }
            >
              {t('teamManagementScreen.resendUserInvitationButton')}
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              onClick={(): void => {
                setSelectedRow(element);
                setShowConfirmationModalRemoveUser(true);
              }}
            >
              {t('teamManagementScreen.deleteUserInvitationButton')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
        <Dropdown.Menu>
          <Dropdown.Item
            eventKey="1"
            onClick={(): void => {
              setSelectedRow(element);
              setShowConfirmationModalRemoveUser(true);
            }}
          >
            {t('teamManagementScreen.deleteUserButton')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const rows: DataGridRow[] = users.map((element) => ({
    id: element.id,
    rowClass: element.id === selectedRow?.id ? 'selected' : '',
    columnExtraWidth: true,
    cells: [
      {
        columnKey: '1',
        dataKey: 'teamManagementScreen.membersColHeader',
        value: element.name,
        columnExtraWidth: true,
        template: (
          <div className="user-permission-member-col">
            <UserInitial
              color={element.userColor}
              displayName={element.name || element.email}
              isInvitationPending={element.isInvitationPending}
            />
            <div className="truncate">
              <div className="name">
                {element.name}{' '}
                {!element.isInvitationPending && (
                  <PenOutlinedIcon
                    className="ms-2 clickable"
                    onClick={(): void =>
                      editUser(UserPermissionEditType.Profile, element)
                    }
                  />
                )}
              </div>
              <div
                className={
                  element.isInvitationPending ? 'pending-email' : 'email'
                }
              >
                {element.email}&nbsp;
              </div>
              {element.isInvitationPending && (
                <div className="pending-tag">
                  <span className="rectangle" />
                  <span className="text">
                    {t('teamManagementScreen.pendingTag')}
                  </span>
                </div>
              )}
            </div>
          </div>
        ),
      },
      {
        columnKey: '2',
        dataKey: 'teamManagementScreen.roleColHeader',
        value: '',
        template: (
          <div className="user-permission-role-col">
            {element && element.roles[0] === OWNER ? (
              <div className="owner">{element.roles[0]}</div>
            ) : (
              <SDCDropdown
                title={`dropdown-role-${element.id}`}
                disabled={element.id === currentUser.id}
                noOutline
                currentItem={getCurrentRoleItem(
                  element.roles.length ? element.roles[0] : ''
                )}
                values={
                  !element.isInvitationPending
                    ? rolesKeyPair
                    : rolesKeyPair.filter((x) => x.Name !== OWNER)
                }
                onSelect={(e): void =>
                  editUser(UserPermissionEditType.Role, element, e.Value)
                }
              />
            )}
          </div>
        ),
      },
      {
        columnKey: '3',
        dataKey: 'teamManagementScreen.containerColHeader',
        value: element.containerPermissionStatus,
        template: (
          <div className="d-flex name">
            {element.containerPermissionStatus === PermissionStatus.None
              ? '-'
              : element.containerPermissionStatus}
            <PenOutlinedIcon
              className="ms-2 clickable"
              onClick={(): void =>
                editUser(UserPermissionEditType.Access, element)
              }
            />
          </div>
        ),
      },
      {
        columnKey: '4',
        dataKey: 'teamManagementScreen.documentTypeColHeader',
        value: element.documentTypePermissionStatus,
        template: (
          <div className="d-flex name">
            {element.documentTypePermissionStatus === PermissionStatus.None
              ? '-'
              : element.documentTypePermissionStatus}{' '}
            <PenOutlinedIcon
              className="ms-2 clickable"
              onClick={(): void =>
                editUser(UserPermissionEditType.DocumentType, element)
              }
            />
          </div>
        ),
      },
      {
        columnKey: '5',
        dataKey: 'dropdown-menu',
        value: '',
        template: (
          <div className="d-flex name">{renderDropdownMenu(element)}</div>
        ),
      },
    ],
    clickable: false,
  }));

  return (
    <div className="team-management-data-grid">
      <DataGrid
        headerDefinitions={headers}
        tableContent={rows}
        selectable={false}
        setSelectableStates={(): void => {}}
        sortableColumnKeys={['teamManagementScreen.membersColHeader']}
        noResultsMessage=""
        isLoading={isLoading}
      />
      {renderDeleteConfirmation()}
    </div>
  );
};

export default TeamManagementDataGrid;
