import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/Auth/AuthContext';
import MainNavigationMenu from '../MainNavigationMenu/MainNavigationMenu';
import MainNavigationNoSideMenu from '../MainNavigationNoSideMenu/MainNavigationNoSideMenu';
import NavigationSideMenu from '../NavigationSideMenu/NavigationSideMenu';

const Header = (): ReactElement => {
  const enabledNavigationSideMenuRoutes = [
    '/pending-tray',
    '/team-management',
    '/container-management',
    '/document-protection',
    '/search',
    '/report',
    '/trash',
    '/setting',
    '/subscriptions',
    '/api-access',
    '/',
  ];
  const { authService } = useAuth();
  const isAuthenticated = authService.isAuthenticated();

  const location = useLocation();

  const checkPendingTraysPath = (): ReactElement => {
    if (isAuthenticated) {
      if (enabledNavigationSideMenuRoutes.includes(location.pathname)) {
        return (
          <>
            <NavigationSideMenu />
            <MainNavigationMenu />
          </>
        );
      }
      return <MainNavigationNoSideMenu />;
    }

    return <div />;
  };

  return checkPendingTraysPath();
};

export default Header;
