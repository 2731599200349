import dayjs from 'dayjs';
import { ReactElement } from 'react';

interface DateTimeFormatterProps {
  date: Date;
  format: string;
}

const DateTimeFormatter = ({
  date,
  format,
}: DateTimeFormatterProps): ReactElement => <>{dayjs(date).format(format)}</>;

export default DateTimeFormatter;
