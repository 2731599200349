export const removeTrailingSlashes = (input: string): string => {
  if (input[input.length - 1] === '/')
    return input.substring(0, input.length - 1);

  return input;
};

export const isEmpty = (text: string): boolean =>
  text == null || text.match(/^\s*$/) !== null;

export const createUUID = (): string =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const generateBarcode = (length: number): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const createEmptyUUID = (): string =>
  '00000000-0000-0000-0000-000000000000';

/**
 * Checks if the given text is a number (integer and decimal).
 * Source: https://stackoverflow.com/a/70408790/9754435
 * @param text
 * @returns
 */
export const isNumeric = (text: string): boolean =>
  /^-?\d+(?:\.\d+)?$/.test(text);

// Get random color code
export const getRandomColorCode = (): string => {
  // random color palette
  const colorPalette: string[] = [
    '00487c',
    '4bb3fd',
    '3e6680',
    '0496ff',
    '027bce',
    'e01a4f',
    'f15946',
    'f9c22e',
    '53b3cb',
    '0c090d',
    '7a3b69',
  ];
  return colorPalette[Math.floor(Math.random() * colorPalette.length)];
};
