const enum SDCButtonVariant {
  Default = 'default',
  Primary = 'primary',
  Warning = 'warning',
  Secondary = 'secondary',
  Outlined = 'outlined',
  OutlinedDark = 'outlined-dark',
  Link = 'link',
  LinkSecondary = 'link-secondary',
  Text = 'text',
}
export default SDCButtonVariant;
