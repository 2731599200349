import {
  FunctionComponent,
  ReactElement,
  SVGProps,
  useEffect,
  useState
} from 'react';
import { Navbar, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronRightLargeIcon } from '../../../assets/svg/ChevronRightLargeIcon.svg';
import { ReactComponent as CloudCheckIcon } from '../../../assets/svg/CloudCheckIcon.svg';
import { ReactComponent as KeyLargeIcon } from '../../../assets/svg/KeyLargeIcon.svg';
import { ReactComponent as ProtectionIcon } from '../../../assets/svg/ProtectionIcon.svg';
import { ReactComponent as SettingIcon } from '../../../assets/svg/SettingIcon.svg';
import KeyPairItem from '../../../common/models/keypairItem';
import PageTitle from '../../../common/pageTitle';
import isTabletSize from '../../../utils/responsiveUtil';
import './ApplicationMenuItem.scss';

type ApplicationMenuItemProps = {
  pageTitle: PageTitle;
};

const ApplicationMenuItem = ({
  pageTitle,
}: ApplicationMenuItemProps): ReactElement => {
  const settingsIconDimension = 22;
  const { t } = useTranslation();
  const [Icon, setIcon] =
    useState<FunctionComponent<SVGProps<SVGSVGElement>>>();
  const settingsItems: KeyPairItem[] = [
    {
      Name: t('mainMenu.documentProtectionMenuItem'),
      Value: '/document-protection',
    },
    {
      Name: t('mainMenu.containerManagementMenuItem'),
      Value: '/container-management',
    },
    {
      Name: t('mainMenu.apiAccessMenuItem'),
      Value: '/api-access',
    },
  ];

  useEffect(() => {
    if (pageTitle.Icon) setIcon(pageTitle.Icon);
  }, [pageTitle]);

  const renderSettingsMenuIcon = (value: string): ReactElement | null => {
    if (value === '/document-protection') {
      return (
        <ProtectionIcon
          width={settingsIconDimension}
          height={settingsIconDimension}
        />
      );
    }
    if (value === '/container-management') {
      return (
        <CloudCheckIcon
          width={settingsIconDimension}
          height={settingsIconDimension}
        />
      );
    }
    if (value === '/api-access') {
      return (
        <KeyLargeIcon
          width={settingsIconDimension}
          height={settingsIconDimension}
        />
      );
    }

    return null;
  };

  const renderSettingsMenu = (): ReactElement | null => {
    if (!pageTitle.useSettingsMenu) {
      return null;
    }

    return (
      <>
        <SettingIcon />
        <NavDropdown title="Settings" className="nav-dropdown">
          {settingsItems.map((item: KeyPairItem) => (
            <NavDropdown.Item
              key={item.Name}
              onClick={(): void => {
                window.location.href = item.Value;
              }}
            >
              {renderSettingsMenuIcon(item.Value)}
              {item.Name}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
        <div className="pe-3">
          <ChevronRightLargeIcon />
        </div>
      </>
    );
  };

  const renderTitleText = (): null | ReactElement => {
    if (!pageTitle.titleText) {
      return null;
    }

    return (
      <>
        <Navbar.Text className="application-menu-nav-title">
          {renderSettingsMenu()}
          {Icon ? <Icon className="title-icon" /> : ''}
          {pageTitle.titleText}
          {pageTitle.descriptionText &&
          !pageTitle.useSettingsMenu &&
          !isTabletSize() ? (
            <div className="description">{pageTitle.descriptionText}</div>
          ) : (
            ''
          )}
        </Navbar.Text>
        <div className="me-auto no-border" />
      </>
    );
  };

  return <>{renderTitleText()}</>;
};
export default ApplicationMenuItem;
