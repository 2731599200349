type ProductInfo = {
  productName: string | undefined;
  supportUrl: string | undefined;
  termsOfServiceUrl: string | undefined;
  privacyPolicyUrl: string | undefined;
};

const getProductInfo = (): ProductInfo => ({
  productName: process.env.REACT_APP_PRODUCT_NAME,
  supportUrl: process.env.REACT_APP_SUPPORT_URL,
  termsOfServiceUrl: process.env.REACT_APP_TERMSOFSERVICE_URL,
  privacyPolicyUrl: process.env.REACT_APP_PRIVACYPOLICY_URL,
});

export default getProductInfo;
