/* eslint-disable default-case */
import {
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import SearchNotFoundImage from '../../assets/img/search-not-found.png';
import DataGridRowSortFunctionProvider from '../../common/providers/dataGridRowSortFunctionProvider';
import SortDirection from '../../common/types/sortDirection';
import LoadingSet from '../LoadingSet/LoadingSet';
import './DataGrid.scss';
import GridHeader, { DataGridHeaderProps } from './GridHeader';
import GridRowCollection, { DataGridRow } from './GridRowCollection';

export interface DataGridHeaderDefinition {
  columnKey: string;
  translationKey: string;
  columnExtraWidth?: boolean;
}

export interface DataGridProps {
  headerDefinitions: Array<DataGridHeaderDefinition>;
  tableContent: DataGridRow[];
  selectable: boolean;
  setSelectableStates: React.Dispatch<React.SetStateAction<string[]>>;
  selectedValues?: string[];
  sortableColumnKeys: string[];
  noResultsMessage: string;
  onSelectAllChange?: (checked: boolean) => void;
  isLoading?: boolean;
}

const createHeaders = (
  headers: Array<DataGridHeaderDefinition>
): Array<DataGridHeaderProps> =>
  headers.map((header) => ({
    columnKey: header.columnKey,
    translationKey: header.translationKey,
    ref: useRef(),
    columnExtraWidth: header.columnExtraWidth,
  }));

const DataGrid = ({
  headerDefinitions,
  tableContent,
  selectable,
  setSelectableStates,
  sortableColumnKeys,
  onSelectAllChange,
  selectedValues,
  noResultsMessage,
  isLoading,
}: DataGridProps): ReactElement => {
  const getDefaultSortDirectionConfig = (): {
    [key: string]: SortDirection;
  } => {
    const directions: { [key: string]: SortDirection } = {};
    sortableColumnKeys.forEach((value: string) => {
      directions[value] = SortDirection.None;
    });
    return directions;
  };

  const [content, setContent] = useState<DataGridRow[]>([]);
  const [sortDirection, setSortDirection] = useState<{
    [key: string]: SortDirection;
  }>(getDefaultSortDirectionConfig());
  const tableElement = useRef(null) as MutableRefObject<any>;
  const columns = createHeaders(headerDefinitions);
  const [currentSortColumn, setCurrentSortColumn] = useState('');

  useEffect(() => {
    setContent([...tableContent]);
  }, [tableContent]);

  const onSort = (columnKey: string): void => {
    let nextDirection = sortDirection[columnKey];

    setCurrentSortColumn(columnKey);

    // Rotate sort directions
    switch (nextDirection) {
      case SortDirection.None: {
        nextDirection = SortDirection.Ascending;
        break;
      }
      case SortDirection.Ascending: {
        nextDirection = SortDirection.Descending;
        break;
      }
      default: {
        nextDirection = SortDirection.None;
      }
    }

    // Update
    const directions = getDefaultSortDirectionConfig();
    directions[columnKey] = nextDirection;
    setSortDirection(directions);

    // Do the sorting
    const sortFunctionProvider = new DataGridRowSortFunctionProvider(
      currentSortColumn
    );
    if (nextDirection === SortDirection.Ascending) {
      const gridColumns = [...tableContent].sort(
        sortFunctionProvider.sortDataRow
      );
      setContent(gridColumns);
      return;
    }
    if (nextDirection === SortDirection.Descending) {
      const gridColumnsReverse = [...tableContent]
        .sort(sortFunctionProvider.sortDataRow)
        .reverse();
      setContent(gridColumnsReverse);
      return;
    }
    setContent([...tableContent]);
  };

  useEffect(() => {
    if (sortableColumnKeys.length > 0) {
      const sortFunctionProvider = new DataGridRowSortFunctionProvider(
        currentSortColumn
      );
      const gridColumns = [...tableContent].sort(
        sortFunctionProvider.sortDataRow
      );
      setContent(gridColumns);
    }
  }, [sortableColumnKeys, tableContent, currentSortColumn]);

  const renderResults = (): ReactElement => {
    if (isLoading) {
      return <LoadingSet />;
    }

    if (content.length === 0) {
      return (
        <>
          <div className="result-placeholder-container">
            <img src={SearchNotFoundImage} alt="" />
            <div className="not-found-content">{noResultsMessage}</div>
          </div>
          <div className="simple-result-placeholder-container">
            {noResultsMessage}
          </div>
        </>
      );
    }

    return (
      <div className="data-row-container">
        <GridRowCollection
          rows={content}
          selectable={selectable}
          setSelectableStates={setSelectableStates}
          values={selectedValues ?? []}
        />
      </div>
    );
  };

  return (
    <div className="data-grid-wrapper">
      <div ref={tableElement} id="sortTable">
        <GridHeader
          headers={columns}
          selectable={selectable}
          onSort={onSort}
          sortDirection={sortDirection}
          sortableColumnKeys={sortableColumnKeys}
          onSelectAllChange={onSelectAllChange}
          disabled={tableContent.length === 0}
        />
        {renderResults()}
      </div>
    </div>
  );
};

export default DataGrid;

DataGrid.defaultProps = {
  onSelectAllChange: (): void => {},
  selectedValues: [],
  isLoading: false,
};
