import { getSdcClient } from '../../common/apiClients/sdcClient.instance';
import handleApplicationError from '../../utils/errorUtil';
import getAuthInfo from '../auth/authInfo';
import DocumentType from '../documentType/models/documentType';
import PaginationResponse from '../paginationResponse';
import TeamManagementResponse from './models/teamManagementResponse';
import UserAccessItem from './models/userAccessItem';

export const getUsers = async (
  page: number,
  pageSize: number,
  errorHandler?: Function | undefined
): Promise<PaginationResponse<TeamManagementResponse>> => {
  const sdcClient = getSdcClient();
  const url: string = './api/v1/users';
  try {
    const response = await sdcClient.get<
      PaginationResponse<TeamManagementResponse>
    >(url, {
      params: {
        page,
        pageSize,
      },
    });
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }
  return {} as PaginationResponse<TeamManagementResponse>;
};

export const getRoles = async (
  errorHandler?: Function | undefined
): Promise<string[]> => {
  const sdcClient = getSdcClient();
  const url: string = './api/v1/users/roles';
  try {
    const response = await sdcClient.get(url);
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }
  return [];
};

export const getDocumentTypes = async (
  errorHandler?: Function | undefined
): Promise<DocumentType[]> => {
  const sdcClient = getSdcClient();
  const url: string = `./api/v1/document-types`;
  try {
    const response = await sdcClient.get(url);
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }
  return [] as DocumentType[];
};

export const getUserContainers = async (
  userId: string,
  errorHandler?: Function | undefined
): Promise<UserAccessItem> => {
  const sdcClient = getSdcClient();
  const url: string = `./api/v1/users/${userId}/accesses/containers`;
  try {
    const response = await sdcClient.get(url);
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }
  return {} as UserAccessItem;
};

export const getUserDocumentTypes = async (
  userId: string,
  errorHandler?: Function | undefined
): Promise<UserAccessItem> => {
  const sdcClient = getSdcClient();
  const url: string = `./api/v1/users/${userId}/accesses/document-types`;
  try {
    const response = await sdcClient.get(url);
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }
  return {} as UserAccessItem;
};

export const saveUserContainers = async (
  userId: string,
  userAccessItem: UserAccessItem,
  errorHandler?: Function | undefined
): Promise<UserAccessItem> => {
  const sdcClient = getSdcClient();
  const url: string = `./api/v1/users/${userId}/accesses/containers`;
  try {
    const response = await sdcClient.patch(url, userAccessItem);
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }
  return {} as UserAccessItem;
};

export const saveUserDocumentTypes = async (
  userId: string,
  userAccessItem: UserAccessItem,
  errorHandler?: Function | undefined
): Promise<UserAccessItem> => {
  const sdcClient = getSdcClient();
  const url: string = `./api/v1/users/${userId}/accesses/document-types`;
  try {
    const response = await sdcClient.patch(url, userAccessItem);
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }
  return {} as UserAccessItem;
};

export const saveUserProfile = async (
  userId: string,
  fullName: string,
  errorHandler?: Function | undefined
): Promise<TeamManagementResponse> => {
  const sdcClient = getSdcClient();
  const url: string = `./api/v1/users/${userId}`;
  try {
    const response = await sdcClient.patch(url, {
      fullName,
    });
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }
  return {} as TeamManagementResponse;
};

export const saveUserRoles = async (
  userId: string,
  roles: string[],
  errorHandler?: Function | undefined
): Promise<TeamManagementResponse> => {
  const sdcClient = getSdcClient();
  const url: string = `./api/v1/users/${userId}/roles`;
  try {
    const response = await sdcClient.put(url, roles);
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }
  return {} as TeamManagementResponse;
};

export const createUser = async (
  email: string,
  roles: string[],
  errorHandler?: Function | undefined
): Promise<void> => {
  const authInfo = getAuthInfo();
  const sdcClient = getSdcClient();
  const url: string = `./api/v1/users`;
  try {
    const response = await sdcClient.post(url, {
      email,
      roles,
      redirectUri: authInfo.redirectUri,
    });
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }
  return undefined;
};

export const deleteUser = async (
  id: string,
  errorHandler?: Function | undefined
): Promise<void> => {
  const sdcClient = getSdcClient();
  const url: string = `./api/v1/users/${id}`;
  try {
    const response = await sdcClient.delete(url);
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }
  return undefined;
};

export default getUsers;
