import { ReactElement } from 'react';
import './BadgePill.scss';

interface BadgePillProps {
  text: string | undefined | null;
  themeClass: string | undefined | null;
}

const BadgePill = ({ text, themeClass }: BadgePillProps): ReactElement => (
  <span className={`badge-container ${themeClass ?? ''}`}>{text || ''}</span>
);

export default BadgePill;
