import { ReactElement } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as QuestionMarkIcon } from '../../assets/svg/QuestionMarkIcon.svg';
import PermissionAccessStatus from '../../common/types/permissionAccessStatus';
import SDCCheckboxSize from '../../common/types/sdcCheckboxSize';
import DocumentType from '../../services/documentType/models/documentType';
import { AccessDetail } from '../../services/teamManagement/models/userAccessItem';
import buildDocumentTypeDropdownListItems from '../../utils/documentTypeUtil';
import SDCCheckbox from '../SDCCheckbox/SDCCheckbox';
import './EditDocumentTypeAccessSidePanel.scss';

interface EditDocumentTypeAccessSidePanelProps {
  userDocumentTypes: AccessDetail[];
  documentTypes: DocumentType[];
  selectAllDocumentType: boolean;
  onSelectAllDocumentType: (arg0: boolean) => void;
  onSetDocumentType: (arg0: string) => void;
}

const EditDocumentTypeAccessSidePanel = ({
  userDocumentTypes,
  documentTypes,
  selectAllDocumentType,
  onSelectAllDocumentType,
  onSetDocumentType,
}: EditDocumentTypeAccessSidePanelProps): ReactElement => {
  const { t } = useTranslation();
  const deletedDocumentTypeState = t(
    'documentsViewScreen.deletedDocumentTypeState'
  );
  const documentTypeItems = buildDocumentTypeDropdownListItems(
    '',
    documentTypes,
    deletedDocumentTypeState
  );

  return (
    <div className="edit-document-type-access-side-panel-container">
      <Form>
        <Row>
          <Col md="12" className="title mb-1">
            <span>{t('teamManagementScreen.sidePanelDocumentTypeTitle')}</span>
            <QuestionMarkIcon />
          </Col>
          <Col md="12" className="input-box">
            <Form.Check
              type="radio"
              id="default-radio"
              name="group1"
              checked={selectAllDocumentType}
              onChange={(): void => onSelectAllDocumentType(true)}
            />
            <div>
              {t('teamManagementScreen.sidePanelDocumentTypeAll')} <br />{' '}
              {t('teamManagementScreen.includeFutureDocumentType')}
            </div>
          </Col>
          <Col md="12" className="input-box">
            <Form.Check
              type="radio"
              id="default-radio"
              name="group1"
              checked={!selectAllDocumentType}
              onChange={(): void => onSelectAllDocumentType(false)}
            />
            <div>{t('teamManagementScreen.sidePanelDocumentTypeSome')}</div>
          </Col>
          <Col className="px-5">
            {documentTypeItems.map((docType) => {
              const id =
                documentTypes.find((x) => x.name === docType.Value)?.id ?? '';
              return (
                <Col key={id} md="12" className="input-box">
                  <SDCCheckbox
                    sdcSize={SDCCheckboxSize.Default}
                    checked={
                      userDocumentTypes.find((doc) => doc.referenceId === id)
                        ?.permission === PermissionAccessStatus.Granted
                    }
                    onCheckChange={(): void => onSetDocumentType(id)}
                    disabled={!!selectAllDocumentType}
                  />
                  <div>{docType.Name}</div>
                </Col>
              );
            })}
          </Col>
        </Row>
      </Form>

      <div className="separator" />
    </div>
  );
};

export default EditDocumentTypeAccessSidePanel;
