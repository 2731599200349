import { FunctionComponent, ReactElement, SVGProps } from 'react';

import './ActionIcon.scss';

interface ActionIconProps {
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  className?: string;
  onClick: () => void;
}

const ActionIcon = ({
  onClick,
  className,
  Icon,
}: ActionIconProps): ReactElement => (
  <button
    className={`icon-wrapper ${className}`}
    type="button"
    onClick={(): void => onClick()}
  >
    <Icon />
  </button>
);

export default ActionIcon;

ActionIcon.defaultProps = {
  className: '',
};
