import { getSdcClient } from '../../common/apiClients/sdcClient.instance';
import ArgumentError from '../../common/error/argumentError';
import handleApplicationError from '../../utils/errorUtil';
import PaginationResponse from '../paginationResponse';
import Container from './models/container';
import CreateContainerRequest from './models/createContainerRequest';
import UpdateContainerRequest from './models/updateContainerRequest';

export const getContainersAsync = async (
  page: number,
  pageSize: number,
  errorHandler?: any
): Promise<PaginationResponse<Container>> => {
  const sdcClient = getSdcClient();
  const url: string = '/api/v1/containers';

  try {
    const response = await sdcClient.get<PaginationResponse<Container>>(url, {
      params: {
        page,
        pageSize,
      },
    });
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return {} as PaginationResponse<Container>;
};

export const createContainerAsync = async (
  request: CreateContainerRequest,
  errorHandler?: any
): Promise<Container> => {
  if (!request || !request.key || !request.name)
    throw new ArgumentError('request');

  const sdcClient = getSdcClient();
  const url: string = '/api/v1/containers';

  try {
    const response = await sdcClient.post<Container>(url, request);
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return {} as Container;
};

export const updateContainerAsync = async (
  id: string,
  request: UpdateContainerRequest,
  errorHandler?: any
): Promise<Container> => {
  if (!request || !request.key || !request.name)
    throw new ArgumentError('request');
  if (!id) throw new ArgumentError('id');

  const sdcClient = getSdcClient();
  const url: string = `/api/v1/containers/${id}`;

  try {
    const response = await sdcClient.put<Container>(url, request);
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return {} as Container;
};
