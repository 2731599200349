import React, { ReactElement } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface PasswordModalProps {
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  onPasswordCallback: ((password: string) => void) | undefined;
  labelTranslationKey: string;
}

const PasswordForm = ({
  password,
  setPassword,
  onPasswordCallback,
  labelTranslationKey,
}: PasswordModalProps): ReactElement => {
  const { t } = useTranslation();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value);
  };

  const onSubmit = (): void => {
    if (onPasswordCallback) {
      onPasswordCallback(password);
    }
  };

  return (
    <Form
      name="passwordForm"
      className="bg-light text-dark rounded p-3"
      onSubmit={onSubmit}
    >
      <Form.Group className="mb-3" controlId="formGroupPassword">
        <Form.Label>{t(labelTranslationKey)}</Form.Label>
        <Form.Control
          type="password"
          value={password}
          onChange={onChange}
          autoFocus
        />
      </Form.Group>
      <Button variant="primary" type="submit">
        {t('PDFPreviewer.okButton')}
      </Button>
    </Form>
  );
};

export default PasswordForm;
