import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import AuthService from '../../services/auth/auth.service';
import getAuthInfo from '../../services/auth/authInfo';
import AuthStorage from '../../services/auth/authStorage';
import AuthServiceProps from '../../services/auth/models/authServiceProps';
import getApiUrl from '../configurations/apiUrl';
import AbortControllerProvider from '../providers/abortControllerProvider';
import HistoryProvider from '../providers/historyProvider';
import LocationProvider from '../providers/locationProvider';
import StorageProvider from '../providers/storageProvider';

const { clientId, provider, audience, redirectUri } = getAuthInfo();
const authServiceBody: AuthServiceProps = {
  clientId,
  provider,
  audience,
  redirectUri,
};
const storageProvider = new StorageProvider();
const authStorage = new AuthStorage(storageProvider);
const locationProvider = new LocationProvider();
const historyProvider = new HistoryProvider();

const authService = new AuthService(
  authServiceBody,
  authStorage,
  locationProvider,
  historyProvider
);

const isCookieValid = (): boolean => {
  if (!authStorage.isAuthenticated()) return false;
  if (authStorage.isExpired()) return false;

  return true;
};

let sdcClientInstance: AxiosInstance | null = null;
const abortControllerInstance = new AbortControllerProvider();

export const getSdcClient = (): AxiosInstance => {
  const { sdcUrl } = getApiUrl();
  if (sdcClientInstance === null) {
    sdcClientInstance = axios.create({
      baseURL: sdcUrl,
      signal: abortControllerInstance.signal(),
      // Allow cookie assign in request header
      withCredentials: true,
      withXSRFToken: true,
    });
  }

  sdcClientInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      if (!config.signal) {
        // eslint-disable-next-line no-param-reassign
        config.signal = abortControllerInstance.signal();
      }

      if (!isCookieValid()) {
        abortControllerInstance.abort();
        const redirectUrl = authService.authorize();
        locationProvider.replace(redirectUrl);
        return config;
      }

      return config;
    },
    (error: any) => {
      if (!abortControllerInstance.signal()?.aborted) Promise.reject(error);
    }
  );

  return sdcClientInstance;
};

export const resetSdcClient = (): void => {
  sdcClientInstance = null;
};
