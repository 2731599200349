type AuthInfo = {
  clientId: string;
  provider: string;
  audience: string;
  redirectUri: string;
};

const getAuthInfo = (): AuthInfo => ({
  clientId: process.env.REACT_APP_AUTHORIZATION_CLIENT_ID || '',
  provider: process.env.REACT_APP_AUTHORIZATION_DOMAIN || '',
  audience: process.env.REACT_APP_AUTHORIZATION_AUDIENCE || '',
  redirectUri: process.env.REACT_APP_AUTHORIZATION_CALLBACKURL || '',
});

export default getAuthInfo;
