import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CalendarIcon } from '../../assets/svg/CalendarIcon.svg';
import { ReactComponent as ProtectionIcon } from '../../assets/svg/ProtectionIcon.svg';
import storageKey from '../../common/constants/storageKey.constants';
import DocumentProtectionViewModel from '../../common/models/documentProtectionViewModel';
import KeyPairItem from '../../common/models/keypairItem';
import RadioDropdownItem from '../../common/models/radioDropdownItem';
import StorageProvider from '../../common/providers/storageProvider';
import DataType from '../../common/types/dataType';
import DropdownItemRole from '../../common/types/dropdownItemRole';
import DropdownVariant from '../../common/types/dropdownVariant';
import NotificationVariant from '../../common/types/notificationVariant';
import ProtectionStatus from '../../common/types/protectionStatus';
import TimespanUnit from '../../common/types/timespanUnit';
import {
  getDocumentProtection,
  updateDocumentProtection,
} from '../../services/documentType/documentType.service';
import DocumentProtection from '../../services/documentType/models/documentProtection';
import DocumentType from '../../services/documentType/models/documentType';
import UserSettings from '../../services/user/models/userSettings';
import DataGrid, { DataGridHeaderDefinition } from '../DataGrid/DataGrid';
import { DataGridRow } from '../DataGrid/GridRowCollection';
import RadioDropdown from '../RadioDropdown/RadioDropdown';
import SecondaryDropdown from '../SecondaryDropdown/SecondaryDropdown';
import Toaster from '../Toaster/Toaster';
import showToaster from '../Toaster/ToasterProvider';
import './DocumentProtectionTable.scss';

export interface DocumentProtectionTableProps {
  documentTypes: DocumentType[];
  settings: UserSettings;
  isLoading: boolean;
}

const DocumentProtectionTable = ({
  documentTypes,
  settings,
  isLoading,
}: DocumentProtectionTableProps): ReactElement => {
  const { t } = useTranslation();
  const defaultYear = 1;
  const [items, setItems] = useState<DocumentProtectionViewModel[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const bulkDropdownTitle = t('documentProtectionPage.dropdownTitle');
  const dropDownDescription = t('documentProtectionPage.dropDownDescription');
  const customOption = t('documentProtectionPage.customOption');
  const unprotectedValue = 'unprotected';

  const statusDropdownItems: KeyPairItem[] = [
    {
      Name: t('documentProtectionPage.protectedOption'),
      Value: ProtectionStatus.Protected,
    },
    {
      Name: t('documentProtectionPage.noProtectedOption'),
      Value: ProtectionStatus.Unprotected,
    },
  ];
  const periodOptionItems: RadioDropdownItem[] = [
    {
      name: t('documentProtectionPage.1yearOption'),
      value: '1',
      dataType: DataType.Decimal,
      role: DropdownItemRole.Button,
    },
    {
      name: t('documentProtectionPage.3yearOption'),
      value: '3',
      dataType: DataType.Decimal,
      role: DropdownItemRole.Button,
    },
    {
      name: t('documentProtectionPage.7yearOption'),
      value: '7',
      dataType: DataType.Decimal,
      role: DropdownItemRole.Button,
    },
    {
      name: customOption,
      value: 'custom',
      dataType: DataType.YearTimespan,
      role: DropdownItemRole.Button,
      editable: true,
    },
    {
      name: t('documentProtectionPage.doNotProtectOption'),
      value: unprotectedValue,
      dataType: DataType.String,
      role: DropdownItemRole.Button,
    },
  ];

  const storageProvider = useMemo(() => new StorageProvider(), []);

  const getDropdownItems = (value: string): RadioDropdownItem[] => {
    const foundOption = periodOptionItems.find((x) => x.value === value);
    if (foundOption) {
      return periodOptionItems;
    }

    return periodOptionItems.map((item: RadioDropdownItem) => {
      if (!item.editable) {
        return item;
      }

      return {
        name: customOption,
        value,
        dataType: DataType.YearTimespan,
        role: DropdownItemRole.Button,
        editable: true,
      };
    });
  };

  const buildViewEntries = useCallback(
    (documentProtections: DocumentProtection[]) => {
      const documentProtectionItems = documentTypes.map(
        (value: DocumentType) => {
          const protectionData = documentProtections.find(
            (x) => x.documentTypeId === value.id
          );
          // Create no protection items
          if (protectionData === undefined) {
            return {
              documentTypeId: value.id,
              status: ProtectionStatus.Unprotected,
              unit: TimespanUnit.Year,
              period: defaultYear,
              displayName: value.displayName,
            } as DocumentProtectionViewModel;
          }

          // If protection is found, it's protected
          return {
            documentTypeId: value.id,
            status: ProtectionStatus.Protected,
            unit: protectionData.unit,
            period: protectionData.period,
            displayName: value.displayName,
          } as DocumentProtectionViewModel;
        }
      );

      return documentProtectionItems;
    },
    [documentTypes]
  );

  useEffect(() => {
    getDocumentProtection().then((response) => {
      const entries = buildViewEntries(response);
      setItems(entries);

      if (storageProvider.getItem(storageKey.NOTIFY_KEY) !== null) {
        storageProvider.removeItem(storageKey.NOTIFY_KEY);
        showToaster(
          t('documentProtectionPage.notificationTitleUpdate'),
          t('documentProtectionPage.notificationMessageUpdate'),
          NotificationVariant.PrimarySuccess
        );
      }
    });
  }, [buildViewEntries, storageProvider, t]);

  const handleSelectAll = (checkedAll: boolean): void => {
    if (checkedAll) {
      const allValues = items.map(
        (entry: DocumentProtectionViewModel) => entry.documentTypeId
      );
      setSelectedItems(allValues);
      return;
    }

    setSelectedItems([]);
  };

  const applyPeriodChanges = (
    option: RadioDropdownItem,
    editedValue: string,
    documentTypeIds: string[],
    isFromCheckboxes: boolean
  ): void => {
    if (documentTypeIds.length === 0) {
      return;
    }
    if (option.editable && !editedValue) {
      return;
    }

    let periodValue = 0;
    if (option.value !== unprotectedValue) {
      periodValue = option.editable
        ? parseInt(editedValue, 10)
        : parseInt(option.value, 10);
    }

    const requests = documentTypeIds.map((documentTypeId: string) => {
      const request: DocumentProtection = {
        documentTypeId,
        period: periodValue,
        unit: TimespanUnit.Year,
        status:
          option.value === unprotectedValue
            ? ProtectionStatus.Unprotected
            : ProtectionStatus.Protected,
      };
      return request;
    });

    updateDocumentProtection(requests).then(() => {
      // Refresh the entire page if doing bulk updates
      if (isFromCheckboxes) {
        storageProvider.setItem(storageKey.NOTIFY_KEY, true.toString());
        window.location.reload();
        return;
      }

      showToaster(
        t('documentProtectionPage.notificationTitleUpdate'),
        t('documentProtectionPage.notificationMessageUpdate'),
        NotificationVariant.PrimarySuccess
      );

      getDocumentProtection().then((response) => {
        const entries = buildViewEntries(response);
        setItems(entries);
      });
    });
  };

  const handleStatusChange = (
    item: KeyPairItem,
    documentTypeId: string
  ): void => {
    let option: RadioDropdownItem | undefined;
    if (item.Value === unprotectedValue) {
      option = periodOptionItems.find((x) => x.value === unprotectedValue);
    } else {
      option = periodOptionItems.at(0);
    }

    if (!option) {
      return;
    }

    applyPeriodChanges(option, '', [documentTypeId], false);
  };

  const getRows = (): DataGridRow[] =>
    items.map((item: DocumentProtectionViewModel) => ({
      id: item.documentTypeId,
      rowClass: '',
      cells: [
        {
          columnKey: 'documentProtectionPage.documentTypeColumnHeader',
          dataKey: 'documentProtectionPage.documentTypeColumnHeader',
          value: item.displayName,
          text: item.displayName,
          template: (
            <div className="table-column">
              {item.displayName}{' '}
              {item.status === ProtectionStatus.Protected ? (
                <ProtectionIcon className="column-icon" fill="#1f6beb" />
              ) : null}
            </div>
          ),
        },
        {
          columnKey: 'documentProtectionPage.statusColumnHeader',
          dataKey: 'documentProtectionPage.statusColumnHeader',
          value: item.status,
          template: (
            <div className="d-flex name">
              <SecondaryDropdown
                selectedValue={item.status}
                items={statusDropdownItems}
                onSelect={(option: KeyPairItem): void =>
                  handleStatusChange(option, item.documentTypeId)
                }
                variant={DropdownVariant.Info}
              />
            </div>
          ),
        },
        {
          columnKey: 'documentProtectionPage.periodColumnHeader',
          dataKey: 'documentProtectionPage.periodColumnHeader',
          value: item.period,
          template: (
            <div className="d-flex name align-items-center">
              {item.status === ProtectionStatus.Protected ? (
                <>
                  <RadioDropdown
                    key={`${item.documentTypeId}-${item.period}`}
                    initialSelectedValue={item.period.toString()}
                    list={getDropdownItems(item.period.toString())}
                    onApply={(selectedDropdownItem, editedValue): void =>
                      applyPeriodChanges(
                        selectedDropdownItem,
                        editedValue,
                        [item.documentTypeId],
                        false
                      )
                    }
                    settings={settings}
                    text="-"
                    variant={DropdownVariant.Info}
                    description={dropDownDescription}
                  />
                  <CalendarIcon className="column-icon" fill="#42495E" />
                </>
              ) : (
                <span className="ps-4">-</span>
              )}
            </div>
          ),
        },
      ],
      clickable: false,
    }));

  const headers: Array<DataGridHeaderDefinition> = [
    {
      columnKey: 'documentProtectionPage.documentTypeColumnHeader',
      translationKey: 'documentProtectionPage.documentTypeColumnHeader',
    },
    {
      columnKey: 'documentProtectionPage.statusColumnHeader',
      translationKey: 'documentProtectionPage.statusColumnHeader',
    },
    {
      columnKey: 'documentProtectionPage.periodColumnHeader',
      translationKey: 'documentProtectionPage.periodColumnHeader',
    },
  ];

  return (
    <div className="document-protection-data-grid">
      <Toaster />
      <div className="action-button-container mb-3">
        <RadioDropdown
          list={periodOptionItems}
          onApply={(selectedDropdownItem, editedValue): void =>
            applyPeriodChanges(
              selectedDropdownItem,
              editedValue,
              selectedItems,
              true
            )
          }
          settings={settings}
          text={bulkDropdownTitle}
          description={dropDownDescription}
          disabled={selectedItems.length === 0}
        />
      </div>
      <DataGrid
        headerDefinitions={headers}
        tableContent={getRows()}
        selectable
        setSelectableStates={setSelectedItems}
        sortableColumnKeys={[
          'documentProtectionPage.documentTypeColumnHeader',
          'documentProtectionPage.statusColumnHeader',
          'documentProtectionPage.periodColumnHeader',
        ]}
        onSelectAllChange={handleSelectAll}
        selectedValues={selectedItems}
        noResultsMessage={t('documentProtectionPage.noResultsMessage')}
        isLoading={isLoading}
      />
    </div>
  );
};

export default DocumentProtectionTable;
