import barcode from '../constants/barcode.constant';
import SizeUnit from '../types/sizeUnit';

export default interface CodeSetting {
  type: string;
  sizeUnit: SizeUnit;
  width: number;
  height: number;
  isDisplayCodeText: boolean;
  isDisplayCodeDescription: boolean;
}

export const DefaultCodeSetting: CodeSetting = {
  type: barcode.QR_CODE,
  sizeUnit: SizeUnit.Millimetre,
  width: 50,
  height: 50,
  isDisplayCodeText: false,
  isDisplayCodeDescription: true,
};
