import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import dateFormat from '../../common/dateFormat';
import CompletedArchiveDocument from '../../common/models/completedArchiveDocument';
import ErrorDetails from '../../common/models/errorDetails';
import UploadFileWithStatus from '../../common/models/uploadFileWithStatus';
import CollapserPositionVariant from '../../common/types/collapserPositionVariant';
import ConfirmationModalVariant from '../../common/types/confirmationModalVariant';
import FileUploadStatus from '../../common/types/fileUploadStatus';
import HttpStatusCode from '../../common/types/httpStatusCode';
import NotificationVariant from '../../common/types/notificationVariant';
import QueryStringKey from '../../common/types/queryStringKey';
import UserPermission from '../../common/types/userPermission';
import ArchiveDocumentSection from '../../components/ArchiveDocumentSection/ArchiveDocumentSection';
import ArchiveTransition from '../../components/ArchiveTransition/ArchiveTransition';
import BlankDropzone from '../../components/BlankDropzone/BlankDropzone';
import CaptureDocumentFooter from '../../components/CaptureDocumentFooter/CaptureDocumentFooter';
import DefaultLoading from '../../components/DefaultLoading/DefaultLoading';
import ConfirmationModal from '../../components/DefaultModal/ConfirmationModal';
import DocumentViewModal from '../../components/DocumentViewModal/DocumentViewModal';
import RestrictedArea from '../../components/RestrictedArea/RestrictedArea';
import TaskBar from '../../components/TaskBar/TaskBar';
import TaskDescription from '../../components/TaskBar/TaskDescription';
import Toaster from '../../components/Toaster/Toaster';
import showToaster from '../../components/Toaster/ToasterProvider';
import UploadDocumentHeader from '../../components/UploadDocumentHeader/UploadDocumentHeader';
import UploadingDropzone from '../../components/UploadingDropzone/UploadingDropzone';
import UploadingStickyDialog from '../../components/UploadingStickyDialog/UploadingStickyDialog';
import { useContainer } from '../../context/Container/ContainerContext';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import { useTenant } from '../../context/Tenant/TenantContext';
import usePreventWindowClose from '../../hooks/usePreventWindowClose/usePreventWindowClose';
import useQueryString from '../../hooks/useQueryString/useQueryString';
import { getCaptureData } from '../../services/capture/capture.service';
import { CaptureObjectResponse } from '../../services/capture/models/captureObjectResponse';
import Metadata from '../../services/capture/models/metadata';
import { UploadedContent } from '../../services/capture/models/uploadedContent';
import { ArchiveObjectResponse } from '../../services/document/models/archiveObjectResponse';
import {
  formatFileSetToHaveStatus,
  getFileExtension,
} from '../../utils/fileUtil';
import formatDateTime from '../../utils/i18n-date-format';
import { setMissingStatus } from '../../utils/metadataUtil';
import userHasPermission from '../../utils/userUtil';
import './ArchiveDocumentPage.scss';

type ArchiveDocumentParams = {
  captureInfoId: string;
};

const ArchiveDocumentPage = ({
  captureInfoId,
}: ArchiveDocumentParams): ReactElement => {
  const { t } = useTranslation();
  const settings = useTenant();
  const { setContainerKey } = useContainer();
  const { currentUser } = useCurrentUser();
  const queryString = useQueryString();
  const handleError = useErrorHandler();

  // Files
  const [files, setFiles] = useState<Array<UploadFileWithStatus>>([]);
  const [successUploadFiles, setSuccessUploadFiles] = useState<
    Array<UploadFileWithStatus>
  >([]);
  const [archiveCompleteFileList, setArchiveCompleteFileList] =
    React.useState<CompletedArchiveDocument[]>();
  const [checkboxSelectedFiles, setCheckboxSelectedFiles] = useState<
    Array<File>
  >([]);

  // Visible control
  const [showLoading, setShowLoading] = useState(false);
  const [isStickyModalVisible, setIsStickyModalVisible] = useState(false);
  const [showDocumentViewModal, setShowDocumentViewModal] =
    React.useState(false);
  const [showCompleteArchiveModal, setShowCompleteArchiveModal] =
    React.useState(false);
  const [showCompletedNotification, setShowCompletedNotification] =
    useState(false);
  const [showCompletedArchiveList, setShowCompletedArchiveList] =
    useState(false);

  // File focused
  const [selectedFileId, setSelectedFileId] = React.useState<string>('');
  const selectedFile =
    successUploadFiles.find((x) => x.id === selectedFileId)?.file ??
    files.find((x) => x.id === selectedFileId)?.file;

  // View controller List view or Thumbnail view
  const [useUploadListView, setUseUploadListView] =
    React.useState<boolean>(false);

  const documentDisplayName = ((): string => {
    const value = queryString.get(QueryStringKey.DisplayName);
    if (value) {
      return value;
    }
    return captureInfoId.toString();
  })();
  const [metadata, setMetadata] = React.useState<Metadata>({
    templateName: '',
    templateGroupName: '',
    data: [],
  });
  const [uploadedContents, setUploadedContents] = React.useState<
    Array<UploadedContent>
  >([]);
  const [showExitConfirmation, setShowExitConfirmation] =
    useState<boolean>(false);

  const totalCount = files.filter(
    (f) => f.status === FileUploadStatus.SUCCESS
  ).length;

  const {
    getRootProps,
    getInputProps,
    open,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: settings.supportedFileTypes,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length) {
        setFiles((currentFiles) =>
          formatFileSetToHaveStatus(acceptedFiles, currentFiles, true)
        );
      }
    },
    // Ignore rejected files and notify the user about them
    onDropRejected: (rejectedFile) => {
      const extensions = rejectedFile.map((x: FileRejection) =>
        getFileExtension(x.file.name)
      );
      const uniques = [...new Set(extensions)];
      showToaster(
        t('dropzoneComponent.rejectFileTitle'),
        t('dropzoneComponent.rejectFileMessage', {
          count: uniques.length,
          extension: uniques.join(', '),
        }),
        NotificationVariant.SoftDanger
      );
    },
  });

  useEffect(() => {
    let controller: AbortController | null = new AbortController();
    async function readMetadata(): Promise<void> {
      setShowLoading(true);
      if (captureInfoId) {
        try {
          const response: CaptureObjectResponse = await getCaptureData(
            captureInfoId,
            handleError
          );
          if (response != null) {
            setContainerKey(response.containerKey);

            setMissingStatus(response.metadata.data);
            setMetadata(response.metadata);
            controller = null;
          }
          setShowLoading(false);
        } catch (err) {
          handleError(err);
          setShowLoading(false);
        }
      }
    }
    if (userHasPermission(currentUser, UserPermission.CaptureDocuments)) {
      readMetadata();
    }
    return (): void => controller?.abort();
  }, [captureInfoId, currentUser, handleError, queryString, setContainerKey]);

  usePreventWindowClose(successUploadFiles.length > 0);

  const displayErrorDialog = (details: ErrorDetails): void => {
    let message = '';
    let variant = NotificationVariant.SoftDanger;
    if (details.status === HttpStatusCode.TooManyRequests) {
      message = t(`errorCode.${details.errorCode}`);
      variant = NotificationVariant.SoftWarning;
    } else if (details.status === HttpStatusCode.BadRequest) {
      message = t(`errorCode.${details.errorCode}`);
    } else {
      message = details.detail;
    }

    showToaster(t('archiveDocumentPage.archiveErrorTitle'), message, variant);
  };

  const removeFileFromSuccessList = (
    removedFile: UploadFileWithStatus
  ): void => {
    setSuccessUploadFiles((successUpload) =>
      successUpload.filter((f) => f.id !== removedFile.id)
    );
  };

  const updateFilePreviewThumbnail = useCallback(
    (fileId: string, file: UploadFileWithStatus): void => {
      setSuccessUploadFiles((previousState) =>
        previousState.map((item) => {
          if (item.id === fileId) return file;
          return item;
        })
      );
    },
    []
  );

  // Move success archived file to completed archive list
  const moveFileToCompleteList = (
    fileId: string,
    document: ArchiveObjectResponse
  ): void => {
    setShowCompletedArchiveList(false);
    setShowCompletedNotification(true);
    const completedList: CompletedArchiveDocument[] = [];
    let completedFileList: CompletedArchiveDocument[] = [];
    const completedFileInfo = successUploadFiles.find((f) => f.id === fileId);

    if (completedFileInfo && successUploadFiles && successUploadFiles.length) {
      completedList.push({
        file: completedFileInfo.file,
        archivedTime: formatDateTime(
          new Date(document.createdOn),
          'en',
          dateFormat.time
        ),
        document,
      });
      if (archiveCompleteFileList && archiveCompleteFileList.length) {
        completedFileList = [...archiveCompleteFileList, ...completedList];
      } else {
        completedFileList = [...completedList];
      }

      // Update status file that archived to ARCHIVED
      setFiles((archivedFile) =>
        archivedFile.map((f: UploadFileWithStatus) => {
          if (f.id === completedFileInfo?.id) {
            // eslint-disable-next-line no-param-reassign
            f.status = FileUploadStatus.ARCHIVED;
          }
          return f;
        })
      );
      setArchiveCompleteFileList(completedFileList);
      removeFileFromSuccessList(completedFileInfo);
      setTimeout(() => {
        setShowCompletedNotification(false);
      }, 5000);
    }
  };

  useEffect(() => {
    const checkStickyVisibleCondition = (): boolean =>
      files.some(
        (f) =>
          f.status !== FileUploadStatus.SUCCESS &&
          f.status !== FileUploadStatus.ARCHIVED &&
          f.status !== FileUploadStatus.CANCELED
      );

    // Delay follow requirement display success then disappear after 3 sec.
    const visibleTimer = setTimeout(() => {
      if (!checkStickyVisibleCondition()) {
        setIsStickyModalVisible(false);
      }
    }, 3000);

    if (checkStickyVisibleCondition()) setIsStickyModalVisible(true);

    return (): void => clearTimeout(visibleTimer);
  }, [files]);

  const pickOneOfFilesToArchive = (): void => {
    if (successUploadFiles.length) {
      // Use the fist file on the list
      const firstFileIndex = 0;
      const { id } = successUploadFiles[firstFileIndex];

      setSelectedFileId(id);
      setShowDocumentViewModal(true);
    }
  };

  const handleDoneCapturing = (): void => {
    if (!successUploadFiles.length) {
      window.close();
      return;
    }
    setShowExitConfirmation(true);
  };

  const handleExitConfirmation = (isYes: boolean): void => {
    if (isYes) {
      window.close();
    }
  };

  const onUploadingStickyDialogClose = useCallback((): void => {
    setFiles((f: Array<UploadFileWithStatus>) =>
      f.filter(
        (fs) =>
          fs.status !== FileUploadStatus.FAILED &&
          fs.status !== FileUploadStatus.CANCELED &&
          fs.status !== FileUploadStatus.DUPLICATED
      )
    );
  }, []);

  const checkboxOnClick = useCallback(
    (checkboxSelectedFile: File, checked: boolean): void => {
      setCheckboxSelectedFiles((previousValue) => {
        if (checked) {
          return [...previousValue, checkboxSelectedFile];
        }
        return previousValue.filter(
          (item) => item.name !== checkboxSelectedFile.name
        );
      });
    },
    []
  );

  if (!userHasPermission(currentUser, UserPermission.CaptureDocuments)) {
    return <RestrictedArea />;
  }

  return (
    <>
      <Toaster />
      <ArchiveTransition
        show={showCompleteArchiveModal}
        fileName={selectedFile?.name}
        filesToArchiveCount={successUploadFiles.length}
        onProceedNextFile={(): void => {
          pickOneOfFilesToArchive();
        }}
        onClose={(): void => {
          setShowCompleteArchiveModal(false);
        }}
      />
      {showLoading && <DefaultLoading />}
      <div className="upload-container">
        <DocumentViewModal
          show={showDocumentViewModal}
          onClose={(): void => setShowDocumentViewModal(false)}
        >
          {showDocumentViewModal ? (
            <ArchiveDocumentSection
              enableEdit
              uploadedContents={uploadedContents}
              selectedFile={selectedFile}
              metadata={metadata}
              displayName={documentDisplayName}
              captureInfoId={captureInfoId}
              onCloseModal={(): void => setShowDocumentViewModal(false)}
              onCompletedArchive={(document: ArchiveObjectResponse): void => {
                setShowCompleteArchiveModal(true);
                moveFileToCompleteList(selectedFileId, document);
              }}
              onError={displayErrorDialog}
              collapserPosition={CollapserPositionVariant.WithTitleBar}
            />
          ) : null}
        </DocumentViewModal>
        {isStickyModalVisible && (
          <UploadingStickyDialog
            files={files}
            setFiles={setFiles}
            show
            onClose={onUploadingStickyDialogClose}
            setIsStickyModalVisible={setIsStickyModalVisible}
            setSuccessUploadFiles={setSuccessUploadFiles}
            captureInfoId={captureInfoId}
            setUploadedContents={setUploadedContents}
          />
        )}
        <TaskBar
          headerPrefix={t('taskBar.capturePrefix')}
          actionText={t('taskBar.done')}
          actionOnClick={handleDoneCapturing}
          isSubNavbar
        >
          <TaskDescription
            descriptionText={t('taskBar.uploadTaskDescription')}
            documentDisplayName={
              documentDisplayName ?? t('taskBar.undefinedDocument')
            }
          />
        </TaskBar>
        <UploadDocumentHeader
          files={files}
          checkboxSelectedFiles={checkboxSelectedFiles}
          useUploadListView={useUploadListView}
          listActionIconOnClick={(): void => setUseUploadListView(true)}
          cardActionIconOnClick={(): void => setUseUploadListView(false)}
          deleteActionOnClick={(): void => {
            setFiles((previousState) =>
              previousState.filter(
                (item) => !checkboxSelectedFiles.includes(item.file)
              )
            );
            setSuccessUploadFiles((previousState) =>
              previousState.filter(
                (item) => !checkboxSelectedFiles.includes(item.file)
              )
            );
            setCheckboxSelectedFiles([]);
          }}
          selectAllOnClick={(checked: boolean): void => {
            if (checked)
              setCheckboxSelectedFiles(
                files
                  .filter(
                    (x) =>
                      x.status !== FileUploadStatus.DUPLICATED &&
                      x.status !== FileUploadStatus.FAILED &&
                      x.status !== FileUploadStatus.CANCELED
                  )
                  .map((value) => value.file)
              );
            else setCheckboxSelectedFiles([]);
          }}
        />
        <div className="dropzone-container">
          <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getRootProps({
              className: `dropzone${
                successUploadFiles && successUploadFiles.length
                  ? ' selected-files'
                  : ''
              }${
                isFocused || isDragAccept || isDragReject ? ' drag-over' : ''
              }`,
            })}
          >
            <input
              data-testid="file-uploader"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...getInputProps({
                accept: settings.supportedFileTypes.join(','),
              })}
            />
            {files && files.length && !(isDragAccept || isDragReject) ? (
              <UploadingDropzone
                fileSet={files}
                successUploadFiles={successUploadFiles}
                setShowDocumentViewModal={setShowDocumentViewModal}
                setSelectedFileId={setSelectedFileId}
                openAddFilesDialog={open}
                isUploadListView={useUploadListView}
                updateFilePreviewThumbnail={updateFilePreviewThumbnail}
                checkboxSelectedFiles={checkboxSelectedFiles}
                checkboxOnClick={checkboxOnClick}
              />
            ) : (
              <BlankDropzone
                open={open}
                isFocused={isDragAccept || isDragReject}
                isUploadListView={useUploadListView}
              />
            )}
            <div
              className={`uploaded-file-total ${
                archiveCompleteFileList && archiveCompleteFileList.length
                  ? 'have-footer'
                  : ''
              }`}
            >
              {t('archiveDocumentPage.uploadedFileTotal', {
                total: totalCount,
                count: totalCount,
              })}
            </div>
            {archiveCompleteFileList && archiveCompleteFileList.length && (
              <CaptureDocumentFooter
                displayName={documentDisplayName}
                captureInfoId={captureInfoId}
                metadata={metadata}
                archiveCompleteFileList={archiveCompleteFileList}
                isShowCompletedNotification={showCompletedNotification}
                isShowCompletedArchiveList={showCompletedArchiveList}
                setShowLoading={setShowLoading}
              />
            )}
          </div>
        </div>
      </div>
      <ConfirmationModal
        title={t('archiveDocumentPage.exitConfimationTitle')}
        bodyText={t('archiveDocumentPage.exitConfimationMessage')}
        show={showExitConfirmation}
        setShow={setShowExitConfirmation}
        onAnswer={handleExitConfirmation}
        primaryButtonText={t('confirmationModal.leaveButton')}
        dismissButtonText={t('confirmationModal.cancelButton')}
        variant={ConfirmationModalVariant.SecondaryDanger}
      />
    </>
  );
};

export default ArchiveDocumentPage;
