import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronRightIcon } from '../../assets/svg/ChevronNextIcon.svg';
import { ReactComponent as ChevronLeftIcon } from '../../assets/svg/ChevronPrevIcon.svg';
import SDCButtonSize from '../../common/types/sdcButtonSize';
import SDCDropdownSize from '../../common/types/sdcDropdownSize';
import PaginationButton from './PaginationButton';
import PaginationDropdown from './PaginationDropdown';
import './PaginationNavigator.scss';

interface PaginationNavigatorProps {
  currentPage: number;
  lastPage: number;
  totalCount: number;
  pageSize: number;
  onPageChange: (newPageNumber: number) => void;
  descriptionTranslationKey: string;
}

const PaginationNavigator = ({
  currentPage,
  lastPage,
  totalCount,
  pageSize,
  onPageChange,
  descriptionTranslationKey,
}: PaginationNavigatorProps): ReactElement => {
  const { t } = useTranslation();
  // No other sizes for global navigator, only use small for the time being
  const buttonSize = SDCButtonSize.Small;
  const summaryDescription = t(descriptionTranslationKey, {
    current:
      currentPage === lastPage
        ? totalCount - (currentPage - 1) * pageSize
        : pageSize,
    total: Number(totalCount).toLocaleString(),
    count: totalCount,
  });

  // If last page is greater than or equal to this number, page buttons will collapse.
  const pagesToCollapse = 6;
  // First page
  const firstPage = 1;
  // In case of collapse, it's the gap for the number of buttons to display before and after the collapse droup down
  const collapsePageGap = 2;
  // In case of collapse, it's the page that will start the collapse.
  const collapseStartPage = 3;
  // In case of collapse, it's the page that will collapse ends.
  const collapseEndPage = lastPage - collapsePageGap;

  const getButton = (value: number): ReactElement => (
    <PaginationButton
      key={`pagination-${value}`}
      text={`${value}`}
      active={currentPage === value}
      onClick={(): void => onPageChange(value)}
      size={buttonSize}
    />
  );

  const renderButtons = (): JSX.Element[] => {
    const buttons = [];

    // Break up the navigation buttons into a drop down if there's too many pages.
    // Otherwise render all the navigation buttons.
    if (lastPage >= pagesToCollapse) {
      // Display before buttons
      for (let number = firstPage; number <= collapsePageGap; number += 1) {
        buttons.push(getButton(number));
      }

      // Display the collapse
      const pages = [];
      for (
        let number = collapseStartPage;
        number <= collapseEndPage;
        number += 1
      ) {
        pages.push(number);
      }

      buttons.push(
        <PaginationDropdown
          key="pagination-dropdown"
          values={pages}
          active={pages.includes(currentPage)}
          onSelect={onPageChange}
          displayText={null}
          size={SDCDropdownSize.Small}
        />
      );

      // Display after buttons
      for (let number = collapseEndPage + 1; number <= lastPage; number += 1) {
        buttons.push(getButton(number));
      }
    } else {
      // Display all buttons
      for (let number = firstPage; number <= lastPage; number += 1) {
        buttons.push(getButton(number));
      }
    }

    return buttons;
  };

  const renderFirstButton = (): JSX.Element[] => {
    const buttons = [];
    if (lastPage > 1) {
      buttons.push(
        <PaginationButton
          key="result-pagination-first-button"
          text={t('paginationNavigator.firstButton')}
          active={false}
          onClick={(): void => onPageChange(firstPage)}
          disabled={currentPage === firstPage}
          size={buttonSize}
          flexibleWidth
        />
      );
    }
    return buttons;
  };

  const renderLastButton = (): JSX.Element[] => {
    const buttons = [];
    if (lastPage > 1) {
      buttons.push(
        <PaginationButton
          key="result-pagination-last-button"
          text={t('paginationNavigator.lastButton')}
          active={false}
          onClick={(): void => onPageChange(lastPage)}
          disabled={currentPage === lastPage}
          size={buttonSize}
          flexibleWidth
        />
      );
    }
    return buttons;
  };

  const renderPreviousButton = (): JSX.Element[] => [
    <PaginationButton
      key="pagination-previous-button"
      Icon={ChevronLeftIcon}
      active={false}
      onClick={(): void => onPageChange(currentPage - 1)}
      disabled={currentPage === firstPage}
      size={buttonSize}
      flexibleWidth
    />,
  ];

  const renderNextButton = (): JSX.Element[] => [
    <PaginationButton
      key="pagination-next-button"
      Icon={ChevronRightIcon}
      active={false}
      onClick={(): void => onPageChange(currentPage + 1)}
      disabled={currentPage === lastPage}
      size={buttonSize}
      flexibleWidth
    />,
  ];

  return (
    <div className="pagination-navigator user-select-none">
      <span>{summaryDescription}</span>
      <span>
        {renderPreviousButton()}
        {renderFirstButton()}
        {renderButtons()}
        {renderLastButton()}
        {renderNextButton()}
      </span>
    </div>
  );
};

export default PaginationNavigator;
