import React, { ReactElement, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Document, Page, pdfjs } from 'react-pdf';
import PDFPagination from '../PDFPagination/PDFPagination';
import './PDFPreviewer.scss';
import PasswordForm from './PasswordForm';
import PasswordResponses from './passwordResponses';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

interface PDFPreviewerProps {
  selectedFile: File | undefined;
}

const PDFPreviewer = ({ selectedFile }: PDFPreviewerProps): ReactElement => {
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [filePassword, setFilePassword] = useState('');
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(1);
  const [onPasswordCallback, setOnPasswordCallback] = React.useState<
    ((password: string) => void) | undefined
  >(undefined);
  const [modalLabelTranslationKey, setModalLabelTranslationKey] = useState(
    'askForPasswordLabel'
  );

  type OnLoadSuccessProps = {
    numPages: number;
  };

  const onDocumentLoadSuccess = ({ numPages }: OnLoadSuccessProps): void => {
    setShowPasswordForm(false);
    setTotalPageNumber(numPages);
  };

  const setCurrentPage = (page: number): void => {
    setPageNumber(page); // SET DATA FROM CHILD (PAGINATION)
  };

  const setCurrentScale = (scale: number): void => {
    setPageScale(scale); // SET DATA FROM CHILD (ZOOM)
  };

  const onPasswordSubmit = (
    callback: (password: string) => void,
    reason: number
  ): void => {
    function callbackProxy(password: string): void {
      setShowPasswordForm(false);
      callback(password);
    }
    setOnPasswordCallback(() => callbackProxy);

    switch (reason) {
      case PasswordResponses.NeedPassword: {
        if (filePassword) {
          callbackProxy(filePassword);
          setShowPasswordForm(false);
        } else {
          setModalLabelTranslationKey('PDFPreviewer.askForPasswordLabel');
          setShowPasswordForm(true);
        }
        break;
      }
      case PasswordResponses.IncorrectPassword: {
        setFilePassword('');
        setModalLabelTranslationKey('PDFPreviewer.invalidPasswordLabel');
        setShowPasswordForm(true);
        break;
      }
      default:
        break;
    }
  };

  if (showPasswordForm)
    return (
      <div className="d-flex align-self-stretch justify-content-center rounded">
        <PasswordForm
          password={filePassword}
          setPassword={setFilePassword}
          onPasswordCallback={onPasswordCallback}
          labelTranslationKey={modalLabelTranslationKey}
        />
      </div>
    );

  return (
    <div className="pdf-previewer-container" data-hj-suppress>
      {selectedFile ? (
        <div className="control-container">
          <PDFPagination
            selectedFile={selectedFile}
            totalPage={totalPageNumber}
            setCurrentPage={setCurrentPage}
            setCurrentScale={setCurrentScale}
            darkMode
            hidePrevNext
          />
        </div>
      ) : null}
      <ScrollContainer className="container" hideScrollbars={false}>
        <Document
          className="document-preview-area"
          file={selectedFile}
          onLoadSuccess={onDocumentLoadSuccess}
          onPassword={onPasswordSubmit}
        >
          <Page
            pageNumber={pageNumber}
            renderTextLayer={false}
            scale={pageScale}
          />
        </Document>
      </ScrollContainer>
    </div>
  );
};

export default PDFPreviewer;
