import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TableMenuIcon } from '../../assets/svg/TableMenuIcon.svg';
import KeyPairItem from '../../common/models/keypairItem';
import { RowCollection, RowData } from '../../common/types/types';
import MetadataTemplateItem from '../../services/documentType/models/metadataTemplateItem';
import UserSettings from '../../services/user/models/userSettings';
import TableRowDropdownMenu from '../TableRowDropdownMenu/TableRowDropdownMenu';
import './DataTable.scss';
import DataTableCell from './DataTableCell';

interface DataTableProps {
  template: MetadataTemplateItem[];
  rows: RowCollection;
  onEdit: (rowIndex: number, columnName: string, value: string) => void;
  onRowAdd: (index: number) => void;
  onRowDelete: (index: number) => void;
  settings: UserSettings;
}

const DataTable = ({
  template,
  rows,
  onEdit,
  onRowAdd,
  onRowDelete,
  settings,
}: DataTableProps): ReactElement => {
  const optionAddBefore = 'add-before';
  const optionAddAfter = 'add-after';
  const optionDelete = 'delete';
  const { t } = useTranslation();
  const tableOptions: KeyPairItem[] = [
    {
      Name: t('dataTable.optionDelete'),
      Value: optionDelete,
    },
    {
      Name: t('dataTable.optionAddRowBefore'),
      Value: optionAddBefore,
    },
    {
      Name: t('dataTable.optionAddRowAfter'),
      Value: optionAddAfter,
    },
  ];

  const handleMenuOption = (item: KeyPairItem, rowIndex: number): void => {
    if (!onRowDelete || !onRowAdd) {
      return;
    }

    if (item.Value === optionDelete) {
      onRowDelete(rowIndex);
    } else if (item.Value === optionAddBefore) {
      onRowAdd(rowIndex);
    } else if (item.Value === optionAddAfter) {
      const index = rowIndex + 1 > rows.length ? rows.length : rowIndex + 1;
      onRowAdd(index);
    }
  };

  const renderCell = (
    rowIndex: number,
    column: MetadataTemplateItem,
    rowData: RowData
  ): ReactElement => {
    const { name, dataType, isRequired } = column;
    const rowItem = rowData.find((x) => x.key === name);
    const data = (rowItem?.value as string) ?? '';

    return (
      <td
        key={`${rowIndex}-${name}`}
        className="clear"
        title={data.length > 20 ? data : ''}
      >
        <DataTableCell
          key={`${rowIndex}-${name}-table-cell`}
          value={data}
          dataType={dataType}
          onEdited={(value: string): void => {
            if (onEdit) {
              onEdit(rowIndex, name, value);
            }
          }}
          settings={settings}
          isRequired={isRequired}
          t={t}
        />
      </td>
    );
  };

  const renderHeader = (): ReactElement => (
    <tr key="row-header">
      <th key="option" />
      <th key="no">{t('dataTable.columnCount')}</th>
      {template.map((value) => (
        <th key={value.name}>{value.displayName}</th>
      ))}
    </tr>
  );

  const renderRow = (rowNumber: number, rowData: RowData): ReactElement => (
    <tr key={rowNumber}>
      <td key="row-option" className="system-column">
        <TableRowDropdownMenu
          Icon={TableMenuIcon}
          values={tableOptions}
          onSelect={(item: KeyPairItem): void =>
            handleMenuOption(item, rowNumber)
          }
        />
      </td>
      <td key="row-count" className="system-column">
        {rowNumber + 1}
      </td>
      {template.map((column) => renderCell(rowNumber, column, rowData))}
    </tr>
  );

  return (
    <div className="data-table-component">
      <table>
        <thead>{renderHeader()}</thead>
        <tbody>
          {rows.map((value: RowData, index: number) => renderRow(index, value))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
