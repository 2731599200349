import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FileProcessIcon } from '../../assets/svg/FileProcessIcon.svg';
import './PendingTrayProcessingStatusCard.scss';

interface PendingTrayProcessingStatusCardProps {
  fileName: string;
}

const PendingTrayProcessingStatusCard = ({
  fileName,
}: PendingTrayProcessingStatusCardProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="status-card">
      <div className="processing-card">
        <div className="icon">
          <FileProcessIcon />
        </div>
        <div className="card-body">
          <div className="title truncate" data-hj-suppress>
            {fileName}
          </div>
          <div className="subtitle blink">
            {t('pendingTrayPage.processing')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingTrayProcessingStatusCard;
