/* eslint-disable react/require-default-props */
import { MutableRefObject, ReactElement, useState } from 'react';
import SortDirection from '../../common/types/sortDirection';
import SDCCheckbox from '../SDCCheckbox/SDCCheckbox';
import GridHeaderCell from './GridHeaderCell';

export interface DataGridHeaderProps {
  columnKey: string;
  translationKey: string;
  ref: MutableRefObject<any>;
  columnExtraWidth?: boolean;
}

export interface GridHeaderProps {
  headers: Array<DataGridHeaderProps>;
  selectable: boolean;
  onSort?: (columnKey: string) => void;
  sortDirection: {
    [key: string]: SortDirection;
  };
  sortableColumnKeys: string[];
  onSelectAllChange?: (checked: boolean) => void;
  disabled: boolean;
}

const GridHeader = ({
  headers,
  selectable,
  onSort,
  sortDirection,
  sortableColumnKeys,
  onSelectAllChange,
  disabled,
}: GridHeaderProps): ReactElement => {
  const [selectedAll, setSelectedAll] = useState(false);
  const sort = (columnKey: string): void => {
    if (onSort) onSort(columnKey);
  };

  return (
    <div className="data-header">
      {selectable ? (
        <div className="data-check">
          <SDCCheckbox
            checked={selectedAll}
            disabled={disabled}
            onCheckChange={(): void => {
              setSelectedAll((currentValue) => {
                const newValue = !currentValue;
                if (onSelectAllChange) {
                  onSelectAllChange(newValue);
                }

                return newValue;
              });
            }}
          />
        </div>
      ) : (
        <div className="padded-column" />
      )}
      {headers.map(({ translationKey, ref, columnExtraWidth }, i: number) => (
        <GridHeaderCell
          key={translationKey}
          text={translationKey}
          className={
            translationKey === ''
              ? 'data-menu'
              : `data-col ${columnExtraWidth ? 'f-2' : ''}`
          }
          cellIndex={i + 1}
          onSort={onSort ? sort : undefined}
          sortDirection={sortDirection}
          element={ref}
          sortableColumnKeys={sortableColumnKeys}
        />
      ))}
    </div>
  );
};

export default GridHeader;
GridHeader.defaultProps = {
  onSort: (): void => {},
  onSelectAllChange: (): void => {},
};
