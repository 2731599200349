import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import TipIcon from '../../assets/svg/TipIcon.svg';
import ActionItem from '../../common/models/actionItem';
import KeyPairItem from '../../common/models/keypairItem';
import CollapserPositionVariant from '../../common/types/collapserPositionVariant';
import DataType from '../../common/types/dataType';
import { RowCollection, RowData } from '../../common/types/types';
import Metadata from '../../services/capture/models/metadata';
import MetadataDetail from '../../services/capture/models/metadataDetail';
import { getDocumentTemplate } from '../../services/documentType/documentType.service';
import DocumentTemplateResponse from '../../services/documentType/models/documentTemplateResponse';
import DocumentType from '../../services/documentType/models/documentType';
import MetadataTemplateItem from '../../services/documentType/models/metadataTemplateItem';
import { updatePendingTrayItemTags } from '../../services/pendingTray/pendingTray.service';
import UserSettings from '../../services/user/models/userSettings';
import DataTable from '../DataTable/DataTable';
import FullSizeModal from '../DefaultModal/FullSizeModal';
import DocumentMetadataTable from '../DocumentMetadataTable/DocumentMetadataTable';
import LoadingSet from '../LoadingSet/LoadingSet';
import SDCDropdown from '../SDCDropdown/SDCDropdown';
import TableMetadataSection from '../TableMetadataSection/TableMetadataSection';
import Tag from '../Tag/Tag';
import './CaptureSidePanel.scss';
import SidePanel from './SidePanel';

interface CaptureSidePanelProps {
  documentTypes: DocumentType[];
  metadata: Metadata;
  onDocumentTypeChange: (
    templateResponse: DocumentTemplateResponse | undefined
  ) => void;
  onMetadataChange: (data: MetadataDetail[]) => void;
  documentTypeValidateError: string;
  settings: UserSettings;
  collapserPosition: CollapserPositionVariant;
  actionItem?: ActionItem;
  tags?: string[];
  pendingTrayId?: string;
  isLoading?: boolean;
  fileName?: string;
}

const CaptureSidePanel = ({
  documentTypes,
  metadata,
  onDocumentTypeChange,
  onMetadataChange,
  documentTypeValidateError,
  settings,
  collapserPosition,
  actionItem,
  tags,
  pendingTrayId,
  isLoading,
  fileName,
}: CaptureSidePanelProps): ReactElement => {
  const handleError = useErrorHandler();
  const { t } = useTranslation();
  const [showTableSection, setShowTableSection] = useState<boolean>(false);
  const [filteredDocumentTypes, setFilteredDocumentTypes] = useState<
    DocumentType[]
  >([]);
  const [documentTypeItems, setDocumentTypeItems] = useState<KeyPairItem[]>([]);
  const [selectedDocumentType, setSelectedDocumenType] =
    useState<KeyPairItem>();
  const [currentTemplate, setCurrentTemplate] = useState<
    MetadataTemplateItem[]
  >([]);
  const [tagsData, setTagsData] = useState<string[]>([]);
  const [showTable, setShowTable] = useState(false);
  const [selectedTable, setSelectedTable] = useState<
    MetadataDetail | undefined
  >(undefined);

  const filterDocumentTypeByGroup = (
    docTypes?: DocumentType[],
    templateGroupName?: string
  ): DocumentType[] => {
    if (!docTypes || !docTypes.length) return [];
    if (!templateGroupName) return docTypes;
    return docTypes.filter((docType) =>
      docType.groups.find(
        (groupName) =>
          groupName.toLowerCase() === templateGroupName?.toLowerCase()
      )
    );
  };

  const onSelected = useCallback(
    (item: KeyPairItem): void => {
      if (item.Value === '') {
        onDocumentTypeChange(undefined);
        return;
      }

      setSelectedDocumenType(item);

      // Load the template when the user switches document type
      getDocumentTemplate(item.Value, handleError).then((response) => {
        setCurrentTemplate(response.template);
        onDocumentTypeChange(response);
      });
    },
    [handleError, onDocumentTypeChange]
  );

  // ---------- Handle tags control ----------
  const callUpdateDocumentTags = async (newTags: string[]): Promise<void> => {
    await updatePendingTrayItemTags(pendingTrayId, newTags);
  };

  const handleAddTag = (value: string): void => {
    const temp = [...tagsData];
    if (value.trim() !== '') {
      temp.push(value.trim());
      setTagsData(temp);
      callUpdateDocumentTags(temp);
    }
  };

  const handleRemoveTag = (value: string): void => {
    const index = tagsData.indexOf(value);
    if (index !== -1) {
      const temp = [...tagsData];
      temp.splice(index, 1);
      setTagsData(temp);
      callUpdateDocumentTags(temp);
    }
  };

  // ---------- End Handle tags control ----------

  // ------------ useEffect ---------------

  useEffect(() => {
    if (tags) {
      setTagsData(() => tags);
    } else {
      setTagsData(() => []);
    }
  }, [tags]);

  useEffect(() => {
    const tableDataAvailable =
      metadata.data.findIndex(
        (x) =>
          x.type === DataType.Table && (x.value as RowCollection).length > 0
      ) !== -1;

    setShowTableSection(tableDataAvailable);
    setFilteredDocumentTypes(() =>
      filterDocumentTypeByGroup(documentTypes, metadata?.templateGroupName)
    );
  }, [documentTypes, metadata]);

  useEffect(() => {
    const dropdownItems = filteredDocumentTypes.map((item: DocumentType) => {
      const dropdownItem: KeyPairItem = {
        Name: item.displayName,
        Value: item.id,
      };
      return dropdownItem;
    });
    const defaultSelectItem: KeyPairItem = {
      Value: '',
      Name: t('sidePanel.noSelectDropdownItem'),
    };

    if (selectedDocumentType) {
      setDocumentTypeItems([...dropdownItems]);
    } else {
      setDocumentTypeItems([defaultSelectItem, ...dropdownItems]);
    }
  }, [filteredDocumentTypes, selectedDocumentType, t]);

  useEffect(() => {
    function getCurrentDocumentTypeItem(): void {
      const item = filteredDocumentTypes.find(
        (x: DocumentType) =>
          x.name.toLowerCase() === metadata?.templateName?.toLowerCase()
      );
      if (item) {
        const currentDocumentType = {
          Name: item.displayName,
          Value: item.id,
        } as KeyPairItem;
        setSelectedDocumenType(currentDocumentType);
        onSelected(currentDocumentType);
      }
    }
    if (!selectedDocumentType) getCurrentDocumentTypeItem();
  }, [filteredDocumentTypes, metadata, onSelected, selectedDocumentType]);

  // For debug
  // useEffect(() => {
  //   console.log('metadata change');
  //   console.log(metadata);
  // }, [metadata]);

  // ------------ End useEffect ---------------
  const triggerMetadataChangeEvent = (metadataItem: MetadataDetail): void => {
    if (!metadata?.data) {
      return;
    }

    // console.log('--------------- before edit');
    // console.log(metadata.data);
    // Replace the item with the updated one
    const data = [...metadata.data];
    const toRemove = data.find((x) => x.key === metadataItem.key);
    if (!toRemove) {
      return;
    }
    const index = data.indexOf(toRemove);
    data.splice(index, 1, metadataItem);

    // console.log(data);
    // console.log('--------------- after edit');
    onMetadataChange(data);
  };

  const handleCellEdit = (
    index: number,
    column: string,
    value: string
  ): void => {
    if (!selectedTable) {
      return;
    }

    // Modify table data
    const updatedTableMetadata: MetadataDetail = {
      ...selectedTable,
      value: (selectedTable.value as RowCollection).map((x, i) => {
        if (i === index) {
          return x.map((y) => {
            if (y.key === column)
              return {
                ...y,
                value,
              };
            return y;
          });
        }
        return x;
      }),
    };
    setSelectedTable(updatedTableMetadata);
    triggerMetadataChangeEvent(updatedTableMetadata);
  };

  const handleRowDelete = (index: number): void => {
    if (!selectedTable) {
      return;
    }

    const tempRow = [...(selectedTable.value as RowCollection)];
    tempRow.splice(index, 1);
    const updatedTableMetadata: MetadataDetail = {
      ...selectedTable,
      value: tempRow,
    };
    setSelectedTable(updatedTableMetadata);
    triggerMetadataChangeEvent(updatedTableMetadata);
  };

  const handleRowAdd = (
    index: number,
    columnTemplate: MetadataTemplateItem[]
  ): void => {
    if (!selectedTable) {
      return;
    }
    const emptyRow: RowData = [];
    columnTemplate.forEach((value: MetadataTemplateItem) => {
      const item: MetadataDetail = {
        key: value.name,
        value: '',
        type: value.dataType,
        displayName: value.displayName,
        isHidden: false,
      };
      emptyRow.push(item);
    });

    const temp = [...(selectedTable.value as RowCollection)];

    temp.splice(index, 0, emptyRow);
    const updatedTableMetadata: MetadataDetail = {
      ...selectedTable,
      value: temp,
    };

    setSelectedTable(updatedTableMetadata);
  };

  const renderContent = (): ReactElement => {
    if (isLoading) {
      return <LoadingSet size="sm" />;
    }

    if (!metadata) {
      return (
        <div className="nothing-selected">
          <div className="title">{t('sidePanel.noSelectionTitle')}</div>
          <div>{t('sidePanel.noSelectionDescription')}</div>
        </div>
      );
    }

    return (
      <>
        <div className="section-label">{t('sidePanel.documentTypeLabel')}</div>
        <div className="description-label">
          {t('sidePanel.documentTypeDescription')}
          <img
            src={TipIcon}
            alt=""
            data-tip={t('sidePanel.documentTypeTooltipDescription')}
          />
        </div>
        <div className="document-type">
          <SDCDropdown
            values={documentTypeItems}
            currentItem={selectedDocumentType}
            onSelect={onSelected}
            validateError={documentTypeValidateError}
          />
        </div>
        {selectedDocumentType && metadata?.data && metadata.data.length > 0 && (
          <>
            <div className="section-divider" />
            <div className="section-label">{t('sidePanel.metadataLabel')}</div>
            <DocumentMetadataTable
              metadata={metadata.data}
              template={currentTemplate}
              onMetadataItemsChange={onMetadataChange}
              settings={settings}
            />
          </>
        )}
        {showTableSection ? (
          <TableMetadataSection
            title={t('sidePanel.tableMetadataLabel')}
            data={metadata.data}
            onSelect={(item: MetadataDetail): void => {
              setSelectedTable(item);
              setShowTable(true);
            }}
          />
        ) : undefined}
        {tags ? (
          <>
            <div className="section-divider" />

            <div className="section-label">{t('sidePanel.tagLabelHeader')}</div>
            <div className="description-label">
              {t('sidePanel.tagLabel')}
              <img
                src={TipIcon}
                alt=""
                data-tip={t('sidePanel.tagTooltipDescription')}
              />
            </div>
            <div className="section-content">
              <Tag
                tags={tagsData}
                onTagAdd={handleAddTag}
                onTagRemove={handleRemoveTag}
              />
            </div>
          </>
        ) : (
          ''
        )}
        <div className="end-of-data">
          <div className="separator" />
          <div>{t('sidePanel.end')}</div>
        </div>
      </>
    );
  };
  return (
    <span className="capture-side-panel">
      <ReactTooltip effect="solid" multiline />
      <SidePanel
        title={fileName}
        collapserPosition={collapserPosition}
        footerActionItem={actionItem}
        data-hj-suppress
        resizable
      >
        <>{renderContent()}</>
      </SidePanel>

      {selectedTable !== undefined ? (
        <FullSizeModal
          className="data-table"
          title={selectedTable.displayName ?? selectedTable.key}
          setShow={setShowTable}
          show={showTable}
        >
          <DataTable
            template={
              currentTemplate.find((x) => x.name === selectedTable.key)
                ?.template ?? []
            }
            rows={selectedTable.value as RowCollection}
            onEdit={handleCellEdit}
            onRowAdd={(index: number): void => {
              const tableTemplate = currentTemplate.find(
                (x) => x.name === selectedTable.key
              )?.template;
              if (!tableTemplate) {
                return;
              }
              handleRowAdd(index, tableTemplate);
            }}
            onRowDelete={handleRowDelete}
            settings={settings}
          />
        </FullSizeModal>
      ) : null}
    </span>
  );
};

CaptureSidePanel.defaultProps = {
  actionItem: undefined,
  tags: undefined,
  pendingTrayId: undefined,
  isLoading: false,
  fileName: '',
};

export default CaptureSidePanel;
