import React from 'react';
import SDCLinkButtonVariant from '../../common/types/sdcLinkButtonVariant';
import './SDCLinkButton.scss';

interface SDCLinkButtonProps {
  variant?: SDCLinkButtonVariant;
  text: string;
  onClick: () => void;
}

const SDCLinkButton: React.FC<SDCLinkButtonProps> = ({
  variant,
  text,
  onClick,
}) => (
  <button
    type="button"
    onClick={onClick}
    className={`sdc-link-button ${variant}`}
  >
    {text}
  </button>
);

SDCLinkButton.defaultProps = {
  variant: SDCLinkButtonVariant.Standard,
};

export default SDCLinkButton;
