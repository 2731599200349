import { ReactElement, ReactNode } from 'react';
import { AuthContext } from '../../context/Auth/AuthContext';
import AuthService from '../../services/auth/auth.service';

interface AuthProviderProps {
  children: ReactNode;
  authService: AuthService;
}

const AuthProvider = (props: AuthProviderProps): ReactElement => {
  const { authService, children } = props;

  return (
    <AuthContext.Provider value={{ authService }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
