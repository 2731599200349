import DocumentSearchOptions from '../common/models/documentSearchOptions';
import DocumentSearchRequest from '../common/models/documentSearchRequest';
import DocumentSortOptions from '../common/models/documentSortOptions';
import TrashSearchOptions from '../common/models/trashSearchOptions';
import TrashDocumentSearchRequest from '../services/trash/models/trashDocumentSearchRequest';

/**
 * Get sort option for split string sort by and sort position to object
 */
export const getSortOptions = (
  sort: string | undefined
): DocumentSortOptions | object => {
  if (!sort) return {};
  try {
    const sortOptions = sort.split('_');
    if (sortOptions.length < 2) return {};
    const formatSortOptions: DocumentSortOptions = {
      sortBy: sortOptions[0],
      sortDirection: sortOptions[1],
    };
    return formatSortOptions;
  } catch (error) {
    return {};
  }
};

/**
 * Get search document request format
 */
export const getSearchParam = (
  searchOptions: DocumentSearchOptions,
  containerKey: string
): DocumentSearchRequest => {
  const {
    text,
    documentType,
    sortBy,
    page,
    scope,
    pageSize,
    filter,
    entityIds,
  } = searchOptions;
  const sortOptions = getSortOptions(sortBy);
  const getText = (searchText: string | undefined): string =>
    !searchText ? '*' : searchText;
  const formatSearchOptions: DocumentSearchRequest = {
    searchText: Array.isArray(text) ? text.join(',') : getText(text),
    documentType,
    containerKey,
    page,
    pageSize,
    scope,
    filter,
    entityIds,
    ...sortOptions,
  };

  return formatSearchOptions;
};

/**
 * Get trash search document request format
 */
export const getTrashSearchParam = (
  searchOptions: TrashSearchOptions,
  containerKey: string
): TrashDocumentSearchRequest => {
  const {
    documentType,
    page,
    pageSize,
    protection,
    searchText,
    deletedByUserId,
  } = searchOptions;

  const getText = (text: string | undefined): string => (!text ? '*' : text);
  const formatSearchOptions: TrashDocumentSearchRequest = {
    searchText: getText(searchText),
    documentType,
    page,
    pageSize,
    protection,
    deletedByUserId,
    containerKey,
  };

  return formatSearchOptions;
};

/**
 * Get search document request format for Pending tray page
 */
export const getPendingTraySearchParam = (
  searchOptions: DocumentSearchOptions,
  containerKey: string
): DocumentSearchRequest => {
  const { text, documentType, sortBy, page, scope, pageSize } = searchOptions;
  const sortOptions = getSortOptions(sortBy);

  const formatSearchOptions: DocumentSearchRequest = {
    searchText: Array.isArray(text) ? text.join(',') : text,
    documentType,
    containerKey,
    page,
    pageSize,
    scope,
    ...sortOptions,
  };

  return formatSearchOptions;
};
