import { ReactElement, useState } from 'react';
import NextPageIcon from '../../assets/svg/NextPageIcon.svg';
import PrevPageIcon from '../../assets/svg/PrevPageIcon.svg';
import SDCButtonSize from '../../common/types/sdcButtonSize';
import SDCDropdownSize from '../../common/types/sdcDropdownSize';
import PaginationButton from '../Pagination/PaginationButton';
import PaginationDropdown from '../Pagination/PaginationDropdown';
import PDFZoomControl from '../PDFZoomControl/PDFZoomControl';
import SDCButton from '../SDCButton/SDCButton';
import './PDFPagination.scss';

type PDFPaginationProps = {
  selectedFile: File | undefined;
  totalPage: number;
  setCurrentPage: Function;
  setCurrentScale: Function;
  hidePrevNext?: boolean;
  hidePaginationNumber?: boolean;
  darkMode?: boolean;
};

const PDFPagination = ({
  selectedFile,
  totalPage,
  setCurrentPage,
  hidePrevNext,
  hidePaginationNumber,
  setCurrentScale,
  darkMode,
}: PDFPaginationProps): ReactElement => {
  const [pageNumber, setPageNumber] = useState(1);

  // If last page is greater than or equal to this number, page buttons will collapse.
  const pagesToCollapse = 6;
  // First page
  const defaultPage = 1;
  // In case of collapse, it's the gap for the number of buttons to display before and after the collapse droup down
  const collapsePageGap = 2;
  // In case of collapse, it's the page that starts the collapse.
  const collapseStartPage = 3;
  // In case of collapse, it's the page that the collapse ends.
  const collapseEndPage = totalPage - collapsePageGap;

  const setPage = (page: any): void => {
    setPageNumber(page);
    setCurrentPage(page);
  };

  const changePage = (offset: number): void => {
    setPage((prevPageNumber: number) => prevPageNumber + offset);
  };

  const previousPage = (): void => {
    changePage(-1);
  };

  const nextPage = (): void => {
    changePage(1);
  };

  const selectPage = (page: number): void => {
    setPage(page);
  };

  const prevNextSection = (): JSX.Element | undefined => {
    const prevNextBtn = (
      <div className="prev-next-btn-group">
        <SDCButton
          className="previous-btn mx-2"
          disabled={pageNumber <= 1}
          onClick={previousPage}
          size={SDCButtonSize.Small}
        >
          <img src={PrevPageIcon} alt="PrevPageIcon" />
        </SDCButton>
        <span className="page-info">
          {pageNumber}/{totalPage}
        </span>
        <SDCButton
          className="next-btn mx-2"
          disabled={pageNumber >= totalPage}
          onClick={nextPage}
          size={SDCButtonSize.Small}
        >
          <img src={NextPageIcon} alt="NextPageIcon" />
        </SDCButton>
      </div>
    );
    if (!hidePrevNext) {
      return prevNextBtn;
    }
    return undefined;
  };

  const getButton = (displayNumber: number): ReactElement => (
    <PaginationButton
      text={`${displayNumber}`}
      onClick={(): void => selectPage(displayNumber)}
      active={pageNumber === displayNumber}
      darkMode={darkMode}
      size={SDCButtonSize.Small}
      key={`${displayNumber}`}
    />
  );

  const renderButtons = (): JSX.Element[] => {
    const buttons = [];
    // Break up the navigation buttons into a drop down if there's too many pages.
    // Otherwise render all the navigation buttons.
    if (totalPage >= pagesToCollapse) {
      // Display before buttons
      for (let number = defaultPage; number <= collapsePageGap; number += 1) {
        buttons.push(getButton(number));
      }

      // Display the collapse
      const pages = [];
      for (
        let number = collapseStartPage;
        number <= collapseEndPage;
        number += 1
      ) {
        pages.push(number);
      }

      buttons.push(
        <PaginationDropdown
          key="page-dropdown"
          values={pages}
          active={pages.includes(pageNumber)}
          onSelect={setPage}
          displayText={pages.includes(pageNumber) ? `${pageNumber}` : '...'}
          darkMode={darkMode}
          size={SDCDropdownSize.Small}
        />
      );

      // Display after buttons
      for (let number = collapseEndPage + 1; number <= totalPage; number += 1) {
        buttons.push(getButton(number));
      }
    } else {
      // Display all buttons
      for (let number = defaultPage; number <= totalPage; number += 1) {
        buttons.push(getButton(number));
      }
    }

    return buttons;
  };

  const paginationSection = (): JSX.Element | undefined => {
    const paginationGroupBtn = (
      <div className="control-group-container">
        <span>{renderButtons()}</span>
        <span className="zoom-control-section">
          <PDFZoomControl setCurrentScale={setCurrentScale} darkMode />
        </span>
      </div>
    );
    if (!hidePaginationNumber) {
      return paginationGroupBtn;
    }
    return undefined;
  };

  return (
    <>
      {selectedFile ? (
        <div className="document-preview-navigator">
          {prevNextSection()}
          {paginationSection()}
        </div>
      ) : null}
    </>
  );
};

PDFPagination.defaultProps = {
  hidePrevNext: false,
  hidePaginationNumber: false,
  darkMode: false,
};

export default PDFPagination;
