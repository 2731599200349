import dateFormat from '../common/dateFormat';

function formatDateTime(
  value: Date,
  lng?: string,
  format?: Intl.DateTimeFormatOptions,
  timeZoneName?: string
): string {
  // Default with DateTime
  const defaultDateFormat = dateFormat.datetime;
  // Options https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
  const dateFormatOptions: Intl.DateTimeFormatOptions =
    format || defaultDateFormat;

  if (timeZoneName) dateFormatOptions.timeZone = timeZoneName;

  const dateValue = new Intl.DateTimeFormat(lng, dateFormatOptions).format(
    value
  );

  return dateValue.replace(/, /g, ' ');
}

export default formatDateTime;
