import React, { ReactElement, useEffect } from 'react';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ReactComponent as CloudCheckIcon } from '../../../assets/svg/CloudCheckIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/DeleteIcon.svg';
import { ReactComponent as KeyLargeIcon } from '../../../assets/svg/KeyLargeIcon.svg';
import { ReactComponent as PendingTrayIcon } from '../../../assets/svg/PendingTrayIcon.svg';
import { ReactComponent as ProtectionIcon } from '../../../assets/svg/ProtectionIcon.svg';
import { ReactComponent as SearchMenuIcon } from '../../../assets/svg/SearchMenuIcon.svg';
import { ReactComponent as SettingIcon } from '../../../assets/svg/SettingIcon.svg';
import { ReactComponent as SpindleDocumentVerticleLogo } from '../../../assets/svg/SpindleDocumentVerticleLogo.svg';
import { ReactComponent as TeamManagementIcon } from '../../../assets/svg/TeamManagementIcon.svg';
import UserPermission from '../../../common/types/userPermission';
import { useCurrentUser } from '../../../context/CurrentUser/CurrentUserContext';
import isTabletSize from '../../../utils/responsiveUtil';
import userHasPermission from '../../../utils/userUtil';
import './NavigationSideMenu.scss';

const NavigationSideMenu = (): ReactElement => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const [activeLocation, setActiveLocation] = React.useState('/');
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== activeLocation)
      setActiveLocation(location.pathname);
  }, [activeLocation, location]);

  if (isTabletSize()) {
    return <></>;
  }

  const renderFullScreenMenu = (): ReactElement => (
    <Navbar id="sidebar-wrapper" className="m-0">
      <Nav className="d-flex sidebar" activeKey={activeLocation}>
        <div>
          <Nav.Item className="pb-3">
            <SpindleDocumentVerticleLogo />
          </Nav.Item>
          {userHasPermission(currentUser, UserPermission.AccessPendingTray) ? (
            <Nav.Item>
              <Nav.Link eventKey="/pending-tray" href="/pending-tray">
                <PendingTrayIcon />
                {t('mainMenu.pendingTrayMenuItem')}
              </Nav.Link>
            </Nav.Item>
          ) : null}
          {userHasPermission(
            currentUser,
            UserPermission.SearchArchivedDocuments
          ) ? (
            <Nav.Item>
              <Nav.Link eventKey="/search" href="/search">
                <SearchMenuIcon />
                {t('mainMenu.documentSearchMenuItem')}
              </Nav.Link>
            </Nav.Item>
          ) : null}
        </div>
        <div>
          {userHasPermission(currentUser, UserPermission.DeleteDocuments) ||
          userHasPermission(
            currentUser,
            UserPermission.RecoverDeletedDocuments
          ) ? (
            <Nav.Item>
              <Nav.Link eventKey="/trash" href="/trash">
                <DeleteIcon />
                {t('mainMenu.trashMenuItem')}
              </Nav.Link>
            </Nav.Item>
          ) : null}
          {userHasPermission(
            currentUser,
            UserPermission.ConfigureSystemSettings
          ) ? (
            <NavDropdown
              title={
                <>
                  <SettingIcon />
                  {t('mainMenu.settingMenuItem')}
                </>
              }
              className="side-menu-settings"
            >
              <NavDropdown.Item
                eventKey="/document-protection"
                href="/document-protection"
              >
                <ProtectionIcon fill="white" />
                {t('mainMenu.documentProtectionMenuItem')}
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey="/container-management"
                href="/container-management"
              >
                <CloudCheckIcon />
                {t('mainMenu.containerManagementMenuItem')}
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="/api-access" href="/api-access">
                <KeyLargeIcon />
                {t('mainMenu.apiAccessMenuItem')}
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey="/team-management"
                href="/team-management"
              >
                <TeamManagementIcon />
                {t('mainMenu.teamManagementMenuItem')}
              </NavDropdown.Item>
            </NavDropdown>
          ) : null}
        </div>
      </Nav>
    </Navbar>
  );

  return (
    <>
      {
        // Window size view
        renderFullScreenMenu()
      }
    </>
  );
};
export default NavigationSideMenu;
