import React, { ReactElement } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import LocationProvider from '../../common/providers/locationProvider';
import { useAuth } from '../../context/Auth/AuthContext';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import DefaultLoading from '../DefaultLoading/DefaultLoading';

type PrivateRouteProps = {
  path: string;
  exact?: boolean;
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
};

const PrivateRoute = ({
  path,
  exact,
  component,
}: PrivateRouteProps): ReactElement => {
  const { authService } = useAuth();
  const { fetchingUserData } = useCurrentUser();

  if (authService.isRequiresAuthentication()) {
    const redirectUrl = authService.authorize();
    const locationProvider = new LocationProvider();
    locationProvider.replace(redirectUrl);
  }

  return authService.isPending() || fetchingUserData ? (
    <DefaultLoading />
  ) : (
    <Route exact={exact} path={path} component={component} />
  );
};

PrivateRoute.defaultProps = {
  exact: true,
};

export default PrivateRoute;
