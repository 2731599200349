import { AxiosError } from 'axios';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../assets/svg/CloseIcon.svg';
import signalRServerEventName from '../../common/constants/signalRServerEventName.constant';
import storageKey from '../../common/constants/storageKey.constants';
import KeyPairItem from '../../common/models/keypairItem';
import StorageProvider from '../../common/providers/storageProvider';
import ConfirmationModalVariant from '../../common/types/confirmationModalVariant';
import ErrorCode from '../../common/types/errorCode';
import NotificationVariant from '../../common/types/notificationVariant';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import SDCDropdownSize from '../../common/types/sdcDropdownSize';
import { useSignalR } from '../../context/SignalR/SignalRContext';
import useDefaultDestinationUser from '../../hooks/useDefaultDestinationUser/useDefaultDestinationUser';
import { movePendingTrayDocumentFiles } from '../../services/pendingTray/pendingTray.service';
import invokeWithIgnoredResult from '../../utils/signalRUtil';
import ConfirmationModal from '../DefaultModal/ConfirmationModal';
import SDCButton from '../SDCButton/SDCButton';
import SDCDropdown from '../SDCDropdown/SDCDropdown';
import showToaster from '../Toaster/ToasterProvider';
import './PendingTrayDocumentMovingModal.scss';

interface PendingTrayDocumentMovingModalProps {
  checkedDocumentIds: string[];
  setCheckedDocumentIds: (checkedDocumentIds: Array<string>) => void;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  scope: string;
  selectedUserDestination: KeyPairItem;
  setSelectedUserDestination: React.Dispatch<React.SetStateAction<KeyPairItem>>;
  setIsMovingFile: (isMovingFile: boolean) => void;
  loadPendingTray: Function;
}

const PendingTrayDocumentMovingModal = ({
  checkedDocumentIds,
  setCheckedDocumentIds,
  show,
  setShow,
  scope,
  selectedUserDestination,
  setSelectedUserDestination,
  setIsMovingFile,
  loadPendingTray,
}: PendingTrayDocumentMovingModalProps): ReactElement => {
  const storage = useMemo(() => new StorageProvider(), []);
  const { connection } = useSignalR();
  const { t } = useTranslation();
  const defaultDestinationUser = useDefaultDestinationUser(scope);
  const rememberedForceAnswer =
    storage.getItem(storageKey.FORCE_MOVING_FILE_IN_PENDING_TRAY) === 'true';
  const [
    isMoveFileConfirmationModalVisible,
    setIsMoveFileConfirmationModalVisible,
  ] = useState(false);

  const onMoveFiles = useCallback(
    (ids: string[], destinationUserId: string, force: boolean | null): void => {
      setIsMovingFile(true);
      const answer = rememberedForceAnswer || force === true;

      movePendingTrayDocumentFiles(ids, destinationUserId, answer)
        .then(() => {
          showToaster(
            t('pendingTrayPage.toasterSuccess'),
            t('pendingTrayPage.movedFilesSuccess', {
              count: ids.length,
            }),
            NotificationVariant.PrimarySuccess
          );
          setCheckedDocumentIds([]);
          invokeWithIgnoredResult(
            connection,
            signalRServerEventName.NOTIFY_PUBLIC_TRAY_UPDATE
          );
          loadPendingTray(true);
        })
        .catch((error: any) => {
          const axiosError = error as AxiosError;
          const errorCode = (axiosError?.response?.data as any)?.errorCode;
          if (errorCode === ErrorCode.DuplicatedFileNameInPendingTray) {
            if (setIsMoveFileConfirmationModalVisible)
              setIsMoveFileConfirmationModalVisible(true);
          } else {
            showToaster(
              t('pendingTrayPage.toasterFailure'),
              t('pendingTrayPage.movedFileFailed'),
              NotificationVariant.PrimaryDanger
            );
          }
        })
        .finally(() => {
          setIsMovingFile(false);
        });
    },
    [
      connection,
      loadPendingTray,
      rememberedForceAnswer,
      setCheckedDocumentIds,
      setIsMovingFile,
      t,
    ]
  );

  const onSubmitDocumentMoving = (): void => {
    if (selectedUserDestination != null) {
      onMoveFiles(
        checkedDocumentIds,
        selectedUserDestination.Value,
        rememberedForceAnswer
      );
      setShow(false);
    }
  };

  if (isMoveFileConfirmationModalVisible)
    return (
      <ConfirmationModal
        title={t('pendingTrayPage.moveFileConfirmationModalTitle')}
        bodyText={t('pendingTrayPage.moveFileConfirmationModalBody')}
        primaryButtonText={t(
          'pendingTrayPage.moveFileConfirmationModalConfirmButton'
        )}
        dismissButtonText={t(
          'pendingTrayPage.moveFileConfirmationModalCancelButton'
        )}
        show={isMoveFileConfirmationModalVisible}
        setShow={setIsMoveFileConfirmationModalVisible}
        onAnswer={(isYes: boolean): void => {
          if (isYes) {
            onMoveFiles(
              checkedDocumentIds,
              selectedUserDestination.Value,
              true
            );
          }
        }}
        variant={ConfirmationModalVariant.PrimaryDefault}
        showRememberThisAnswer
        rememberKey={storageKey.FORCE_MOVING_FILE_IN_PENDING_TRAY}
      />
    );

  return (
    <>
      <Modal
        show={show}
        dialogClassName="pending-tray-document-moving-modal-container"
        centered
        onHide={(): void => setShow(false)}
      >
        <Modal.Header>
          <Modal.Title>
            {t('pendingTrayPage.documentMovingModalTitle')}
          </Modal.Title>
          <button
            type="button"
            className="close-button"
            onClick={(): void => {
              setShow(false);
            }}
          >
            <CloseIcon width={22} height={22} />
          </button>
        </Modal.Header>

        <>
          <div className="move-document-modal">
            <Modal.Body>
              <Row className="move-document-body">
                <Col>
                  <SDCDropdown
                    currentItem={selectedUserDestination}
                    values={[defaultDestinationUser]}
                    onSelect={(e): void => setSelectedUserDestination(e)}
                    size={SDCDropdownSize.Default}
                  />
                </Col>
              </Row>
            </Modal.Body>
          </div>
          <Modal.Footer className="move-document-footer">
            <SDCButton
              text={t('pendingTrayPage.documentMovingModalSubmitButton')}
              onClick={onSubmitDocumentMoving}
              variant={SDCButtonVariant.Primary}
            />
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};

export default PendingTrayDocumentMovingModal;
