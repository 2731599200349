type UploadingFileConfiguration = {
  limitFileUploadQueue: number;
};

const getFileUploadConfig = (): UploadingFileConfiguration => ({
  limitFileUploadQueue: process.env.REACT_APP_LIMIT_FILE_UPLOAD_QUEUE
    ? parseInt(process.env.REACT_APP_LIMIT_FILE_UPLOAD_QUEUE, 10)
    : // Expand limit if can't get limit from enviroment
      2,
});

export default getFileUploadConfig;
