import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UploadCardDisplayModel from '../../common/models/uploadCardDisplayModel';
import UploadFileWithStatus from '../../common/models/uploadFileWithStatus';
import FileUploadStatus from '../../common/types/fileUploadStatus';
import FileUploadStatusCard from '../../common/types/fileUploadStatusCard';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import { formatFileSizeBytes } from '../../utils/fileUtil';
import SDCButton from '../SDCButton/SDCButton';
import './UploadedCard.scss';

interface UploadedCardProps {
  fileInfo: UploadFileWithStatus;
  getCardProps: (cardStatus: FileUploadStatus) => UploadCardDisplayModel;
  setUploadFileStatus: (uploadedFile: UploadFileWithStatus) => void;
}

const UploadedCard = ({
  fileInfo,
  getCardProps,
  setUploadFileStatus,
}: UploadedCardProps): ReactElement => {
  const { t } = useTranslation();
  const [isDismiss, setIsDismiss] = useState(false);
  const { className, Icon, title }: UploadCardDisplayModel = getCardProps(
    fileInfo.status
  );

  const dismissCard = (): void => {
    setIsDismiss(true);
    const newState = {
      ...fileInfo,
      status: FileUploadStatus.CANCELED,
    };
    setUploadFileStatus(newState);
  };

  const duplicatedErrorMessage = t(
    'uploadingCardComponent.errorDescriptionFileDuplicateSuccess'
  );

  if (isDismiss) return <></>;
  return (
    <div className={`uploaded-card-container ${className}`}>
      <div className="file-info-wrapper">
        <Icon />
        <div className="file-info">
          {title && <span className="file-status">{title}</span>}
          &nbsp;
          {fileInfo.file.name}
          &nbsp;
          <span className="file-size">{`(${formatFileSizeBytes(
            fileInfo.file.size
          )})`}</span>
          &nbsp;
          {(fileInfo.status === FileUploadStatus.FAILED ||
            fileInfo.status === FileUploadStatus.DUPLICATED) && (
            <div className={`file-${className}`}>
              {fileInfo.error ?? duplicatedErrorMessage}
            </div>
          )}
        </div>
        <div className="close-file-upload">
          {(className === FileUploadStatusCard.FAILED ||
            className === FileUploadStatusCard.DUPLICATED) && (
            <SDCButton
              text={t('uploadingCardComponent.dismissButton')}
              className="dismiss-btn body-bold"
              onClick={(): void => {
                dismissCard();
              }}
              variant={SDCButtonVariant.Link}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadedCard;
