import React, { FunctionComponent, SVGProps } from 'react';
import SDCButtonIconPosition from '../../common/types/sdcButtonIconPosition';
import SDCButtonSize from '../../common/types/sdcButtonSize';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';

interface Props {
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  iconPosition?: SDCButtonIconPosition;
  variant?: SDCButtonVariant;
  size?: SDCButtonSize;
  disabled?: boolean;
  text?: string;
  onClick: () => void;
  className?: string;
}

const SDCButton: React.FC<Props> = ({
  Icon,
  iconPosition,
  variant,
  size,
  disabled,
  text,
  onClick,
  className,
}) => (
  <button
    type="button"
    onClick={onClick}
    className={`custom-button ${variant} ${size} ${className || ''}`}
    disabled={disabled}
  >
    {Icon !== undefined && iconPosition === SDCButtonIconPosition.Left && (
      <Icon />
    )}
    {text !== undefined ? text : ''}
    {Icon !== undefined && iconPosition === SDCButtonIconPosition.Right && (
      <Icon />
    )}
  </button>
);

SDCButton.defaultProps = {
  variant: SDCButtonVariant.Default,
  Icon: undefined,
  iconPosition: SDCButtonIconPosition.Left,
  disabled: false,
  size: SDCButtonSize.Default,
  className: undefined,
  text: undefined,
};

export default SDCButton;
