import { AxiosError } from 'axios';
import { ReactElement, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../assets/svg/CloseIcon.svg';
import lengths from '../../common/constants/lengths.constant';
import ErrorDetails from '../../common/models/errorDetails';
import ErrorCode from '../../common/types/errorCode';
import NotificationVariant from '../../common/types/notificationVariant';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import {
  createContainerAsync,
  updateContainerAsync,
} from '../../services/container/container.service';
import Container from '../../services/container/models/container';
import NotificationAlert from '../NotificationAlert/NotifcationAlert';
import SDCButton from '../SDCButton/SDCButton';
import SDCTextField from '../SDCTextField/SDCTextField';
import './EditContainerModal.scss';

interface EditContainerModalProps {
  container: Container;
  show: boolean;
  onCreationComplete: () => void;
  onUpdateComplete: () => void;
  onClose: () => void;
}

const EditContainerModal = ({
  container,
  show,
  onCreationComplete,
  onUpdateComplete,
  onClose,
}: EditContainerModalProps): ReactElement => {
  const { t } = useTranslation();
  const tempItem = container;
  const isNewContainer = !tempItem.id;

  const {
    register,
    setValue,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<Container>({ defaultValues: tempItem });

  useEffect(() => {
    register('name', {
      required: t('validation.textMissingError'),
      maxLength: {
        value: lengths.name,
        message: t('validation.textLengthError', {
          count: lengths.name,
        }),
      },
    });
    register('key', {
      required: t('validation.textMissingError'),
      maxLength: {
        value: lengths.name,
        message: t('validation.textLengthError', {
          count: lengths.containerKey,
        }),
      },
    });
  }, [register, t]);

  const handleApiError = (error: any, item: Container): void => {
    const axiosError = error as AxiosError;
    const detail = axiosError.response?.data as ErrorDetails;
    if (detail.errorCode === ErrorCode.ContainerKeyAlreadyExists) {
      setError('key', {
        message: t('containerPage.errorKeyAlreadyExists', {
          key: item.key,
        }),
      });
      return;
    }
    if (detail.errorCode === ErrorCode.SystemContainerModification) {
      setError('key', { message: t('containerPage.errorSystemContainer') });
      return;
    }
    if (detail.errorCode === ErrorCode.InvalidContainerKeyFormat) {
      setError('key', {
        message: t('containerPage.errorInvalidContainerKeyFormat'),
      });
      return;
    }
    if (detail.errorCode === ErrorCode.InvalidContainerNameFormat) {
      setError('name', {
        message: t('containerPage.errorInvalidContainerNameFormat'),
      });
      return;
    }
    setError('id', {
      message: isNewContainer
        ? t('containerPage.errorGenericCreate')
        : t('containerPage.errorGenericUpdate'),
    });
  };

  const handleButtonSubmit = (item: Container): void => {
    if (isNewContainer) {
      createContainerAsync({
        name: item.name,
        key: item.key,
      })
        .then(() => {
          onCreationComplete();
        })
        .catch((error) => {
          handleApiError(error, item);
        });

      return;
    }

    updateContainerAsync(tempItem.id, {
      name: item.name,
      key: item.key,
    })
      .then(() => {
        onUpdateComplete();
      })
      .catch((error) => {
        handleApiError(error, item);
      });
  };

  return (
    <>
      <Modal
        dialogClassName="edit-container-modal-container"
        show={show}
        onHide={onClose}
        centered
      >
        <Modal.Header>
          <Modal.Title className="user-select-none">
            {container?.id
              ? t('containerPage.editModalTitle')
              : t('containerPage.createModalTitle')}
          </Modal.Title>
          <button type="button" className="close-button" onClick={onClose}>
            <CloseIcon width={22} height={22} />
          </button>
        </Modal.Header>

        <Modal.Body>
          <SDCTextField
            onTextChange={(value: string): void => {
              setValue('name', value);
            }}
            value={container.name}
            hasError={errors.name !== undefined}
            errorMessage={errors.name?.message}
            errorMessageId="container-name"
            labelText={t('containerPage.containerNameLabel')}
          />
          <div className="pb-4" />
          <SDCTextField
            onTextChange={(value: string): void => {
              setValue('key', value);
            }}
            value={container.key}
            hasError={errors.key !== undefined}
            errorMessage={errors.key?.message}
            errorMessageId="container-key"
            labelText={t('containerPage.containerKeyLabel')}
          />
          {!isNewContainer && errors.id === undefined ? (
            <>
              <div className="pb-4" />
              <NotificationAlert
                title=""
                message={t('containerPage.alertMessage')}
                variant={NotificationVariant.SecondaryWarning}
              />
            </>
          ) : null}
          {errors.id !== undefined ? (
            <>
              <div className="pb-4" />
              <NotificationAlert
                title={t('containerPage.errorGenericTitle')}
                message={errors.id?.message ?? ''}
                variant={NotificationVariant.SecondaryDanger}
              />
            </>
          ) : null}
        </Modal.Body>

        <Modal.Footer>
          <SDCButton
            className="ps-5 pe-5"
            text={
              isNewContainer
                ? t('containerPage.createButton')
                : t('containerPage.saveButton')
            }
            onClick={handleSubmit(handleButtonSubmit)}
            variant={SDCButtonVariant.Primary}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditContainerModal;
