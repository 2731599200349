import { ReactElement } from 'react';
import { ReactComponent as CloseCircleSolidIcon } from '../../assets/svg/CloseCircleSolidIcon.svg';
import './TagItem.scss';

interface TagItemProps {
  text: string;
  onRemove: (text: string) => void;
}

const TagItem = ({ text, onRemove }: TagItemProps): ReactElement => (
  <div className="tag-item">
    #{text}
    <button type="button" onClick={(): void => onRemove(text)}>
      <CloseCircleSolidIcon fill="#ffffff" />
    </button>
  </div>
);

export default TagItem;
