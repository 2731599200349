import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronRightIcon } from '../../assets/svg/ChevronRightIcon.svg';
import CloudDarkIcon from '../../assets/svg/CloudDarkIcon.svg';
import SDCButtonIconPosition from '../../common/types/sdcButtonIconPosition';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import SDCLinkButtonVariant from '../../common/types/sdcLinkButtonVariant';
import SDCButton from '../SDCButton/SDCButton';
import SDCLinkButton from '../SDCLinkButton/SDCLinkButton';
import './ArchiveTransition.scss';
import CheerIcon from './CheerIcon';
import CloudIcon from './CloudIcon';

interface ArchiveTransitionProps {
  show: boolean;
  fileName: string | undefined;
  filesToArchiveCount: number;
  onProceedNextFile: () => void;
  onClose: () => void;
}
const ArchiveTransition = ({
  filesToArchiveCount,
  onProceedNextFile,
  onClose,
  show,
  fileName,
}: ArchiveTransitionProps): ReactElement => {
  // TODO: call the API once finalized
  const { t } = useTranslation();

  const handleGoBackToUploadClick = (): void => {
    onClose();
  };

  const renderMoreFilesFlow = (): ReactElement => (
    <div className={show ? 'modal-more-files active' : 'modal-more-files'}>
      <div className="body-bold" data-hj-suppress>
        {fileName}
      </div>
      <div>{t('archiveTransitionScreen.fileArchiveConfirmationMessage')}</div>
      <div className="icon">
        <CloudIcon text={t('archiveTransitionScreen.moreFilesIconText')} />
      </div>
      <div>
        {t(
          filesToArchiveCount === 1
            ? 'archiveTransitionScreen.fileCountMessage'
            : 'archiveTransitionScreen.fileCountMessage_plural',
          {
            fileCount: filesToArchiveCount,
          }
        )}
      </div>
      <div className="primary-action">
        <SDCButton
          text={t('archiveTransitionScreen.moreFilesActionText')}
          onClick={onProceedNextFile}
          variant={SDCButtonVariant.OutlinedDark}
          Icon={ChevronRightIcon}
          iconPosition={SDCButtonIconPosition.Right}
        />
        <SDCLinkButton
          text={t('archiveTransitionScreen.uploadActionText')}
          onClick={handleGoBackToUploadClick}
          variant={SDCLinkButtonVariant.Dark}
        />
      </div>
      <div className="close-notes">
        <div className="or">
          {t('archiveTransitionScreen.sectionSeparator')}
        </div>
        <p>{t('archiveTransitionScreen.notes')}</p>
      </div>
      <div className="watermark-large">
        <img src={CloudDarkIcon} alt="" width={1520} height={1010} />
      </div>
      <div className="watermark-small">
        <img src={CloudDarkIcon} alt="" width={250} height={167} />
      </div>
    </div>
  );

  const renderNoFilesFlow = (): ReactElement => (
    <div className={show ? 'modal-no-files active' : 'modal-no-files'}>
      <div className="body-bold">{fileName}</div>
      <div>{t('archiveTransitionScreen.fileArchiveConfirmationMessage')}</div>
      <div className="icon">
        <CheerIcon text={t('archiveTransitionScreen.noFilesIconText')} />
      </div>
      <div className="heading-title-18">
        {t('archiveTransitionScreen.noFilesMessage')}
      </div>
      <div className="primary-action">
        <SDCButton
          text={t('archiveTransitionScreen.noFilesActionText')}
          onClick={onClose}
          variant={SDCButtonVariant.Outlined}
        />
      </div>
      <div className="close-notes">
        <p>{t('archiveTransitionScreen.notes')}</p>
      </div>
    </div>
  );

  return (
    <>
      <div className="archive-transition-container">
        {filesToArchiveCount > 0 ? renderMoreFilesFlow() : renderNoFilesFlow()}
      </div>
    </>
  );
};

export default ArchiveTransition;
