/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { ReactElement } from 'react';
import SDCCheckboxSize from '../../common/types/sdcCheckboxSize';
import './SDCCheckbox.scss';

type SDCCheckboxProps = {
  /**
   * Optional label text
   */
  labelText?: string;

  /**
   * Should this checkbox be checked. The parent must maintain the check state.
   */
  checked: boolean;

  /**
   * Checkbox size. If not specified, it uses the default.
   */
  sdcSize?: SDCCheckboxSize;

  /**
   * On check change. The value will be the reversed flag of @param checked
   */
  onCheckChange?: (value: boolean) => void;

  /**
   * On click. The value will be the reversed flag of @param checked
   */
  onClickEvent?: (e: React.MouseEvent<HTMLInputElement>) => void;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const SDCCheckbox = ({
  labelText,
  checked,
  sdcSize,
  onCheckChange,
  onClickEvent,
  ...props
}: SDCCheckboxProps): ReactElement => (
  <label
    className={`sdc-checkbox ${
      sdcSize !== SDCCheckboxSize.Default ? 'large' : ''
    } ${props.disabled ? 'disabled' : ''}`}
    onClick={(e: React.MouseEvent<HTMLLabelElement>): void => {
      e.stopPropagation();
    }}
  >
    {labelText ?? ''}
    <input
      type="checkbox"
      onChange={(): void => {
        if (onCheckChange) onCheckChange(!checked);
      }}
      onClick={(e: React.MouseEvent<HTMLInputElement>): void => {
        if (onClickEvent) onClickEvent(e);
      }}
      checked={checked}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
    <span
      className={`checkmark ${
        sdcSize !== SDCCheckboxSize.Default ? 'large' : ''
      } ${props.disabled ? 'disabled' : ''}`}
    />
  </label>
);

SDCCheckbox.defaultProps = {
  labelText: undefined,
  sdcSize: SDCCheckboxSize.Default,
  onClickEvent: undefined,
  onCheckChange: undefined,
};

export default SDCCheckbox;
