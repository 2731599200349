import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CollapserPositionVariant from '../../common/types/collapserPositionVariant';
import { RowCollection } from '../../common/types/types';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import Metadata from '../../services/capture/models/metadata';
import MetadataDetail from '../../services/capture/models/metadataDetail';
import { getDocumentTemplate } from '../../services/documentType/documentType.service';
import DocumentType from '../../services/documentType/models/documentType';
import MetadataTemplateItem from '../../services/documentType/models/metadataTemplateItem';
import FullSizeModal from '../DefaultModal/FullSizeModal';
import DocumentMetadataTable from '../DocumentMetadataTable/DocumentMetadataTable';
import ReadonlyDataTable from '../ReadonlyDataTable/ReadonlyDataTable';
import TableMetadataSection from '../TableMetadataSection/TableMetadataSection';
import './ArchivedDocumentSidePanel.scss';
import SidePanel from './SidePanel';

interface ArchivedDocumentSidePanelProps {
  documentType: DocumentType | undefined;
  metadata: Metadata;
}

const ArchivedDocumentSidePanel = ({
  documentType,
  metadata,
}: ArchivedDocumentSidePanelProps): ReactElement => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const defaultDocumentTypeName = t('sidePanel.documentTypeDefault');
  const [metadataTemplate, setMetadataTemplate] = useState<
    MetadataTemplateItem[]
  >([]);
  const [selectedTable, setSelectedTable] = useState<
    MetadataDetail | undefined
  >(undefined);
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    if (!documentType) {
      return;
    }

    getDocumentTemplate(documentType.id).then((response) => {
      setMetadataTemplate(response.template);
    });
  }, [documentType]);

  return (
    <div className="archived-document-side-panel">
      <SidePanel
        collapserPosition={CollapserPositionVariant.WithTitleBarLarge}
        resizable
      >
        <>
          <div className="section-label">
            {t('sidePanel.documentTypeLabel')}
          </div>
          <div className="document-type">
            {documentType?.displayName ?? defaultDocumentTypeName}
          </div>
          {metadata?.data && metadata.data.length > 0 && (
            <>
              <div className="section-divider" />
              <div className="section-label">
                {t('sidePanel.metadataLabel')}
              </div>
              <DocumentMetadataTable
                metadata={metadata.data}
                settings={currentUser.settings}
                template={metadataTemplate}
                isArchived
              />
              <TableMetadataSection
                title={t('sidePanel.tableMetadataLabel')}
                data={metadata.data}
                onSelect={(item: MetadataDetail): void => {
                  setSelectedTable(item);
                  setShowTable(true);
                }}
              />
              <div className="end-of-data">
                <div className="separator" />
                <div>{t('sidePanel.end')}</div>
              </div>
            </>
          )}
        </>
      </SidePanel>
      {selectedTable !== undefined ? (
        <FullSizeModal
          className="data-table"
          title={selectedTable.displayName ?? selectedTable.key}
          setShow={setShowTable}
          show={showTable}
        >
          <ReadonlyDataTable rows={selectedTable.value as RowCollection} />
        </FullSizeModal>
      ) : null}
    </div>
  );
};

export default ArchivedDocumentSidePanel;
