import { Dispatch, SetStateAction, createContext, useContext } from 'react';

export type ContainerContextProps = {
  containerKey: string;
  setContainerKey: Dispatch<SetStateAction<string>>;
};

export const ContainerContext = createContext<
  ContainerContextProps | undefined
>(undefined);

export const useContainer = (): ContainerContextProps => {
  const context = useContext(ContainerContext);
  if (context === undefined) {
    throw new Error('useContainer must be used within a ContainerProvider');
  }
  return context;
};
