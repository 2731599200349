import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import LocationProvider from '../../common/providers/locationProvider';
import QueryStringKey from '../../common/types/queryStringKey';
import QueryStringValue from '../../common/types/queryStringValue';
import useQueryString from '../../hooks/useQueryString/useQueryString';
import ArchiveDocumentPage from '../ArchiveDocument/ArchiveDocumentPage';
import PendingTrayCapturePage from '../PendingTrayCapture/PendingTrayCapturePage';

interface CapturePageProps {
  captureId: string;
}

const CapturePage = (): ReactElement => {
  const { captureId } = useParams<CapturePageProps>();
  const queryString = useQueryString();
  const type = queryString.get(QueryStringKey.Type);

  if (type === QueryStringValue.PendingTray) {
    return <PendingTrayCapturePage captureInfoId={captureId} />;
  }
  if (type === QueryStringValue.File) {
    return <ArchiveDocumentPage captureInfoId={captureId} />;
  }

  new LocationProvider().replace('/error/404');
  return <div />;
};

export default CapturePage;
