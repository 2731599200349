import { ReactElement } from 'react';
import TabItem from '../../common/models/tabItem';
import './Tab.scss';

interface TabProps {
  items: TabItem[];
  currentTabValue?: string;
  onChange: (item: string | undefined) => void;
}

const Tab = ({ items, currentTabValue, onChange }: TabProps): ReactElement => {
  const handleTabChange = (item: TabItem): void => {
    onChange(item.value);
  };

  const renderBadge = (item: TabItem): null | ReactElement => {
    if (!item.badgeText) {
      return null;
    }

    return <span className="badge">{item.badgeText}</span>;
  };

  const renderIcon = (item: TabItem): null | ReactElement => {
    if (!item.Icon) {
      return null;
    }

    return (
      <span className="icon">
        <item.Icon />
      </span>
    );
  };

  return (
    <div className="tab-container" data-hj-suppress>
      {items.map((item: TabItem, index: number) => (
        <div
          key={`tab-${item.value}`}
          className={`tab-item ${
            currentTabValue === item.value ? 'selected' : ''
          }`}
        >
          {renderIcon(item)}
          <span
            role="button"
            tabIndex={index}
            onKeyUp={(): void => handleTabChange(item)}
            onClick={(): void => handleTabChange(item)}
          >
            {item.text}
          </span>
          {renderBadge(item)}
        </div>
      ))}
    </div>
  );
};

Tab.defaultProps = {
  currentTabValue: undefined,
};

export default Tab;
