import { AxiosError } from 'axios';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseCardIcon } from '../../assets/svg/CloseCardIcon.svg';
import storageKey from '../../common/constants/storageKey.constants';
import ErrorDetails from '../../common/models/errorDetails';
import UploadCardDisplayModel from '../../common/models/uploadCardDisplayModel';
import UploadFileWithStatus from '../../common/models/uploadFileWithStatus';
import StorageProvider from '../../common/providers/storageProvider';
import ErrorCode from '../../common/types/errorCode';
import FileUploadStatus from '../../common/types/fileUploadStatus';
import FileUploadStatusCard from '../../common/types/fileUploadStatusCard';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import { useTenant } from '../../context/Tenant/TenantContext';
import PendingTrayUploadResponse from '../../services/pendingTray/models/pendingTrayUploadResponse';
import { uploadPendingTrayFile } from '../../services/pendingTray/pendingTray.service';
import { formatFileSizeBytes } from '../../utils/fileUtil';
import SDCButton from '../SDCButton/SDCButton';
import './PendingTrayUploadingCard.scss';

interface PendingTrayUploadingCardProps {
  fileInfo: UploadFileWithStatus;
  setUploadFileStatus: Function;
  startProgressTracking: (response: PendingTrayUploadResponse) => void;
  setIsUploadFileConfirmationModalVisible: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  getCardProps: (cardStatus: FileUploadStatus) => UploadCardDisplayModel;
  setDuplicatedNameFiles: React.Dispatch<
    React.SetStateAction<UploadFileWithStatus[]>
  >;
}

const PendingTrayUploadingCard = ({
  fileInfo,
  setUploadFileStatus,
  startProgressTracking,
  setIsUploadFileConfirmationModalVisible,
  getCardProps,
  setDuplicatedNameFiles,
}: PendingTrayUploadingCardProps): ReactElement => {
  const localStorageProvider = useMemo(() => new StorageProvider(), []);

  const { t } = useTranslation();
  const { maxFileSizeInBytes } = useTenant();
  const [progress, setProgress] = useState(0);
  const [fileError, setFileError] = useState<string>('');
  const [isDismiss, setIsDismiss] = useState(false);

  const errorFileExceedMessage = t(
    'uploadingCardComponent.errorDescriptionFileSizeExceed'
  );
  const errorMessage = t('uploadingCardComponent.errorMessage');
  const errorContainerNotMatch = t(
    'uploadingCardComponent.errorDescriptionContainerNotMatch'
  );
  const checkFileSizeExceedValidation = useCallback(
    (oriFile: File): boolean => {
      if (oriFile.size > maxFileSizeInBytes) {
        return true;
      }
      return false;
    },
    [maxFileSizeInBytes]
  );

  const checkFileValidationError = useCallback(
    (oriFile: File): boolean => {
      if (checkFileSizeExceedValidation(oriFile)) {
        setFileError(errorFileExceedMessage);
        return true;
      }
      setFileError('');
      return false;
    },
    [checkFileSizeExceedValidation, errorFileExceedMessage]
  );

  const addAdditionalParametersToForm = useCallback(
    (formData: FormData): void => {
      // Add container key from the current container scope
      const containerKey = localStorageProvider.getItem(
        storageKey.CONTAINER_KEY
      );
      if (containerKey) {
        formData.append('containerKey', containerKey);
      }

      // Add current privacy scope
      const scope = localStorageProvider.getItem(storageKey.PENDING_TRAY_SCOPE);
      if (scope) {
        formData.append('scope', scope);
      }
    },
    [localStorageProvider]
  );

  const convertToFormData = (oriFile: File): FormData => {
    const formData = new FormData();
    formData.append('file', oriFile);
    return formData;
  };

  const getResponseFromUpload = useCallback(
    async (formData: FormData): Promise<PendingTrayUploadResponse> => {
      // Additional data for pending trays
      addAdditionalParametersToForm(formData);
      const uploadContentResponse = await uploadPendingTrayFile(
        formData,
        setProgress,
        fileInfo.force,
        fileInfo.abortController.signal
      );

      startProgressTracking(uploadContentResponse);

      return uploadContentResponse;
    },
    [
      addAdditionalParametersToForm,
      fileInfo.abortController.signal,
      fileInfo.force,
      startProgressTracking,
    ]
  );

  const uploadContent = useCallback(
    async (targetFile: File): Promise<void> => {
      const formData = convertToFormData(targetFile);
      try {
        const uploadContentResponse: PendingTrayUploadResponse =
          await getResponseFromUpload(formData);
        const uploadId = uploadContentResponse.id;
        const uploadStatus = uploadContentResponse
          ? FileUploadStatus.SUCCESS
          : FileUploadStatus.FAILED;

        setTimeout(() => {
          const successFileSet = {
            id: fileInfo.id,
            uploadId,
            previewImg: undefined,
            file: targetFile,
            status: uploadStatus,
            abortController: fileInfo.abortController,
          };
          setUploadFileStatus(successFileSet, uploadStatus);
        }, 1000);
      } catch (error: any) {
        const axiosError = error as AxiosError;
        const errorCode = (axiosError?.response?.data as any)?.errorCode;
        if (errorCode === ErrorCode.DuplicatedFileNameInPendingTray) {
          setFileError(
            t('uploadingCardComponent.errorMessageForDuplicatedFileName')
          );
          setDuplicatedNameFiles((prev) =>
            prev ? [...prev, fileInfo] : [fileInfo]
          );

          setIsUploadFileConfirmationModalVisible(true);
        }
        if (error.message !== 'canceled') {
          setProgress(() => 100);
          let message = errorMessage;
          const detail = axiosError.response?.data as ErrorDetails;
          if (
            detail &&
            detail.errorCode === ErrorCode.PendingTrayContainerNotMatch
          ) {
            message = errorContainerNotMatch;
          }

          setFileError(message);
          const errorFileSet = {
            id: fileInfo.id,
            file: targetFile,
            status: FileUploadStatus.FAILED,
            abortController: fileInfo.abortController,
          };
          setUploadFileStatus(errorFileSet, FileUploadStatus.FAILED);
        }
      }
    },
    [
      errorContainerNotMatch,
      errorMessage,
      fileInfo,
      getResponseFromUpload,
      setDuplicatedNameFiles,
      setIsUploadFileConfirmationModalVisible,
      setUploadFileStatus,
      t,
    ]
  );

  // Call API upload file.
  const uploadFile = useCallback(async (): Promise<void> => {
    if (!checkFileValidationError(fileInfo.file)) {
      await uploadContent(fileInfo.file);
    } else if (fileInfo.status !== FileUploadStatus.SUCCESS) {
      setProgress(100);
      const errorFileSet = {
        id: fileInfo.id,
        file: fileInfo.file,
        status: FileUploadStatus.FAILED,
        abortController: fileInfo.abortController,
      };
      setUploadFileStatus(errorFileSet, FileUploadStatus.FAILED);
    }
  }, [
    checkFileValidationError,
    fileInfo.abortController,
    fileInfo.file,
    fileInfo.id,
    fileInfo.status,
    setUploadFileStatus,
    uploadContent,
  ]);

  useEffect(() => {
    uploadFile();
  }, [uploadFile]);

  const cancelUploadFileRequest = (): void => {
    fileInfo.abortController.abort();
    setUploadFileStatus(
      {
        id: fileInfo.id,
        file: fileInfo.file,
        status: fileInfo.status,
        abortController: fileInfo.abortController,
      },
      FileUploadStatus.CANCELED
    );
  };

  const dismissCard = (): void => {
    setIsDismiss(true);
    setUploadFileStatus(
      {
        id: fileInfo.id,
        file: fileInfo.file,
        status: fileInfo.status,
      },
      FileUploadStatus.CANCELED
    );
  };

  const fileDisplayCard = (
    uploadStatus: FileUploadStatus
  ): null | ReactElement => {
    const { className, Icon, title }: UploadCardDisplayModel =
      getCardProps(uploadStatus);

    if (isDismiss) return null;
    return (
      <div className={`uploading-card-container ${className}`}>
        <div className="file-info-wrapper">
          <Icon />
          <div className="file-info">
            {title && <span className="file-status">{title}</span>}
            &nbsp;
            <span data-hj-suppress>{fileInfo.file.name}</span>
            &nbsp;
            <span className="file-size">{`(${formatFileSizeBytes(
              fileInfo.file.size
            )})`}</span>
            &nbsp;
            {fileError && (
              <span className={`file-${className}`}>{fileError}</span>
            )}
          </div>
          <div className="close-file-upload">
            {(className === FileUploadStatusCard.FAILED ||
              className === FileUploadStatusCard.DUPLICATED) && (
              <SDCButton
                text={t('uploadingCardComponent.dismissButton')}
                className="dismiss-btn body-bold"
                onClick={(): void => {
                  dismissCard();
                }}
                variant={SDCButtonVariant.Link}
              />
            )}
            {(className === FileUploadStatusCard.UPLOADING ||
              className === FileUploadStatusCard.QUEUING) && (
              <CloseCardIcon onClick={(): void => cancelUploadFileRequest()} />
            )}
          </div>
        </div>
        {!fileError && uploadStatus === FileUploadStatus.UPLOADING && (
          <div className={`upload-progress ${className} pt-2`}>
            <ProgressBar now={progress} />
          </div>
        )}
      </div>
    );
  };

  return <>{fileDisplayCard(fileInfo.status)}</>;
};
export default PendingTrayUploadingCard;
