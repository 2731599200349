import { ReactElement } from 'react';
import { Button } from 'react-bootstrap';
import CloseIcon from '../../assets/svg/CloseIcon.svg';
import './DocumentTitleBar.scss';

interface DocumentTitleBarProps {
  documentName: string;
  descriptionPrefix: string;
  onClose?: Function;
}

const DocumentTitleBar = ({
  descriptionPrefix,
  documentName,
  onClose,
}: DocumentTitleBarProps): ReactElement => (
  <div className="document-title-bar-container">
    <div className="prefix-content">
      <span>{descriptionPrefix}</span>
    </div>
    <span className="arrow-container">
      <div className="arrow-right" />
    </span>
    <span className="detail">
      <span className="document-name">{documentName}</span>
    </span>
    <span>
      {onClose && (
        <Button onClick={(): void => onClose()} className="close-button">
          <img src={CloseIcon} alt="" />
        </Button>
      )}
    </span>
  </div>
);

export default DocumentTitleBar;

DocumentTitleBar.defaultProps = {
  onClose: undefined,
};
