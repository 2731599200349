import HistoryProvider from '../../common/providers/historyProvider';
import LocationProvider from '../../common/providers/locationProvider';
import { removeTrailingSlashes } from '../../utils/stringUtil';
import toUrlEncoded from '../../utils/urlUtil';
import authConstants from './auth.constants';
import getAuthInfo from './authInfo';
import AuthStorage from './authStorage';
import AuthServiceProps from './models/authServiceProps';
import { AuthTokens } from './models/authTokens';
import TokenRequestBody from './models/tokenRequestBody';
import { createPKCECodes, PKCECodePair } from './pkce';

class AuthService {
  isExchangingToken: boolean;

  props: AuthServiceProps;

  storage: AuthStorage;

  location: LocationProvider;

  history: HistoryProvider;

  timeout?: number;

  constructor(
    props: AuthServiceProps,
    authStorage: AuthStorage,
    locationProvider: LocationProvider,
    historyProvider: HistoryProvider
  ) {
    this.props = props;
    this.storage = authStorage;
    this.location = locationProvider;
    this.history = historyProvider;
    this.isExchangingToken = true;
  }

  public async initialize(): Promise<string | null> {
    const code = this.getValueFromLocation(authConstants.CODE);
    const state = this.getValueFromLocation(authConstants.STATE);
    const isLoginCallback = this.isLoginCallback();

    let redirectUrl: string | null = null;
    // Checks if code, state and callback url are ready
    if (code !== null && state !== null && isLoginCallback) {
      try {
        this.isExchangingToken = true;
        await this.fetchToken(code);
        redirectUrl = this.restoreUri();
      } catch (e) {
        this.clearAuthData();
        this.removeCodeAndStateFromLocation();
      } finally {
        this.isExchangingToken = false;
      }
    }
    const isAuthenticated = this.isAuthenticated();
    // If code, state and callbackURL are not ready and user is not authenticated
    // Then redirect to login page
    if (!isAuthenticated) {
      redirectUrl = this.authorize();
    }

    return redirectUrl;
  }

  private isLoginCallback(): boolean {
    // Get the current url, remove query params and trailing slashes and compare to callback url.
    const currentUrl = this.location.href().split('?')[0];

    return (
      removeTrailingSlashes(currentUrl) ===
      removeTrailingSlashes(this.props.redirectUri)
    );
  }

  public isPending(): boolean {
    return this.isExchangingToken && !this.isAuthenticated();
  }

  public isAuthenticated(): boolean {
    return this.storage.isAuthenticated();
  }

  public isExpired(): boolean {
    return this.storage.isExpired();
  }

  private async clearAuthData(): Promise<void> {
    const { audience } = getAuthInfo();
    const url: string = `${audience}/api/v1/logout`;
    // Call api logout clear cookie
    try {
      await fetch(url, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
    } catch (error) {
      throw new Error('Logout not correct.');
    }
    this.storage.clearAuthData();
  }

  public async logout(): Promise<string> {
    await this.clearAuthData();

    const { clientId, provider, redirectUri } = getAuthInfo();
    const url = `${provider}/signout?redirect_uri=${redirectUri}&client_id=${clientId}`;
    return url;
  }

  public isRequiresAuthentication(): boolean {
    const isPending = this.isPending();
    const isAuthenticated = this.isAuthenticated();
    const isExpired = this.isExpired();
    return !isPending && (!isAuthenticated || isExpired);
  }

  public authorize(): string {
    const { clientId, provider, audience, redirectUri } = this.props;

    const pkce = createPKCECodes();
    this.storage.setNewPKCE(pkce, this.location.href());
    const { codeChallenge } = pkce;
    const { state } = pkce;

    const query = {
      clientId,
      responseType: authConstants.RESPONSE_TYPE_CODE,
      audience,
      redirectUri,
      state,
      codeChallenge,
      codeChallengeMethod: authConstants.CODE_CHALLENGE_METHOD_S256,
    };
    // Responds with a 302 redirect
    const url = `${`${provider}/authorize`}?${toUrlEncoded(query)}`;
    return url;
  }

  // eslint-disable-next-line class-methods-use-this
  private async login(accessToken?: string): Promise<void> {
    const { audience } = getAuthInfo();
    const url: string = `${audience}/api/v1/login`;

    try {
      await fetch(url, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ accessToken }),
      });
    } catch (error) {
      throw new Error('Authentication not correct.');
    }
  }

  private async fetchToken(code: string): Promise<void> {
    const { clientId, provider, redirectUri } = this.props;

    const pkce: PKCECodePair = this.getPkce();
    const actualState = this.getValueFromLocation(authConstants.STATE);
    if (pkce.state !== actualState) {
      throw new Error('State not matched');
    }

    const { codeVerifier } = pkce;
    const payload: TokenRequestBody = {
      clientId,
      redirectUri,
      grantType: authConstants.GRANT_TYPE,
      code,
      codeVerifier,
    };

    const response = await fetch(`${provider}/oauth/token`, {
      headers: {
        'Content-Type': authConstants.FORM_URL_ENCODED,
      },
      method: 'POST',
      body: toUrlEncoded(payload),
    });
    const json = await response.json();
    await this.login(json.access_token);
    this.setAuthTokens(json as AuthTokens);
  }

  private getValueFromLocation(field: string): string | null {
    const split = this.location.toString().split('?');
    if (split.length < 2) {
      return null;
    }
    const pairs: string[] = split[1].split('&');
    let result: string | null = null;
    pairs.forEach((pair: string) => {
      const [key, value] = pair.split('=');
      if (key === field) {
        result = decodeURIComponent(value || '');
      }
    });
    return result;
  }

  private removeCodeAndStateFromLocation(): void {
    const [base, search] = this.location.href().split('?');
    if (!search) {
      return;
    }
    const newSearch = search
      .split('&')
      .map((param: string) => param.split('='))
      .filter(
        ([key]) => key !== authConstants.CODE && key !== authConstants.STATE
      )
      .map((keyAndVal: any[]) => keyAndVal.join('='))
      .join('&');

    this.history.replaceState(
      this.history.state,
      'null',
      base + (newSearch.length ? `?${newSearch}` : '')
    );
  }

  private getPkce(): PKCECodePair {
    const pkce = this.storage.getPCKE();
    if (pkce === null) {
      throw new Error('PKCE pair not found in local storage');
    } else {
      return JSON.parse(pkce);
    }
  }

  private setAuthTokens(authToken: AuthTokens): void {
    const now = new Date().getTime();
    const auth: AuthTokens = authToken;
    auth.expires_at = now + auth.expires_in * 1000;
    this.storage.setNewAuthTokens(auth.expires_at);
  }

  private restoreUri(): string | null {
    const uri = this.storage.getPreAuthURI();
    this.storage.removePreAuthURI();
    this.removeCodeAndStateFromLocation();
    return uri;
  }
}

export default AuthService;
