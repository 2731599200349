import { ReactElement } from 'react';
import { ReactComponent as ThumbnailIcon } from '../../assets/svg/ThumbnailIcon.svg';
import './ActionIcon.scss';

interface ListActionIconProps {
  active: boolean;
  color: string;
  activeColor: string;
  onClick: () => void;
}

const CardActionIcon = ({
  active,
  color,
  activeColor,
  onClick,
}: ListActionIconProps): ReactElement => (
  <button
    className="icon-wrapper"
    type="button"
    onClick={(): void => onClick()}
  >
    <ThumbnailIcon
      width="24px"
      height="17px"
      fill={active ? activeColor : color}
    />
  </button>
);

export default CardActionIcon;
