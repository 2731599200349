import { AxiosProgressEvent } from 'axios';
import { getSdcClient } from '../../common/apiClients/sdcClient.instance';
import ArgumentError from '../../common/error/argumentError';
import DocumentSearchOptions from '../../common/models/documentSearchOptions';
import PendingTrayUploadingStatus from '../../common/types/pendingTrayUploadingStatus';
import handleApplicationError from '../../utils/errorUtil';
import { getFileName } from '../../utils/fileUtil';
import { getPendingTraySearchParam } from '../../utils/searchOptionUtil';
import PaginationResponse from '../paginationResponse';
import PendingTrayItemResponse from './models/pendingTrayItemResponse';
import PendingTrayUploadResponse from './models/pendingTrayUploadResponse';
import UpdatePendingTrayRequest from './models/updatePendingTrayRequest';

export const getPendingTray = async (
  searchOptions: DocumentSearchOptions,
  containerKey: string,
  signal?: AbortSignal,
  errorHandler?: any
): Promise<PaginationResponse<PendingTrayItemResponse>> => {
  const sdcClient = getSdcClient();
  const params = getPendingTraySearchParam(searchOptions, containerKey);
  const url: string = `./api/v1/pending-trays/documents`;

  try {
    const response = await sdcClient.get<
      PaginationResponse<PendingTrayItemResponse>
    >(url, {
      params,
      signal,
    });
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return {} as PaginationResponse<PendingTrayItemResponse>;
};

export const uploadPendingTrayFile = async (
  fileContent: FormData,
  updateProgress: React.Dispatch<React.SetStateAction<number>>,
  force: boolean,
  signal: AbortSignal,
  errorHandler?: any
): Promise<PendingTrayUploadResponse> => {
  const sdcClient = getSdcClient();
  const url: string = `./api/v1/pending-trays/documents?force=${force.toString()}`;

  try {
    const response = await sdcClient.post<PendingTrayUploadResponse>(
      url,
      fileContent,
      {
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const percentComplete: number =
            (progressEvent.loaded / (progressEvent.total ?? 1)) * 100;
          updateProgress(percentComplete);
        },
        signal,
      }
    );

    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return {} as PendingTrayUploadResponse;
};

export const getPendingTrayItemTags = async (
  pendingTrayItemId: string | undefined,
  errorHandler?: any
): Promise<string[]> => {
  if (!pendingTrayItemId) return [] as string[];
  const sdcClient = getSdcClient();
  const url: string = `./api/v1/pending-trays/documents/${pendingTrayItemId}/tags`;

  try {
    const response = await sdcClient.get<string[]>(url);
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return [] as string[];
};

export const updatePendingTrayItemTags = async (
  pendingTrayItemId: string | undefined,
  tags: string[],
  errorHandler?: any
): Promise<string[]> => {
  if (!pendingTrayItemId) return [] as string[];
  const sdcClient = getSdcClient();
  const url: string = `./api/v1/pending-trays/documents/${pendingTrayItemId}/tags`;

  try {
    const response = await sdcClient.put<string[]>(url, tags);
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return [] as string[];
};

export const updatePendingTray = async (
  pendingTrayItemId: string | undefined,
  request: UpdatePendingTrayRequest,
  errorHandler?: any
): Promise<PendingTrayItemResponse> => {
  const sdcClient = getSdcClient();
  const url: string = `./api/v1/pending-trays/documents/${pendingTrayItemId}`;

  try {
    const response = await sdcClient.patch<PendingTrayItemResponse>(
      url,
      request
    );
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return {} as PendingTrayItemResponse;
};

export const getPendingTrayDocumentPreviewImage = async (
  fileId: string,
  errorHandler?: Function | undefined
): Promise<File> => {
  if (!fileId) throw new ArgumentError('fileId');

  const sdcClient = getSdcClient();
  const url: string = `./api/v1/pending-trays/documents/${fileId}/thumbnails/default`;

  try {
    const response = await sdcClient.get<any>(url, { responseType: 'blob' });
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return {} as any;
};

const getPendingTrayDocumentContent = async (
  fileId: string,
  errorHandler?: any
): Promise<File> => {
  if (!fileId) throw new ArgumentError('fileId');

  const sdcClient = getSdcClient();
  const url: string = `./api/v1/pending-trays/documents/${fileId}/content`;

  try {
    const response = await sdcClient.get<Blob>(url, {
      responseType: 'blob',
    });
    const fileName = getFileName(response) ?? `${fileId}`;
    return new File([response.data], fileName);
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return new File([], '');
};

const getCompressedPendingTrayDocumentFiles = async (
  ids: string[],
  errorHandler?: any
): Promise<File> => {
  if (ids.length === 0) throw new ArgumentError('ids');

  const sdcClient = getSdcClient();
  const idsQueryParam = ids.join(',');
  const url: string = `./api/v1/pending-trays/documents/content?ids=${idsQueryParam}`;

  try {
    const response = await sdcClient.get<Blob>(url, {
      responseType: 'blob',
    });
    const fileName = getFileName(response) ?? 'pending-tray-files.zip';
    return new File([response.data], fileName);
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return new File([], '');
};

export const downloadDocumentFiles = async (
  documentIds: string[],
  errorHandler?: any
): Promise<File> => {
  let response: File;
  if (documentIds.length === 1)
    response = await getPendingTrayDocumentContent(
      documentIds[0],
      errorHandler
    );
  else
    response = await getCompressedPendingTrayDocumentFiles(
      documentIds,
      errorHandler
    );

  return response;
};

// Delete seleted items if it's belongs to current user
export const deletePendingTrayDocumentFiles = async (
  ids: string[],
  errorHandler?: any
): Promise<void> => {
  if (ids.length === 0) throw new ArgumentError('ids');

  const sdcClient = getSdcClient();
  const idsQueryParam = ids.join(',');
  const url: string = `./api/v1/pending-trays/documents?ids=${idsQueryParam}`;

  try {
    await sdcClient.delete<Blob>(url);
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }
};

export const movePendingTrayDocumentFiles = async (
  ids: string[],
  destinationUserId: string,
  force: boolean,
  errorHandler?: any
): Promise<void> => {
  if (ids.length === 0) throw new ArgumentError('ids');

  const sdcClient = getSdcClient();
  const idsQueryParam = ids.join(',');
  const url: string = `./api/v1/pending-trays/documents/scope?ids=${idsQueryParam}&force=${force.toString()}`;

  try {
    await sdcClient.patch(url, {
      scope: destinationUserId,
    });
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }
};

export const getPendingTrayUploadStatuses = async (
  ids: string,
  errorHandler?: any
): Promise<Array<PendingTrayUploadingStatus>> => {
  const sdcClient = getSdcClient();
  const url: string = `api/v1/pending-trays/documents/statuses?ids=${ids}`;

  try {
    const response = await sdcClient.get<Array<PendingTrayUploadingStatus>>(
      url
    );
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return {} as Array<PendingTrayUploadingStatus>;
};
