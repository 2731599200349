import { getSdcClient } from '../../common/apiClients/sdcClient.instance';
import handleApplicationError from '../../utils/errorUtil';
import SubscriptionResponse from './models/subscriptionResponse';

export const getCurrentSubscription = async (
  errorHandler?: Function | undefined
): Promise<SubscriptionResponse> => {
  const sdcClient = getSdcClient();
  const url: string = `./api/v1/subscriptions/current`;

  try {
    const response = await sdcClient.get<SubscriptionResponse>(url);
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return {} as SubscriptionResponse;
};

export default getCurrentSubscription;
