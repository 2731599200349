import { ReactElement } from 'react';
import { Navbar } from 'react-bootstrap';
import ContainerScope from '../../../common/types/containerScope';
import { useCurrentUser } from '../../../context/CurrentUser/CurrentUserContext';
import useApplicationLink from '../../../hooks/useApplicationLink/useApplicationLink';
import isTabletSize from '../../../utils/responsiveUtil';
import ContainerDropdown from '../../ContainerDropdown/ContainerDropdown';
import MenuOptions from '../../MenuOptions/MenuOptions';
import UserHeaderOptions from '../../UserHeaderOptions/UserHeaderOptions';
import ApplicationMenuItem from '../ApplicationMenuItem/ApplicationMenuItem';
import BrandLogo from '../BrandLogo/BrandLogo';
import './MainNavigationNoSideMenu.scss';

const MainNavigationNoSideMenu = (): ReactElement => {
  const pageTitleInfo = useApplicationLink();
  const { currentUser } = useCurrentUser();

  const getMenuItem = (): ReactElement => {
    if (pageTitleInfo) {
      return <ApplicationMenuItem pageTitle={pageTitleInfo} />;
    }
    return <></>;
  };

  const getUserMenu = (): ReactElement => {
    if (currentUser && pageTitleInfo?.showUserMenu) {
      if (!isTabletSize()) {
        return (
          <UserHeaderOptions
            displayName={currentUser.name || currentUser.email}
            userRole={currentUser.roles[0]}
          />
        );
      }
      return (
        <MenuOptions
          displayName={currentUser.name}
          userRole={currentUser.roles[0]}
        />
      );
    }
    return <></>;
  };

  const getContainer = (): ReactElement => {
    if (
      currentUser &&
      pageTitleInfo?.isScopeContainer !== ContainerScope.None
    ) {
      return <ContainerDropdown display={pageTitleInfo?.isScopeContainer} />;
    }
    return <></>;
  };

  return (
    <Navbar className="app-header" variant="dark">
      <div className="container-fluid main-navbar p-0">
        <BrandLogo />
        {getMenuItem()}
        <div className="options-group">
          {getContainer()}
          {currentUser && pageTitleInfo?.showUserMenu ? getUserMenu() : ''}
        </div>
      </div>
    </Navbar>
  );
};

export default MainNavigationNoSideMenu;
