import { AxiosError } from 'axios';
import { ReactElement, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import BackArrowIcon from '../../assets/svg/BackArrowIcon.svg';
import ArgumentError from '../../common/error/argumentError';
import ErrorDetails from '../../common/models/errorDetails';
import CollapserPositionVariant from '../../common/types/collapserPositionVariant';
import FileSource from '../../common/types/fileSource';
import NotificationVariant from '../../common/types/notificationVariant';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import TaskBarSize from '../../common/types/taskBarSize';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import ArchivedDocument from '../../services/archivedDocument';
import Metadata from '../../services/capture/models/metadata';
import MetadataDetail from '../../services/capture/models/metadataDetail';
import { UploadedContent } from '../../services/capture/models/uploadedContent';
import { postArchiveData } from '../../services/document/document.service';
import { ArchiveObjectResponse } from '../../services/document/models/archiveObjectResponse';
import { getActiveDocumentTypes } from '../../services/documentType/documentType.service';
import DocumentTemplateResponse from '../../services/documentType/models/documentTemplateResponse';
import DocumentType from '../../services/documentType/models/documentType';
import MetadataTemplateItem from '../../services/documentType/models/metadataTemplateItem';
import {
  downloadFile,
  getDocumentNameWithExtension,
  getFileExtension,
  isViewable,
} from '../../utils/fileUtil';
import {
  buildMetadataRequest,
  validateMetadataRequest,
} from '../../utils/metadataUtil';
import DefaultLoading from '../DefaultLoading/DefaultLoading';
import DocumentTitleBar from '../DocumentTitleBar/DocumentTitleBar';
import ImageViewer from '../ImageViewer/ImageViewer';
import PDFPreviewer from '../PDFPreviewer/PDFPreviewer';
import SDCButton from '../SDCButton/SDCButton';
import ArchivedDocumentSidePanel from '../SidePanel/ArchivedDocumentSidePanel';
import CaptureSidePanel from '../SidePanel/CaptureSidePanel';
import TaskBar from '../TaskBar/TaskBar';
import TaskDescription from '../TaskBar/TaskDescription';
import Toaster from '../Toaster/Toaster';
import showToaster from '../Toaster/ToasterProvider';
import UnsupportedFileViewer from '../UnsupportedFileViewer/UnsupportedFileViewer';
import './ArchiveDocumentSection.scss';

type ArchiveDocumentSectionProps = {
  captureInfoId?: string;
  selectedFile: File | undefined;
  metadata: Metadata;
  displayName: string;
  document?: ArchivedDocument;
  documentName?: string;
  onCloseModal?: Function;
  enableEdit?: boolean;
  showArchiveButton?: boolean;
  showDownloadButton?: boolean;
  onCompletedArchive?: (document: ArchiveObjectResponse) => void;
  uploadedContents: Array<UploadedContent> | undefined;
  onError?: (error: ErrorDetails) => void;
  collapserPosition: CollapserPositionVariant;
};

const ArchiveDocumentSection = ({
  captureInfoId,
  selectedFile,
  metadata,
  displayName,
  document,
  documentName,
  onCloseModal,
  showArchiveButton,
  showDownloadButton,
  onCompletedArchive,
  uploadedContents,
  onError,
  enableEdit,
  collapserPosition,
}: ArchiveDocumentSectionProps): ReactElement => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const handleError = useErrorHandler();
  const [showLoading, setShowLoading] = useState(false);
  const [archiveStatus, setArchiveStatus] = useState(false);
  const [archiveError, setArchiveError] = useState(false);
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
  const [modifiedMetadata, setModifiedMetadata] = useState<MetadataDetail[]>(
    []
  );
  const [mutableMetadata, setMutableMetadata] = useState(metadata);
  const [selectedDocumentType, setSelectedDocumentType] = useState('');
  const [documentTypeValidateError, setDocumentTypeValidateError] =
    useState<string>('');
  const [selectedTemplate, setSelectedTemplate] = useState<
    MetadataTemplateItem[]
  >([]);

  useEffect(() => {
    async function readDocumentType(): Promise<void> {
      const response = await getActiveDocumentTypes(handleError);
      if (response) {
        setDocumentTypes(response);
      }
    }
    readDocumentType();
  }, [handleError]);

  const onDownloadButtonClick = (): void => {
    if (!selectedFile) throw new ArgumentError('selectedFile');

    downloadFile(selectedFile, selectedFile.name);
  };

  const validateDataBeforeArchive = (): boolean => {
    if (!selectedFile) throw new Error('File is not selected.');
    if (!uploadedContents)
      throw new Error('Uploaded content is null or empty.');

    if (!selectedDocumentType) {
      setDocumentTypeValidateError(
        t('archiveDocumentSectionComponent.validateDocumentTypeRequiredLabel')
      );
      return false;
    }
    setDocumentTypeValidateError('');

    if (
      modifiedMetadata.length &&
      !validateMetadataRequest(modifiedMetadata, selectedTemplate)
    ) {
      showToaster(
        t('archiveDocumentSectionComponent.invalidMetadataInputErrorTitle'),
        t('archiveDocumentSectionComponent.invalidMetadataInputErrorMessage'),
        NotificationVariant.SoftDanger
      );
      return false;
    }

    setShowLoading(true);

    return true;
  };

  const archive = async (): Promise<void> => {
    if (!validateDataBeforeArchive()) {
      return;
    }
    // Reset any error. This could be an archival attempt
    setArchiveError(false);

    try {
      let completedArchiveResponse: ArchiveObjectResponse | null = null;
      const selectedUploadedContentId = uploadedContents?.filter(
        (x) => x.name === selectedFile?.name
      )[0].id;

      if (!selectedUploadedContentId)
        throw new Error('Selected uploaded content id is null.');

      if (captureInfoId) {
        // Send the modified metadata to the payload if there's any metadata changes
        const metadataRequest = buildMetadataRequest(
          modifiedMetadata,
          selectedDocumentType
        );

        completedArchiveResponse = await postArchiveData(
          captureInfoId,
          selectedUploadedContentId,
          FileSource.Capture,
          metadataRequest,
          handleError
        );
      }

      setArchiveStatus(true);
      setModifiedMetadata([]);
      setShowLoading(false);

      if (onCompletedArchive && completedArchiveResponse) {
        onCompletedArchive(completedArchiveResponse);
      }

      // Always notify on close for the last step
      // to not to interfere with other states
      if (onCloseModal) onCloseModal();
    } catch (error) {
      setArchiveStatus(true);
      setArchiveError(true);
      setShowLoading(false);

      const axiosError = error as AxiosError;
      const detail = axiosError.response?.data as ErrorDetails;
      if (onError) {
        onError(detail);
      }
    }
  };

  const handleDocumentTypeChange = (
    response: DocumentTemplateResponse | undefined
  ): void => {
    if (response) {
      setSelectedDocumentType(response.documentType.name);
      setSelectedTemplate(response.template);
      setDocumentTypeValidateError('');

      // If the selected document type is original, clear out metadata request.
      // Otherwise, build new metada request for the document from the original metadata against the new document type.
      if (response.documentType.name === metadata.templateName) {
        setModifiedMetadata([]);
      } else {
        setModifiedMetadata(metadata.data);
      }
    } else {
      setSelectedDocumentType('');
    }
  };

  const findDocumentType = (name: string): DocumentType | undefined =>
    documentTypes.find((x) => x.name.toLowerCase() === name?.toLowerCase());

  const renderFilePreviewer = (): ReactElement => {
    if (!selectedFile) {
      return (
        <DefaultLoading progressTranslationKey="archiveDocumentSectionComponent.loadingPreviewDocumentLabel" />
      );
    }

    if (!isViewable(selectedFile?.name)) {
      return (
        <UnsupportedFileViewer
          onDownloadClick={onDownloadButtonClick}
          archived={!enableEdit}
        />
      );
    }

    return getFileExtension(selectedFile?.name)?.toLowerCase() === 'pdf' ? (
      <PDFPreviewer selectedFile={selectedFile} />
    ) : (
      <ImageViewer file={selectedFile} />
    );
  };

  const renderTopBar = (): ReactElement => {
    if (enableEdit) {
      return (
        <TaskBar
          data-hj-suppress
          headerPrefix={t('taskBar.capturePrefix')}
          size={TaskBarSize.Large}
          stickOnTop
        >
          <TaskDescription
            descriptionText={t('taskBar.uploadTaskDescription')}
            documentDisplayName={displayName}
          />
        </TaskBar>
      );
    }

    return (
      <DocumentTitleBar
        data-hj-suppress
        descriptionPrefix={t('documentTitleBar.prefix')}
        documentName={
          getDocumentNameWithExtension(
            document?.documentName || documentName,
            document?.fileName
          ) ?? t('documentTitleBar.undefinedValue')
        }
        onClose={onCloseModal}
      />
    );
  };

  const renderFooter = (): ReactElement | undefined => {
    if (enableEdit)
      return (
        <div className="archive-footer">
          <div>
            <img src={BackArrowIcon} alt="" />
            <Button
              variant="link"
              onClick={(): void => {
                if (onCloseModal !== undefined) {
                  onCloseModal();
                }
              }}
            >
              {t('archiveDocumentSectionComponent.cancel')}
            </Button>
          </div>
          {showArchiveButton && (
            <SDCButton
              className="ps-5 pe-5"
              variant={SDCButtonVariant.Primary}
              onClick={archive}
              disabled={!selectedFile}
              text={t('archiveDocumentSectionComponent.archiveButton')}
            />
          )}
          {showDownloadButton && (
            <SDCButton
              className="ps-2 pe-2"
              variant={SDCButtonVariant.Primary}
              onClick={onDownloadButtonClick}
              disabled={!selectedFile}
              text={t('archiveDocumentSectionComponent.downloadButton')}
            />
          )}
        </div>
      );
    return undefined;
  };

  const renderSidePanel = (): ReactElement | null => {
    if (enableEdit) {
      return (
        <CaptureSidePanel
          onDocumentTypeChange={handleDocumentTypeChange}
          documentTypeValidateError={documentTypeValidateError}
          onMetadataChange={(editedMetadata: MetadataDetail[]): void => {
            // console.log('metadata change');
            setModifiedMetadata(editedMetadata);
            setMutableMetadata({
              ...metadata,
              data: editedMetadata,
            });
          }}
          settings={currentUser.settings}
          documentTypes={documentTypes}
          metadata={mutableMetadata}
          collapserPosition={collapserPosition}
        />
      );
    }

    return (
      <ArchivedDocumentSidePanel
        documentType={findDocumentType(metadata.templateName)}
        metadata={metadata}
      />
    );
  };

  return (
    <>
      <Toaster />
      {showLoading && (
        <DefaultLoading
          status={archiveStatus}
          error={archiveError}
          progressTranslationKey="defaultLoadingComponent.archiving"
          successTranslationKey="defaultLoadingComponent.archiveSuccess"
          errorTranslationKey="defaultLoadingComponent.archiveError"
        />
      )}
      {renderTopBar()}
      <div className="archive-document-container">
        <div className="view-container">
          <div className="pdf-preview-container">
            {renderFilePreviewer()}
            {selectedFile && (
              <div className="file-name-header" data-hj-suppress>
                {selectedFile?.name}
              </div>
            )}
          </div>
          {renderSidePanel()}
          {renderFooter()}
        </div>
      </div>
    </>
  );
};

ArchiveDocumentSection.defaultProps = {
  showArchiveButton: true,
  showDownloadButton: false,
  enableEdit: false,
  document: undefined,
  captureInfoId: undefined,
  documentName: undefined,
  onCompletedArchive: (): boolean => true,
  onCloseModal: undefined,
  onError: undefined,
};

export default ArchiveDocumentSection;
