import { getSdcClient } from '../../common/apiClients/sdcClient.instance';
import ArgumentError from '../../common/error/argumentError';
import DocumentSearchOptions from '../../common/models/documentSearchOptions';
import FileSource from '../../common/types/fileSource';
import handleApplicationError from '../../utils/errorUtil';
import { getFileName } from '../../utils/fileUtil';
import { getSearchParam } from '../../utils/searchOptionUtil';
import { ArchiveObjectResponse } from './models/archiveObjectResponse';
import ArchivedDocumentWithMetadata from './models/archivedDocumentWithMetadata';
import DocumentsResponse from './models/documentsResponse';
import MetadataRequest from './models/metadataRequest';

export const getArchivedDocument = async (
  archivedObjectInfoId: string,
  errorHandler?: Function | undefined
): Promise<ArchivedDocumentWithMetadata> => {
  if (!archivedObjectInfoId) throw new ArgumentError('archivedObjectInfoId');

  const sdcClient = getSdcClient();
  const url: string = `./api/v1/documents/${archivedObjectInfoId}`;

  try {
    const response = await sdcClient.get<ArchivedDocumentWithMetadata>(url);
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return {} as ArchivedDocumentWithMetadata;
};

const getArchivedDocumentContent = async (
  archivedObjectInfoId: string,
  errorHandler?: any
): Promise<File> => {
  if (!archivedObjectInfoId) throw new ArgumentError('archivedObjectInfoId');

  const sdcClient = getSdcClient();
  const url: string = `./api/v1/documents/${archivedObjectInfoId}/content`;

  try {
    const response = await sdcClient.get<Blob>(url, {
      responseType: 'blob',
    });
    const fileName = getFileName(response) ?? `${archivedObjectInfoId}`;
    return new File([response.data], fileName);
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return new File([], '');
};

const getCompressedDocumentFiles = async (
  documentIds: string[],
  errorHandler?: any
): Promise<File> => {
  if (!documentIds) throw new ArgumentError('documentIds');

  const sdcClient = getSdcClient();
  const idsQueryParam = documentIds.join(',');
  const url: string = `./api/v1/documents/content?ids=${idsQueryParam}`;

  try {
    const response = await sdcClient.get<Blob>(url, {
      responseType: 'blob',
    });
    const fileName = getFileName(response) ?? `${documentIds}`;
    return new File([response.data], fileName);
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return new File([], '');
};

export const downloadArchivedDocuments = async (
  documentIds: string[],
  errorHandler?: any
): Promise<File> => {
  let response: File;
  if (documentIds.length === 1)
    response = await getArchivedDocumentContent(documentIds[0], errorHandler);
  else response = await getCompressedDocumentFiles(documentIds, errorHandler);

  return response;
};

export const searchDocuments = async (
  searchOptions: DocumentSearchOptions,
  containerKey: string,
  signal?: AbortSignal,
  errorHandler?: any
): Promise<DocumentsResponse | undefined> => {
  const sdcClient = getSdcClient();
  const params = getSearchParam(searchOptions, containerKey);
  const uri: string = `./api/v1/documents`;

  try {
    const response = await sdcClient.get<DocumentsResponse>(uri, {
      params,
      signal,
    });
    return response.data;
  } catch (error: any) {
    handleApplicationError(error, errorHandler);
  }

  return undefined;
};

export const deleteDocuments = async (
  documentIds: string[],
  errorHandler?: any
): Promise<void> => {
  if (!documentIds) throw new ArgumentError('documentIds');

  const sdcClient = getSdcClient();
  const idsQueryParam = documentIds.join(',');
  const url: string = `./api/v1/documents?ids=${idsQueryParam}`;

  try {
    await sdcClient.delete<Blob>(url);
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }
};

export const postArchiveData = async (
  captureId: string,
  fileId: string,
  fileSource: FileSource,
  metadata?: MetadataRequest,
  errorHandler?: any
): Promise<ArchiveObjectResponse> => {
  if (!captureId) throw new ArgumentError('captureId');
  if (!fileId) throw new ArgumentError('fileId');
  if (!fileSource) throw new ArgumentError('fileSource');

  const sdcClient = getSdcClient();
  const url: string = `./api/v1/documents`;

  try {
    const response = await sdcClient.post<ArchiveObjectResponse>(url, {
      fileId,
      fileSource,
      captureId,
      metadata,
    });
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return {} as ArchiveObjectResponse;
};

export const getDocumentPreviewImage = async (
  documentId: string,
  errorHandler?: Function | undefined
): Promise<File> => {
  if (!documentId) throw new ArgumentError('documentId');

  const sdcClient = getSdcClient();
  const url: string = `./api/v1/documents/${documentId}/thumbnails/default`;

  try {
    const response = await sdcClient.get<any>(url, { responseType: 'blob' });
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return {} as any;
};
