import { ReactElement } from 'react';
import UploadCardDisplayModel from '../../common/models/uploadCardDisplayModel';
import UploadFileWithStatus from '../../common/models/uploadFileWithStatus';
import FileUploadStatus from '../../common/types/fileUploadStatus';
import { formatFileSizeBytes } from '../../utils/fileUtil';
import './PendingTrayUploadedCard.scss';

interface PendingTrayUploadedCardProps {
  fileInfo: UploadFileWithStatus;
  getCardProps: (cardStatus: FileUploadStatus) => UploadCardDisplayModel;
}

const PendingTrayUploadedCard = ({
  fileInfo,
  getCardProps,
}: PendingTrayUploadedCardProps): ReactElement => {
  const cardProps: UploadCardDisplayModel = getCardProps(fileInfo.status);
  const { className, Icon, title }: UploadCardDisplayModel = cardProps;
  return (
    <div className={`uploading-card-container ${className}`}>
      <div className="file-info-wrapper">
        <Icon />
        <div className="file-info">
          {title && <span className="file-status">{title}</span>}
          &nbsp;
          {fileInfo.file.name}
          &nbsp;
          <span className="file-size">{`(${formatFileSizeBytes(
            fileInfo.file.size
          )})`}</span>
        </div>
      </div>
    </div>
  );
};

export default PendingTrayUploadedCard;
