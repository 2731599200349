import { HubConnectionState } from '@microsoft/signalr';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import signalRClientEventName from '../../common/constants/signalRClientEventName.constant';
import Tray from '../../common/types/tray';
import { useSignalR } from '../../context/SignalR/SignalRContext';
import { getPendingTray } from '../../services/pendingTray/pendingTray.service';

const usePrivatePendingTrayCount = (
  containerKey: string
): [number, Dispatch<SetStateAction<number>>] => {
  const { connection, connectionState } = useSignalR();
  const [count, setCount] = useState<number>(0);

  const refreshData = useCallback(() => {
    getPendingTray(
      {
        scope: Tray.Private,
        page: 1,
        pageSize: 1,
      },
      containerKey
    ).then((response) => {
      setCount(response.totalCount);
    });
  }, [containerKey]);

  useEffect(() => {
    if (connectionState === HubConnectionState.Connected) {
      connection.on(
        signalRClientEventName.PUBLIC_PENDING_TRAY_UPDATE_NOTIFICATION,
        refreshData
      );
    }
    return (): void => {
      connection.off(
        signalRClientEventName.PUBLIC_PENDING_TRAY_UPDATE_NOTIFICATION,
        refreshData
      );
    };
  }, [connection, connectionState, containerKey, refreshData]);

  useEffect(() => {
    let ignore = false;

    getPendingTray(
      {
        scope: Tray.Private,
        page: 1,
        pageSize: 1,
      },
      containerKey
    ).then((response) => {
      if (!ignore) {
        setCount(response.totalCount);
      }
    });

    return (): void => {
      ignore = true;
    };
  }, [containerKey]);

  return [count, setCount];
};

export default usePrivatePendingTrayCount;
