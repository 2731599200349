import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { MutableRefObject, ReactElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataTypeItem from '../../common/models/dataTypeItem';
import FilterConditionItem from '../../common/models/filterConditionItem';
import KeyPairItem from '../../common/models/keypairItem';
import DataType from '../../common/types/dataType';
import QueryOperator from '../../common/types/queryOperator';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import SDCTextFieldSize from '../../common/types/sdcTextFieldSize';
import UserSettings from '../../services/user/models/userSettings';
import { getMaskOptions, validateDataType } from '../../utils/dataTypeUtil';
import {
  buildBetweenFilterString,
  buildCommonFilterString,
} from '../../utils/filterUtil';
import RadioOption from '../RadioOption/RadioOption';
import SDCButton from '../SDCButton/SDCButton';
import SDCTextField from '../SDCTextField/SDCTextField';
import './FilterOperatorSelector.scss';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

interface FilterOperatorSelectorProps {
  field: DataTypeItem;
  onApplyFilter: (filterCondition: FilterConditionItem) => void;
  settings: UserSettings;
}

const FilterOperatorSelector = ({
  field,
  onApplyFilter,
  settings,
}: FilterOperatorSelectorProps): ReactElement => {
  const { t } = useTranslation();
  const radioGroupName = 'operations';
  const betweenKeyFirst = `${QueryOperator.Between}-a`;
  const betweenKeySecond = `${QueryOperator.Between}-b`;
  const inputRef = useRef(null) as MutableRefObject<any>;
  const [selectedOperation, setSelectedOperation] = useState('');
  const [textFieldValues, setTextFiledValues] = useState<{
    [key: string]: string;
  }>({});
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [errorMessageBetweenFirst, setErrorMessageBetweenFirst] = useState<
    string | undefined
  >(undefined);
  const [errorMessageBetweenSecond, setErrorMessageBetweenSecond] = useState<
    string | undefined
  >(undefined);
  const stringDataTypeItems: KeyPairItem[] = [
    {
      Name: t('searchFilterComponent.filterContains'),
      Value: QueryOperator.Contains,
    },
    {
      Name: t('searchFilterComponent.filterDoesNotContain'),
      Value: QueryOperator.DoesNotContain,
    },
    {
      Name: t('searchFilterComponent.filterStartsWith'),
      Value: QueryOperator.StartsWith,
    },
    {
      Name: t('searchFilterComponent.filterEndsWith'),
      Value: QueryOperator.EndsWith,
    },
    {
      Name: t('searchFilterComponent.filterEquals'),
      Value: QueryOperator.EqualsTo,
    },
    {
      Name: t('searchFilterComponent.filterNotEqual'),
      Value: QueryOperator.NotEqualTo,
    },
  ];

  // For date and number
  const logicalDataTypeItems: KeyPairItem[] = [
    {
      Name: t('searchFilterComponent.filterEquals'),
      Value: QueryOperator.EqualsTo,
    },
    {
      Name: t('searchFilterComponent.filterLessThan'),
      Value: QueryOperator.LessThan,
    },
    {
      Name: t('searchFilterComponent.filterGreaterThan'),
      Value: QueryOperator.GreaterThan,
    },
    {
      Name: t('searchFilterComponent.filterLessThanInclusive'),
      Value: QueryOperator.LessThanInclusive,
    },
    {
      Name: t('searchFilterComponent.filterGreaterThanInclusive'),
      Value: QueryOperator.GreaterThanInclusive,
    },
    {
      Name: t('searchFilterComponent.filterBetween'),
      Value: QueryOperator.Between,
    },
  ];

  const inputErrors: {
    [key: string]: string;
  } = {
    String: t('searchFilterComponent.errorInvalidString'),
    Date: t('searchFilterComponent.errorInvalidDate'),
    DateTime: t('searchFilterComponent.errorInvalidDateTime'),
    Decimal: t('searchFilterComponent.errorInvalidDecimal'),
  };
  const errorRequired = t('searchFilterComponent.errorRequired');

  const getItems = (): KeyPairItem[] =>
    field.dataType === DataType.String
      ? stringDataTypeItems
      : logicalDataTypeItems;

  const getQueryValue = (value: string): string | undefined => {
    if (!validateDataType(value, field.dataType, settings)) {
      return undefined;
    }

    if (field.dataType === DataType.Date) {
      // Convert to ISO for the API
      const date = dayjs.utc(value, settings.dateFormat);
      return date.toISOString();
    }
    if (field.dataType === DataType.DateTime) {
      // Convert to ISO for the API
      const date = dayjs.utc(value, settings.dateTimeFormat);
      return date.toISOString();
    }

    return value.trim();
  };

  const handleApplyBetweenFilter = (): void => {
    const operationItem = getItems().find((x) => x.Value === selectedOperation);
    if (!operationItem) {
      return;
    }
    const value1 = textFieldValues[betweenKeyFirst];
    const value2 = textFieldValues[betweenKeySecond];
    if (!value1) {
      setErrorMessageBetweenFirst(errorRequired);
    }
    if (!value2) {
      setErrorMessageBetweenSecond(errorRequired);
    }

    if (!value1 || !value2) {
      return;
    }

    const queryValue1 = getQueryValue(value1);
    if (!queryValue1) {
      setErrorMessageBetweenFirst(inputErrors[field.dataType]);
    }
    const queryValue2 = getQueryValue(value2);
    if (!queryValue2) {
      setErrorMessageBetweenSecond(inputErrors[field.dataType]);
    }

    if (!queryValue1 || !queryValue2) {
      return;
    }

    const conditionFilter = buildBetweenFilterString(
      field,
      queryValue1,
      queryValue2
    );

    onApplyFilter({
      query: conditionFilter,
      fieldName: field.name,
      operatorDescription: operationItem.Name,
      values: [value1, value2],
    } as FilterConditionItem);
  };

  const handleApplyFilter = (): void => {
    if (selectedOperation === QueryOperator.Between) {
      handleApplyBetweenFilter();
      return;
    }

    const operationItem = getItems().find((x) => x.Value === selectedOperation);
    if (!operationItem) {
      return;
    }
    const value = textFieldValues[selectedOperation];
    if (!value) {
      setErrorMessage(errorRequired);
      return;
    }

    const queryValue = getQueryValue(value);
    if (!queryValue) {
      setErrorMessage(inputErrors[field.dataType]);
      return;
    }

    const conditionFilter = buildCommonFilterString(
      field,
      operationItem,
      queryValue
    );

    onApplyFilter({
      query: conditionFilter,
      fieldName: field.name,
      operatorDescription: operationItem.Name,
      values: [value],
    } as FilterConditionItem);
  };

  const renderInput = (item: KeyPairItem): ReactElement | null => {
    if (selectedOperation !== item.Value) {
      return null;
    }

    const getMaskingInfo = (): KeyPairItem => {
      if (field.dataType === DataType.DateTime) {
        return {
          Name: settings.dateTimeFormat,
          Value: settings.dateTimeFormat,
        };
      }
      if (field.dataType === DataType.Date) {
        return {
          Name: settings.dateFormat,
          Value: settings.dateFormat,
        };
      }
      return {
        Name: t('searchFilterComponent.placeholderFilterValue'),
        Value: '',
      };
    };

    const maskingInfo = getMaskingInfo();
    const dateMaskOptions = getMaskOptions(field.dataType, maskingInfo.Value);

    if (selectedOperation === QueryOperator.Between) {
      return (
        <>
          <div className="option-input-container">
            <SDCTextField
              placeholder={maskingInfo.Name}
              fieldSize={SDCTextFieldSize.Small}
              value={textFieldValues[betweenKeyFirst] ?? ''}
              onTextChange={(text: string): void => {
                textFieldValues[betweenKeyFirst] = text;
                setTextFiledValues(() => textFieldValues);
              }}
              maskOptions={dateMaskOptions}
              errorMessage={errorMessageBetweenFirst}
              hasError={errorMessageBetweenFirst !== undefined}
              errorMessageId={betweenKeyFirst}
            />
          </div>
          <div className="option-input-container">
            <SDCTextField
              placeholder={maskingInfo.Name}
              fieldSize={SDCTextFieldSize.Small}
              value={textFieldValues[betweenKeySecond] ?? ''}
              onTextChange={(text: string): void => {
                textFieldValues[betweenKeySecond] = text;
                setTextFiledValues(() => textFieldValues);
              }}
              maskOptions={dateMaskOptions}
              errorMessage={errorMessageBetweenSecond}
              hasError={errorMessageBetweenSecond !== undefined}
              errorMessageId={betweenKeySecond}
            />
          </div>
        </>
      );
    }

    return (
      <div className="option-input-container">
        <SDCTextField
          inputRef={inputRef}
          placeholder={maskingInfo.Name}
          fieldSize={SDCTextFieldSize.Small}
          value={textFieldValues[item.Value] ?? ''}
          onTextChange={(text: string): void => {
            textFieldValues[item.Value] = text;
            setTextFiledValues(() => textFieldValues);
          }}
          errorMessage={errorMessage}
          maskOptions={dateMaskOptions}
          hasError={errorMessage !== undefined}
          errorMessageId={item.Value}
        />
      </div>
    );
  };

  return (
    <>
      <div className="filter-operator-selector-container">
        {getItems().map((item: KeyPairItem) => (
          <div className="option-item-container" key={item.Value}>
            <RadioOption
              item={item}
              groupName={radioGroupName}
              selectedValue={selectedOperation}
              onClick={(): void => {
                setSelectedOperation(item.Value);
                setErrorMessage(undefined);
                setImmediate(() => {
                  inputRef?.current?.focus();
                });
              }}
            />
            {renderInput(item)}
          </div>
        ))}

        <div className="submit-filter-container">
          <SDCButton
            text={t('searchFilterComponent.applyFilterButton')}
            variant={SDCButtonVariant.Primary}
            onClick={handleApplyFilter}
          />
        </div>
      </div>
    </>
  );
};

export default FilterOperatorSelector;
