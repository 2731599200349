import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HashIcon } from '../../assets/svg/HashIcon.svg';
import SDCTextField from '../SDCTextField/SDCTextField';
import './Tag.scss';
import TagItem from './TagItem';

interface TagProps {
  tags: string[];
  onTagAdd: (newTag: string) => void;
  onTagRemove: (tag: string) => void;
}

const Tag = ({ tags, onTagAdd, onTagRemove }: TagProps): ReactElement => {
  const { t } = useTranslation();
  const [text, setText] = useState('');

  const handleAddTag = (e: any): void => {
    const enterKey: number = 13;
    if (e.keyCode === enterKey && text) {
      const checkDuplicateTag = tags.find((tx) => tx === text);
      if (!checkDuplicateTag) onTagAdd(text);
      setText(() => '');
    }
  };

  return (
    <div className="tag-container">
      <div className="tag-input-form pb-1">
        <SDCTextField
          value={text}
          Icon={HashIcon}
          onTextChange={(value): void => setText(value)}
          placeholder={t('tag.placeholder')}
          onKeyDown={handleAddTag}
          maskOptions={{
            maskFilter: /^(?=.{0,50}$)[a-zA-Z0-9\s]*$/,
          }}
        />
      </div>
      <div className="tag-items">
        {tags.map((value: string) => (
          <TagItem key={value} text={value} onRemove={onTagRemove} />
        ))}
      </div>
    </div>
  );
};

export default Tag;
