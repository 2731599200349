import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CollapserPositionVariant from '../../common/types/collapserPositionVariant';
import { RowCollection } from '../../common/types/types';
import Metadata from '../../services/capture/models/metadata';
import MetadataDetail from '../../services/capture/models/metadataDetail';
import {
  downloadArchivedDocuments,
  getDocumentPreviewImage,
} from '../../services/document/document.service';
import ArchivedDocumentDetail from '../../services/document/models/archivedDocumentDetail';
import DocumentType from '../../services/documentType/models/documentType';
import UserSettings from '../../services/user/models/userSettings';
import { isViewable } from '../../utils/fileUtil';
import FullSizeModal from '../DefaultModal/FullSizeModal';
import DocumentFilePreviewer from '../DocumentFilePreviewer/DocumentFilePreviewer';
import UnsupportedFilePreviewer from '../DocumentFilePreviewer/UnsupportedFilePreviewer';
import DocumentViewModal from '../DocumentViewModal/DocumentViewModal';
import FileInformation from '../FileInformation/FileInformation';
import LoadingSet from '../LoadingSet/LoadingSet';
import ReadonlyArchiveDocumentSection from '../ReadonlyArchiveDocumentSection/ReadonlyArchiveDocumentSection';
import ReadonlyDataTable from '../ReadonlyDataTable/ReadonlyDataTable';
import ReadonlyDocumentMetadataTable from '../ReadonlyDocumentMetadataTable/ReadonlyDocumentMetadataTable';
import TableMetadataSection from '../TableMetadataSection/TableMetadataSection';
import './DocumentReadonlySidePanel.scss';
import SidePanel from './SidePanel';

interface DocumentReadonlySidePanelProps {
  file: File | undefined;
  id: string;
  metadata: Metadata;
  document?: ArchivedDocumentDetail;
  documentTypes: DocumentType[];
  settings: UserSettings;
  loadFileContent: () => void;
  collapserPosition: CollapserPositionVariant;
  /**
   * Adjust itself based on the presence of the sidebar. Defaults to true.
   */
  withSideBar?: boolean;
}

const DocumentReadonlySidePanel = ({
  file,
  id,
  document,
  documentTypes,
  settings,
  loadFileContent,
  collapserPosition,
  withSideBar,
}: DocumentReadonlySidePanelProps): ReactElement => {
  const { t } = useTranslation();

  // Preview document image
  const [previewImage, setPreviewImage] = useState<string | undefined>();
  const [isFetchingThumbnail, setIsFetchingThumbnail] = useState(true);
  const [showDocumentViewModal, setShowDocumentViewModal] =
    useState<boolean>(false);
  const [selectedTable, setSelectedTable] = useState<
    MetadataDetail | undefined
  >(undefined);
  const [showTable, setShowTable] = useState(false);

  const findDocumentTypeDisplayNameByName = (
    name: string
  ): string | undefined =>
    documentTypes.find((x) => x.name.toLowerCase() === name?.toLowerCase())
      ?.displayName;

  // Reset counter and clear current thumbnail when get new selected file
  useEffect(() => {
    async function getPreviewThumbnailImage(): Promise<void> {
      setPreviewImage(undefined);
      try {
        const imageContent = await getDocumentPreviewImage(id);
        // Then create a local URL for that image and set it to preview image
        const imageObjectURL = imageContent
          ? URL.createObjectURL(imageContent)
          : undefined;
        setPreviewImage(imageObjectURL);
      } catch (e) {
        setPreviewImage(undefined);
      } finally {
        setIsFetchingThumbnail(false);
      }
    }
    getPreviewThumbnailImage();
  }, [id]);

  const onFullViewClick = (): void => {
    loadFileContent();
    setShowDocumentViewModal(true);
  };

  const handleModalClose = (): void => setShowDocumentViewModal(false);

  const handleDownloadDocument = (): void => {
    downloadArchivedDocuments([id]).then((response) => {
      const fileUrl = URL.createObjectURL(response);
      const link = window.document.createElement('a');
      link.href = fileUrl;
      link.download = response.name;
      link.click();

      window.URL.revokeObjectURL(fileUrl);
      link.remove();
    });
  };

  const renderPreviewer = (): ReactElement => {
    if (isViewable(document?.fileName)) {
      return (
        <DocumentFilePreviewer
          onFullViewClick={onFullViewClick}
          imageData={previewImage || ''}
        />
      );
    }

    return (
      <UnsupportedFilePreviewer onDownloadClick={handleDownloadDocument} />
    );
  };

  const renderContent = (): ReactElement => {
    if (!document) {
      return (
        <div className="nothing-selected">
          <div className="title">{t('sidePanel.noSelectionTitle')}</div>
          <div>{t('sidePanel.noSelectionDescription')}</div>
        </div>
      );
    }

    return (
      <div className="document-readonly-side-panel">
        <div className="section-label">{t('sidePanel.viewLabel')}</div>
        {document && isFetchingThumbnail && previewImage === undefined ? (
          <LoadingSet size="sm" />
        ) : (
          <>
            <DocumentViewModal
              show={showDocumentViewModal}
              onClose={(): void => handleModalClose()}
            >
              <ReadonlyArchiveDocumentSection
                selectedFile={file}
                documentName={document.documentName}
                document={document}
                documentTypes={documentTypes}
                onCloseModal={(): void => handleModalClose()}
              />
            </DocumentViewModal>
            {renderPreviewer()}
          </>
        )}
        {document?.metadata?.templateName && (
          <>
            <div className="section-label">
              {t('sidePanel.documentTypeLabel')}
            </div>
            <div className="document-type">
              {findDocumentTypeDisplayNameByName(
                document.metadata.templateName
              )}
            </div>
          </>
        )}
        {document?.metadata?.data && document?.metadata.data.length > 0 && (
          <>
            <div className="section-divider" />
            <div className="section-label">{t('sidePanel.metadataLabel')}</div>
            <ReadonlyDocumentMetadataTable
              metadata={document.metadata.data}
              settings={settings}
            />
            <TableMetadataSection
              title={t('sidePanel.tableMetadataLabel')}
              data={document.metadata.data}
              onSelect={(item: MetadataDetail): void => {
                setSelectedTable(item);
                setShowTable(true);
              }}
            />
          </>
        )}
        {document && (
          <>
            <div className="section-divider" />
            <div className="section-label">
              {t('sidePanel.fileInformationLabel')}
            </div>
            <FileInformation document={document} />
            <div className="end-of-data">
              <div className="separator" />
              <div>{t('sidePanel.end')}</div>
            </div>
          </>
        )}
      </div>
    );
  };
  return (
    <>
      <SidePanel collapserPosition={collapserPosition} resizable>
        {renderContent()}
      </SidePanel>

      {selectedTable !== undefined ? (
        <FullSizeModal
          className="data-table"
          title={selectedTable.displayName ?? selectedTable.key}
          setShow={setShowTable}
          show={showTable}
          withSideBar={withSideBar}
        >
          <ReadonlyDataTable rows={selectedTable.value as RowCollection} />
        </FullSizeModal>
      ) : null}
    </>
  );
};

DocumentReadonlySidePanel.defaultProps = {
  document: undefined,
  withSideBar: true,
};

export default DocumentReadonlySidePanel;
