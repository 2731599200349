const storageKey = {
  PENDING_TRAY_SCOPE: 'pending-tray-scope',
  CONTAINER_KEY: 'container-key',
  PENDING_TRAY_FILES_PROGRESS: 'pending-tray-files-progress',
  FORCE_UPLOADING_FILE_IN_PENDING_TRAY: 'force-uploading-file-in-pending-tray',
  FORCE_MOVING_FILE_IN_PENDING_TRAY: 'force-moving-file-in-pending-tray',
  SIDE_PANEL_WIDTH: 'side-panel-width',
  NOTIFY_KEY: 'updated-protection',
  BARCODE_SETTING: 'barcode-settings',
};

export default storageKey;
