enum DataType {
  String = 'String',
  Decimal = 'Decimal',
  DateTime = 'DateTime',
  Date = 'Date',
  Table = 'Table',
  YearTimespan = 'Year',
}

export default DataType;
