import { ReactElement } from 'react';
import { Dropdown } from 'react-bootstrap';
import KeyPairItem from '../../common/models/keypairItem';
import DropdownVariant from '../../common/types/dropdownVariant';
import './SecondaryDropdown.scss';

interface SecondaryDropdownProps {
  selectedValue: string;
  items: KeyPairItem[];
  onSelect: (value: KeyPairItem) => void;
  disabled?: boolean;
  variant?: DropdownVariant;
}

const SecondaryDropdown = ({
  selectedValue,
  items,
  onSelect,
  disabled,
  variant,
}: SecondaryDropdownProps): ReactElement => (
  <Dropdown className="secondary-dropdown">
    <div className={variant}>
      <Dropdown.Toggle
        className="shadow-none"
        variant="secondary"
        disabled={disabled}
      >
        {items.find((x) => x.Value === selectedValue)?.Name ?? '-'}
      </Dropdown.Toggle>
    </div>

    <Dropdown.Menu>
      {items.map((item: KeyPairItem) => (
        <Dropdown.Item
          key={`$dropdown-item-${item.Value}`}
          eventKey={item.Name}
          onClick={(): void => onSelect(item)}
        >
          {item.Name}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

export default SecondaryDropdown;

SecondaryDropdown.defaultProps = {
  disabled: false,
  variant: DropdownVariant.Default,
};
