import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArchiveSuccessIcon from '../../assets/svg/ArchiveSuccessIcon.svg';
import Loading from '../Loading/Loading';
import './DefaultLoading.scss';

interface DefaultLoadingProps {
  progressTranslationKey?: string;
  successTranslationKey?: string;
  errorTranslationKey?: string;
  status?: boolean;
  error?: boolean;
}

const DefaultLoading = ({
  progressTranslationKey,
  successTranslationKey,
  errorTranslationKey,
  status,
  error,
}: DefaultLoadingProps): ReactElement => {
  const { t } = useTranslation();
  const [loadingStatus, setLoadingStatus] = useState<boolean | undefined>(
    false
  );
  const [loadingError, setLoadingError] = useState<boolean | undefined>(false);

  useEffect(() => {
    setLoadingStatus(status);
    setLoadingError(error);
  }, [error, status]);

  const checkDefaultLoading = (): ReactElement => (
    <h1> {t(progressTranslationKey ?? '')}</h1>
  );

  const checkArchiveStatus = (): ReactElement | null => {
    if (loadingStatus) {
      if (loadingError) {
        return (
          <div className="archive-status error">
            <img src={ArchiveSuccessIcon} alt="ArchiveSuccessIcon" />
            <h1 className="error">{t(errorTranslationKey ?? '')}</h1>
          </div>
        );
      }
      return (
        <div className="archive-status success">
          <img src={ArchiveSuccessIcon} alt="ArchiveSuccessIcon" />
          <h1 className="success">{t(successTranslationKey ?? '')}</h1>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="loading-wrapper">
      {!loadingStatus ? (
        <div className="loading-status">
          <Loading theme="light" />
          {checkDefaultLoading()}
        </div>
      ) : (
        checkArchiveStatus()
      )}
    </div>
  );
};

DefaultLoading.defaultProps = {
  status: false,
  error: false,
  progressTranslationKey: 'defaultLoadingComponent.loading',
  successTranslationKey: 'defaultLoadingComponent.success',
  errorTranslationKey: 'defaultLoadingComponent.fail',
};

export default DefaultLoading;
