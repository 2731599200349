import { ReactElement } from 'react';
import Icon from '../../assets/svg/CheerIcon.svg';
import './CheerIcon.scss';

interface CheerIconProps {
  text: string;
}

const CheerIcon = ({ text }: CheerIconProps): ReactElement => (
  <div className="cheer-icon-container">
    <div className="frame">
      <img src={Icon} alt="" />
      <div className="text">{text}</div>
    </div>
  </div>
);

export default CheerIcon;
