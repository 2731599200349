enum QueryOperator {
  Contains = 'ct',
  DoesNotContain = 'nct',
  StartsWith = 'sw',
  EndsWith = 'ew',
  EqualsTo = 'eq',
  NotEqualTo = 'neq',
  LessThan = 'lt',
  GreaterThan = 'gt',
  LessThanInclusive = 'le',
  GreaterThanInclusive = 'ge',
  Between = 'bt',
}

export default QueryOperator;
