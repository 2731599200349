import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloudCheckDarkSmallIcon } from '../../assets/svg/CloudCheckDarkSmallIcon.svg';
import NotificationVariant from '../../common/types/notificationVariant';
import SDCButtonSize from '../../common/types/sdcButtonSize';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import UserPermission from '../../common/types/userPermission';
import ContainerManagementDataGrid from '../../components/DataGrid/ContainerManagementDataGrid/ContainerManagementDataGrid';
import DefaultLoading from '../../components/DefaultLoading/DefaultLoading';
import EditContainerModal from '../../components/DefaultModal/EditContainerModal';
import PaginationNavigator from '../../components/Pagination/PaginationNavigator';
import RestrictedArea from '../../components/RestrictedArea/RestrictedArea';
import SDCButton from '../../components/SDCButton/SDCButton';
import Toaster from '../../components/Toaster/Toaster';
import showToaster from '../../components/Toaster/ToasterProvider';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import { getContainersAsync } from '../../services/container/container.service';
import Container from '../../services/container/models/container';
import PaginationResponse from '../../services/paginationResponse';
import userHasPermission from '../../utils/userUtil';
import './ContainerManagementPage.scss';

const ContainerManagementPage = (): ReactElement => {
  const PAGE_SIZE = 10;
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const [page, setPage] = useState(1);
  const [containerResponse, setContainerResponse] = useState<
    PaginationResponse<Container> | undefined
  >(undefined);
  const emptyContainer: Container = {
    id: '',
    name: '',
    key: '',
    createdOn: new Date(),
    isSystemDefined: false,
    permissions: [],
  };

  const [isLoading, setIsLoading] = useState(true);
  const [showContainerModal, setShowContainerModal] = useState(false);
  const [selectedContainer, setSelectedContainer] =
    useState<Container>(emptyContainer);

  const fetchContainers = (): void => {
    getContainersAsync(page, PAGE_SIZE).then((resolve) => {
      setContainerResponse(resolve);
    });
  };

  useEffect(() => {
    if (
      !userHasPermission(currentUser, UserPermission.ConfigureSystemSettings)
    ) {
      return;
    }
    getContainersAsync(page, PAGE_SIZE).then((resolve) => {
      setContainerResponse(resolve);
      setIsLoading(false);
    });
  }, [currentUser, page]);

  const handleUpdate = (item: Container): void => {
    setSelectedContainer(item);
    setShowContainerModal(true);
  };

  const renderTable = (): JSX.Element | null => {
    if (!containerResponse) {
      return null;
    }

    return (
      <ContainerManagementDataGrid
        containers={containerResponse?.results}
        onUpdate={handleUpdate}
      />
    );
  };

  const handleNewContaierClick = (): void => {
    setSelectedContainer(emptyContainer);
    setShowContainerModal(true);
  };

  const handleContainerActionComplete = (): void => {
    fetchContainers();
    setShowContainerModal(false);
    setSelectedContainer(emptyContainer);
  };

  const renderContainerModal = (): JSX.Element | undefined => {
    if (!showContainerModal) {
      return undefined;
    }
    return (
      <EditContainerModal
        container={selectedContainer}
        show={showContainerModal}
        onClose={(): void => {
          setShowContainerModal(false);
        }}
        onCreationComplete={(): void => {
          handleContainerActionComplete();
          showToaster(
            t('containerPage.notificationTitleCreate'),
            t('containerPage.notificationMessageCreate'),
            NotificationVariant.PrimarySuccess
          );
        }}
        onUpdateComplete={(): void => {
          handleContainerActionComplete();
          showToaster(
            t('containerPage.notificationTitleUpdate'),
            t('containerPage.notificationMessageUpdate'),
            NotificationVariant.PrimarySuccess
          );
        }}
      />
    );
  };

  if (!userHasPermission(currentUser, UserPermission.ConfigureSystemSettings)) {
    return (
      <div className="container-management-page">
        <RestrictedArea />
      </div>
    );
  }

  if (isLoading) {
    return <DefaultLoading />;
  }

  return (
    <>
      <Toaster />
      <div className="container-management-page">
        {renderContainerModal()}
        <div className="container-management-body">
          <div className="pb-2">
            <SDCButton
              onClick={handleNewContaierClick}
              variant={SDCButtonVariant.Default}
              size={SDCButtonSize.Small}
              text={t('containerPage.newContainerButton')}
              Icon={CloudCheckDarkSmallIcon}
            />
          </div>
          {renderTable()}
          <PaginationNavigator
            pageSize={containerResponse?.pageSize || 0}
            lastPage={containerResponse?.lastPage || 0}
            currentPage={containerResponse?.page || 1}
            totalCount={containerResponse?.totalCount || 0}
            onPageChange={(newPageNumber: number): void => {
              setPage(newPageNumber);
              fetchContainers();
            }}
            descriptionTranslationKey="containerPage.paginationDescription"
          />
        </div>
      </div>
    </>
  );
};

export default ContainerManagementPage;
