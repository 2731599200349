import { AxiosError } from 'axios';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import signalRServerEventName from '../../common/constants/signalRServerEventName.constant';
import ErrorDetails from '../../common/models/errorDetails';
import ConfirmationModalVariant from '../../common/types/confirmationModalVariant';
import NotificationVariant from '../../common/types/notificationVariant';
import { useSignalR } from '../../context/SignalR/SignalRContext';
import { deletePendingTrayDocumentFiles } from '../../services/pendingTray/pendingTray.service';
import invokeWithIgnoredResult from '../../utils/signalRUtil';
import ConfirmationModal from '../DefaultModal/ConfirmationModal';
import showToaster from '../Toaster/ToasterProvider';

interface PendingTrayDeletionModalProps {
  idsToDelete: string[];
  setCheckedDocumentIds: (checkedDocumentIds: Array<string>) => void;
  showDeleteConfirmationModal: boolean;
  setShowDeleteConfirmationModal: Dispatch<SetStateAction<boolean>>;
  loadPendingTray: Function;
  setIsDeletingFile: (isDeletingFile: boolean) => void;
}

const PendingTrayDeletionModal = ({
  idsToDelete,
  setCheckedDocumentIds,
  showDeleteConfirmationModal,
  setShowDeleteConfirmationModal,
  loadPendingTray,
  setIsDeletingFile,
}: PendingTrayDeletionModalProps): ReactElement => {
  const { t } = useTranslation();
  const { connection } = useSignalR();

  const deletePendingTrayItems = (): void => {
    setIsDeletingFile(true);
    deletePendingTrayDocumentFiles(idsToDelete)
      .then(() => {
        showToaster(
          t('pendingTrayPage.deletePendingTrayItemsLabel', {
            count: idsToDelete.length,
          }),
          '',
          NotificationVariant.PrimarySuccess
        );
        setCheckedDocumentIds([]);
        invokeWithIgnoredResult(
          connection,
          signalRServerEventName.NOTIFY_PUBLIC_TRAY_UPDATE
        );
        loadPendingTray(true);
      })
      .catch((error) => {
        const axiosError = error as AxiosError;
        const detail = axiosError.response?.data as ErrorDetails;
        showToaster(
          t('pendingTrayPage.notificationTitleError'),
          detail.detail,
          NotificationVariant.SoftDanger
        );
      })
      .finally(() => {
        setIsDeletingFile(false);
      });
  };

  return (
    <ConfirmationModal
      bodyText={t('pendingTrayPage.deleteMessage', {
        count: idsToDelete.length,
      })}
      title={t('pendingTrayPage.deleteTitle')}
      dismissButtonText={t('pendingTrayPage.cancelButton')}
      primaryButtonText={t('pendingTrayPage.deleteButton')}
      show={showDeleteConfirmationModal}
      setShow={setShowDeleteConfirmationModal}
      variant={ConfirmationModalVariant.PrimaryDefault}
      onAnswer={(isYes: boolean): void => {
        if (isYes) deletePendingTrayItems();
      }}
    />
  );
};

export default PendingTrayDeletionModal;
