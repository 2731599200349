import { ReactElement, useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import DataType from '../../common/types/dataType';
import SDCButtonSize from '../../common/types/sdcButtonSize';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import UserSettings from '../../services/user/models/userSettings';
import { validateDataType } from '../../utils/dataTypeUtil';
import CloseActionIcon from '../ActionIcon/CloseActionIcon';
import SDCButton from '../SDCButton/SDCButton';
import './DataTableCell.scss';

interface DataTableCellProps {
  value: string;
  dataType: DataType;
  settings: UserSettings;
  isRequired?: boolean;
  onEdited: (value: string) => void;
  t: TFunction<'translation', undefined>;
}

const DataTableCell = ({
  value,
  dataType,
  settings,
  isRequired,
  onEdited,
  t,
}: DataTableCellProps): ReactElement => {
  const [textValue, setTextValue] = useState(value);
  const [error, setError] = useState('');

  useEffect(() => {
    if (isRequired && !value.trim()) {
      setError(t('validation.textMissingError'));
    }

    setTextValue(value);

    // Reset cell state when unmount
    return (): void => {
      setError('');
    };
  }, [value, isRequired, t]);

  const handleTextChange = (text: string): void => {
    setTextValue(text);
    if (isRequired && !text.trim()) {
      setError(t('validation.textMissingError'));
      return;
    }
    if (validateDataType(text, dataType, settings)) {
      setError('');
      return;
    }
    if (dataType === DataType.Date) {
      setError(
        t('validation.invalidInputDateFormat', { value: settings.dateFormat })
      );
      return;
    }
    if (dataType === DataType.DateTime) {
      setError(
        t('validation.invalidInputDateTimeFormat', {
          value: settings.dateTimeFormat,
        })
      );
      return;
    }
    if (dataType === DataType.Decimal) {
      setError(t('validation.invalidInputDecimal'));
    }
  };

  const isDirty = (): boolean => !error && textValue !== value;

  const renderTextField = (): ReactElement => (
    <div className="cell-container">
      {error ? (
        <ReactTooltip
          place="top"
          backgroundColor="#C91B1B"
          effect="solid"
          className="custom-tooltip"
        />
      ) : null}
      <input
        data-hj-suppress
        className={`${error ? 'error' : ''}`}
        data-tip={error}
        type="text"
        value={textValue}
        onChange={(e): void => {
          handleTextChange(e.target.value);
        }}
        onBlur={(): void => {
          if (!error) {
            onEdited(textValue.trim());
          }
        }}
      />
      {isDirty() ? (
        <SDCButton
          className="action-button"
          text={t('dataTable.doneButton')}
          variant={SDCButtonVariant.Link}
          size={SDCButtonSize.Small}
          onClick={(): void => {
            onEdited(textValue.trim());
          }}
        />
      ) : null}
      {error && textValue ? (
        <CloseActionIcon
          className="action-button close-icon"
          onClick={(): void => {
            handleTextChange('');
          }}
        />
      ) : null}
    </div>
  );

  return <div className="data-table-cell-component">{renderTextField()}</div>;
};

export default DataTableCell;
DataTableCell.defaultProps = {
  isRequired: false,
};
