enum FileUploadStatus {
  UPLOADING = 'UPLOADING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  DUPLICATED = 'DUPLICATED',
  PENDING = 'PENDING',
  ARCHIVED = 'ARCHIVED',
  CANCELED = 'CANCELED',
}

export default FileUploadStatus;
