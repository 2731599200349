class EventListener {
  private window: Window;

  constructor() {
    this.window = window;
  }

  public add(type: string, listener: EventListenerOrEventListenerObject): void {
    this.window.addEventListener(type, listener);
  }

  public remove(
    type: string,
    listener: EventListenerOrEventListenerObject
  ): void {
    this.window.removeEventListener(type, listener);
  }
}

export default EventListener;
