/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DocumentUploadIcon } from '../../assets/svg/DocumentUploadIcon.svg';
import './UploadDocumentBox.scss';

interface UploadDocumentBoxProps {
  openAddFilesDialog: React.MouseEventHandler<HTMLButtonElement>;
  isUploadListView?: boolean;
}

const UploadDocumentBox = ({
  openAddFilesDialog,
  isUploadListView,
}: UploadDocumentBoxProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <div
      className={`upload-document-box-container ${
        isUploadListView ? 'list' : ''
      }`}
    >
      <button
        type="button"
        className="upload-document-box"
        onClick={openAddFilesDialog}
      >
        <DocumentUploadIcon />
        <div className="title">
          {t('uploadDocumentBoxComponent.uploadBoxTitle')}
        </div>
        <div className="constant">
          {isUploadListView && t('uploadDocumentBoxComponent.uploadConstant')}
        </div>
      </button>
      <div className="constant">
        {!isUploadListView && t('uploadDocumentBoxComponent.uploadConstant')}
      </div>
    </div>
  );
};

UploadDocumentBox.defaultProps = {
  isUploadListView: false,
};

export default UploadDocumentBox;
