import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UploadCloudIcon } from '../../assets/svg/UploadCloudIcon.svg';
import UploadDocumentBox from '../UploadDocumentBox/UploadDocumentBox';
import './BlankDropzone.scss';

interface BlankDropzoneProps {
  open: React.MouseEventHandler<HTMLButtonElement>;
  isFocused?: boolean;
  isUploadListView?: boolean;
}

const BlankDropzone = ({
  open,
  isFocused,
  isUploadListView,
}: BlankDropzoneProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className={`upload-dropzone ${isUploadListView ? 'list' : ''}`}>
      {!isFocused && (
        <UploadDocumentBox
          isUploadListView={isUploadListView}
          openAddFilesDialog={open}
        />
      )}
      <div className="dropzone-background">
        {!isFocused && <UploadCloudIcon />}
        <div className={`description ${isFocused && 'focused'}`}>
          {t('dropzoneComponent.beginDragAndDrop')}
        </div>
      </div>
    </div>
  );
};

BlankDropzone.defaultProps = {
  isFocused: false,
  isUploadListView: false,
};

export default BlankDropzone;
