import { ReactElement, useState } from 'react';
import { ReactComponent as ZoomDefaultIcon } from '../../assets/svg/ZoomDefaultIcon.svg';
import { ReactComponent as ZoomInIcon } from '../../assets/svg/ZoomInIcon.svg';
import { ReactComponent as ZoomOutIcon } from '../../assets/svg/ZoomOutIcon.svg';
import KeyPairItem from '../../common/models/keypairItem';
import SDCButtonSize from '../../common/types/sdcButtonSize';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import SDCDropdownSize from '../../common/types/sdcDropdownSize';
import SDCButton from '../SDCButton/SDCButton';
import SDCDropdown from '../SDCDropdown/SDCDropdown';
import './PDFZoomControl.scss';

type PDFZoomControlProps = {
  setCurrentScale: Function;
  darkMode?: boolean;
};

const PDFZoomControl = ({
  setCurrentScale,
  darkMode,
}: PDFZoomControlProps): ReactElement => {
  const [pageScale, setPageScale] = useState(1);
  const maxScale = 1.5;
  const minScale = 0.5;
  const scaleStep = 0.25;
  const [currentScaleItem, setCurrentScaleItem] = useState<KeyPairItem>({
    Name: '100%',
    Value: '1',
  });
  const scaleItems: KeyPairItem[] = [
    {
      Name: '150%',
      Value: '1.5',
    },
    {
      Name: '125%',
      Value: '1.25',
    },
    {
      Name: '100%',
      Value: '1',
    },
    {
      Name: '75%',
      Value: '0.75',
    },
    {
      Name: '50%',
      Value: '0.5',
    },
  ];

  const setScale = (scale: number): void => {
    setPageScale(scale);
    setCurrentScale(scale);

    const scaleItem = scaleItems.find((item) => Number(item.Value) === scale);
    if (scaleItem) {
      setCurrentScaleItem(scaleItem);
    }
  };

  const handleScaleSelect = (item: KeyPairItem): void => {
    setCurrentScaleItem(item);
    setScale(Number(item.Value));
  };

  const onZoomIn = (): void => {
    if (pageScale < maxScale) setScale(pageScale + scaleStep);
  };

  const onZoomOut = (): void => {
    if (pageScale > minScale) setScale(pageScale - scaleStep);
  };

  const onZoomDefault = (): void => {
    setScale(1);
  };

  return (
    <span className="zoom-control-group">
      <SDCButton
        text={undefined}
        onClick={onZoomIn}
        Icon={ZoomInIcon}
        variant={
          darkMode ? SDCButtonVariant.OutlinedDark : SDCButtonVariant.Default
        }
        size={SDCButtonSize.Small}
      />
      <SDCDropdown
        values={scaleItems}
        onSelect={handleScaleSelect}
        currentItem={currentScaleItem}
        darkMode={darkMode}
        size={SDCDropdownSize.Small}
      />
      <SDCButton
        text={undefined}
        onClick={onZoomOut}
        Icon={ZoomOutIcon}
        variant={
          darkMode ? SDCButtonVariant.OutlinedDark : SDCButtonVariant.Default
        }
        size={SDCButtonSize.Small}
      />
      <SDCButton
        text={undefined}
        onClick={onZoomDefault}
        Icon={ZoomDefaultIcon}
        variant={
          darkMode ? SDCButtonVariant.OutlinedDark : SDCButtonVariant.Default
        }
        size={SDCButtonSize.Small}
      />
    </span>
  );
};

PDFZoomControl.defaultProps = {
  darkMode: false,
};

export default PDFZoomControl;
