import { ReactElement } from 'react';
import { ReactComponent as CellsIcon } from '../../assets/svg/CellsIcon.svg';
import DataType from '../../common/types/dataType';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import MetadataDetail from '../../services/capture/models/metadataDetail';
import SDCButton from '../SDCButton/SDCButton';
import './TableMetadataSection.scss';

interface TableMetadataSectionProps {
  title: string;
  data: MetadataDetail[];
  onSelect: (item: MetadataDetail) => void;
}

const TableMetadataSection = ({
  data,
  title,
  onSelect,
}: TableMetadataSectionProps): ReactElement => (
  <>
    {data.find((x) => x.type === DataType.Table) && (
      <div className="table-metadata-section-component">
        <div className="section-divider" />
        <div className="section-label">{title}</div>
        <div className="container" data-hj-suppress>
          {data
            .filter((x) => x.type === DataType.Table)
            .map((tableData: MetadataDetail) => (
              <SDCButton
                key={tableData.key}
                Icon={CellsIcon}
                variant={SDCButtonVariant.Secondary}
                text={tableData.displayName ?? tableData.key}
                onClick={(): void => {
                  onSelect(tableData);
                }}
              />
            ))}
        </div>
      </div>
    )}
  </>
);

export default TableMetadataSection;
