import { ReactElement } from 'react';
import CheckMarkIcon from '../../assets/svg/CheckMarkIcon.svg';
import CloudLightIcon from '../../assets/svg/CloudLightIcon.svg';
import './CloudIcon.scss';

interface CloudIconProps {
  text: string;
}

const CloudIcon = ({ text }: CloudIconProps): ReactElement => (
  <div className="cloud-icon-container">
    <div className="frame">
      <img className="cloud" src={CloudLightIcon} alt="" />
      <img className="check-mark" src={CheckMarkIcon} alt="" />
      <span className="text">{text}</span>
    </div>
  </div>
);

export default CloudIcon;
