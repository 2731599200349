import {
  FunctionComponent,
  MutableRefObject,
  ReactElement,
  SVGProps,
  useEffect,
  useState,
} from 'react';
import { Form, FormControlProps } from 'react-bootstrap';
import { useMask } from 'react-mask-field';
import { ReactComponent as ExclamationMarkIcon } from '../../assets/svg/ExclamationMarkIcon.svg';
import { ReactComponent as LightBulbIcon } from '../../assets/svg/LightBulbIcon.svg';
import MaskOptions from '../../common/models/maskOptions';
import SDCButtonIconPosition from '../../common/types/sdcButtonIconPosition';
import SDCTextFieldSize from '../../common/types/sdcTextFieldSize';
import { createUUID } from '../../utils/stringUtil';
import './SDCTextField.scss';

type SDCTextFieldProps = {
  onTextChange?: (text: string) => void;
  fieldSize?: SDCTextFieldSize;
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  iconPosition?: SDCButtonIconPosition;
  helpText?: string;
  helpTextId?: string;
  hasError?: boolean;
  errorMessage?: string;
  errorMessageId?: string;
  maskOptions?: MaskOptions;
  labelText?: string;
  inputRef?: MutableRefObject<any>;
} & FormControlProps;

const SDCTextField = ({
  value,
  Icon,
  iconPosition,
  placeholder,
  onTextChange,
  onChange,
  onKeyDown,
  onFocus,
  onBlur,
  disabled,
  fieldSize,
  helpText,
  helpTextId,
  hasError,
  errorMessage,
  errorMessageId,
  maskOptions,
  labelText,
  inputRef,
  ...props
}: SDCTextFieldProps): ReactElement => {
  const [text, setText] = useState<any>('');
  const id = createUUID();

  useEffect(() => {
    if (value || value === '') setText(value);
  }, [value]);

  const handleChange = (val: string): void => {
    if (maskOptions?.maskFilter && !maskOptions.maskFilter.test(val)) {
      return;
    }
    setText(val);
    if (onTextChange) onTextChange(val);
  };

  const maskRef = useMask({
    mask: maskOptions?.maskText,
    replacement: maskOptions?.maskTextMapping,
    showMask: true,
  });

  return (
    <>
      <span
        className={`text-field-container ${labelText ? 'form-floating' : ''}`}
      >
        {Icon !== undefined && iconPosition === SDCButtonIconPosition.Left && (
          <span
            className={`icon-container left-icon ${
              fieldSize === SDCTextFieldSize.Small ? 'small' : ''
            }`}
          >
            <Icon />
          </span>
        )}
        <Form.Control
          data-hj-suppress
          id={id}
          className={`text-field 
          ${fieldSize === SDCTextFieldSize.Small ? 'small' : ' '}
          ${hasError ? 'error' : ' '}
          ${
            Icon !== undefined && iconPosition === SDCButtonIconPosition.Left
              ? 'left-icon'
              : ' '
          }
          ${
            Icon !== undefined && iconPosition === SDCButtonIconPosition.Right
              ? 'right-icon'
              : ' '
          }`}
          value={text}
          onChange={(event): void => {
            if (onChange) onChange(event);
            handleChange(event.target.value);
          }}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          // This ' ' allows the floating label to work properly since a place holder is required
          // See https://getbootstrap.com/docs/5.0/forms/floating-labels/#example
          placeholder={labelText ? ' ' : placeholder}
          disabled={disabled}
          aria-describedby={helpTextId}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          ref={
            maskOptions?.maskText !== undefined &&
            maskOptions?.maskTextMapping !== undefined
              ? maskRef
              : inputRef
          }
        />
        {Icon !== undefined && iconPosition === SDCButtonIconPosition.Right && (
          <span
            className={`icon-container right-icon ${
              fieldSize === SDCTextFieldSize.Small ? 'small' : ''
            }`}
          >
            <Icon />
          </span>
        )}
        {labelText && (
          <Form.Label className="show-label" htmlFor={id}>
            {labelText}
          </Form.Label>
        )}
      </span>
      {errorMessage && hasError && (
        <div className="text-field-error">
          <div>
            <ExclamationMarkIcon />
          </div>
          <span id={errorMessageId}>{errorMessage}</span>
        </div>
      )}
      {helpText && (
        <div className="text-field-description">
          <div>
            <LightBulbIcon />
          </div>
          <span id={helpTextId}>{helpText}</span>
        </div>
      )}
    </>
  );
};

SDCTextField.defaultProps = {
  fieldSize: SDCTextFieldSize.Default,
  iconPosition: SDCButtonIconPosition.Left,
  onTextChange: (): boolean => false,
  Icon: undefined,
  helpText: undefined,
  helpTextId: undefined,
  hasError: false,
  errorMessage: undefined,
  errorMessageId: undefined,
  maskOptions: undefined,
  labelText: undefined,
  inputRef: undefined,
};

export default SDCTextField;
