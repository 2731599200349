import { ReactElement } from 'react';
import './ReadonlyDataTableCell.scss';

interface ReadonlyDataTableCellProps {
  value: string;
}

const ReadonlyDataTableCell = ({
  value,
}: ReadonlyDataTableCellProps): ReactElement => {
  const renderTextField = (): ReactElement => (
    <div className="cell-container">
      <input data-hj-suppress type="text" disabled value={value} />
    </div>
  );

  return <div className="data-table-cell-component">{renderTextField()}</div>;
};

export default ReadonlyDataTableCell;
