import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import TipIcon from '../../assets/svg/TipIcon.svg';
import KeyPairItem from '../../common/models/keypairItem';
import CollapserPositionVariant from '../../common/types/collapserPositionVariant';
import PreviewImage from '../../common/types/previewImage';
import Metadata from '../../services/capture/models/metadata';
import PendingTrayItemResponse from '../../services/pendingTray/models/pendingTrayItemResponse';
import {
  downloadDocumentFiles,
  getPendingTrayDocumentPreviewImage,
  updatePendingTrayItemTags,
} from '../../services/pendingTray/pendingTray.service';
import ArchiveDocumentSection from '../ArchiveDocumentSection/ArchiveDocumentSection';
import DocumentFilePreviewer from '../DocumentFilePreviewer/DocumentFilePreviewer';
import DocumentViewModal from '../DocumentViewModal/DocumentViewModal';
import SDCDropdown from '../SDCDropdown/SDCDropdown';
import Tag from '../Tag/Tag';
import Toaster from '../Toaster/Toaster';
import './PendingTraySidePanel.scss';
import SidePanel from './SidePanel';

interface PendingTraySidePanelProps {
  pendingTrayItem?: PendingTrayItemResponse;
  fileId?: string;
  documentTypes: KeyPairItem[];
  documentTags: string[];
  onTemplateChanged: (item: KeyPairItem) => void;
}

const emptyMetadata = {
  templateName: '',
  templateGroupName: '',
  data: [],
} as Metadata;

const PendingTraySidePanel = ({
  pendingTrayItem,
  documentTags,
  fileId,
  documentTypes,
  onTemplateChanged,
}: PendingTraySidePanelProps): ReactElement => {
  const previewImageLimitCall = 5;
  const delayTimeout = 6000;

  const { t } = useTranslation();
  const errorHandler = useErrorHandler();

  const [documentFile, setDocumentFile] = useState<File>();
  const [tags, setTags] = useState<string[]>([]);

  // Visible control
  const [showDocumentViewModal, setShowDocumentViewModal] = useState(false);

  // Preview document image
  const [tempPreviewImages, setTempPreviewImages] = useState<PreviewImage[]>(
    []
  );
  const [previewImg, setPreviewImage] = useState<string>('');
  const [previewImageCounter, setPreviewImageCounter] = useState<number>(0);

  const callUpdateDocumentTags = async (newTags: string[]): Promise<void> => {
    await updatePendingTrayItemTags(fileId, newTags);
  };

  const handleAddTag = (value: string): void => {
    const temp = [...tags];
    if (value.trim() !== '') {
      temp.push(value.trim());
      setTags(temp);
      callUpdateDocumentTags(temp);
    }
  };

  const handleRemoveTag = (value: string): void => {
    const index = tags.indexOf(value);
    if (index !== -1) {
      const temp = [...tags];
      temp.splice(index, 1);
      setTags(temp);
      callUpdateDocumentTags(temp);
    }
  };

  useEffect(() => {
    if (documentTags) {
      setTags(documentTags);
    }
  }, [documentTags]);

  const onSelected = (item: KeyPairItem): void => {
    // Do nothing if no document type selected
    if (!pendingTrayItem?.id) {
      return;
    }
    onTemplateChanged(item);
  };

  const getPreviewThumbnailImage = useCallback(async (): Promise<void> => {
    if (fileId && !previewImg) {
      const lookupFileId = fileId;
      try {
        const existingPreviewImg = tempPreviewImages.find(
          (x) => x.id === fileId
        );
        if (existingPreviewImg) {
          setPreviewImage(existingPreviewImg.imageObjectURL);
          return;
        }

        let imageContent;
        if (previewImageCounter < previewImageLimitCall) {
          imageContent = await getPendingTrayDocumentPreviewImage(fileId);
        }
        // Then create a local URL for that image and set it to preview image
        const imageObjectURL = imageContent
          ? URL.createObjectURL(imageContent)
          : '';

        setTempPreviewImages((prev) => {
          if (!prev.find((x) => x.id === fileId)) {
            return [
              ...prev,
              {
                id: lookupFileId,
                imageObjectURL,
              },
            ];
          }

          return prev;
        });

        if (lookupFileId === fileId) setPreviewImage(imageObjectURL);
      } catch {
        setPreviewImageCounter((c) => c + 1);
      }
    }
  }, [fileId, previewImageCounter, previewImg, tempPreviewImages]);

  useEffect(() => {
    if (!previewImg) getPreviewThumbnailImage();
  }, [getPreviewThumbnailImage, previewImg]);

  // Check and get preview image
  useEffect(() => {
    if (!previewImg && fileId && previewImageCounter < previewImageLimitCall) {
      // Delay 6 sec. then call api get preview image only 5 times.
      setTimeout(() => {
        getPreviewThumbnailImage();
      }, delayTimeout);
    }
  }, [fileId, getPreviewThumbnailImage, previewImageCounter, previewImg]);

  const loadFileContent = async (id?: string): Promise<void> => {
    if (!id) return;
    setDocumentFile(undefined);
    const file = await downloadDocumentFiles([id], errorHandler);
    if (file) {
      setDocumentFile(file);
    }
  };

  const handleModalClose = (): void => setShowDocumentViewModal(false);

  const onFullViewClick = (): void => {
    loadFileContent(fileId);
    setShowDocumentViewModal(true);
  };

  const renderPreviewThumbnailSection = (): ReactElement => (
    <>
      <div className="section-label">{t('sidePanel.viewLabel')}</div>
      <DocumentFilePreviewer
        imageData={previewImg || ''}
        onFullViewClick={onFullViewClick}
      />
      <div className="section-divider" />
    </>
  );

  const renderContent = (): ReactElement => {
    if (!pendingTrayItem) {
      return (
        <div className="nothing-selected">
          <div className="title">{t('sidePanel.noSelectionTitle')}</div>
          <div>{t('sidePanel.noSelectionDescription')}</div>
        </div>
      );
    }

    return (
      <>
        <Toaster />
        <ReactTooltip effect="solid" multiline />
        <DocumentViewModal
          show={showDocumentViewModal}
          onClose={(): void => handleModalClose()}
        >
          <ArchiveDocumentSection
            selectedFile={documentFile}
            // Pending tray files do not yet have metadata associated with it
            metadata={emptyMetadata}
            displayName={
              pendingTrayItem
                ? pendingTrayItem.fileName
                : t('documentTitleBar.undefinedValue')
            }
            documentName={pendingTrayItem.fileName}
            onCloseModal={(): void => handleModalClose()}
            showDownloadButton={false}
            showArchiveButton={false}
            onCompletedArchive={undefined}
            uploadedContents={undefined}
            onError={undefined}
            collapserPosition={CollapserPositionVariant.WithTitleBarLarge}
          />
        </DocumentViewModal>
        {renderPreviewThumbnailSection()}
        <div className="section-label">{t('sidePanel.documentTypeLabel')}</div>
        <div className="description-label">
          {t('sidePanel.documentTypeDescription')}
          <img
            src={TipIcon}
            alt=""
            data-tip={t('sidePanel.documentTypeTooltipDescription')}
          />
        </div>
        <div className="document-type">
          <SDCDropdown
            values={documentTypes}
            currentItem={documentTypes.find(
              (x) => x.Value === pendingTrayItem.documentType
            )}
            onSelect={onSelected}
          />
        </div>
        <div className="section-divider" />

        <div className="section-label">{t('sidePanel.tagLabelHeader')}</div>
        <div className="description-label">
          {t('sidePanel.tagLabel')}
          <img
            src={TipIcon}
            alt=""
            data-tip={t('sidePanel.tagTooltipDescription')}
          />
        </div>
        <div className="tag-section">
          <Tag
            tags={tags}
            onTagAdd={handleAddTag}
            onTagRemove={handleRemoveTag}
          />
        </div>
      </>
    );
  };
  return (
    <span className="pending-tray-side-panel">
      <SidePanel
        collapserPosition={CollapserPositionVariant.WithTitleBarAndTaskBar}
        resizable
      >
        {renderContent()}
      </SidePanel>
    </span>
  );
};

PendingTraySidePanel.defaultProps = {
  pendingTrayItem: undefined,
  fileId: undefined,
};

export default PendingTraySidePanel;
