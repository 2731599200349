import storageKey from '../../common/constants/storageKey.constants';
import CodeSetting, {
  DefaultCodeSetting,
} from '../../common/models/codeSetting';
import StorageProvider from '../../common/providers/storageProvider';

export const getCodeSetting = (): CodeSetting => {
  const localStorage = new StorageProvider();

  const setting = localStorage.getItem(storageKey.BARCODE_SETTING);
  if (setting) {
    return JSON.parse(setting);
  }
  return DefaultCodeSetting;
};

export const setCodeSetting = (setting: CodeSetting): void => {
  const localStorage = new StorageProvider();
  localStorage.setItem(storageKey.BARCODE_SETTING, JSON.stringify(setting));
};
