import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import lengths from '../common/constants/lengths.constant';
import MaskOptions from '../common/models/maskOptions';
import DataType from '../common/types/dataType';
import UserSettings from '../services/user/models/userSettings';
import { isNumeric } from './stringUtil';

const iso8601Format = 'yyyy-MM-ddTHH:mm:ssz';

/**
 * Validates if the value is valid for the given data type.
 * @param value
 * @param type
 * @param settings
 * @returns
 */
export const validateDataType = (
  value: string | undefined,
  type: DataType,
  settings: UserSettings | undefined
): boolean => {
  if (value === undefined || value === null) {
    return false;
  }

  if (value && type === DataType.Decimal) {
    return isNumeric(value);
  }

  if (settings) {
    if (type === DataType.DateTime) {
      dayjs.extend(customParseFormat);
      return dayjs(value, settings.dateTimeFormat, true).isValid();
    }
    if (type === DataType.Date) {
      dayjs.extend(customParseFormat);
      return dayjs(value, settings.dateFormat, true).isValid();
    }
  } else {
    if (type === DataType.DateTime) {
      return dayjs(value, iso8601Format).isValid();
    }
    if (type === DataType.Date) {
      dayjs.extend(customParseFormat);
      return dayjs(value, iso8601Format).isValid();
    }
  }

  return true;
};

export const toDate = (value: string, settings: UserSettings): Date => {
  dayjs.extend(customParseFormat);
  dayjs.extend(utc);
  return dayjs.utc(value, settings.dateFormat, true).toDate();
};

export const toDateTime = (value: string, settings: UserSettings): Date => {
  dayjs.extend(customParseFormat);
  dayjs.extend(utc);
  return dayjs.utc(value, settings.dateTimeFormat, true).toDate();
};

/**
 * Format the value for the given data type.
 * @param value
 * @param type
 * @param settings
 * @returns
 */
export const formatValue = (
  value: string | undefined | null,
  type: DataType,
  settings: UserSettings
): string => {
  if (value === undefined || value === null) {
    return '';
  }

  if (type === DataType.DateTime) {
    const format = settings.dateTimeFormat;

    dayjs.extend(utc);
    return dayjs.utc(value).format(format);
  }
  if (type === DataType.Date) {
    const format = settings.dateFormat;

    dayjs.extend(utc);
    return dayjs.utc(value).format(format);
  }

  return value;
};

/**
 * Validates the string lengh based on the data type.
 * @param value
 * @param type
 * @returns
 */
export const validateLength = (
  value: string | undefined | null,
  type: DataType
): boolean => {
  // This function is not to validate undefined values
  if (value === null || value === undefined) {
    return true;
  }

  if (type === DataType.String) {
    return value.length <= lengths.metadataValue;
  }
  if (type === DataType.Decimal) {
    return value.length <= lengths.number;
  }
  return true;
};

/**
 * Gets mask options for the data type
 * @param dataType
 * @param maskString
 * @returns
 */
export const getMaskOptions = (
  dataType: DataType,
  maskString: string | undefined
): MaskOptions | undefined => {
  if (dataType === DataType.Date || dataType === DataType.DateTime) {
    return {
      maskText: maskString,
      maskTextMapping: {
        D: /\d/,
        d: /\d/,
        M: /\d/,
        m: /\d/,
        Y: /\d/,
        y: /\d/,
        H: /\d/,
        h: /\d/,
        s: /\d/,
      },
    } as MaskOptions;
  }

  if (dataType === DataType.YearTimespan) {
    return {
      maskText: undefined,
      maskTextMapping: undefined,
      maskFilter: /^\d{0,2}$/,
    } as MaskOptions;
  }

  return undefined;
};
