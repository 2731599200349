import { ReactElement, useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Usage from '../../services/subscription/models/usage';
import './UsageCard.scss';

interface UsageCardProps {
  title: string;
  usage: Usage;
  converter?: (value: number) => string;
}

const UsageCard = ({
  title,
  usage,
  converter,
}: UsageCardProps): ReactElement => {
  const { t } = useTranslation();
  const [percentage, setPercentage] = useState<number>(0);
  const percentMultiplier = 100.0;

  useEffect(() => {
    const percentValue = (usage.value / usage.limit) * percentMultiplier;
    setPercentage(percentValue);
  }, [usage]);

  return (
    <div className="usage-card">
      <div>{title}</div>
      <div>
        {t('subscriptionScreen.usageFormat', {
          value: converter ? converter(usage.value) : usage.value,
          limit: converter ? converter(usage.limit) : usage.limit,
        })}
      </div>
      <div />
      <div className="progress-container">
        <ProgressBar now={percentage} />
      </div>
    </div>
  );
};

UsageCard.defaultProps = {
  converter: (value: number): string => `${value}`,
};

export default UsageCard;
