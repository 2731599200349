// A variant is meant to be the class for the dropdown
// We have various dropdowns, if they want to opt-in, they have to adjust accordingly.
enum DropdownVariant {
  /**
   * Empty class, default style
   */
  Default = '',
  /**
   * Info style, specifically a blue caret
   */
  Info = 'info',
}
export default DropdownVariant;
