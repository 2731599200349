import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import CollapseArrow from '../../assets/svg/CollapseArrow.svg';
import CollapserIcon from '../../assets/svg/CollapserIcon.svg';
import './HelpSidePanel.scss';

interface HelpSidePanelProps {
  title: string;
  children: ReactElement;
  image?: string;
  show: boolean;
  onClose: () => void;
}

const HelpSidePanel = ({
  title,
  image,
  children,
  show,
  onClose,
}: HelpSidePanelProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <div className={`help-side-panel ${show ? 'show' : 'hide'}`}>
      <div className="header">
        <div>{t('helpSidePanel.header')}</div>
        <div>
          <button type="button" onClick={onClose}>
            <div className="frame">
              <img className="box" src={CollapserIcon} alt="" />
              <img className="arrow" src={CollapseArrow} alt="" />
            </div>
          </button>
        </div>
      </div>

      <div className="content">
        <div className="title">{title}</div>
        {image !== undefined ? <img src={image} alt="" /> : null}
        {children}
      </div>
    </div>
  );
};

export default HelpSidePanel;

HelpSidePanel.defaultProps = {
  image: undefined,
};
