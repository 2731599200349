const toSnakeCase = (str: string): string =>
  str
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();

const toUrlFormat = (key: string, val: string): string =>
  `${encodeURIComponent(toSnakeCase(key))}=${encodeURIComponent(val)}`;

const toUrlEncoded = (obj: Record<string, unknown>): string =>
  Object.keys(obj)
    .map((k) => {
      const value = obj[k];
      if (typeof value === 'string') {
        return toUrlFormat(k, value);
      }
      if (typeof value === 'number') {
        return toUrlFormat(k, value.toString());
      }
      if (typeof value === 'bigint') {
        return toUrlFormat(k, value.toString());
      }
      if (typeof value === 'boolean') {
        return toUrlFormat(k, value.toString());
      }
      throw new Error('Invalid Data Type.');
    })
    .join('&');

export default toUrlEncoded;
