/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { ReactComponent as CloseIcon } from '../../assets/svg/CloseIcon.svg';
import barcode from '../../common/constants/barcode.constant';
import Code from '../../common/models/code';
import CodeSetting from '../../common/models/codeSetting';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import SizeUnit from '../../common/types/sizeUnit';
import UserPermission from '../../common/types/userPermission';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import { getCodeSeparator } from '../../services/code/code.service';
import { getCodeSetting } from '../../services/userSetting/userSetting.service';
import userHasPermission from '../../utils/userUtil';
import BarcodePreviewer from '../BarcodePreviewer/BarcodePreviewer';
import QRCodePreviewer from '../BarcodePreviewer/QRCodePreviewer';
import CodeSettingPanel from '../CodeSettingPanel/CodeSettingPanel';
import DefaultLoading from '../DefaultLoading/DefaultLoading';
import SDCButton from '../SDCButton/SDCButton';
import './PrintSeparateCodeModal.scss';

interface PrintSeparateQRCodeModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: () => void;
}

const PrintSeparateQRCodeModal = ({
  show,
  setShow,
  onSubmit,
}: PrintSeparateQRCodeModalProps): ReactElement => {
  const { t } = useTranslation();
  const codeRef = useRef(null);
  const { currentUser } = useCurrentUser();
  const [code, setCode] = useState<Code | undefined>();
  const [codeSetting, setCodeSetting] = useState<CodeSetting>(() =>
    getCodeSetting()
  );
  const [showLoading, setShowLoading] = useState(true);

  const createPageStyle = useCallback((): string => {
    const unit = codeSetting.sizeUnit === SizeUnit.Inch ? 'in' : 'mm';
    const width = `${codeSetting.width}${unit}`;
    const height = `${codeSetting.height}${unit}`;
    const pageStyle = `
      @media print {
        @page {
          background-color: white;
        }        
      }
      svg {
        width: ${width};
        height: ${height};
      }
    `;
    return pageStyle;
  }, [codeSetting]);

  const [printingPageStyle, setPrintingPageStyle] = useState<string>(() =>
    createPageStyle()
  );

  useEffect(() => {
    setPrintingPageStyle(createPageStyle());
  }, [codeSetting, createPageStyle]);

  useEffect(() => {
    if (!userHasPermission(currentUser, UserPermission.CaptureDocuments)) {
      return;
    }

    getCodeSeparator()
      .then((response) => {
        setCode({
          value: response.barcode,
          description: response.barcodeDescription,
        });
      })
      .finally(() => {
        setShowLoading(false);
      });
  }, [currentUser]);

  const reactToPrintContent = useCallback(() => codeRef.current, [codeRef]);

  const renderBarcodePreviewer = (): ReactElement => {
    if (codeSetting.type === barcode.CODE_39) {
      return (
        <BarcodePreviewer
          barcode={code}
          barcodeRef={codeRef}
          setting={codeSetting}
        />
      );
    }
    return (
      <QRCodePreviewer
        qrCode={code}
        qrCodeRef={codeRef}
        setting={codeSetting}
      />
    );
  };

  const print = useReactToPrint({
    content: reactToPrintContent,
    removeAfterPrint: true,
    copyStyles: false,
    pageStyle: printingPageStyle,
  });

  if (!show) return <></>;

  return (
    <>
      {showLoading && <DefaultLoading />}
      <Modal
        size="lg"
        show={show}
        dialogClassName="code-separate-modal-container"
        centered
        onHide={(): void => setShow(false)}
      >
        <Modal.Header>
          <Modal.Title>{t('codeSeparatorModal.header')}</Modal.Title>
          <button
            type="button"
            className="close-button"
            onClick={(): void => {
              setShow(false);
            }}
          >
            <CloseIcon width={22} height={22} />
          </button>
        </Modal.Header>
        <div className="invitation-modal">
          <Modal.Body>
            <div className="preview-qr-label">
              {t('codeSeparatorModal.subheader')}
            </div>
            <div className="qr-container">{renderBarcodePreviewer()}</div>
            <CodeSettingPanel
              setting={codeSetting}
              setSetting={setCodeSetting}
              isSeparate
            />
          </Modal.Body>
        </div>
        <Modal.Footer className="invite-footer">
          <SDCButton
            text={t('codeSeparatorModal.cancelButton')}
            onClick={(): void => setShow(false)}
            variant={SDCButtonVariant.Link}
          />
          <SDCButton
            text={t('codeSeparatorModal.printButton')}
            onClick={(): void => print()}
            variant={SDCButtonVariant.Primary}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PrintSeparateQRCodeModal;
