import { ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import './App.scss';
import { reactPlugin } from './appInsights';
import HistoryProvider from './common/providers/historyProvider';
import LocationProvider from './common/providers/locationProvider';
import StorageProvider from './common/providers/storageProvider';
import HttpStatusCode from './common/types/httpStatusCode';
import AppInsightsProvider from './components/AppInsights/AppInsightsProvider';
import AuthProvider from './components/Auth/AuthProvider';
import ContainerProvider from './components/Container/ContainerProvider';
import CurrentUserProvider from './components/CurrentUser/CurrentUserProvider';
import ErrorFallback from './components/ErrorFallback/ErrorFallback';
import HotjarTracking from './components/HotjarTracking/HotjarTracking';
import Header from './components/Layout/Header/Header';
import ProviderComposer, {
  contextProvider,
} from './components/ProviderComposer/ProviderComposer';
import PrivateRoute from './components/Route/PrivateRoute';
import SignalRProvider from './components/SignalR/SignalRProvider';
import TenantProvider from './components/Tenant/TenantProvider';
import './i18n';
import AuthService from './services/auth/auth.service';
import getAuthInfo from './services/auth/authInfo';
import AuthStorage from './services/auth/authStorage';
import AuthServiceProps from './services/auth/models/authServiceProps';
import AccessKeyPage from './views/AccessKey/AccessKeyPage';
import CapturePage from './views/Capture/CapturePage';
import CodePage from './views/Code/CodePage';
import ContainerManagementPage from './views/ContainerManagement/ContainerManagementPage';
import DefaultPage from './views/Default/DefaultPage';
import DocumentProtectionPage from './views/DocumentProtection/DocumentProtectionPage';
import DocumentSearchPage from './views/DocumentSearch/DocumentSearchPage';
import DocumentViewPage from './views/DocumentView/DocumentViewPage';
import EntityViewPage from './views/EntityView/EntityViewPage';
import EntityViewFromKeyValuePage from './views/EntityViewFromKeyValue/EntityViewFromKeyValuePage';
import ErrorPage from './views/Errors/ErrorPage';
import PendingTrayPage from './views/PendingTray/PendingTrayPage';
import StyleSheetPage from './views/StyleSheet/StyleSheetPage';
import SubscriptionPage from './views/Subscription/SubscriptionPage';
import TeamManagementPage from './views/TeamManagement/TeamManagementPage';
import TrashPage from './views/Trash/TrashPage';

const { clientId, provider, audience, redirectUri } = getAuthInfo();
const authServiceBody: AuthServiceProps = {
  clientId,
  provider,
  audience,
  redirectUri,
};
const storageProvider = new StorageProvider();
const authStorage = new AuthStorage(storageProvider);
const locationProvider = new LocationProvider();
const historyProvider = new HistoryProvider();
const authService = new AuthService(
  authServiceBody,
  authStorage,
  locationProvider,
  historyProvider
);

const checkAuthorization = async (): Promise<void> => {
  const redirectUrl = await authService.initialize();
  if (redirectUrl) {
    locationProvider.replace(redirectUrl);
  }
};

if (
  !locationProvider.pathName().endsWith('health') &&
  !locationProvider.pathName().endsWith('stylesheet')
) {
  checkAuthorization();
}

// We can use auth provider to get authentication context, e.g. role claim.
const App = (): ReactElement => (
  <ProviderComposer
    providers={[
      contextProvider(AppInsightsProvider, { reactPlugin }),
      contextProvider(AuthProvider, { authService }),
      contextProvider(ErrorBoundary, {
        FallbackComponent: ErrorFallback,
      }),
      contextProvider(TenantProvider),
      contextProvider(CurrentUserProvider),
      contextProvider(ContainerProvider),
      contextProvider(SignalRProvider),
    ]}
  >
    <Router>
      <HotjarTracking />
      <Header />
      <div className="fullscreen">
        <Switch>
          <Route path="/health">
            <span>Healthy</span>
          </Route>
          <PrivateRoute path="/" exact component={DefaultPage} />
          <PrivateRoute
            path="/documents/capture/:captureId"
            component={CapturePage}
          />
          <PrivateRoute
            path="/documents/entities/:entityId"
            component={EntityViewPage}
          />
          <PrivateRoute path="/documents" component={EntityViewFromKeyValuePage} />
          <PrivateRoute
            path="/documents/:archivedObjectInfoId"
            component={DocumentViewPage}
          />
          <PrivateRoute path="/search" component={DocumentSearchPage} />
          <PrivateRoute path="/pending-tray" component={PendingTrayPage} />
          <PrivateRoute
            path="/team-management"
            component={TeamManagementPage}
          />
          <PrivateRoute path="/api-access" component={AccessKeyPage} />
          <PrivateRoute path="/trash" component={TrashPage} />
          <PrivateRoute
            path="/container-management"
            component={ContainerManagementPage}
          />
          <PrivateRoute
            path="/document-protection"
            component={DocumentProtectionPage}
          />
          <PrivateRoute path="/subscriptions" component={SubscriptionPage} />
          <PrivateRoute
            path="/documents/registration/:id"
            component={CodePage}
          />
          {process.env.REACT_APP_DEVELOPMENT === 'Development' && (
            <Route path="/stylesheet" component={StyleSheetPage} />
          )}
          <Route
            path="/error/:httpStatusCode?/(code)?/:errorCode?"
            component={ErrorPage}
          />
          <Redirect to={`/error/${HttpStatusCode.NotFound.toString()}`} />
        </Switch>
      </div>
    </Router>
  </ProviderComposer>
);

export default App;
