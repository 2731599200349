import { HubConnectionState } from '@microsoft/signalr';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GlobeIcon } from '../../assets/svg/GlobeIcon.svg';
import { ReactComponent as LockIcon } from '../../assets/svg/LockIcon.svg';
import { ReactComponent as PlusIcon } from '../../assets/svg/PlusIcon.svg';
import { ReactComponent as QRCodeIcon } from '../../assets/svg/QRCodeIcon.svg';
import getDefaultPageSize from '../../common/configurations/pageConfig';
import storageKey from '../../common/constants/storageKey.constants';
import dateFormat from '../../common/dateFormat';
import CompletedArchiveDocument from '../../common/models/completedArchiveDocument';
import DocumentSearchOptions from '../../common/models/documentSearchOptions';
import KeyPairItem from '../../common/models/keypairItem';
import UploadFileWithStatus from '../../common/models/uploadFileWithStatus';
import StorageProvider from '../../common/providers/storageProvider';
import ConfirmationModalVariant from '../../common/types/confirmationModalVariant';
import dropdownValue from '../../common/types/dropdownValue';
import FileUploadStatus from '../../common/types/fileUploadStatus';
import NotificationVariant from '../../common/types/notificationVariant';
import PendingTrayFileProgressTracking from '../../common/types/pendingTrayFileProgressTracking';
import PendingTrayFileState from '../../common/types/pendingTrayFileState';
import PendingTrayFileStatus from '../../common/types/pendingTrayFileStatus';
import PendingTrayUploadingStatus from '../../common/types/pendingTrayUploadingStatus';
import SDCButtonSize from '../../common/types/sdcButtonSize';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import SortItemKey from '../../common/types/sortItemKey';
import TaskBarSize from '../../common/types/taskBarSize';
import Tray from '../../common/types/tray';
import UserPermission from '../../common/types/userPermission';
import DefaultLoading from '../../components/DefaultLoading/DefaultLoading';
import ConfirmationModal from '../../components/DefaultModal/ConfirmationModal';
import PaginationNavigator from '../../components/Pagination/PaginationNavigator';
import PendingTrayAutoArchiveSuccessFooter from '../../components/PendingTrayAutoArchiveSuccessFooter/PendingTrayAutoArchiveSuccessFooter';
import PendingTrayUploadingStickyDialog from '../../components/PendingTrayUploadingStickyDialog/PendingTrayUploadingStickyDialog';
import PrintSeparateQRCodeModal from '../../components/PrintSeparateCodeModal/PrintSeparateCodeModal';
import RestrictedArea from '../../components/RestrictedArea/RestrictedArea';
import ResultPendingTrayList from '../../components/ResultPendingTrayList/ResultPendingTrayList';
import SDCButton from '../../components/SDCButton/SDCButton';
import PendingTraySidePanel from '../../components/SidePanel/PendingTraySidePanel';
import Tab from '../../components/Tab/Tab';
import TaskBar from '../../components/TaskBar/TaskBar';
import Toaster from '../../components/Toaster/Toaster';
import showToaster from '../../components/Toaster/ToasterProvider';
import { useContainer } from '../../context/Container/ContainerContext';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import { useSignalR } from '../../context/SignalR/SignalRContext';
import { useTenant } from '../../context/Tenant/TenantContext';
import useDefaultDestinationUser from '../../hooks/useDefaultDestinationUser/useDefaultDestinationUser';
import useInterval from '../../hooks/useInterval/useInterval';
import usePendingTrayResult from '../../hooks/usePendingTrayResult/usePendingTrayResult';
import usePendingTrayScope from '../../hooks/usePendingTrayScope/usePendingTrayScope';
import usePrivatePendingTrayCount from '../../hooks/usePrivatePendingTrayCount/usePrivatePendingTrayCount';
import usePublicPendingTrayCount from '../../hooks/usePublicPendingTrayCount/usePublicPendingTrayCount';
import { getActiveDocumentTypes } from '../../services/documentType/documentType.service';
import DocumentType from '../../services/documentType/models/documentType';
import PendingTrayItemResponse from '../../services/pendingTray/models/pendingTrayItemResponse';
import PendingTrayUploadResponse from '../../services/pendingTray/models/pendingTrayUploadResponse';
import {
  getPendingTray,
  getPendingTrayItemTags,
  getPendingTrayUploadStatuses,
  updatePendingTray,
} from '../../services/pendingTray/pendingTray.service';
import { formatFileSetToHaveStatus } from '../../utils/fileUtil';
import formatDateTime from '../../utils/i18n-date-format';
import { createEmptyUUID } from '../../utils/stringUtil';
import userHasPermission from '../../utils/userUtil';
import './PendingTrayPage.scss';

const PendingTrayPage = (): ReactElement => {
  const emptyUUID = createEmptyUUID();
  const storage = useMemo(() => new StorageProvider(), []);
  const { t } = useTranslation();
  const { supportedFileTypes } = useTenant();
  const { currentUser } = useCurrentUser();
  const { containerKey } = useContainer();
  const { connectionState } = useSignalR();
  const [privateTrayCount, setPrivateTrayCount] =
    usePrivatePendingTrayCount(containerKey);
  const [publicTrayCount, setPublicTrayCount] =
    usePublicPendingTrayCount(containerKey);

  const fileTypeFilter = supportedFileTypes.join(',');
  const abortControllerRef = useRef<AbortController>(new AbortController());

  const [showLoading, setShowLoading] = useState(false);
  const [isSearchingResults, setIsSearchingResults] = useState<boolean>(false);
  const [isDeletingFile, setIsDeletingFile] = useState<boolean>(false);
  const [isMovingFile, setIsMovingFile] = useState<boolean>(false);
  const [fileProgressTrackings, setFileProgressTrackings] = useState<
    Array<PendingTrayFileProgressTracking>
  >((): PendingTrayFileProgressTracking[] => {
    const savedFilesJSON = storage.getItem(
      storageKey.PENDING_TRAY_FILES_PROGRESS
    );
    if (savedFilesJSON) {
      const savedFiles = JSON.parse(
        savedFilesJSON
      ) as PendingTrayFileProgressTracking[];
      return savedFiles.filter((x) => x.shouldBeRemoved === false);
    }
    return [];
  });

  // Files
  const [files, setFiles] = useState<Array<UploadFileWithStatus>>([]);
  const [duplicatedNameFiles, setDuplicatedNameFiles] = useState<
    Array<UploadFileWithStatus>
  >([]);

  const [documentTags, setDocumentTags] = React.useState<string[]>([]);
  const [checkedDocumentIds, setCheckedDocumentIds] = useState<string[]>([]);
  const [selectedDocumentId, setSelectedDocumentId] = useState('');

  // Document search options
  const [currentTab, setCurrentTab] = usePendingTrayScope();
  const defaultPageSize = getDefaultPageSize();
  const firstPage = 1;
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
  const documentTypeDropdownItems = ((): KeyPairItem[] => {
    const dropdownItems = documentTypes.map(
      (item) =>
        ({
          Name: item.displayName,
          Value: item.name,
        } as KeyPairItem)
    );
    const items = [
      {
        Name: t('pendingTrayPage.unclassifiedOption'),
        Value: dropdownValue.Unclassified,
      },
      ...dropdownItems,
    ];
    return items;
  })();

  const defaultSearchOptions: DocumentSearchOptions = {
    scope: currentTab,
    page: firstPage,
    pageSize: defaultPageSize,
    sortBy: `${SortItemKey.Date}_descending`,
  };
  const [searchOptions, setSearchOptions] = useState<DocumentSearchOptions>({
    scope: currentTab,
    page: firstPage,
    pageSize: defaultPageSize,
    sortBy: `${SortItemKey.Date}_descending`,
  });
  const [pendingTrayItems, setPendingTrayItems, isSearching] =
    usePendingTrayResult(searchOptions, containerKey);
  const pendingTrayItem = pendingTrayItems.results.find(
    (x) => x.id === selectedDocumentId
  );

  const defaultDestinationUser = useDefaultDestinationUser(currentTab);
  const [selectedUserDestination, setSelectedUserDestination] =
    useState<KeyPairItem>(defaultDestinationUser);

  // Complete
  const [archiveDocumentCompletedList, setArchiveDocumentCompletedList] =
    useState<CompletedArchiveDocument[]>([]);
  const [showCompletedNotification, setShowCompletedNotification] =
    useState(false);
  const [showCompletedArchiveList, setShowCompletedArchiveList] =
    useState(false);

  // Visible control
  const [isStickyModalVisible, setIsStickyModalVisible] = useState(false);
  const [
    isPrintSeparateBarcodeModalVisible,
    setIsPrintSeparateBarcodeModalVisible,
  ] = useState(false);
  const [
    isUploadFileConfirmationModalVisible,
    setIsUploadFileConfirmationModalVisible,
  ] = useState(false);

  const handleError = useErrorHandler();
  const inputFile = useRef<HTMLInputElement>(null);

  const loadPendingTray = useCallback(
    (withLoadingScreen: boolean) => {
      let ignore = false;
      if (withLoadingScreen) setIsSearchingResults(true);

      // Get records
      getPendingTray(
        searchOptions,
        containerKey,
        abortControllerRef.current.signal,
        handleError
      )
        .then((response) => {
          if (!ignore) {
            setPendingTrayItems(response);
          }
        })
        .finally(() => {
          if (withLoadingScreen) setIsSearchingResults(false);
        });

      // Get private tray counts
      getPendingTray(
        {
          scope: Tray.Private,
          page: firstPage,
          pageSize: 1,
        },
        containerKey
      ).then((response) => {
        setPrivateTrayCount(response.totalCount);
      });

      // Get public tray counts
      getPendingTray(
        {
          scope: Tray.Public,
          page: firstPage,
          pageSize: 1,
        },
        containerKey
      ).then((response) => {
        setPublicTrayCount(response.totalCount);
      });

      return (): void => {
        ignore = true;
      };
    },
    [
      containerKey,
      handleError,
      searchOptions,
      setPendingTrayItems,
      setPrivateTrayCount,
      setPublicTrayCount,
    ]
  );

  const onTrayTabChange = (tabValue: string | undefined): void => {
    if (currentTab === tabValue || !tabValue) return;
    setCurrentTab(tabValue);
    setSearchOptions((currentSearchOptions) => ({
      ...currentSearchOptions,
      scope: tabValue,
    }));
    setSelectedDocumentId('');
    setCheckedDocumentIds([]);

    storage.setItem(storageKey.PENDING_TRAY_SCOPE, tabValue);
  };

  const startProgressTracking = useCallback(
    (uploadResponse: PendingTrayUploadResponse): void => {
      setFileProgressTrackings((prev) => [
        ...prev,
        {
          id: uploadResponse.id,
          name: uploadResponse.fileName,
          newName: '',
          scope: uploadResponse.scope,
          status: PendingTrayFileStatus.Processing,
          state: PendingTrayFileState.Uploaded,
          shouldBeRemoved: false,
          counter: 0,
        },
      ]);
    },
    []
  );

  const classifyDocument = (item: KeyPairItem, id: string): void => {
    if (!id) {
      return;
    }

    updatePendingTray(id, {
      documentType:
        item.Value === dropdownValue.Unclassified || !item.Value
          ? null
          : item.Value,
    })
      .then(() => {
        const message =
          item.Value === dropdownValue.Unclassified
            ? t('pendingTrayPage.unclassifiedTemplateNotificationMessage')
            : t('pendingTrayPage.changeTemplateNotificationMessage', {
                name: item.Name,
              });

        showToaster(
          t('pendingTrayPage.changeTemplateNotificationTitle'),
          message,
          NotificationVariant.PrimarySuccess
        );
      })
      .catch(() => {
        showToaster(
          t('pendingTrayPage.errorNotificationTitle'),
          t('pendingTrayPage.changeTemplateErrorNotificationMessage'),
          NotificationVariant.PrimaryDanger
        );
      })
      .finally(() => {
        loadPendingTray(false);
      });
  };

  const onClassifyTemplate = (item: KeyPairItem): void => {
    // Classify the selected ones if any
    // Otherwise, classify the current selected one.
    const idsToUpdates = checkedDocumentIds.length
      ? checkedDocumentIds
      : [selectedDocumentId];
    idsToUpdates.forEach((pendingTrayItemId) => {
      classifyDocument(item, pendingTrayItemId);
    });
  };

  const addNewItemIfNotSearched = (items: PendingTrayItemResponse[]): void => {
    // Add items only if the user hasn't searched
    if (
      searchOptions.documentType ||
      searchOptions.page !== defaultSearchOptions.page ||
      searchOptions.sortBy !== defaultSearchOptions.sortBy
    ) {
      return;
    }

    setPendingTrayItems((prev) => {
      const newItems = items.filter(
        (x) => !prev.results.find((y) => y.id === x.id)
      );
      return {
        ...prev,
        results: prev.results.concat(newItems),
        totalCount: prev.totalCount + newItems.length,
      };
    });
  };

  const addCompletedItems = (
    statuses: Array<PendingTrayUploadingStatus>
  ): void => {
    const pendingItems = statuses.filter(
      (x) =>
        x.status === PendingTrayFileStatus.Done &&
        (x.state === PendingTrayFileState.Pending ||
          x.state === PendingTrayFileState.Splitted)
    );

    const addingItems: Array<PendingTrayItemResponse> = pendingItems
      .map((x) => ({
        id: x.newPendingTrayDocumentId ?? x.pendingTrayDocumentId,
        fileName: x.newFileName ?? x.fileName,
        documentType: '',
        createdOn: new Date(),
        modifiedOn: new Date(),
        createdBy: currentUser.name,
        createdByUserId: currentUser.id,
        userId: x.scopeUserId,
      }))
      .filter(
        (x) =>
          (currentTab === Tray.Private && x.userId === currentUser.id) ||
          (currentTab === Tray.Public && x.userId === emptyUUID)
      );

    addNewItemIfNotSearched(addingItems);

    const newItemsInPrivateTrayCount = addingItems.filter(
      (x) => x.userId === currentUser.id
    ).length;

    const newItemsInPublicTrayCount = addingItems.filter(
      (x) => x.userId === emptyUUID
    ).length;

    if (connectionState !== HubConnectionState.Connected) {
      setPrivateTrayCount((prev) => prev + newItemsInPrivateTrayCount);
      setPublicTrayCount((prev) => prev + newItemsInPublicTrayCount);
    }

    const archivedItems = statuses.filter(
      (x) =>
        x.status === PendingTrayFileStatus.Done &&
        x.state === PendingTrayFileState.Archived
    );

    if (archivedItems.length > 0) {
      setShowCompletedArchiveList(false);
      setShowCompletedNotification(true);
      setArchiveDocumentCompletedList((prev) => {
        const newItems = archivedItems.filter(
          (x) => !prev.find((y) => y.document.documentId === x.documentId)
        );

        return prev.concat(
          newItems.map((x) => ({
            file: undefined,
            archivedTime: formatDateTime(
              new Date(x.createdOn),
              'en',
              dateFormat.time
            ),
            document: {
              documentId: x.documentId,
              fileName: x.fileName,
              createdOn: x.createdOn,
            },
            metadata: undefined,
          }))
        );
      });
    }
  };

  const updateStatusTrackings = (
    statuses: Array<PendingTrayUploadingStatus>
  ): void => {
    setFileProgressTrackings((prev) => {
      const doneByPendingItems = statuses.filter(
        (x) =>
          x.status === PendingTrayFileStatus.Done &&
          x.state === PendingTrayFileState.Pending
      );

      const doneByArchivedItems = statuses.filter(
        (x) =>
          x.status === PendingTrayFileStatus.Done &&
          x.state === PendingTrayFileState.Archived
      );
      // Remove from the local so that it won't show next time
      const toRemove = prev.filter(
        (f) =>
          doneByPendingItems.find((x) => x.pendingTrayDocumentId === f.id) ||
          doneByArchivedItems.find(
            (x) => x.pendingTrayDocumentId === f.id && f.shouldBeRemoved
          )
      );

      const updatedItems = prev.filter(
        (x) => !toRemove.find((y) => y.id === x.id)
      );
      const result = updatedItems.map((x) => {
        const item = x;
        const matched = statuses.find((y) => x.id === y.pendingTrayDocumentId);
        if (matched) {
          item.state = matched.state;
          item.status = matched.status;
          item.counter += 1;
        }
        return item;
      });

      return result.filter((x) => x.shouldBeRemoved === false);
    });
  };

  useInterval(async () => {
    const findingStatusIds = [...new Set(fileProgressTrackings)]
      .map((x) => x.id)
      .join(',');
    if (findingStatusIds) {
      const response = await getPendingTrayUploadStatuses(findingStatusIds);
      updateStatusTrackings(response);

      addCompletedItems(response);
    }
  }, 7000);

  // --------------------------------------- USE EFFECT ------------------------------------- //
  useEffect(() => {
    const beforeUnLoad = (): void => {
      storage.setItem(
        storageKey.PENDING_TRAY_FILES_PROGRESS,
        JSON.stringify(fileProgressTrackings)
      );
    };

    window.addEventListener('beforeunload', beforeUnLoad);

    return (): void => {
      window.removeEventListener('beforeunload', beforeUnLoad);
    };
  }, [fileProgressTrackings, storage]);

  useEffect(() => {
    getActiveDocumentTypes().then((response) => {
      setDocumentTypes(response);
    });
  }, []);

  useEffect(() => {
    const checkStickyVisibleCondition = (): boolean =>
      files.some(
        (f) =>
          f.status !== FileUploadStatus.SUCCESS &&
          f.status !== FileUploadStatus.ARCHIVED &&
          f.status !== FileUploadStatus.CANCELED
      );

    // Delay follow requirement display success then disappear after 3 sec.
    const visibleTimer = setTimeout(() => {
      if (!checkStickyVisibleCondition()) {
        setIsStickyModalVisible(false);
      }
    }, 3000);

    if (checkStickyVisibleCondition()) setIsStickyModalVisible(true);

    return (): void => clearTimeout(visibleTimer);
  }, [files]);

  // --------------------------------------- END USE EFFECT ------------------------------------- //

  // User click item in result list
  const itemClickHandler = async (
    item: PendingTrayItemResponse
  ): Promise<void> => {
    // If select the same one, do nothing
    if (JSON.stringify(pendingTrayItem) === JSON.stringify(item)) {
      return;
    }

    async function getTags(id: string): Promise<void> {
      const tags = await getPendingTrayItemTags(id);
      if (tags) {
        setDocumentTags(tags);
      }
    }

    getTags(item.id);
    setSelectedDocumentId(item.id);
  };

  // User select file for upload to tray
  const handleUploadToTray = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const event = e;
    const uploadFile: File[] = Array.from(
      event.target.files as ArrayLike<File>
    );
    if (!uploadFile) return;
    setIsStickyModalVisible(true);
    setFiles((currentFiles) =>
      formatFileSetToHaveStatus(uploadFile, currentFiles, false)
    );

    event.target.value = '';
  };

  const openFileDialog = (): void => {
    if (inputFile.current !== null) inputFile.current.click();
  };

  const getUserTrayName = (): string => {
    if (!currentUser.name) {
      return t('pendingTrayPage.myPrivateTrayTabTitle');
    }
    return t('pendingTrayPage.userProfileNamePrivateTrayTabTitle', {
      userProfileName: currentUser.name.split(' ')[0],
    });
  };

  const renderResults = (): ReactElement => (
    <div
      className={`result ${
        archiveDocumentCompletedList.length > 0 ? 'archived-list' : ''
      }`}
    >
      <ResultPendingTrayList
        itemResult={pendingTrayItems}
        onItemClick={itemClickHandler}
        documentTypes={documentTypes}
        searchOptions={searchOptions}
        setSearchOptions={setSearchOptions}
        isSearchingItem={isSearchingResults || isSearching}
        scope={currentTab}
        loadPendingTray={loadPendingTray}
        setIsDeletingFile={(value: boolean): void => {
          setIsDeletingFile(value);
        }}
        checkedDocumentIds={checkedDocumentIds}
        setCheckedDocumentIds={(value: Array<string>): void =>
          setCheckedDocumentIds(value)
        }
        selectedDocumentId={selectedDocumentId}
        setSelectedDocumentId={(value: string): void =>
          setSelectedDocumentId(value)
        }
        fileProgressTrackings={fileProgressTrackings}
        setFileProgressTrackings={setFileProgressTrackings}
        selectedUserDestination={selectedUserDestination}
        setSelectedUserDestination={setSelectedUserDestination}
        setFiles={setFiles}
        setIsMovingFile={setIsMovingFile}
      />
      <PaginationNavigator
        currentPage={searchOptions.page}
        lastPage={pendingTrayItems.lastPage}
        totalCount={pendingTrayItems.totalCount}
        pageSize={searchOptions.pageSize}
        onPageChange={(currentPage: number): void =>
          setSearchOptions((prevOptions) => ({
            ...prevOptions,
            page: currentPage,
          }))
        }
        descriptionTranslationKey={
          pendingTrayItems.totalCount > searchOptions.pageSize
            ? 'pendingTrayPage.paginationDescription_moreThanPageSize'
            : 'pendingTrayPage.paginationDescription_withinPageSize'
        }
      />
    </div>
  );

  if (!userHasPermission(currentUser, UserPermission.AccessPendingTray)) {
    return (
      <div className="pending-tray-container">
        <RestrictedArea />
      </div>
    );
  }

  return (
    <>
      {showLoading && <DefaultLoading />}
      {isMovingFile && (
        <DefaultLoading
          progressTranslationKey="defaultLoadingComponent.moving"
          successTranslationKey="defaultLoadingComponent.moveSuccess"
          errorTranslationKey="defaultLoadingComponent.moveError"
        />
      )}
      {isDeletingFile && (
        <DefaultLoading
          progressTranslationKey="defaultLoadingComponent.deleting"
          successTranslationKey="defaultLoadingComponent.deleteSuccess"
          errorTranslationKey="defaultLoadingComponent.deleteError"
        />
      )}
      <Toaster />
      <div className="pending-tray-container">
        <TaskBar
          headerPrefix=""
          actionText={
            currentTab === Tray.Private
              ? t('pendingTrayPage.uploadButtonTextPrivate', {
                  trayName: getUserTrayName(),
                })
              : t('pendingTrayPage.uploadButtonTextPublic')
          }
          actionOnClick={(): void => {
            openFileDialog();
          }}
          Icon={PlusIcon}
          size={TaskBarSize.Default}
          isShowButton={SDCButtonVariant.Warning}
          isHaveSideNavBar
          isSubNavbar
        >
          <Tab
            items={[
              {
                text: getUserTrayName(),
                value: Tray.Private,
                badgeText: `${privateTrayCount}`,
                Icon: LockIcon,
              },
              {
                text: t('pendingTrayPage.publicTrayTabTitle'),
                value: Tray.Public,
                badgeText: `${publicTrayCount}`,
                Icon: GlobeIcon,
              },
            ]}
            currentTabValue={currentTab}
            onChange={onTrayTabChange}
          />
          <div className="button-group">
            <SDCButton
              size={SDCButtonSize.Small}
              variant={SDCButtonVariant.Secondary}
              onClick={(): void => {
                setIsPrintSeparateBarcodeModalVisible(true);
              }}
              text={t('pendingTrayPage.printSeparatorButton')}
              Icon={QRCodeIcon}
            />
            <input
              className="hide-file-input"
              accept={fileTypeFilter}
              ref={inputFile}
              onChange={handleUploadToTray}
              type="file"
              multiple
            />
          </div>
        </TaskBar>
        <div className="view-container">
          <div className="main-content">
            {renderResults()}
            {archiveDocumentCompletedList.length !== 0 ? (
              <PendingTrayAutoArchiveSuccessFooter
                archiveCompleteFileList={archiveDocumentCompletedList}
                showCompletedNotification={showCompletedNotification}
                showCompletedArchiveList={showCompletedArchiveList}
                setShowCompletedArchiveList={setShowCompletedArchiveList}
                setShowCompletedNotification={setShowCompletedNotification}
                setShowLoading={setShowLoading}
              />
            ) : null}
          </div>
          <PendingTraySidePanel
            key={selectedDocumentId}
            documentTags={documentTags}
            pendingTrayItem={pendingTrayItem}
            fileId={selectedDocumentId}
            documentTypes={documentTypeDropdownItems}
            onTemplateChanged={onClassifyTemplate}
          />
        </div>

        {isPrintSeparateBarcodeModalVisible && (
          <PrintSeparateQRCodeModal
            show={isPrintSeparateBarcodeModalVisible}
            setShow={setIsPrintSeparateBarcodeModalVisible}
            onSubmit={(): void => {}}
          />
        )}
        {isStickyModalVisible && (
          <PendingTrayUploadingStickyDialog
            files={files}
            setFiles={setFiles}
            show
            onClose={(): void => {
              setFiles((f: Array<UploadFileWithStatus>) =>
                f.filter(
                  (fs) =>
                    fs.status !== FileUploadStatus.FAILED &&
                    fs.status !== FileUploadStatus.CANCELED
                )
              );
            }}
            setIsStickyModalVisible={setIsStickyModalVisible}
            startProgressTracking={startProgressTracking}
            setIsUploadFileConfirmationModalVisible={
              setIsUploadFileConfirmationModalVisible
            }
            setDuplicatedNameFiles={setDuplicatedNameFiles}
          />
        )}
        {isUploadFileConfirmationModalVisible && (
          <ConfirmationModal
            title={t('pendingTrayPage.uploadConfirmationModalTitle')}
            bodyText={t('pendingTrayPage.uploadConfirmationModalBody')}
            primaryButtonText={t(
              'pendingTrayPage.uploadConfirmationModalConfirmButton'
            )}
            dismissButtonText={t(
              'pendingTrayPage.uploadConfirmationModalCancelButton'
            )}
            show={isUploadFileConfirmationModalVisible}
            setShow={setIsUploadFileConfirmationModalVisible}
            onAnswer={(isYes: boolean): void => {
              if (isYes) {
                setFiles((currentFiles) =>
                  currentFiles.map((x) => {
                    const currentFile = x;
                    if (
                      currentFile.status === FileUploadStatus.FAILED &&
                      duplicatedNameFiles.find((y) => y.id === currentFile.id)
                    ) {
                      currentFile.force = true;
                      currentFile.status = FileUploadStatus.UPLOADING;
                    }
                    return currentFile;
                  })
                );
              } else {
                setDuplicatedNameFiles([]);
              }
            }}
            variant={ConfirmationModalVariant.PrimaryDefault}
            showRememberThisAnswer
            rememberKey={storageKey.FORCE_UPLOADING_FILE_IN_PENDING_TRAY}
          />
        )}
      </div>
    </>
  );
};

export default PendingTrayPage;
