import { ReactElement } from 'react';
import { ReactComponent as CheckMarkIcon } from '../../assets/svg/CheckMarkSolidIcon.svg';
import { ReactComponent as ExclamationMarkIcon } from '../../assets/svg/ExclamationMarkIcon.svg';
import { ReactComponent as InfoIcon } from '../../assets/svg/InfoIcon.svg';
import { ReactComponent as WarningSignIcon } from '../../assets/svg/WarningSignIcon.svg';
import NotificationVariant from '../../common/types/notificationVariant';
import './Toaster.scss';

interface ToasterContentProps {
  title: string;
  variant: NotificationVariant;
  message: string;
}

const successIconVariants = [
  NotificationVariant.PrimarySuccess,
  NotificationVariant.SecondarySuccess,
  NotificationVariant.SoftSuccess,
];
const infoIconVariants = [
  NotificationVariant.PrimaryBasic,
  NotificationVariant.PrimaryInfo,
  NotificationVariant.SecondaryBasic,
  NotificationVariant.SecondaryInfo,
  NotificationVariant.SoftBasic,
];
const warningIconVariants = [
  NotificationVariant.PrimaryWarning,
  NotificationVariant.SecondaryWarning,
  NotificationVariant.SoftWarning,
];
const dangerIconVariants = [
  NotificationVariant.SoftDanger,
  NotificationVariant.PrimaryDanger,
  NotificationVariant.SecondaryDanger,
];

const ToasterContent = ({
  title,
  message,
  variant,
}: ToasterContentProps): ReactElement => {
  const getIcon = (): JSX.Element => {
    if (successIconVariants.includes(variant)) {
      return <CheckMarkIcon />;
    }
    if (infoIconVariants.includes(variant)) {
      return <InfoIcon />;
    }
    if (warningIconVariants.includes(variant)) {
      return <WarningSignIcon />;
    }
    if (dangerIconVariants.includes(variant)) {
      return <ExclamationMarkIcon />;
    }

    return <InfoIcon />;
  };

  return (
    <div className="toaster-container">
      <div className={`toaster-content ${variant}`}>
        <div className="toaster-icon">{getIcon()}</div>
        <div>
          <div className="title">{title}</div>
          {message ? <div className="message">{message}</div> : null}
        </div>
      </div>
    </div>
  );
};

export default ToasterContent;
