import { ReactElement } from 'react';
import ReactTooltip from 'react-tooltip';
import itemListAction from '../../common/models/itemListAction';

interface ResultActionProps extends itemListAction {
  isDisabled?: boolean;
}

const ResultDocumentActionIcon = ({
  name,
  displayName,
  Icon,
  onAction,
  isDisabled,
}: ResultActionProps): ReactElement => (
  <>
    <ReactTooltip place="top" effect="solid" multiline />
    <button
      id={name}
      aria-label={name}
      className="icon-wrapper"
      type="button"
      onClick={(): void => onAction()}
      data-tip={displayName}
      disabled={isDisabled}
    >
      <Icon width="22px" height="18px" fill="#42495E" />
    </button>
  </>
);

ResultDocumentActionIcon.defaultProps = {
  isDisabled: false,
};

export default ResultDocumentActionIcon;
