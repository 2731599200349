import { ReactElement, useCallback, useEffect, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContainerScope from '../../common/types/containerScope';
import { useContainer } from '../../context/Container/ContainerContext';
import { getContainersAsync } from '../../services/container/container.service';
import Container from '../../services/container/models/container';
import './ContainerDropdown.scss';

interface ContainerDropdownProps {
  display?: ContainerScope;
}

const ContainerDropdown = ({
  display,
}: ContainerDropdownProps): ReactElement => {
  const { t } = useTranslation();
  // Containers
  const [containers, setContainers] = useState<Container[]>([]);

  const { containerKey, setContainerKey } = useContainer();
  // loading
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Selected Container
  const selectedContainer = containers.find((x) => x.key === containerKey);

  // Get containers
  const fetchContainers = useCallback(async () => {
    setIsLoading(true);
    const response = await getContainersAsync(1, 100);
    if (response != null) {
      // Any page that uses this component requires at least a container
      // otherwise redirect to forbidden
      if (!response.results.length) {
        window.location.href = '/error/403';
      }

      setContainers(response.results);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchContainers();
  }, [fetchContainers]);

  useEffect(() => {
    if (!selectedContainer && containers.length > 0) {
      setContainerKey('default-container');
    }
  }, [selectedContainer, setContainerKey, containers]);

  // Replace container with new container then refresh page
  const onChangeContainer = (containerItem: Container): void => {
    setContainerKey(containerItem.key);
  };

  const displayContainer = (): ReactElement => {
    if (display === ContainerScope.DropDown) {
      return (
        <NavDropdown
          title={selectedContainer ? selectedContainer.name : ''}
          id="containers-dropdown"
          className="nav-dropdown"
          data-hj-suppress
        >
          {containers.map((container: Container) => (
            <NavDropdown.Item
              key={container.key}
              className={
                container.name === selectedContainer?.name ? 'active' : ''
              }
              onClick={(): void => onChangeContainer(container)}
            >
              {container.name}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      );
    }
    return (
      <div className="container-text">
        {selectedContainer ? selectedContainer.name : ''}
      </div>
    );
  };

  return (
    <>
      {display !== ContainerScope.None ? (
        <>
          {isLoading ? (
            <div className="container-text">
              {t('containerDropdownComponent.loading')}
            </div>
          ) : (
            ''
          )}
          <span>
            {containers && containers.length ? displayContainer() : null}
          </span>
        </>
      ) : null}
    </>
  );
};

ContainerDropdown.defaultProps = {
  display: ContainerScope.None,
};

export default ContainerDropdown;
