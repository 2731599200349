import { MutableRefObject, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import PrintButton from '../PrintButton/PrintButton';
import './CodePageFooter.scss';

interface CodePageFooterProps {
  componentToPrintRef: MutableRefObject<null>;
  validateData: () => Promise<boolean>;
  onAfterPrint: () => Promise<void>;
  printingPageStyle: string | undefined;
}

const CodePageFooter = ({
  componentToPrintRef,
  validateData,
  printingPageStyle,
  onAfterPrint,
}: CodePageFooterProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="code-footer">
      <div className="code-footer-description">
        {t('codeScreen.footerDescription')}
      </div>
      <PrintButton
        componentToPrintRef={componentToPrintRef}
        buttonTitleKey="codeScreen.printButton"
        validateData={validateData}
        pageStyle={printingPageStyle}
        onAfterPrint={onAfterPrint}
      />
    </div>
  );
};

export default CodePageFooter;
