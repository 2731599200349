import { ReactElement, useEffect, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { toBase64 } from '../../utils/fileUtil';
import PDFZoomControl from '../PDFZoomControl/PDFZoomControl';
import './ImageViewer.scss';

interface ImageViewerProps {
  file: File | undefined;
}

const ImageViewer = ({ file }: ImageViewerProps): ReactElement => {
  const defaultScale = 45;
  const [content, setContent] = useState<string | undefined>(undefined);
  const [scalePercentage, setScalePercentage] = useState(defaultScale);

  useEffect(() => {
    function getFileContent(): void {
      if (!file) {
        return;
      }

      const response = toBase64(file);
      response.then((base64Value: string) => {
        setContent(base64Value);
      });
    }
    getFileContent();
  }, [file]);

  const handleScaleChange = (scale: number): void => {
    setScalePercentage(defaultScale * scale);
  };

  return (
    <div className="image-viewer-container">
      <div className="control-pane-container">
        <div className="control-pane">
          <PDFZoomControl setCurrentScale={handleScaleChange} darkMode />
        </div>
      </div>
      <ScrollContainer className="image-frame" hideScrollbars={false}>
        {content && (
          <img
            width={`${scalePercentage}%`}
            height="auto"
            src={content}
            alt=""
          />
        )}
      </ScrollContainer>
    </div>
  );
};

export default ImageViewer;
