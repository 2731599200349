import { ReactElement } from 'react';
import { ReactComponent as CloseSmallIcon } from '../../assets/svg/CloseSmallIcon.svg';

import './ActionIcon.scss';

interface CloseActionIconProps {
  className: string;
  onClick: () => void;
}

const CloseActionIcon = ({
  onClick,
  className,
}: CloseActionIconProps): ReactElement => (
  <button
    className={`icon-wrapper ${className}`}
    type="button"
    onClick={(): void => onClick()}
  >
    <CloseSmallIcon />
  </button>
);

export default CloseActionIcon;
