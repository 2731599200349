import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from '../../assets/svg/SearchIcon.svg';
import KeyPairItem from '../../common/models/keypairItem';
import KeyCode from '../../common/types/keyCode';
import SDCButtonSize from '../../common/types/sdcButtonSize';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import SDCTextFieldSize from '../../common/types/sdcTextFieldSize';
import SDCButton from '../SDCButton/SDCButton';
import SDCTextField from '../SDCTextField/SDCTextField';
import SecondaryDropdown from '../SecondaryDropdown/SecondaryDropdown';
import './SearchBox.scss';

interface SearchBoxProps {
  documentTypeItems: KeyPairItem[];
  onSearch: (
    searchText: string,
    documentTypeItem: KeyPairItem | undefined
  ) => void;
}

const SearchBox = ({
  onSearch,
  documentTypeItems,
}: SearchBoxProps): ReactElement => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [selectedItem, setSelectedItem] = useState<KeyPairItem | undefined>(
    undefined
  );

  const keyUpHandler = (value: string): void => {
    setSearchText(value);
  };

  const handleOnSearch = (): void => {
    onSearch(searchText, selectedItem);
  };

  const handleKeyPress = (key: string): void => {
    if (key === KeyCode.KeyEnter) {
      handleOnSearch();
    }
  };

  const handleDocumentTypeSelect = (item: KeyPairItem): void => {
    setSelectedItem(item);
  };

  useEffect(() => {
    if (selectedItem === undefined) {
      setSelectedItem(documentTypeItems[0]);
    }
  }, [documentTypeItems, selectedItem]);

  const renderSearchBox = (): ReactElement => (
    <div className="text-field-container">
      <SDCTextField
        value={searchText}
        Icon={SearchIcon}
        fieldSize={SDCTextFieldSize.Small}
        placeholder={t('searchFilterComponent.placeholderSearch')}
        onChange={(e): void => keyUpHandler(e.target.value)}
        onKeyPress={(e): void => handleKeyPress(e.key)}
      />
      <div className="dropdown-container">
        <SecondaryDropdown
          selectedValue={selectedItem?.Value ?? ''}
          items={documentTypeItems}
          onSelect={handleDocumentTypeSelect}
        />
      </div>
    </div>
  );

  return (
    <div className="search-box-component-container">
      <div className="search-control-container">
        {renderSearchBox()}
        <SDCButton
          size={SDCButtonSize.Small}
          variant={SDCButtonVariant.Primary}
          onClick={(): void => handleOnSearch()}
          text={t('searchFilterComponent.searchButton')}
        />
      </div>
    </div>
  );
};

export default SearchBox;
