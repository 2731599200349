import React, { ReactElement, useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import barcode from '../../common/constants/barcode.constant';
import CodeSetting from '../../common/models/codeSetting';
import KeyPairItem from '../../common/models/keypairItem';
import SDCCheckboxSize from '../../common/types/sdcCheckboxSize';
import SizeUnit from '../../common/types/sizeUnit';
import { setCodeSetting } from '../../services/userSetting/userSetting.service';
import SDCCheckbox from '../SDCCheckbox/SDCCheckbox';
import SDCDropdown from '../SDCDropdown/SDCDropdown';
import SDCTextField from '../SDCTextField/SDCTextField';
import './CodeSettingPanel.scss';

interface CodeSettingPanelProps {
  setting: CodeSetting;
  setSetting: React.Dispatch<React.SetStateAction<CodeSetting>>;
  isSeparate?: boolean;
}

const CodeSettingPanel = ({
  setting,
  setSetting,
  isSeparate,
}: CodeSettingPanelProps): ReactElement => {
  const { t } = useTranslation();
  const sizeUnits = [
    { Value: SizeUnit.Millimetre, Name: SizeUnit.Millimetre },
    { Value: SizeUnit.Inch, Name: SizeUnit.Inch },
  ];
  const typeOptions: KeyPairItem[] = [
    { Name: 'QR code', Value: barcode.QR_CODE },
    { Name: 'Code 39', Value: barcode.CODE_39 },
  ];
  const divElement = useRef(null);

  useEffect(() => {
    setCodeSetting(setting);
  }, [setting]);

  return (
    <div className="code-setting-panel-container" ref={divElement}>
      <div className="section-label">{t('codeSettingsPanel.header')}</div>
      <div className={`qr-code-setting-body ${isSeparate ? 'no-bg' : ''}`}>
        <Row className="qr-code-page-setting">
          <Col className="col" sm="12" lg="4">
            {!isSeparate && <span>{t('codeSettingsPanel.typeLabel')}</span>}
            <SDCDropdown
              values={typeOptions}
              onSelect={(value): void =>
                setSetting({ ...setting, type: value.Value })
              }
              currentItem={typeOptions.find((x) => x.Value === setting.type)}
            />
          </Col>
          <Col className="col" sm="12" lg="4">
            {!isSeparate && <span>{t('codeSettingsPanel.unitLabel')}</span>}
            <SDCDropdown
              values={sizeUnits}
              onSelect={(value): void =>
                setSetting({ ...setting, sizeUnit: value.Value as SizeUnit })
              }
              currentItem={{ Name: setting.sizeUnit, Value: setting.sizeUnit }}
            />
          </Col>
          <Col>
            {!isSeparate && <span>{t('codeSettingsPanel.widthLabel')}</span>}
            <SDCTextField
              onTextChange={(value: string): void => {
                if (value)
                  setSetting({
                    ...setting,
                    width: parseInt(value, 10),
                  });
              }}
              placeholder={t('codeSettingsPanel.placeHolderTextWidth')}
              value={setting.width.toString()}
            />
          </Col>
          <Col>
            {!isSeparate && <span>{t('codeSettingsPanel.heightLabel')}</span>}
            <SDCTextField
              onTextChange={(value: string): void => {
                if (value)
                  setSetting({
                    ...setting,
                    height: parseInt(value, 10),
                  });
              }}
              placeholder={t('codeSettingsPanel.placeHolderTextHeight')}
              value={setting.height.toString()}
            />
          </Col>
        </Row>
        <Row className="qr-code-display-setting checkbox-setting">
          <SDCCheckbox
            sdcSize={SDCCheckboxSize.Large}
            labelText={t('codeSettingsPanel.displayBarcodeTextCheckboxLabel', {
              type: setting.type === barcode.QR_CODE ? 'QR code' : 'barcode',
            })}
            onCheckChange={(value): void =>
              setSetting({
                ...setting,
                isDisplayCodeText: value,
              })
            }
            checked={setting.isDisplayCodeText}
          />
          <SDCCheckbox
            sdcSize={SDCCheckboxSize.Large}
            labelText={t(
              'codeSettingsPanel.displayBarcodeDescriptionCheckboxLabel',
              { type: setting.type === barcode.QR_CODE ? 'QR code' : 'barcode' }
            )}
            onCheckChange={(value): void => {
              setSetting({
                ...setting,
                isDisplayCodeDescription: value,
              });
            }}
            checked={setting.isDisplayCodeDescription}
          />
        </Row>
      </div>
    </div>
  );
};

CodeSettingPanel.defaultProps = {
  isSeparate: false,
};

export default CodeSettingPanel;
