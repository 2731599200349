import { ReactElement } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { imgError403401, imgError404 } from '../../assets/img';
import getProductInfo from '../../common/configurations/productInfo';
import ErrorCode from '../../common/types/errorCode';
import HttpStatusCode from '../../common/types/httpStatusCode';
import './ErrorPage.scss';

type ErrorParams = {
  httpStatusCode?: string;
  errorCode?: string;
};

const ErrorPage = (): ReactElement => {
  const { httpStatusCode, errorCode } = useParams<ErrorParams>();
  const { t } = useTranslation();
  const { productName } = getProductInfo();

  let imageError = null;
  let errorMessage = null;
  let detailMessage = null;
  let actionMessage = null;
  let goHome = false;

  switch (httpStatusCode) {
    case HttpStatusCode.NotFound.toString():
      imageError = imgError404;
      errorMessage = t('httpError404NotFound.errorMessage', {
        ProductName: productName,
      });
      actionMessage = t('httpError404NotFound.actionMessage');
      goHome = true;
      break;

    case HttpStatusCode.Forbidden.toString():
      imageError = imgError403401;
      errorMessage = t('httpError403Forbidden.errorMessage', {
        ProductName: productName,
      });
      actionMessage = t('httpError403Forbidden.actionMessage');
      goHome = true;
      break;

    case HttpStatusCode.BadGateway.toString():
      errorMessage = t('httpError502BadGateway.errorMessage', {
        ProductName: productName,
      });
      actionMessage = t('httpError502BadGateway.actionMessage');
      goHome = true;
      break;

    case HttpStatusCode.ServiceUnavailable.toString():
      errorMessage = t('httpError503ServiceUnavailable.errorMessage', {
        ProductName: productName,
      });
      actionMessage = t('httpError503ServiceUnavailable.actionMessage');
      goHome = true;
      break;

    default:
      errorMessage = t('defaultUnhandledError.errorMessage');
      detailMessage = t('defaultUnhandledError.detailMessage');
      actionMessage = t('defaultUnhandledError.actionMessage');
      goHome = true;
      break;
  }

  // override status code
  if (errorCode) {
    switch (errorCode) {
      case ErrorCode.Forbidden.toString():
        imageError = imgError403401;
        errorMessage = t('httpError403Forbidden.errorMessage', {
          ProductName: productName,
        });
        actionMessage = t('httpError403Forbidden.actionMessage');
        goHome = true;
        break;

      case ErrorCode.UnauthorizedSubscription.toString():
        imageError = imgError403401;
        errorMessage = t('httpError403Forbidden.subscriptionErrorMessage');
        detailMessage = t(`errorCode.${ErrorCode.UnauthorizedSubscription}`);
        break;

      case ErrorCode.TenantNotFound.toString():
        imageError = imgError403401;
        errorMessage = t('httpError403Forbidden.tenantNotFoundErrorMessage');
        detailMessage = t(`errorCode.${ErrorCode.TenantNotFound}`);
        break;

      case ErrorCode.InvalidDocumentType.toString():
        imageError = imgError403401;
        errorMessage = t(
          'httpError400BadRequest.invalidDocumentTypeErrorMessage'
        );
        detailMessage = t(
          'httpError400BadRequest.invalidDocumentTypeDetailMessage'
        );
        goHome = false;
        break;

      default:
        errorMessage = t('defaultUnhandledError.errorMessage');
        detailMessage = t('defaultUnhandledError.detailMessage');
        actionMessage = t('defaultUnhandledError.actionMessage');
        goHome = true;
        break;
    }
  }

  return (
    <Container fluid="xl">
      <Row>
        <Col className="p-4">
          <div className="d-flex flex-column align-items-center error-container page-container">
            {imageError ? <img alt="" src={imageError} /> : null}
            {errorMessage ? (
              <span className="error-message">{errorMessage}</span>
            ) : null}
            {detailMessage ? (
              <span className="detail-message">{detailMessage}</span>
            ) : (
              <></>
            )}
            {goHome ? (
              <Link className="go-home" to="/">
                {actionMessage}
              </Link>
            ) : null}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorPage;
