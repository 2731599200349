import React, { ReactElement } from 'react';
import './TaskDescription.scss';

interface TaskDescriptionProps {
  descriptionText: string;
  documentDisplayName: string;
}

const TaskDescription = ({
  descriptionText,
  documentDisplayName,
}: TaskDescriptionProps): ReactElement => (
  <span className="task-description-container">
    <span>{descriptionText}</span>
    <span className="separator" />
    <span className="display-name">{documentDisplayName}</span>
  </span>
);

export default TaskDescription;
