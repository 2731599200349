import { FunctionComponent, ReactElement, SVGProps } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as MenuOptionsIcon } from '../../assets/svg/MenuOptionsIcon.svg';
import KeyPairItem from '../../common/models/keypairItem';
import './TableRowDropdownMenu.scss';

interface TableRowDropdownMenuProps {
  values: KeyPairItem[];
  onSelect: (value: KeyPairItem) => void;
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

const TableRowDropdownMenu = ({
  values,
  onSelect,
  Icon,
}: TableRowDropdownMenuProps): ReactElement => (
  <div className="table-row-dropdown-menu">
    <Dropdown>
      <Dropdown.Toggle variant="none" className="shadow-none no-outline">
        {Icon !== undefined ? <Icon /> : <MenuOptionsIcon />}
      </Dropdown.Toggle>
      <Dropdown.Menu className="m-0 shadow-none">
        {values.map((item: KeyPairItem) => (
          <Dropdown.Item
            key={item.Value}
            eventKey={item.Value}
            onClick={(): void => onSelect(item)}
            disabled={item.Value === ''}
          >
            {item.Name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  </div>
);

export default TableRowDropdownMenu;

TableRowDropdownMenu.defaultProps = {
  Icon: undefined,
};
