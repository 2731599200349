import { MutableRefObject, ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import SDCButton from '../SDCButton/SDCButton';

interface PrintButtonProps {
  pageStyle: string | undefined;
  componentToPrintRef: MutableRefObject<null>;
  buttonTitleKey: string;
  validateData: () => Promise<boolean>;
  onAfterPrint: () => Promise<void>;
}

const PrintButton = ({
  pageStyle,
  componentToPrintRef,
  buttonTitleKey,
  validateData,
  onAfterPrint,
}: PrintButtonProps): ReactElement => {
  const { t } = useTranslation();
  const reactToPrintContent = useCallback(
    () => componentToPrintRef.current,
    [componentToPrintRef]
  );

  const runAfterPrint = (): void => {
    onAfterPrint();
  };

  const print = useReactToPrint({
    content: reactToPrintContent,
    removeAfterPrint: true,
    copyStyles: false,
    pageStyle,
    onAfterPrint: runAfterPrint,
  });

  const onClick = async (): Promise<void> => {
    const isValid = await validateData();
    if (!isValid) {
      return;
    }

    print();
  };

  return (
    <SDCButton
      className="ps-5 pe-5"
      text={t(buttonTitleKey)}
      onClick={onClick}
      variant={SDCButtonVariant.Primary}
    />
  );
};

export default PrintButton;
