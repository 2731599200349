import { ReactElement } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from '../../assets/svg/DeleteIcon.svg';
import { ReactComponent as DownloadCloudSmallIcon } from '../../assets/svg/DownloadCloudSmallIcon.svg';
import { ReactComponent as MoveDocumentIcon } from '../../assets/svg/MoveDocumentIcon.svg';
import DocumentSearchOptions from '../../common/models/documentSearchOptions';
import KeyPairItem from '../../common/models/keypairItem';
import NotificationVariant from '../../common/types/notificationVariant';
import SDCDropdownSize from '../../common/types/sdcDropdownSize';
import SortItemKey from '../../common/types/sortItemKey';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import PaginationResponse from '../../services/paginationResponse';
import PendingTrayItemResponse from '../../services/pendingTray/models/pendingTrayItemResponse';
import { downloadDocumentFiles } from '../../services/pendingTray/pendingTray.service';
import ResultDocumentActionIcon from '../ResultDocumentList/ResultDocumentActionIcon';
import SDCCheckbox from '../SDCCheckbox/SDCCheckbox';
import SDCDropdown from '../SDCDropdown/SDCDropdown';
import showToaster from '../Toaster/ToasterProvider';
import './PendingTrayActionPane.scss';

interface PendingTrayActionPaneProps {
  itemResult: PaginationResponse<PendingTrayItemResponse>;
  searchOptions: DocumentSearchOptions;
  setSearchOptions: React.Dispatch<React.SetStateAction<DocumentSearchOptions>>;
  checkedDocumentIds: Array<string>;
  setCheckedDocumentIds: (value: Array<string>) => void;
  setSelectedDocumentId?: (value: string) => void;
  setIsDocumentMovingModalVisible: (value: boolean) => void;
  setShowDeleteConfirmationModal: (value: boolean) => void;
  setIdsToDelete: (value: Array<string>) => void;
}

const PendingTrayActionPane = ({
  itemResult,
  searchOptions,
  setSearchOptions,
  checkedDocumentIds,
  setCheckedDocumentIds,
  setSelectedDocumentId,
  setIsDocumentMovingModalVisible,
  setShowDeleteConfirmationModal,
  setIdsToDelete,
}: PendingTrayActionPaneProps): ReactElement => {
  const ascending = 'ascending';
  const descending = 'descending';
  const download = 'Download';

  const { currentUser } = useCurrentUser();
  const { t } = useTranslation();
  const errorHandler = useErrorHandler();
  const pendingTraySortOptions: KeyPairItem[] = [
    {
      Name: t('documentsViewScreen.sortByDropdown'),
      Value: '',
    },
    {
      Name: t(`documentsViewScreen.sortBy${SortItemKey.FileName}ASC`),
      Value: `${SortItemKey.FileName}_${ascending}`,
    },
    {
      Name: t(`documentsViewScreen.sortBy${SortItemKey.FileName}DESC`),
      Value: `${SortItemKey.FileName}_${descending}`,
    },
    {
      Name: t(`documentsViewScreen.sortBy${SortItemKey.Type}ASC`),
      Value: `${SortItemKey.Type}_${ascending}`,
    },
    {
      Name: t(`documentsViewScreen.sortBy${SortItemKey.Type}DESC`),
      Value: `${SortItemKey.Type}_${descending}`,
    },
    {
      Name: t(`documentsViewScreen.sortBy${SortItemKey.Date}ASC`),
      Value: `${SortItemKey.Date}_${ascending}`,
    },
    {
      Name: t(`documentsViewScreen.sortBy${SortItemKey.Date}DESC`),
      Value: `${SortItemKey.Date}_${descending}`,
    },
  ];

  const sortItemBy = (attribute: string): void => {
    if (setSelectedDocumentId) setSelectedDocumentId('');

    setSearchOptions((prevOptions) => ({
      ...prevOptions,
      sortBy: attribute,
    }));
  };

  const checkedAllItems = (): boolean =>
    itemResult.results.length > 0 &&
    itemResult.results.every((value) => checkedDocumentIds.includes(value.id));

  // Disable actions button if user not seleted any items or seleted items aren't own by user.
  const isDisabledAction = ((): boolean => {
    const seletedPendingTrayItems = itemResult.results?.filter((item) =>
      checkedDocumentIds.find((id) => id === item.id)
    );

    if (seletedPendingTrayItems) {
      return seletedPendingTrayItems.some(
        (document) =>
          currentUser.id !== document?.createdByUserId &&
          document?.userId === null
      );
    }
    return true;
  })();

  const handleSelectAll = (checked: boolean): void => {
    if (!itemResult.results) return;

    if (checked) {
      // Check/uncheck all documents on the current page
      const allIds = itemResult.results.map(
        (item: PendingTrayItemResponse) => item.id
      );
      setCheckedDocumentIds(allIds);
    } else {
      setCheckedDocumentIds([]);
    }
  };

  const onDownloadFiles = (ids: string[]): void => {
    async function downloadFiles(): Promise<void> {
      downloadDocumentFiles(ids, errorHandler)
        .then((response) => {
          const fileUrl = URL.createObjectURL(response);
          const link = window.document.createElement('a');
          link.href = fileUrl;
          link.download = response.name;
          link.click();

          window.URL.revokeObjectURL(fileUrl);
          link.remove();
          showToaster(
            t('pendingTrayPage.toasterSuccess'),
            t('pendingTrayPage.downloadFileCompleted', {
              count: ids.length,
            }),
            NotificationVariant.PrimarySuccess
          );
        })
        .catch(() => {
          showToaster(
            t('pendingTrayPage.toasterFailure'),
            t('pendingTrayPage.downloadFileFailed'),
            NotificationVariant.PrimaryDanger
          );
        });
    }
    downloadFiles();
    showToaster(
      t('pendingTrayPage.toasterDownloading'),
      t('pendingTrayPage.downloading'),
      NotificationVariant.PrimaryInfo
    );
  };

  const onDeletePendingTrayItems = (ids: string[]): void => {
    setIdsToDelete(ids);
    setShowDeleteConfirmationModal(true);
  };

  const onMoveFilesButtonClick = (): void => {
    setIsDocumentMovingModalVisible(true);
  };

  const actions = [
    {
      name: 'Download',
      displayName: t('pendingTrayPage.downloadIconDisplayName'),
      Icon: DownloadCloudSmallIcon,
      onAction: onDownloadFiles,
    },
    {
      name: 'Move',
      displayName: t('pendingTrayPage.moveIconDisplayName'),
      Icon: MoveDocumentIcon,
      onAction: onMoveFilesButtonClick,
    },
    {
      name: 'Delete',
      displayName: t('pendingTrayPage.deleteIconDisplayName'),
      Icon: DeleteIcon,
      onAction: onDeletePendingTrayItems,
    },
  ];

  return (
    <>
      <div className="pending-tray-action-pane-container">
        <div className="action-group">
          <SDCCheckbox
            labelText={t('documentsViewScreen.selectAllCheckbox')}
            checked={checkedAllItems()}
            onCheckChange={handleSelectAll}
            disabled={itemResult.results.length === 0}
          />
          <span className="divider" />
          {actions.map((action) => {
            const { name, displayName, Icon, onAction } = action;
            return (
              <ResultDocumentActionIcon
                key={name}
                name={name}
                displayName={displayName}
                Icon={Icon}
                onAction={(): void => {
                  onAction(checkedDocumentIds);
                }}
                isDisabled={
                  (name !== download && isDisabledAction) ||
                  !checkedDocumentIds.length
                }
              />
            );
          })}
        </div>
        <div className="control-items">
          <SDCDropdown
            currentItem={pendingTraySortOptions.find(
              (sortBy) => sortBy.Value === searchOptions.sortBy
            )}
            values={pendingTraySortOptions}
            onSelect={(item: KeyPairItem): void => sortItemBy(item.Value)}
            size={SDCDropdownSize.Small}
          />
          <span className="divider" />
        </div>
      </div>
    </>
  );
};

PendingTrayActionPane.defaultProps = {
  setSelectedDocumentId: undefined,
};

export default PendingTrayActionPane;
