import { FunctionComponent, ReactElement, SVGProps } from 'react';
import SDCButtonSize from '../../common/types/sdcButtonSize';
import './PaginationButton.scss';

interface PaginationButtonProps {
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  active: boolean;
  text?: string;
  size?: SDCButtonSize;
  flexibleWidth?: boolean;
  disabled?: boolean;
  darkMode?: boolean;
  onClick: () => void;
}

const PaginationButton = ({
  Icon,
  active,
  text,
  size,
  darkMode,
  flexibleWidth,
  disabled = false,
  onClick,
}: PaginationButtonProps): ReactElement => (
  <button
    className={`pagination-button 
    ${active ? 'active' : ''} 
    ${size === SDCButtonSize.Small ? 'small' : ''}
    ${darkMode ? 'dark' : ''}
    ${flexibleWidth ? 'flexible-width' : ''}`}
    type="button"
    onClick={(): void => onClick()}
    disabled={disabled}
  >
    {Icon !== undefined ? <Icon /> : ''}
    {text ?? ''}
  </button>
);

PaginationButton.defaultProps = {
  size: SDCButtonSize.Default,
  flexibleWidth: false,
  disabled: false,
  darkMode: false,
  Icon: undefined,
  text: undefined,
};

export default PaginationButton;
