import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from '../../assets/svg/DeleteIcon.svg';
import { ReactComponent as VerticalBar } from '../../assets/svg/VerticalBar.svg';
import UploadFileWithStatus from '../../common/models/uploadFileWithStatus';
import FileUploadStatus from '../../common/types/fileUploadStatus';
import SDCButtonIconPosition from '../../common/types/sdcButtonIconPosition';
import SDCButtonSize from '../../common/types/sdcButtonSize';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import CardActionIcon from '../ActionIcon/CardActionIcon';
import ListActionIcon from '../ActionIcon/ListActionIcon';
import SDCButton from '../SDCButton/SDCButton';
import SDCCheckbox from '../SDCCheckbox/SDCCheckbox';
import './UploadDocumentHeader.scss';

interface UploadDocumentHeaderProps {
  files: Array<UploadFileWithStatus>;
  useUploadListView: boolean;
  listActionIconOnClick: () => void;
  cardActionIconOnClick: () => void;
  deleteActionOnClick: () => void;
  checkboxSelectedFiles: Array<File>;
  selectAllOnClick: (checked: boolean) => void;
}

const UploadDocumentHeader = ({
  files,
  useUploadListView,
  listActionIconOnClick,
  cardActionIconOnClick,
  deleteActionOnClick,
  checkboxSelectedFiles,
  selectAllOnClick,
}: UploadDocumentHeaderProps): ReactElement => {
  const iconColor = '#B2BFD2';
  const iconActiveColor = '#42495E';
  const { t } = useTranslation();
  const [checkedAll, setCheckedAll] = useState(false);
  const renderDeleteButton = (): ReactElement | null =>
    checkboxSelectedFiles.length > 0 ? (
      <>
        <SDCButton
          aria-label="delete"
          variant={SDCButtonVariant.Default}
          text={t('archiveDocumentPage.deleteButton', {
            count: checkboxSelectedFiles.length,
          })}
          onClick={(): void => {
            deleteActionOnClick();
            setCheckedAll(false);
          }}
          Icon={DeleteIcon}
          iconPosition={SDCButtonIconPosition.Left}
          size={SDCButtonSize.Small}
          className="delete-button"
        />
        <div className="vertical-bar">
          <VerticalBar />
        </div>
      </>
    ) : null;

  const renderSelectAllCheckbox = (): ReactElement | null =>
    files.filter((f) => f.status === FileUploadStatus.SUCCESS).length > 0 ? (
      <>
        <SDCCheckbox
          data-testid="select-all-checkbox"
          checked={checkedAll}
          labelText={t('archiveDocumentPage.selectAllLabel')}
          onClick={(e): void => {
            setCheckedAll(e.currentTarget.checked);
            selectAllOnClick(e.currentTarget.checked);
          }}
        />
        <div className="vertical-bar">
          <VerticalBar />
        </div>
      </>
    ) : null;

  return (
    <div className="upload-header">
      <div className="upload-title-group">
        <div className="upload-title">{t('archiveDocumentPage.title')}</div>
        <div className="upload-description">
          {t('archiveDocumentPage.descripiton')}
        </div>
      </div>
      <div className="upload-file-view-control">
        {renderDeleteButton()}
        {renderSelectAllCheckbox()}
        <ListActionIcon
          active={useUploadListView}
          color={iconColor}
          activeColor={iconActiveColor}
          onClick={listActionIconOnClick}
        />
        <CardActionIcon
          active={!useUploadListView}
          color={iconColor}
          activeColor={iconActiveColor}
          onClick={cardActionIconOnClick}
        />
      </div>
    </div>
  );
};

export default UploadDocumentHeader;
