import { FunctionComponent, ReactElement, ReactNode, SVGProps } from 'react';
import { ReactComponent as TriangleRightIcon } from '../../assets/svg/TriangleRightIcon.svg';
import SDCButtonSize from '../../common/types/sdcButtonSize';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import TaskBarSize from '../../common/types/taskBarSize';
import isTabletSize from '../../utils/responsiveUtil';
import SDCButton from '../SDCButton/SDCButton';
import './PendingTrayTaskBar.scss';

interface PendingTrayTaskBarProps {
  headerPrefix: string;
  children: ReactNode;
  actionText?: string;
  actionOnClick?: () => void;
  size?: TaskBarSize;
  stickOnTop?: boolean;
  darkMode?: boolean;
  isShowButton?: SDCButtonVariant;
  isHaveSideNavBar?: boolean;
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  isSubNavbar?: boolean;
}

const PendingTrayTaskBar = ({
  headerPrefix,
  children,
  actionText,
  actionOnClick,
  size,
  stickOnTop,
  darkMode,
  isShowButton,
  isHaveSideNavBar,
  Icon,
  isSubNavbar,
}: PendingTrayTaskBarProps): ReactElement => {
  const renderAction = (): null | ReactElement => {
    if (actionText) {
      return (
        <div className="action-area">
          {isShowButton && (
            <SDCButton
              Icon={Icon}
              text={actionText}
              onClick={
                actionOnClick !== undefined
                  ? actionOnClick
                  : (): void => {
                      // Do nothing
                    }
              }
              variant={isShowButton}
              size={SDCButtonSize.Small}
            />
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={`pending-tray-task-bar-container 
      ${isSubNavbar ? 'sub-nav' : ''}
      ${darkMode ? 'dark' : ''} 
      ${size === TaskBarSize.Large ? 'task-bar-large' : ''} 
      ${stickOnTop ? 'stick-top' : 'stick-navbar'}
      ${isHaveSideNavBar && !isTabletSize() ? 'expand-left' : ''}`}
    >
      <div className="prefix-content">
        <span className={`prefix ${darkMode ? 'dark' : ''}`}>
          {headerPrefix}
        </span>
        {headerPrefix && (
          <span className="arrow-container">
            <TriangleRightIcon />
          </span>
        )}
        {children}
      </div>
      {renderAction()}
      <div className="me-auto" />
    </div>
  );
};

PendingTrayTaskBar.defaultProps = {
  size: TaskBarSize.Default,
  actionText: null,
  actionOnClick: null,
  stickOnTop: false,
  darkMode: false,
  isShowButton: undefined,
  isHaveSideNavBar: false,
  Icon: undefined,
  isSubNavbar: false,
};

export default PendingTrayTaskBar;
