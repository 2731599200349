import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { RowData } from '../../common/types/types';
import MetadataDetail from '../../services/capture/models/metadataDetail';
import './ReadonlyDataTable.scss';
import ReadonlyDataTableCell from './ReadonlyDataTableCell';

interface ReadonlyDataTableProps {
  rows: RowData[];
}

const ReadonlyDataTable = ({ rows }: ReadonlyDataTableProps): ReactElement => {
  const { t } = useTranslation();

  const renderCell = (
    rowIndex: number,
    column: MetadataDetail
  ): ReactElement => {
    const { key, value } = column;
    const stringValue = value as string;

    return (
      <td
        key={`${rowIndex}-${key}`}
        className="clear"
        title={stringValue.length > 20 ? stringValue : ''}
      >
        <ReadonlyDataTableCell
          key={`${rowIndex}-${key}-table-cell`}
          value={stringValue}
        />
      </td>
    );
  };

  const renderHeader = (): ReactElement => (
    <tr key="row-header">
      <th key="no">{t('dataTable.columnCount')}</th>
      {rows[0].map((value) => (
        <th key={value.key}>{value.displayName}</th>
      ))}
    </tr>
  );

  const renderRow = (rowNumber: number, rowData: RowData): ReactElement => (
    <tr key={rowNumber}>
      <td key="row-count" className="system-column">
        {rowNumber + 1}
      </td>
      {rowData.map((col) => renderCell(rowNumber, col))}
    </tr>
  );

  return (
    <div className="data-table-component">
      <table>
        <thead>{renderHeader()}</thead>
        <tbody>
          {rows.map((value: RowData, index: number) => renderRow(index, value))}
        </tbody>
      </table>
    </div>
  );
};

export default ReadonlyDataTable;
