import { ReactElement } from 'react';
import './GridCheckbox.scss';

interface GridCheckBoxProps {
  value: string;
  selectedValues: string[];
  setSelectableStates: React.Dispatch<React.SetStateAction<string[]>>;
}

const GridCheckBox = ({
  value,
  selectedValues,
  setSelectableStates,
}: GridCheckBoxProps): ReactElement => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      setSelectableStates((prev) => [...prev, e.target.value]);
    } else {
      setSelectableStates((prev) => prev.filter((x) => x !== e.target.value));
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className="grid-checkbox">
      <input
        type="checkbox"
        value={value}
        checked={selectedValues.includes(value)}
        onChange={onChange}
      />
      <span className="checkmark" />
    </label>
  );
};

export default GridCheckBox;
