import StorageProvider from '../../common/providers/storageProvider';
import authConstants from './auth.constants';
import { PKCECodePair } from './pkce';

class AuthStorage {
  storage: StorageProvider;

  constructor(storage: StorageProvider) {
    this.storage = storage;
  }

  public clearAuthData(): void {
    this.storage.removeItem(authConstants.PKCE);
    this.storage.removeItem(authConstants.VALID_TO);
  }

  public isExpired(): boolean {
    const validTo = Number(this.storage.getItem(authConstants.VALID_TO));
    const millisecondsNow = new Date().getTime();

    return !validTo || millisecondsNow >= validTo;
  }

  public isAuthenticated(): boolean {
    return this.storage.getItem(authConstants.VALID_TO) !== null;
  }

  public getPCKE(): string | null {
    return this.storage.getItem(authConstants.PKCE);
  }

  public setNewPKCE(pkce: PKCECodePair, preAuthURI: string): void {
    this.storage.setItem(authConstants.PKCE, JSON.stringify(pkce));
    this.storage.setItem(authConstants.PRE_AUTH_URI, preAuthURI);
    this.storage.removeItem(authConstants.VALID_TO);
  }

  public setNewAuthTokens(expireAt: number): void {
    this.storage.setItem(authConstants.VALID_TO, expireAt.toString());
  }

  public getPreAuthURI(): string | null {
    return this.storage.getItem(authConstants.PRE_AUTH_URI);
  }

  public removePreAuthURI(): void {
    this.storage.removeItem(authConstants.PRE_AUTH_URI);
  }
}

export default AuthStorage;
