import { ReactElement } from 'react';
import { ReactComponent as TipIcon } from '../../assets/svg/TipIcon.svg';
import './HelpActionIcon.scss';

interface HelpActionIconProps {
  text: string;
  onClick: () => void;
}

const HelpActionIcon = ({
  text,
  onClick,
}: HelpActionIconProps): ReactElement => (
  <div className="help-action-icon">
    <button type="button" onClick={onClick}>
      <div className="pe-1">{text}</div>
      <TipIcon />
    </button>
  </div>
);

export default HelpActionIcon;
