import PropTypes from 'prop-types';
import { ReactElement } from 'react';
import './Loading.scss';

interface LoadingProps {
  message?: string;
  theme?: string;
}

const Loading = ({ message, theme }: LoadingProps): ReactElement => (
  <div className={`loading-container ${theme}`}>
    <h1>{message}</h1>
    <span className={`loader ${theme}`} />
  </div>
);

Loading.propTypes = {
  message: PropTypes.string,
};

Loading.defaultProps = {
  message: '',
  theme: 'dark',
};

export default Loading;
