import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import SubscriptionStatus from '../../common/types/subscriptionStatus';
import './SubscriptionStatusBadge.scss';

interface SubscriptionStatusBadgeProps {
  status: SubscriptionStatus | undefined;
}

const SubscriptionStatusBadge = ({
  status,
}: SubscriptionStatusBadgeProps): ReactElement => {
  const { t } = useTranslation();

  const getStatusText = (): string => {
    const statuses: { [key: string]: string } = {
      Unknown: t('subscriptionScreen.unknown'),
      OnHold: t('subscriptionScreen.onHold'),
      Active: t('subscriptionScreen.active'),
      Expired: t('subscriptionScreen.expired'),
      NotStarted: t('subscriptionScreen.notStarted'),
      ExceededUsageQuota: t('subscriptionScreen.exceededUsageQuota'),
    };

    const key = status ?? '';
    const text = statuses[key];
    if (!text) {
      return statuses.Unknown;
    }
    return text;
  };

  const getClass = (): string =>
    status === SubscriptionStatus.Active
      ? 'status-text active'
      : 'status-text error';

  return <span className={getClass()}>{getStatusText()}</span>;
};

export default SubscriptionStatusBadge;
