/* eslint-disable jsx-a11y/label-has-associated-control */
import { ReactElement } from 'react';
import KeyPairItem from '../../common/models/keypairItem';
import './RadioOption.scss';

interface RadioOptionProps {
  item: KeyPairItem;
  groupName: string;
  selectedValue: string;
  onClick: () => void;
}

const RadioOption = ({
  item,
  groupName,
  selectedValue,
  onClick,
}: RadioOptionProps): ReactElement => (
  <div className="radio-option">
    <label className="container">
      {item.Name}
      <input
        type="radio"
        checked={item.Value === selectedValue}
        name={groupName}
        onKeyUp={(): void => onClick()}
        onChange={(): void => onClick()}
      />
      <span className="checkmark" />
    </label>
  </div>
);

export default RadioOption;
