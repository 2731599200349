import { ReactElement } from 'react';
import { ReactComponent as CheckMarkIcon } from '../../assets/svg/CheckMarkSolidIcon.svg';
import { ReactComponent as ExclamationMarkIcon } from '../../assets/svg/ExclamationMarkIcon.svg';
import { ReactComponent as InfoIcon } from '../../assets/svg/InfoIcon.svg';
import { ReactComponent as WarningSignIcon } from '../../assets/svg/WarningSignIcon.svg';
import NotificationVariant from '../../common/types/notificationVariant';
import './NotificationAlert.scss';

interface NotificationAlertProps {
  title: string;
  variant: NotificationVariant;
  message: string;
}

const successIconVariants = [
  NotificationVariant.PrimarySuccess,
  NotificationVariant.SecondarySuccess,
  NotificationVariant.SoftSuccess,
  NotificationVariant.SoftWarning,
  NotificationVariant.SoftDanger,
  NotificationVariant.SoftInfo,
];
const infoIconVariants = [
  NotificationVariant.PrimaryBasic,
  NotificationVariant.PrimaryInfo,
  NotificationVariant.SecondaryBasic,
  NotificationVariant.SecondaryInfo,
  NotificationVariant.SoftBasic,
];
const warningIconVariants = [
  NotificationVariant.PrimaryWarning,
  NotificationVariant.SecondaryWarning,
  NotificationVariant.SoftWarning,
];
const dangerIconVariants = [
  NotificationVariant.SoftDanger,
  NotificationVariant.PrimaryDanger,
  NotificationVariant.SecondaryDanger,
];

const NotificationAlert = ({
  title,
  message,
  variant,
}: NotificationAlertProps): ReactElement => {
  const getIcon = (): JSX.Element => {
    if (successIconVariants.includes(variant)) {
      return <CheckMarkIcon />;
    }
    if (infoIconVariants.includes(variant)) {
      return <InfoIcon />;
    }
    if (warningIconVariants.includes(variant)) {
      return <WarningSignIcon />;
    }
    if (dangerIconVariants.includes(variant)) {
      return <ExclamationMarkIcon />;
    }

    return <InfoIcon />;
  };

  return (
    <div className="notification-alert-container">
      <div>
        <div className={`notification-alert ${variant}`}>
          <div className="notification-alert-content">
            <div className="notification-alert-icon">{getIcon()}</div>
            <div className="notification-alert-body">
              {title ? <div className="title">{title}</div> : null}
              {title && message ? (
                <div className="message">{message}</div>
              ) : null}
              {!title && message ? (
                <div className="message no-title">{message}</div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationAlert;
