import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GlobeIcon } from '../../assets/svg/GlobeIcon.svg';
import { ReactComponent as LockIcon } from '../../assets/svg/LockIcon.svg';
import { ReactComponent as PlusIcon } from '../../assets/svg/PlusIcon.svg';
import getDefaultPageSize from '../../common/configurations/pageConfig';
import CompletedArchiveDocument from '../../common/models/completedArchiveDocument';
import DocumentSearchOptions from '../../common/models/documentSearchOptions';
import KeyPairItem from '../../common/models/keypairItem';
import StorageProvider from '../../common/providers/storageProvider';
import QueryStringKey from '../../common/types/queryStringKey';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import SortItemKey from '../../common/types/sortItemKey';
import TaskBarSize from '../../common/types/taskBarSize';
import Tray from '../../common/types/tray';
import UserPermission from '../../common/types/userPermission';
import DefaultLoading from '../../components/DefaultLoading/DefaultLoading';
import PaginationNavigator from '../../components/Pagination/PaginationNavigator';
import PendingTrayArchiveSuccessFooter from '../../components/PendingTrayArchiveSuccessFooter/PendingTrayArchiveSuccessFooter';
import RestrictedArea from '../../components/RestrictedArea/RestrictedArea';
import ResultPendingTrayCaptureList from '../../components/ResultPendingTrayCaptureList/ResultPendingTrayCaptureList';
import PendingTrayCaptureSidePanel from '../../components/SidePanel/PendingTrayCaptureSidePanel';
import Tab from '../../components/Tab/Tab';
import PendingTrayTaskBar from '../../components/TaskBar/PendingTrayTaskBar';
import TaskBar from '../../components/TaskBar/TaskBar';
import TaskDescription from '../../components/TaskBar/TaskDescription';
import Toaster from '../../components/Toaster/Toaster';
import { useContainer } from '../../context/Container/ContainerContext';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import useDefaultDestinationUser from '../../hooks/useDefaultDestinationUser/useDefaultDestinationUser';
import usePendingTrayResult from '../../hooks/usePendingTrayResult/usePendingTrayResult';
import usePendingTrayScope from '../../hooks/usePendingTrayScope/usePendingTrayScope';
import usePrivatePendingTrayCount from '../../hooks/usePrivatePendingTrayCount/usePrivatePendingTrayCount';
import usePublicPendingTrayCount from '../../hooks/usePublicPendingTrayCount/usePublicPendingTrayCount';
import useQueryString from '../../hooks/useQueryString/useQueryString';
import { getCaptureData } from '../../services/capture/capture.service';
import { CaptureObjectResponse } from '../../services/capture/models/captureObjectResponse';
import Metadata from '../../services/capture/models/metadata';
import { getActiveDocumentTypes } from '../../services/documentType/documentType.service';
import DocumentType from '../../services/documentType/models/documentType';
import PendingTrayItemResponse from '../../services/pendingTray/models/pendingTrayItemResponse';
import { getPendingTray } from '../../services/pendingTray/pendingTray.service';
import { setMissingStatus } from '../../utils/metadataUtil';
import userHasPermission from '../../utils/userUtil';
import './PendingTrayCapturePage.scss';

type CaptureDocumentParams = {
  captureInfoId: string;
};

const emptyMetadata = {
  templateName: '',
  templateGroupName: '',
  data: [],
} as Metadata;

const PendingTrayCapturePage = ({
  captureInfoId,
}: CaptureDocumentParams): ReactElement => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const handleError = useErrorHandler();

  const queryString = useQueryString();
  const storage = useMemo(() => new StorageProvider(), []);

  const { containerKey, setContainerKey } = useContainer();
  const [privateTrayCount, setPrivateTrayCount] =
    usePrivatePendingTrayCount(containerKey);
  const [publicTrayCount, setPublicTrayCount] =
    usePublicPendingTrayCount(containerKey);

  // Visible control
  const [showLoading, setShowLoading] = useState(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isArchivingFile, setIsArchivingFile] = useState<boolean>(false);
  const [isDeletingFile, setIsDeletingFile] = useState<boolean>(false);
  const [isMovingFile, setIsMovingFile] = useState<boolean>(false);
  const [archiveStatus, setArchiveStatus] = useState(false);
  const [archiveError, setArchiveError] = useState(false);

  // Files
  const [checkedDocumentIds, setCheckedDocumentIds] = useState<string[]>([]);

  // Metadata from capture
  const [captureDocumentMetadata, setCaptureDocumentMetadata] =
    React.useState<Metadata>(emptyMetadata);

  // Document search options
  const [currentTab, setCurrentTab] = usePendingTrayScope();
  const defaultPageSize = getDefaultPageSize();
  const firstPage = 1;
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
  const [searchOptions, setSearchOptions] = useState<DocumentSearchOptions>({
    scope: currentTab,
    page: firstPage,
    pageSize: defaultPageSize,
    sortBy: `${SortItemKey.Date}_descending`,
  });

  const [pendingTrayItems, setPendingTrayItems] = usePendingTrayResult(
    searchOptions,
    containerKey
  );
  const pendingTrayItem = ((): PendingTrayItemResponse | undefined => {
    if (!pendingTrayItems?.results) return undefined;

    const filteredItems = pendingTrayItems.results.filter((pItem) => {
      const foundedItem = checkedDocumentIds.find((id) => id === pItem.id);
      return foundedItem ? pItem : false;
    });
    return filteredItems.length > 0 ? filteredItems[0] : undefined;
  })();

  const defaultDestinationUser = useDefaultDestinationUser(currentTab);
  const [selectedUserDestination, setSelectedUserDestination] =
    useState<KeyPairItem>(defaultDestinationUser);

  // Complete
  const [archiveDocumentCompletedList, setArchiveDocumentCompletedList] =
    useState<CompletedArchiveDocument[]>([]);
  const [showCompletedNotification, setShowCompletedNotification] =
    useState(false);
  const [showCompletedArchiveList, setShowCompletedArchiveList] =
    useState(false);

  const documentDisplayName = ((): string => {
    const value = queryString.get(QueryStringKey.DisplayName);
    if (value) {
      return value;
    }
    return captureInfoId;
  })();

  const loadPendingTray = useCallback(() => {
    setIsSearching(true);

    // Get records
    getPendingTray(searchOptions, containerKey).then((response) => {
      setIsSearching(false);
      setPendingTrayItems(response);
    });

    // Get private tray counts
    getPendingTray(
      {
        scope: Tray.Private,
        page: firstPage,
        pageSize: 1,
      },
      containerKey
    ).then((response) => {
      setPrivateTrayCount(response.totalCount);
    });

    // Get public tray counts
    getPendingTray(
      {
        scope: Tray.Public,
        page: firstPage,
        pageSize: 1,
      },
      containerKey
    ).then((response) => {
      setPublicTrayCount(response.totalCount);
    });
  }, [
    containerKey,
    searchOptions,
    setPendingTrayItems,
    setPrivateTrayCount,
    setPublicTrayCount,
  ]);

  const onTrayTabChange = (tabValue: string | undefined): void => {
    if (currentTab === tabValue || !tabValue) return;
    setCurrentTab(tabValue);
    setSearchOptions((currentSearchOptions) => ({
      ...currentSearchOptions,
      scope: tabValue,
    }));
    setCheckedDocumentIds([]);
  };
  // --------------------------------------- USE EFFECT ------------------------------------- //

  useEffect(() => {
    let controller: AbortController | null = new AbortController();
    async function readMetadata(): Promise<void> {
      setShowLoading(true);
      if (captureInfoId) {
        try {
          const response: CaptureObjectResponse = await getCaptureData(
            captureInfoId,
            handleError
          );
          if (response != null) {
            setContainerKey(response.containerKey);
            setMissingStatus(response.metadata.data);
            setCaptureDocumentMetadata({
              templateName: response.metadata.templateName,
              templateGroupName: response.metadata.templateGroupName,
              data: response.metadata.data,
            });
            controller = null;
          }
          setShowLoading(false);
        } catch (err) {
          handleError(err);
          setShowLoading(false);
        }
      }
    }
    if (userHasPermission(currentUser, UserPermission.CaptureDocuments)) {
      readMetadata();
    }
    return (): void => controller?.abort();
  }, [captureInfoId, currentUser, handleError, setContainerKey, storage]);

  useEffect(() => {
    if (!userHasPermission(currentUser, UserPermission.AccessPendingTray)) {
      return;
    }
    async function fetchDocumentTypes(): Promise<void> {
      const response: DocumentType[] = await getActiveDocumentTypes(
        handleError
      );
      if (response) {
        setDocumentTypes(response);
      }
    }
    fetchDocumentTypes();
  }, [currentUser, handleError]);

  // --------------------------------------- END USE EFFECT ------------------------------------- //

  // User click item in result list
  const itemClickHandler = async (
    item: PendingTrayItemResponse
  ): Promise<void> => {
    const { id } = item;
    const foundIndex = checkedDocumentIds.indexOf(id);
    const unchecked = foundIndex === -1;
    if (unchecked) {
      setCheckedDocumentIds([...checkedDocumentIds, id]);
    } else {
      const temp = [...checkedDocumentIds];
      temp.splice(foundIndex, 1);
      setCheckedDocumentIds(temp);
    }
  };

  const getUserTrayName = (): string => {
    if (!currentUser.name) {
      return t('pendingTrayPage.myPrivateTrayTabTitle');
    }
    return t('pendingTrayPage.userProfileNamePrivateTrayTabTitle', {
      userProfileName: currentUser.name.split(' ')[0],
    });
  };

  // After user click archive and archive file complete
  const onArchiveFileComplete = async (
    complete: CompletedArchiveDocument
  ): Promise<void> => {
    setShowCompletedArchiveList(false);
    setShowCompletedNotification(true);
    setArchiveDocumentCompletedList((currentCompleteList) => [
      ...currentCompleteList,
      complete,
    ]);
    // Reload pending tray items
    loadPendingTray();
  };

  const renderResults = (): ReactElement => (
    <div
      className={`result ${
        archiveDocumentCompletedList.length > 0 ? 'archived-list' : ''
      }`}
    >
      <ResultPendingTrayCaptureList
        itemResult={pendingTrayItems}
        onItemClick={itemClickHandler}
        documentTypes={documentTypes}
        searchOptions={searchOptions}
        setSearchOptions={setSearchOptions}
        isSearchingItem={isSearching}
        scope={currentTab}
        isWithArchiveNotification={archiveDocumentCompletedList.length > 0}
        loadPendingTray={loadPendingTray}
        setIsDeletingFile={(value: boolean): void => setIsDeletingFile(value)}
        checkedDocumentIds={checkedDocumentIds}
        setCheckedDocumentIds={(value: Array<string>): void =>
          setCheckedDocumentIds(value)
        }
        selectedUserDestination={selectedUserDestination}
        setSelectedUserDestination={setSelectedUserDestination}
        setIsMovingFile={setIsMovingFile}
      />
      <PaginationNavigator
        currentPage={searchOptions.page}
        lastPage={pendingTrayItems.lastPage}
        totalCount={pendingTrayItems.totalCount}
        pageSize={searchOptions.pageSize}
        onPageChange={(currentPage: number): void =>
          setSearchOptions((prevOptions) => ({
            ...prevOptions,
            page: currentPage,
          }))
        }
        descriptionTranslationKey="documentsViewScreen.paginationDescription"
      />
    </div>
  );

  if (!userHasPermission(currentUser, UserPermission.AccessPendingTray)) {
    return (
      <div className="pending-tray-container">
        <RestrictedArea />
      </div>
    );
  }

  return (
    <>
      {showLoading && <DefaultLoading />}
      {isArchivingFile && (
        <DefaultLoading
          status={archiveStatus}
          error={archiveError}
          progressTranslationKey="defaultLoadingComponent.archiving"
          successTranslationKey="defaultLoadingComponent.archiveSuccess"
          errorTranslationKey="defaultLoadingComponent.archiveError"
        />
      )}
      {isMovingFile && (
        <DefaultLoading
          progressTranslationKey="defaultLoadingComponent.moving"
          successTranslationKey="defaultLoadingComponent.moveSuccess"
          errorTranslationKey="defaultLoadingComponent.moveError"
        />
      )}
      {isDeletingFile && (
        <DefaultLoading
          progressTranslationKey="defaultLoadingComponent.deleting"
          successTranslationKey="defaultLoadingComponent.deleteSuccess"
          errorTranslationKey="defaultLoadingComponent.deleteError"
        />
      )}
      <Toaster />
      <div className="pending-tray-capture-container">
        <TaskBar headerPrefix={t('taskBar.capturePrefix')} isSubNavbar>
          <TaskDescription
            descriptionText={t('taskBar.uploadTaskDescription')}
            documentDisplayName={
              documentDisplayName ?? t('taskBar.undefinedDocument')
            }
          />
        </TaskBar>
        <PendingTrayTaskBar
          headerPrefix=""
          Icon={PlusIcon}
          size={TaskBarSize.Default}
          isShowButton={SDCButtonVariant.Warning}
          isHaveSideNavBar
          isSubNavbar
        >
          <Tab
            items={[
              {
                text: getUserTrayName(),
                value: Tray.Private,
                badgeText: `${privateTrayCount}`,
                Icon: LockIcon,
              },
              {
                text: t('pendingTrayPage.publicTrayTabTitle'),
                value: Tray.Public,
                badgeText: `${publicTrayCount}`,
                Icon: GlobeIcon,
              },
            ]}
            currentTabValue={currentTab}
            onChange={onTrayTabChange}
          />
        </PendingTrayTaskBar>
        <div className="view-container">
          <div className="main-content">
            {renderResults()}
            {archiveDocumentCompletedList.length !== 0 ? (
              <PendingTrayArchiveSuccessFooter
                captureInfoId=""
                metadata={captureDocumentMetadata}
                archiveCompleteFileList={archiveDocumentCompletedList}
                isShowCompletedNotification={showCompletedNotification}
                isShowCompletedArchiveList={showCompletedArchiveList}
                setShowLoading={setShowLoading}
              />
            ) : null}
          </div>
          <PendingTrayCaptureSidePanel
            key={checkedDocumentIds.length > 0 ? checkedDocumentIds[0] : ''}
            captureInfoId={captureInfoId}
            pendingTrayItemId={
              checkedDocumentIds.length > 0 ? checkedDocumentIds[0] : ''
            }
            currentUser={currentUser}
            documentTypes={documentTypes}
            metadata={captureDocumentMetadata}
            onArchiveCompleted={onArchiveFileComplete}
            setArchiveError={setArchiveError}
            setArchiveStatus={setArchiveStatus}
            setIsArchivingFile={setIsArchivingFile}
            checkedDocumentIds={checkedDocumentIds}
            fileName={pendingTrayItem?.fileName}
            clearCheckedDocumentIds={(): void => setCheckedDocumentIds([])}
          />
        </div>
      </div>
    </>
  );
};

export default PendingTrayCapturePage;
