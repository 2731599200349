import axios, { AxiosError } from 'axios';
import ErrorDetails from '../common/models/errorDetails';
import ErrorCode from '../common/types/errorCode';
import HttpStatusCode from '../common/types/httpStatusCode';

export const handledErrorCodes = [
  ErrorCode.Forbidden,
  ErrorCode.TenantNotFound,
  ErrorCode.UnauthorizedSubscription,
  ErrorCode.InvalidDocumentType,
];

const handleApplicationError = (
  error: any,
  errorHandler?: Function | undefined
): void => {
  // Cancel call api no need to redirect
  if (axios.isCancel(error) && errorHandler) {
    return;
  }

  // Any stack errors need to redirect
  if (!axios.isAxiosError(error) && errorHandler) {
    errorHandler(error);
    return;
  }

  // Some Axios errors need to redirect
  const object = error as AxiosError;
  const statusCode = object.response?.status;
  const errorDetails = object.response?.data as ErrorDetails;
  const shouldHandle =
    statusCode === HttpStatusCode.Unauthorized ||
    statusCode === HttpStatusCode.BadGateway ||
    statusCode === HttpStatusCode.ServiceUnavailable ||
    statusCode === HttpStatusCode.InternalServerError ||
    (statusCode === HttpStatusCode.BadRequest &&
      handledErrorCodes.includes(errorDetails.errorCode)) ||
    (statusCode === HttpStatusCode.Forbidden &&
      handledErrorCodes.includes(errorDetails.errorCode));

  if (shouldHandle && errorHandler) {
    errorHandler(error);
    return;
  }

  // Rethrow application error like BadRequest so the app can try/catch
  // to handle specific error instead of redirect
  throw error;
};

export default handleApplicationError;
