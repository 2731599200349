import dayjs from 'dayjs';
import React, { ReactElement, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MenuOptionsIcon } from '../../../assets/svg/MenuOptionsIcon.svg';
import { ReactComponent as PenOutlinedIcon } from '../../../assets/svg/PenOutlinedIcon.svg';
import AccessKeyEditType from '../../../common/types/accessKeyEditType';
import ConfirmationModalVariant from '../../../common/types/confirmationModalVariant';
import { useCurrentUser } from '../../../context/CurrentUser/CurrentUserContext';
import { revokeAccessKey } from '../../../services/accessKey/accessKey.service';
import AccessKey from '../../../services/accessKey/models/accessKey';
import ConfirmationModal from '../../DefaultModal/ConfirmationModal';
import DataGrid, { DataGridHeaderDefinition } from '../DataGrid';
import { DataGridRow } from '../GridRowCollection';
import './AccessKeyTable.scss';

export interface AccessKeyTableProps {
  items: AccessKey[];
  newItemId?: string;
  setSidePanel: (type: AccessKeyEditType, accessKey: AccessKey) => void;
  onDeletionCompleted: (user: AccessKey) => void;
  onLoading: () => void;
}

const AccessKeyTable = ({
  items,
  newItemId,
  setSidePanel,
  onDeletionCompleted,
  onLoading,
}: AccessKeyTableProps): ReactElement => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [selectedRow, setSelectedRow] = useState<AccessKey>();
  const headers: Array<DataGridHeaderDefinition> = [
    {
      columnKey: 'accessKeyPage.nameColHeader',
      translationKey: 'accessKeyPage.nameColHeader',
    },
    {
      columnKey: 'accessKeyPage.permissionColHeader',
      translationKey: 'accessKeyPage.permissionColHeader',
    },
    {
      columnKey: 'accessKeyPage.containerColHeader',
      translationKey: 'accessKeyPage.containerColHeader',
    },
    {
      columnKey: 'accessKeyPage.documentTypeColHeader',
      translationKey: 'accessKeyPage.documentTypeColHeader',
    },
    {
      columnKey: 'accessKeyPage.expiryColHeader',
      translationKey: 'accessKeyPage.expiryColHeader',
    },
    {
      columnKey: 'accessKeyPage.createdOnColHeader',
      translationKey: 'accessKeyPage.createdOnColHeader',
    },
    {
      columnKey: '',
      translationKey: '',
    },
  ];

  const editEntry = (type: AccessKeyEditType, accessKey: AccessKey): void => {
    setSelectedRow(accessKey);
    setSidePanel(type, accessKey);
  };

  // Dropdown needs access to the DOM node in order to position the Menu
  // eslint-disable-next-line react/prop-types
  const CustomToggle = React.forwardRef(
    (
      { children, onClick }: any,
      ref: React.LegacyRef<HTMLAnchorElement> | undefined
    ) => (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        ref={ref}
        href=""
        onClick={(e): void => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {/* Render custom icon here */}
        <MenuOptionsIcon />
        {children}
      </a>
    )
  );

  const deleteAccessKey = async (confirm: boolean): Promise<void> => {
    if (selectedRow && confirm) {
      onLoading();
      revokeAccessKey(selectedRow.id).then(() => {
        onDeletionCompleted(selectedRow);
        setSelectedRow(undefined);
      });
    }
  };

  const renderDeleteConfirmation = (): ReactElement => (
    <ConfirmationModal
      title={t('accessKeyPage.deleteTitle')}
      show={showDeleteConfirmationModal}
      setShow={setShowDeleteConfirmationModal}
      onAnswer={(isYes: boolean): Promise<void> => deleteAccessKey(isYes)}
      primaryButtonText={t('accessKeyPage.deleteConfirmButton')}
      dismissButtonText={t('confirmationModal.cancelButton')}
      variant={ConfirmationModalVariant.SecondaryWarning}
    >
      <div className="p-4">
        <p>
          {t('accessKeyPage.deleteMessage1', {
            name: selectedRow?.displayName,
            prefix: selectedRow?.prefix,
          })}
        </p>
        <p>{t('accessKeyPage.deleteMessage2')}</p>
        <strong>{t('accessKeyPage.deleteMessage3')}</strong>
      </div>
    </ConfirmationModal>
  );

  const renderDropdownMenu = (element: AccessKey): ReactElement => (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
      <Dropdown.Menu>
        <Dropdown.Item
          eventKey="1"
          onClick={(): void => {
            setSelectedRow(element);
            setShowDeleteConfirmationModal(true);
          }}
        >
          {t('accessKeyPage.deleteButton')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const renderExpiry = (accessKey: AccessKey): ReactElement => {
    if (!accessKey.validUntil) {
      return <div>{t('accessKeyPage.unsetExpiry')}</div>;
    }

    return (
      <div>
        {dayjs(accessKey.validUntil).format(currentUser.settings.dateFormat)}
      </div>
    );
  };

  const renderNewTag = (item: AccessKey): ReactElement => {
    if (item.id === newItemId) {
      return <span className="new-tag ms-2">{t('accessKeyPage.newTag')}</span>;
    }
    return <></>;
  };

  const rows: DataGridRow[] = items.map((item) => ({
    id: item.id,
    rowClass: item.id === selectedRow?.id ? 'selected' : '',
    columnExtraWidth: true,
    cells: [
      {
        columnKey: 'accessKeyPage.nameColHeader',
        dataKey: 'accessKeyPage.nameColHeader',
        value: item.displayName,
        template: (
          <div>
            <div className="d-flex">
              <div className="truncate">{item.displayName}</div>

              <PenOutlinedIcon
                className="ms-2 mt-2 clickable pen"
                onClick={(): void => editEntry(AccessKeyEditType.Name, item)}
              />
              {renderNewTag(item)}
            </div>
            <div className="tag">{item.prefix}</div>
          </div>
        ),
      },

      {
        columnKey: 'accessKeyPage.permissionColHeader',
        dataKey: 'accessKeyPage.permissionColHeader',
        value: item.permissionStatus,
        template: (
          <div className="d-flex name">
            {item.permissionStatus}
            <PenOutlinedIcon
              className="ms-2 clickable"
              onClick={(): void =>
                editEntry(AccessKeyEditType.Permission, item)
              }
            />
          </div>
        ),
      },
      {
        columnKey: 'accessKeyPage.containerColHeader',
        dataKey: 'accessKeyPage.containerColHeader',
        value: item.containerPermissionStatus,
        template: (
          <div className="d-flex name">
            {item.containerPermissionStatus}
            <PenOutlinedIcon
              className="ms-2 clickable"
              onClick={(): void => editEntry(AccessKeyEditType.Container, item)}
            />
          </div>
        ),
      },
      {
        columnKey: 'accessKeyPage.documentTypeColHeader',
        dataKey: 'accessKeyPage.documentTypeColHeader',
        value: item.documentTypePermissionStatus,
        template: (
          <div className="d-flex name">
            {item.documentTypePermissionStatus}{' '}
            <PenOutlinedIcon
              className="ms-2 clickable"
              onClick={(): void =>
                editEntry(AccessKeyEditType.DocumentType, item)
              }
            />
          </div>
        ),
      },
      {
        columnKey: 'accessKeyPage.expiryColHeader',
        dataKey: 'accessKeyPage.expiryColHeader',
        value: item.validUntil ?? '',
        template: (
          <div>
            <div className="d-flex name">
              {renderExpiry(item)}
              <PenOutlinedIcon
                className="ms-2 clickable"
                onClick={(): void => editEntry(AccessKeyEditType.Expiry, item)}
              />
            </div>
          </div>
        ),
      },
      {
        columnKey: 'accessKeyPage.createdOnColHeader',
        dataKey: 'accessKeyPage.createdOnColHeader',
        value: item.createdOn ?? '',
        template: (
          <>{dayjs(item.createdOn).format(currentUser.settings.dateFormat)}</>
        ),
      },
      {
        columnKey: 'dropdown-menu',
        dataKey: 'dropdown-menu',
        value: '',
        template: <div className="d-flex name">{renderDropdownMenu(item)}</div>,
      },
    ],
    clickable: false,
  }));

  return (
    <div className="access-key-table">
      <DataGrid
        headerDefinitions={headers}
        tableContent={rows}
        selectable={false}
        setSelectableStates={(): void => {}}
        sortableColumnKeys={[
          'accessKeyPage.nameColHeader',
          'accessKeyPage.expiryColHeader',
          'accessKeyPage.createdOnColHeader',
        ]}
        noResultsMessage=""
      />
      {renderDeleteConfirmation()}
    </div>
  );
};

export default AccessKeyTable;
AccessKeyTable.defaultProps = {
  newItemId: undefined,
};
