import { ReactElement, useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import LocationProvider from '../../common/providers/locationProvider';
import QueryStringKey from '../../common/types/queryStringKey';
import Loading from '../../components/Loading/Loading';
import { useContainer } from '../../context/Container/ContainerContext';
import useQueryString from '../../hooks/useQueryString/useQueryString';
import findEntity from '../../services/entity/entity.service';

const ENTITY_PREFIX = 'e.';

const EntityViewFromKeyValuePage = (): ReactElement => {
  const queryString = useQueryString();
  const displayName = queryString.get(QueryStringKey.DisplayName);
  const templateName = queryString.get(QueryStringKey.TemplateName);
  const { containerKey } = useContainer();
  const errorHandler = useErrorHandler();
  const dictionary: Record<string, string> = ((): Record<string, string> => {
    const result: Record<string, string> = {};
    Array.from(queryString)
      .filter(([key]) => key.startsWith(ENTITY_PREFIX))
      .forEach(([key, value]) => {
        const entityKey: string = key.substring(ENTITY_PREFIX.length);
        result[entityKey] = value;
      });
    return result;
  })();

  useEffect(() => {
    findEntity(dictionary, templateName, errorHandler)
      .then((response: any) => {
        new LocationProvider().replace(
          `/documents/entities/${response.id}?${QueryStringKey.ContainerKey}=${containerKey}&${QueryStringKey.DisplayName}=${displayName}`
        );
      })
      .catch(() => {
        new LocationProvider().replace('/error/404');
      });
  }, [
    containerKey,
    dictionary,
    displayName,
    errorHandler,
    queryString,
    templateName,
  ]);

  return <Loading />;
};

export default EntityViewFromKeyValuePage;
