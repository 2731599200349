import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReactComponent as DeleteIcon } from '../../assets/svg/DeleteIcon.svg';
import { ReactComponent as DownloadCloudSmallIcon } from '../../assets/svg/DownloadCloudSmallIcon.svg';
import getDefaultPageSize from '../../common/configurations/pageConfig';
import DocumentSearchOptions from '../../common/models/documentSearchOptions';
import CollapserPositionVariant from '../../common/types/collapserPositionVariant';
import ConfirmationModalVariant from '../../common/types/confirmationModalVariant';
import QueryStringKey from '../../common/types/queryStringKey';
import SortItemKey from '../../common/types/sortItemKey';
import TaskBarSize from '../../common/types/taskBarSize';
import UserPermission from '../../common/types/userPermission';
import DefaultLoading from '../../components/DefaultLoading/DefaultLoading';
import ConfirmationModal from '../../components/DefaultModal/ConfirmationModal';
import DocumentViewModal from '../../components/DocumentViewModal/DocumentViewModal';
import PaginationNavigator from '../../components/Pagination/PaginationNavigator';
import ReadonlyArchiveDocumentSection from '../../components/ReadonlyArchiveDocumentSection/ReadonlyArchiveDocumentSection';
import RestrictedArea from '../../components/RestrictedArea/RestrictedArea';
import ResultDocumentList from '../../components/ResultDocumentList/ResultDocumentList';
import DocumentReadonlySidePanel from '../../components/SidePanel/DocumentReadonlySidePanel';
import TaskBar from '../../components/TaskBar/TaskBar';
import { useContainer } from '../../context/Container/ContainerContext';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import useQueryString from '../../hooks/useQueryString/useQueryString';
import Metadata from '../../services/capture/models/metadata';
import {
  deleteDocuments,
  downloadArchivedDocuments,
  searchDocuments,
} from '../../services/document/document.service';
import ArchivedDocumentDetail from '../../services/document/models/archivedDocumentDetail';
import { getAllDocumentTypes } from '../../services/documentType/documentType.service';
import DocumentType from '../../services/documentType/models/documentType';
import PaginationResponse from '../../services/paginationResponse';
import { isViewable } from '../../utils/fileUtil';
import userHasPermission from '../../utils/userUtil';
import './EntityViewPage.scss';

interface EntityViewPageParams {
  entityId: string;
  associated: string;
}

const defaultDocumentResponse = {
  pageSize: 0,
  lastPage: 0,
  totalCount: 0,
  page: 0,
  results: [],
} as PaginationResponse<ArchivedDocumentDetail>;

const EntityViewPage = (): ReactElement => {
  const { t } = useTranslation();
  const queryString = useQueryString();
  const errorHandler = useErrorHandler();
  const { currentUser } = useCurrentUser();
  const { containerKey } = useContainer();
  const { entityId } = useParams<EntityViewPageParams>();
  const abortControllerRef = useRef<AbortController>(new AbortController());
  const [isSearchingDocument, setIsSearchingDocument] =
    useState<boolean>(false);
  const [documentResponse, setDocumentResponse] = useState<
    PaginationResponse<ArchivedDocumentDetail>
  >(defaultDocumentResponse);
  const defaultPageSize = getDefaultPageSize();
  const firstPage = 1;
  const associated = queryString.get(QueryStringKey.Associated);
  const entityIds = associated ? `${entityId},${associated}` : entityId;
  const [searchOptions, setSearchOptions] = useState<DocumentSearchOptions>(
    () =>
      ({
        page: firstPage,
        pageSize: defaultPageSize,
        sortBy: `${SortItemKey.Date}_descending`,
        entityIds,
      } as DocumentSearchOptions)
  );

  const [showDocumentViewModal, setShowDocumentViewModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = React.useState<
    File | undefined
  >(undefined);
  const [selectedDocumentMetadata, setSelectedDocumentMetadata] =
    React.useState<Metadata>({
      templateName: '',
      templateGroupName: '',
      data: [],
    });
  const [document, setDocument] = useState<ArchivedDocumentDetail | undefined>(
    undefined
  );
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
  const [showDeletionConfirmationDialog, setShowDeletionConfirmationDialog] =
    useState(false);

  const [checkedDocumentIds, setCheckedDocumentIds] = useState<string[]>([]);

  useEffect(() => {
    let ignore = false;
    let abortController: AbortController | null = null;

    async function fetchDocuments(): Promise<void> {
      setIsSearchingDocument(true);

      const response = await searchDocuments(
        searchOptions,
        containerKey,
        abortController?.signal,
        errorHandler
      );
      if (response && !ignore) {
        setDocumentResponse(response);
        setIsSearchingDocument(false);
      }
    }
    if (userHasPermission(currentUser, UserPermission.ViewDocuments)) {
      abortController = new AbortController();
      fetchDocuments();
    }

    return (): void => {
      ignore = true;
      if (abortController) abortController.abort();
    };
  }, [currentUser, entityId, errorHandler, searchOptions, containerKey]);

  const onDownloadFiles = (documentIds: string[]): void => {
    async function downloadDocumentFiles(): Promise<void> {
      const response = await downloadArchivedDocuments(documentIds);

      const fileUrl = URL.createObjectURL(response);
      const link = window.document.createElement('a');
      link.href = fileUrl;
      link.download = response.name;
      link.click();

      window.URL.revokeObjectURL(fileUrl);
      link.remove();
    }
    downloadDocumentFiles();
  };

  const deleteSelectedDocuments = async (
    isConfimred: boolean
  ): Promise<void> => {
    if (!isConfimred) {
      return;
    }

    async function deleteDocumentsInternal(): Promise<void> {
      await deleteDocuments(checkedDocumentIds);
      setDocumentResponse((previousState) => ({
        ...previousState,
        lastPage:
          (previousState.totalCount - checkedDocumentIds.length) /
          previousState.pageSize,
        totalCount: previousState.totalCount - checkedDocumentIds.length,
        results: previousState.results.filter(
          (x) => !checkedDocumentIds.includes(x.id)
        ),
      }));
    }
    deleteDocumentsInternal();
    setCheckedDocumentIds([]);
  };

  const [rowAction, setRowAction] = useState([
    {
      name: 'Download',
      displayName: t('documentsViewScreen.downloadIconDisplayName'),
      Icon: DownloadCloudSmallIcon,
      onAction: onDownloadFiles.bind(this),
    },
  ]);

  useEffect(() => {
    if (!currentUser) return;

    if (userHasPermission(currentUser, UserPermission.DeleteDocuments)) {
      setRowAction((act) => [
        ...act,
        {
          name: 'Delete',
          displayName: t('documentsViewScreen.deleteIconDisplayName'),
          Icon: DeleteIcon,
          onAction: (): void => setShowDeletionConfirmationDialog(true),
        },
      ]);
    }
  }, [currentUser, t]);

  const itemClickHandler = async (
    documentItem: ArchivedDocumentDetail
  ): Promise<void> => {
    // If select the same one, do nothing
    if (document === documentItem) {
      return;
    }

    setSelectedDocumentMetadata(documentItem.metadata);
    setDocument(documentItem);
    setSelectedDocument(undefined);
  };

  const loadFileContent = async (): Promise<void> => {
    if (!document) return;
    if (!isViewable(document?.fileName)) {
      return;
    }
    setSelectedDocument(undefined);
    const fileContent = await downloadArchivedDocuments(
      [document.id],
      errorHandler
    );
    if (fileContent) {
      setSelectedDocument(fileContent);
    }
  };

  const getDisplayName = (): string => {
    const displayName = queryString.get(QueryStringKey.DisplayName);
    if (displayName) {
      return displayName;
    }
    return entityId.toString();
  };

  const handleSearch = (options: DocumentSearchOptions): void => {
    if (abortControllerRef && isSearchingDocument) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    const newOptions = { ...options, entityIds } as DocumentSearchOptions;

    setIsSearchingDocument(true);
    setSearchOptions(newOptions);

    searchDocuments(
      newOptions,
      containerKey,
      abortControllerRef.current.signal,
      errorHandler
    ).then((response) => {
      setIsSearchingDocument(false);
      if (response) {
        setDocumentResponse(response);
      }
    });
  };

  const renderResults = (): ReactElement => {
    if (documentResponse === defaultDocumentResponse) {
      return <DefaultLoading />;
    }

    return (
      <>
        <ResultDocumentList
          documentResult={documentResponse}
          onItemClick={itemClickHandler}
          documentTypes={documentTypes}
          actions={rowAction}
          isFetchingDocuments={isSearchingDocument}
          checkedDocumentIds={checkedDocumentIds}
          setCheckedDocumentIds={setCheckedDocumentIds}
          resultCount={
            documentResponse === defaultDocumentResponse
              ? undefined
              : documentResponse.totalCount
          }
          onSearch={handleSearch}
          title={getDisplayName()}
          collapsable
          mainEntityId={associated ? entityId : undefined}
        />
        <PaginationNavigator
          currentPage={searchOptions.page}
          lastPage={documentResponse.lastPage}
          totalCount={documentResponse.totalCount}
          pageSize={documentResponse.pageSize}
          onPageChange={(currentPage: number): void => {
            const newOptions = { ...searchOptions, page: currentPage };
            handleSearch(newOptions);
          }}
          descriptionTranslationKey="documentsViewScreen.paginationDescription"
        />
      </>
    );
  };

  useEffect(() => {
    async function fetchDocumentTypes(): Promise<void> {
      const response = await getAllDocumentTypes(errorHandler);
      if (response) {
        setDocumentTypes(response);
      }
    }
    fetchDocumentTypes();
  }, [errorHandler]);

  const handleModalClose = (): void => {
    setShowDocumentViewModal(false);
  };

  if (!userHasPermission(currentUser, UserPermission.ViewDocuments)) {
    return (
      <div className="entity-view-page">
        <RestrictedArea />
      </div>
    );
  }

  return (
    <>
      <div className="entity-view-page">
        <TaskBar
          headerPrefix={t('taskBar.viewPrefix')}
          size={TaskBarSize.Large}
          darkMode
          isSubNavbar
        >
          {getDisplayName()}
        </TaskBar>
        {document === undefined ? null : (
          <DocumentViewModal
            show={showDocumentViewModal}
            onClose={(): void => handleModalClose()}
          >
            <ReadonlyArchiveDocumentSection
              selectedFile={selectedDocument}
              documentName={
                document && document.fileName ? document.fileName : ''
              }
              document={document}
              documentTypes={documentTypes}
              onCloseModal={(): void => handleModalClose()}
            />
          </DocumentViewModal>
        )}

        <div className="entity-document-main-content">
          <div className="result-container"> {renderResults()}</div>
          <DocumentReadonlySidePanel
            loadFileContent={loadFileContent}
            file={selectedDocument}
            document={document}
            documentTypes={documentTypes}
            metadata={selectedDocumentMetadata}
            id={document?.id || ''}
            settings={currentUser.settings}
            collapserPosition={CollapserPositionVariant.WithTitleBar}
            withSideBar={false}
          />
        </div>
      </div>
      <ConfirmationModal
        title={t('confirmationModal.deleteDocumentDialogTitle')}
        bodyText={t('confirmationModal.deleteDocumentDialogBody', {
          count: checkedDocumentIds.length,
        })}
        show={showDeletionConfirmationDialog}
        setShow={setShowDeletionConfirmationDialog}
        onAnswer={deleteSelectedDocuments}
        primaryButtonText={t('confirmationModal.deleteButton')}
        dismissButtonText={t('confirmationModal.cancelButton')}
        variant={ConfirmationModalVariant.SecondaryWarning}
      />
    </>
  );
};

export default EntityViewPage;
