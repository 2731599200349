import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchNotFoundImage from '../../assets/img/search-not-found.png';
import DocumentSearchOptions from '../../common/models/documentSearchOptions';
import KeyPairItem from '../../common/models/keypairItem';
import DocumentType from '../../services/documentType/models/documentType';
import PaginationResponse from '../../services/paginationResponse';
import PendingTrayItemResponse from '../../services/pendingTray/models/pendingTrayItemResponse';
import buildDocumentTypeDropdownListItems from '../../utils/documentTypeUtil';
import LoadingSet from '../LoadingSet/LoadingSet';
import PendingTrayActionPane from '../PendingTrayActionPane/PendingTrayActionPane';
import PendingTrayDeletionModal from '../PendingTrayDeletionModal/PendingTrayDeletionModal';
import PendingTrayDocumentMovingModal from '../PendingTrayDocumentMovingModal/PendingTrayDocumentMovingModal';
import ResultPendingTrayListItem from '../ResultPendingTrayListItem/ResultPendingTrayListItem';
import SearchBox from '../SearchBox/SearchBox';
import './ResultPendingTrayCaptureList.scss';

interface ResultPendingTrayCaptureListProps {
  itemResult: PaginationResponse<PendingTrayItemResponse>;
  onItemClick: (item: PendingTrayItemResponse) => void;
  documentTypes: DocumentType[];
  searchOptions: DocumentSearchOptions;
  setSearchOptions: React.Dispatch<React.SetStateAction<DocumentSearchOptions>>;
  isSearchingItem: boolean;
  isNoTaskBar?: boolean;
  isWithArchiveNotification: boolean;
  scope: string;
  loadPendingTray: () => void;
  setIsDeletingFile: (isDeletingFile: boolean) => void;
  setIsMovingFile: (isMovingFile: boolean) => void;
  checkedDocumentIds: Array<string>;
  setCheckedDocumentIds: (checkedDocumentIds: Array<string>) => void;
  selectedUserDestination: KeyPairItem;
  setSelectedUserDestination: React.Dispatch<React.SetStateAction<KeyPairItem>>;
}

const ResultPendingTrayCaptureList = ({
  itemResult,
  onItemClick,
  documentTypes,
  searchOptions,
  setSearchOptions,
  isSearchingItem,
  isNoTaskBar,
  isWithArchiveNotification,
  scope,
  loadPendingTray,
  setIsDeletingFile,
  setIsMovingFile,
  checkedDocumentIds,
  setCheckedDocumentIds,
  selectedUserDestination,
  setSelectedUserDestination,
}: ResultPendingTrayCaptureListProps): ReactElement => {
  const { t } = useTranslation();
  const selectedDocumentId =
    checkedDocumentIds.length > 0 ? checkedDocumentIds[0] : '';

  const allDocumentType = t('documentsViewScreen.documentTypeDropDownDefault');
  const deletedDocumentTypeState = t(
    'documentsViewScreen.deletedDocumentTypeState'
  );
  const documentTypeItems: KeyPairItem[] = buildDocumentTypeDropdownListItems(
    allDocumentType,
    documentTypes,
    deletedDocumentTypeState
  );
  const [searched, setSearched] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [idsToDelete, setIdsToDelete] = useState<string[]>([]);
  const [isDocumentMovingModalVisible, setIsDocumentMovingModalVisible] =
    useState(false);

  const hasNoResult = itemResult.totalCount === 0;

  const renderItems = (): JSX.Element => {
    if (hasNoResult) {
      const message = searched
        ? t('pendingTrayPage.noSearchResults')
        : t('pendingTrayPage.noResults');
      return (
        <>
          <div className="result-placeholder-container">
            <img src={SearchNotFoundImage} alt="" />
            <div className="not-found-content">{message}</div>
          </div>
          <div className="simple-result-placeholder-container">{message}</div>
        </>
      );
    }

    return (
      <>
        {itemResult?.results?.map((item: PendingTrayItemResponse) => (
          <ResultPendingTrayListItem
            key={item.id}
            date={item.modifiedOn}
            subtitle={
              documentTypes.find((x) => x.name === item.documentType)
                ?.displayName
            }
            title={item.fileName}
            authorName={item.createdBy}
            onClick={(): void => onItemClick(item)}
            onCheckChange={(): void => onItemClick(item)}
            selected={item.id === selectedDocumentId}
            checked={checkedDocumentIds.includes(item.id)}
          />
        ))}
      </>
    );
  };

  return (
    <>
      <div
        className={`result-pending-tray-container ${
          isWithArchiveNotification ? 'pad-for-notification' : ''
        }`}
      >
        <SearchBox
          documentTypeItems={documentTypeItems}
          onSearch={(text: string, item: KeyPairItem | undefined): void => {
            setSearchOptions((prevOptions) => ({
              ...prevOptions,
              text,
              scope,
              documentType:
                item?.Name === allDocumentType ? undefined : item?.Value,
            }));
            setSearched(true);
          }}
        />
        <div className="result-description">
          {hasNoResult
            ? t('pendingTrayPage.resultDescriptionNotFound')
            : t('pendingTrayPage.resultDescription', {
                count: itemResult.totalCount ?? 0,
              })}
        </div>
        <PendingTrayActionPane
          itemResult={itemResult}
          searchOptions={searchOptions}
          setSearchOptions={setSearchOptions}
          checkedDocumentIds={checkedDocumentIds}
          setCheckedDocumentIds={setCheckedDocumentIds}
          setIsDocumentMovingModalVisible={(value: boolean): void =>
            setIsDocumentMovingModalVisible(value)
          }
          setShowDeleteConfirmationModal={(value: boolean): void =>
            setShowDeleteConfirmationModal(value)
          }
          setIdsToDelete={(value: Array<string>): void => setIdsToDelete(value)}
        />
        <div
          className={`items-container ${
            isWithArchiveNotification ? 'pad-for-notification' : ''
          } ${isNoTaskBar ? 'full' : ''}`}
        >
          {isSearchingItem ? <LoadingSet /> : renderItems()}
        </div>
      </div>
      <PendingTrayDocumentMovingModal
        show={isDocumentMovingModalVisible}
        setShow={setIsDocumentMovingModalVisible}
        selectedUserDestination={selectedUserDestination}
        setSelectedUserDestination={setSelectedUserDestination}
        scope={scope}
        checkedDocumentIds={checkedDocumentIds}
        setCheckedDocumentIds={setCheckedDocumentIds}
        setIsMovingFile={setIsMovingFile}
        loadPendingTray={loadPendingTray}
      />
      <PendingTrayDeletionModal
        idsToDelete={idsToDelete}
        setCheckedDocumentIds={setCheckedDocumentIds}
        showDeleteConfirmationModal={showDeleteConfirmationModal}
        setShowDeleteConfirmationModal={setShowDeleteConfirmationModal}
        loadPendingTray={loadPendingTray}
        setIsDeletingFile={setIsDeletingFile}
      />
    </>
  );
};

ResultPendingTrayCaptureList.defaultProps = {
  isNoTaskBar: true,
};

export default ResultPendingTrayCaptureList;
