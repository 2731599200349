import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import ArchivedDocument from '../../services/archivedDocument';
import { getFileExtension } from '../../utils/fileUtil';
import FileInformationEntry from './FileInformationEntry';

dayjs.extend(utc);

interface FileInformationProps {
  document: ArchivedDocument;
}

const FileInformation = ({ document }: FileInformationProps): ReactElement => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();

  return (
    <div>
      <FileInformationEntry
        labelText={t('fileInformation.originalFileNameLabel')}
        valueText={document.fileName}
      />
      <FileInformationEntry
        labelText={t('fileInformation.archivedDateLabel')}
        valueText={dayjs
          .utc(document.createdOn)
          .format(currentUser.settings.dateTimeFormat)}
      />
      <FileInformationEntry
        labelText={t('fileInformation.archivedByLabel')}
        valueText={document.createdBy ?? '-'}
      />
      <FileInformationEntry
        labelText={t('fileInformation.containerNameLabel')}
        valueText={document.containerName}
      />
      <FileInformationEntry
        labelText={t('fileInformation.fileTypeLabel')}
        valueText={getFileExtension(document.fileName) ?? '-'}
      />
      <FileInformationEntry
        labelText={t('fileInformation.captureMethodLabel')}
        valueText={document.captureMethod}
      />
      <FileInformationEntry
        labelText={t('fileInformation.barcodeCapturedLabel')}
        valueText={document.barcode ? `${document.barcode}`.toUpperCase() : '-'}
      />
    </div>
  );
};

export default FileInformation;
