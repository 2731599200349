import { HubConnectionState } from '@microsoft/signalr';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useErrorHandler } from 'react-error-boundary';
import signalRClientEventName from '../../common/constants/signalRClientEventName.constant';
import DocumentSearchOptions from '../../common/models/documentSearchOptions';
import UserPermission from '../../common/types/userPermission';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import { useSignalR } from '../../context/SignalR/SignalRContext';
import PaginationResponse from '../../services/paginationResponse';
import PendingTrayItemResponse from '../../services/pendingTray/models/pendingTrayItemResponse';
import { getPendingTray } from '../../services/pendingTray/pendingTray.service';
import userHasPermission from '../../utils/userUtil';

const defaultPendingTrayItems = {
  pageSize: 0,
  lastPage: 0,
  totalCount: 0,
  page: 0,
  results: [],
};

const usePendingTrayResult = (
  searchOptions: DocumentSearchOptions,
  containerKey: string
): [
  PaginationResponse<PendingTrayItemResponse>,
  Dispatch<SetStateAction<PaginationResponse<PendingTrayItemResponse>>>,
  boolean,
  Dispatch<SetStateAction<boolean>>
] => {
  const { currentUser } = useCurrentUser();
  const { connection, connectionState } = useSignalR();
  const handleError = useErrorHandler();
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [result, setResult] = useState<
    PaginationResponse<PendingTrayItemResponse>
  >(defaultPendingTrayItems);

  const refreshData = useCallback((): void => {
    const newSearchOptions = { ...searchOptions, containerKey };
    getPendingTray(newSearchOptions, containerKey).then((response) => {
      setResult(response);
    });
  }, [containerKey, searchOptions]);

  useEffect(() => {
    if (connectionState === HubConnectionState.Connected) {
      connection.on(
        signalRClientEventName.PUBLIC_PENDING_TRAY_UPDATE_NOTIFICATION,
        refreshData
      );
    }

    return (): void => {
      connection.off(
        signalRClientEventName.PUBLIC_PENDING_TRAY_UPDATE_NOTIFICATION,
        refreshData
      );
    };
  }, [
    connection,
    connectionState,
    containerKey,
    handleError,
    refreshData,
    searchOptions,
  ]);

  useEffect(() => {
    let ignore = false;
    let abort: AbortController | null = null;
    if (userHasPermission(currentUser, UserPermission.AccessPendingTray)) {
      abort = new AbortController();
      setIsSearching(true);

      const newSearchOptions = { ...searchOptions, containerKey };
      getPendingTray(
        newSearchOptions,
        containerKey,
        abort.signal,
        handleError
      ).then((response) => {
        if (!ignore) {
          setIsSearching(false);
          setResult(response);
        }
      });
    }

    return (): void => {
      setIsSearching(false);
      ignore = true;
      abort = null;
    };
  }, [containerKey, currentUser, handleError, searchOptions]);

  return [result, setResult, isSearching, setIsSearching];
};

export default usePendingTrayResult;
