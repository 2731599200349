import { ReactElement, ReactNode, useState } from 'react';
import { ReactComponent as CloseIconCircle } from '../../assets/svg/CloseIconCircle.svg';
import './FilterTag.scss';

interface FilterTagProps {
  children: ReactNode;
  onRemove: () => void;
}

const FilterTag = ({ children, onRemove }: FilterTagProps): ReactElement => {
  const [showClose, setShowClose] = useState(false);
  return (
    <div
      className="filter-tag"
      onMouseOver={(): void => {
        setShowClose(true);
      }}
      onMouseLeave={(): void => {
        setShowClose(false);
      }}
      onFocus={(): void => {
        setShowClose(true);
      }}
    >
      {children}
      {showClose && (
        <button type="button" onClick={(): void => onRemove()}>
          <CloseIconCircle />
        </button>
      )}
    </div>
  );
};

export default FilterTag;
