import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ReactComponent as CloudCheckIcon } from '../../assets/svg/CloudCheckIcon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/svg/DeleteIcon.svg';
import { ReactComponent as KeyLargeIcon } from '../../assets/svg/KeyLargeIcon.svg';
import { ReactComponent as PendingTrayIcon } from '../../assets/svg/PendingTrayIcon.svg';
import { ReactComponent as ProtectionIcon } from '../../assets/svg/ProtectionIcon.svg';
import { ReactComponent as ReportIcon } from '../../assets/svg/ReportIcon.svg';
import { ReactComponent as SearchMenuIcon } from '../../assets/svg/SearchMenuIcon.svg';
import { ReactComponent as TeamManagementIcon } from '../../assets/svg/TeamManagementIcon.svg';

import PageTitle from '../../common/pageTitle';
import ContainerScope from '../../common/types/containerScope';
import Page from '../../common/types/page';
import QueryStringKey from '../../common/types/queryStringKey';

const useApplicationLink = (): PageTitle | undefined => {
  const { t } = useTranslation();
  const [pageTitle, setPageTitle] = useState<PageTitle | undefined>(undefined);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/documents/capture')) {
      setPageTitle({
        titleText: t('mainMenu.captureTitle'),
        pageType: Page.ApplicationSpecific,
        showUserMenu: true,
        isScopeContainer: ContainerScope.Text,
      });
    } else if (location.pathname.startsWith('/documents/entities')) {
      const specifiedContainerKey = location.search.includes(
        `${QueryStringKey.ContainerKey}=`
      );
      setPageTitle({
        titleText: t('mainMenu.viewDocumentsTitle'),
        pageType: Page.ApplicationSpecific,
        showUserMenu: true,
        isScopeContainer: specifiedContainerKey
          ? ContainerScope.Text
          : ContainerScope.DropDown,
      });
    } else if (location.pathname.startsWith('/qr-codes/')) {
      setPageTitle({
        titleText: '',
        pageType: Page.ApplicationSpecific,
        showUserMenu: true,
        isScopeContainer: ContainerScope.Text,
      });
    } else if (location.pathname.startsWith('/documents/')) {
      setPageTitle({
        titleText: t('mainMenu.viewDocumentTitle'),
        pageType: Page.ApplicationSpecific,
      });
    } else if (location.pathname.startsWith('/pending-tray')) {
      setPageTitle({
        Icon: PendingTrayIcon,
        titleText: t('mainMenu.pendingTrayTitle'),
        pageType: Page.ApplicationSpecific,
        showUserMenu: true,
        isScopeContainer: ContainerScope.DropDown,
      });
    } else if (location.pathname.startsWith('/qrcode')) {
      setPageTitle({
        titleText: t('mainMenu.qrCodeTitle'),
        pageType: Page.ApplicationSpecific,
        showUserMenu: true,
        isScopeContainer: ContainerScope.Text,
      });
    } else if (location.pathname.startsWith('/search')) {
      setPageTitle({
        Icon: SearchMenuIcon,
        titleText: t('mainMenu.searchTitle'),
        descriptionText: t('mainMenu.searchDescription'),
        pageType: Page.ApplicationSpecific,
        showUserMenu: true,
        isScopeContainer: ContainerScope.DropDown,
      });
    } else if (location.pathname.startsWith('/subscriptions')) {
      setPageTitle({
        Icon: SearchMenuIcon,
        titleText: t('mainMenu.subscriptionTitle'),
        pageType: Page.ApplicationSpecific,
        showUserMenu: true,
        isScopeContainer: ContainerScope.None,
      });
    } else if (location.pathname.startsWith('/team-management')) {
      setPageTitle({
        Icon: TeamManagementIcon,
        titleText: t('mainMenu.teamManagementTitle'),
        descriptionText: t('mainMenu.teamManagementDescription'),
        pageType: Page.ApplicationSpecific,
        showUserMenu: true,
        isScopeContainer: ContainerScope.None,
      });
    } else if (location.pathname.startsWith('/container-management')) {
      setPageTitle({
        Icon: CloudCheckIcon,
        titleText: t('mainMenu.containerManagementTitle'),
        pageType: Page.ApplicationSpecific,
        showUserMenu: true,
        isScopeContainer: ContainerScope.None,
        useSettingsMenu: true,
      });
    } else if (location.pathname.startsWith('/document-protection')) {
      setPageTitle({
        Icon: ProtectionIcon,
        titleText: t('mainMenu.documentProtectionMenuItem'),
        pageType: Page.ApplicationSpecific,
        showUserMenu: true,
        isScopeContainer: ContainerScope.None,
        useSettingsMenu: true,
      });
    } else if (location.pathname.startsWith('/api-access')) {
      setPageTitle({
        Icon: KeyLargeIcon,
        titleText: t('mainMenu.accessKeyTitle'),
        pageType: Page.ApplicationSpecific,
        showUserMenu: true,
        isScopeContainer: ContainerScope.None,
        useSettingsMenu: true,
      });
    } else if (location.pathname.startsWith('/trash')) {
      setPageTitle({
        Icon: DeleteIcon,
        titleText: t('mainMenu.trashAreaTitle'),
        descriptionText: t('mainMenu.trashAreaDescription'),
        pageType: Page.ApplicationSpecific,
        showUserMenu: true,
        isScopeContainer: ContainerScope.DropDown,
      });
    } else if (location.pathname.startsWith('/report')) {
      setPageTitle({
        Icon: ReportIcon,
        titleText: t('mainMenu.reportTitle'),
        pageType: Page.ApplicationSpecific,
        showUserMenu: true,
        isScopeContainer: ContainerScope.DropDown,
      });
    } else if (location.pathname.startsWith('/error')) {
      setPageTitle({
        pageType: Page.Error,
      });
    } else {
      setPageTitle({
        pageType: Page.Landing,
      });
    }
  }, [location.pathname, location.search, t]);

  return pageTitle;
};

export default useApplicationLink;
