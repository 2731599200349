import dayjs from 'dayjs';
import KeyPairItem from '../models/keypairItem';
import UserPermission from '../types/userPermission';

const defaultExpiryPeriod = 2;
const defaultExpiry = dayjs().add(defaultExpiryPeriod, 'year').toDate();

const accessKeyConstants = {
  MaxDisplayNameLength: 40,
  OptionExpire: 'expire',
  OptionExpireDefault: 'expire-default',
  OptionNonExpire: 'non-expire',
  DefaultExpiryPeriod: defaultExpiryPeriod,
  DefaultExpiry: defaultExpiry,
  Permissions: [
    {
      Value: UserPermission.CaptureDocuments,
      Name: 'permission.captureDocuments',
    },
    {
      Value: UserPermission.SearchArchivedDocuments,
      Name: 'permission.searchArchivedDocuments',
    },
    {
      Value: UserPermission.ViewDocuments,
      Name: 'permission.viewDocuments',
    },
    {
      Value: UserPermission.DeleteDocuments,
      Name: 'permission.deleteDocuments',
    },
    {
      Value: UserPermission.AccessPendingTray,
      Name: 'permission.accessPendingTray',
    },
    {
      Value: UserPermission.AccessTrash,
      Name: 'permission.accessTrash',
    },
    {
      Value: UserPermission.ViewAllTrashDocuments,
      Name: 'permission.viewAllTrashDocuments',
    },
    {
      Value: UserPermission.ViewAuditLogs,
      Name: 'permission.viewAuditLogs',
    },
    {
      Value: UserPermission.BillingManagement,
      Name: 'permission.billingManagement',
    },
    {
      Value: UserPermission.ConfigureSystemSettings,
      Name: 'permission.configureSystemSettings',
    },
    {
      Value: UserPermission.MigrateData,
      Name: 'permission.migrateData',
    },
  ] as KeyPairItem[],
};

export default accessKeyConstants;
