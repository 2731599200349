import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import DateTimeFormatter from '../DateTime/DateTimeFormatter';
import SDCCheckbox from '../SDCCheckbox/SDCCheckbox';
import './ResultPendingTrayListItem.scss';

interface ResultPendingTrayListItemProps {
  title: string;
  subtitle: string | undefined;
  date: Date;
  authorName: string | undefined;
  onClick: () => void;
  onCheckChange: (value: boolean | undefined) => void;
  selected: boolean;
  checked: boolean;
}

const ResultPendingTrayListItem = ({
  title,
  subtitle,
  date,
  authorName,
  onClick,
  onCheckChange,
  selected,
  checked,
}: ResultPendingTrayListItemProps): ReactElement => {
  const { t } = useTranslation();
  const maxTitleLength = 50;

  return (
    <div
      data-hj-suppress
      className={`pending-tray-list-item ${
        selected || checked ? 'selected' : ''
      }`}
    >
      {title?.length > maxTitleLength ? (
        <ReactTooltip place="top" effect="solid" multiline />
      ) : null}
      <div className="checkbox-container">
        <SDCCheckbox checked={checked} onCheckChange={onCheckChange} />
      </div>
      <div>
        <button type="button" onClick={onClick} data-tip={title}>
          <span className="item-body">
            <div className={`document-type ${subtitle ? '' : 'undefined'}`}>
              {subtitle ?? t('listItem.undefinedDocumentType')}
            </div>
            {title}
          </span>
          <span className="details">
            <div>
              <DateTimeFormatter date={date} format="DD MMM YYYY" />
            </div>
            <div className="author-name">
              {authorName ?? t('documentsViewScreen.undefinedAuthor')}
            </div>
          </span>
        </button>
      </div>
    </div>
  );
};

export default ResultPendingTrayListItem;
