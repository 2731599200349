import { ReactElement, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../assets/svg/CloseIcon.svg';
import KeyPairItem from '../../common/models/keypairItem';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import SDCButton from '../SDCButton/SDCButton';
import SDCDropdown from '../SDCDropdown/SDCDropdown';
import SDCLinkButton from '../SDCLinkButton/SDCLinkButton';
import SDCTextField from '../SDCTextField/SDCTextField';
import './UserInvitationModal.scss';

interface UserInvitationModalProps {
  show: boolean;
  roles: string[];
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (email: string, role: string[]) => Promise<void>;
}

const UserInvitationModal = ({
  show,
  setShow,
  onSubmit,
  roles,
}: UserInvitationModalProps): ReactElement => {
  const { t } = useTranslation();
  const defaultRole: KeyPairItem = {
    Name: 'Administrator',
    Value: 'Administrator',
  };
  // invite user email
  const [inviteEmail, setInviteEmail] = useState<string>('');
  const [inviteRole, setInviteRole] = useState<KeyPairItem>(defaultRole);
  const [isInviteEmailHasError, setIsInviteEmailHasError] =
    useState<boolean>(false);
  const [isInviteEmailErrorMsg, setIsInviteEmailErrorMsg] =
    useState<string>('');
  const emailRegex = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  const validateEmail = (email: string): boolean => {
    setInviteEmail(email);
    if (email === '') {
      setIsInviteEmailHasError(true);
      setIsInviteEmailErrorMsg(t('validation.invalidEmail'));
      return false;
    }
    if (!emailRegex.test(email)) {
      setIsInviteEmailHasError(true);
      setIsInviteEmailErrorMsg(
        t('invitationModal.inviteEmailWrongFormatErrorMessage')
      );
      return false;
    }
    setIsInviteEmailHasError(false);
    setIsInviteEmailErrorMsg('');
    return true;
  };

  const onSubmitInviteUser = (): void => {
    if (!isInviteEmailHasError) {
      onSubmit(inviteEmail, [inviteRole.Value]);
    }
  };

  const rolesTranslation: KeyPairItem[] = [
    { Value: 'Administrator', Name: t('teamManagementScreen.roleAdmin') },
    { Value: 'SuperUser', Name: t('teamManagementScreen.roleSuperUser') },
    {
      Value: 'CaptureAndView',
      Name: t('teamManagementScreen.roleCaptureAndView'),
    },
    { Value: 'View', Name: t('teamManagementScreen.roleView') },
    { Value: 'Owner', Name: t('teamManagementScreen.roleOwner') },
  ];

  if (!show) return <></>;

  return (
    <>
      <Modal
        show={show}
        dialogClassName="user-invitation-modal-container"
        centered
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        onHide={() => setShow(false)}
      >
        <Modal.Header>
          <Modal.Title>{t('invitationModal.inviteUserTitle')}</Modal.Title>
          <button
            type="button"
            className="close-button"
            onClick={(): void => {
              setShow(false);
            }}
          >
            <CloseIcon width={22} height={22} />
          </button>
        </Modal.Header>
        <div className="invitation-modal">
          <Modal.Body>
            <Row className="invite-body">
              <Col>
                <SDCTextField
                  onTextChange={validateEmail}
                  errorMessage={isInviteEmailErrorMsg}
                  errorMessageId={t('invitationModal.inviteEmailLabel')}
                  hasError={isInviteEmailHasError}
                  labelText={t('invitationModal.inviteEmailLabel')}
                />
              </Col>
              <Col>
                <SDCDropdown
                  labelText={t('invitationModal.inviteRoleLabel')}
                  currentItem={inviteRole}
                  values={roles.map(
                    (r) =>
                      ({
                        Name: rolesTranslation.find((x) => x.Value === r)?.Name,
                        Value: r,
                      } as KeyPairItem)
                  )}
                  onSelect={(e): void => setInviteRole(e)}
                />
              </Col>
            </Row>
          </Modal.Body>
        </div>
        <Modal.Footer className="invite-footer">
          <SDCLinkButton
            onClick={(): boolean => false}
            text={t('invitationModal.learnMore')}
          />
          <SDCButton
            text={t('invitationModal.submitButton')}
            onClick={onSubmitInviteUser}
            variant={SDCButtonVariant.Primary}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserInvitationModal;
