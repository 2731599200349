import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ArgumentError from '../../common/error/argumentError';
import CollapserPositionVariant from '../../common/types/collapserPositionVariant';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import ArchivedDocumentWithMetadata from '../../services/document/models/archivedDocumentWithMetadata';
import DocumentType from '../../services/documentType/models/documentType';
import {
  downloadFile,
  getDocumentNameWithExtension,
  getFileExtension,
  isViewable,
} from '../../utils/fileUtil';
import DefaultLoading from '../DefaultLoading/DefaultLoading';
import DocumentTitleBar from '../DocumentTitleBar/DocumentTitleBar';
import ImageViewer from '../ImageViewer/ImageViewer';
import PDFPreviewer from '../PDFPreviewer/PDFPreviewer';
import DocumentReadonlyNoThumbnailSidePanel from '../SidePanel/DocumentReadonlyNoThumbnailSidePanel';
import Toaster from '../Toaster/Toaster';
import UnsupportedFileViewer from '../UnsupportedFileViewer/UnsupportedFileViewer';
import './ReadonlyArchiveDocumentSection.scss';

type ReadonlyArchiveDocumentSectionProps = {
  selectedFile: File | undefined;
  document: ArchivedDocumentWithMetadata;
  documentName?: string;
  documentTypes: DocumentType[];
  onCloseModal?: Function;
};

const ReadonlyArchiveDocumentSection = ({
  selectedFile,
  document,
  documentName,
  documentTypes,
  onCloseModal,
}: ReadonlyArchiveDocumentSectionProps): ReactElement => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();

  const onDownloadButtonClick = (): void => {
    if (!selectedFile) throw new ArgumentError('selectedFile');

    downloadFile(selectedFile, selectedFile.name);
  };

  const renderFilePreviewer = (): ReactElement => {
    if (!selectedFile) {
      return (
        <DefaultLoading progressTranslationKey="archiveDocumentSectionComponent.loadingPreviewDocumentLabel" />
      );
    }

    if (!isViewable(selectedFile?.name)) {
      return (
        <UnsupportedFileViewer
          onDownloadClick={onDownloadButtonClick}
          archived
        />
      );
    }

    return getFileExtension(selectedFile?.name)?.toLowerCase() === 'pdf' ? (
      <PDFPreviewer selectedFile={selectedFile} />
    ) : (
      <ImageViewer file={selectedFile} />
    );
  };

  const renderTopBar = (): ReactElement => (
    <DocumentTitleBar
      data-hj-suppress
      descriptionPrefix={t('documentTitleBar.prefix')}
      documentName={
        getDocumentNameWithExtension(
          document?.documentName || documentName,
          document?.fileName
        ) ?? t('documentTitleBar.undefinedValue')
      }
      onClose={onCloseModal}
    />
  );

  const renderSidePanel = (): ReactElement | null => (
    <DocumentReadonlyNoThumbnailSidePanel
      documentTypes={documentTypes}
      settings={currentUser.settings}
      collapserPosition={CollapserPositionVariant.NoTitleBar}
      document={document}
    />
  );

  return (
    <>
      <Toaster />
      {renderTopBar()}
      <div className="readonly-archive-document-container">
        <div className="view-container">
          <div className="pdf-preview-container">
            {renderFilePreviewer()}
            {selectedFile && (
              <div className="file-name-header" data-hj-suppress>
                {selectedFile?.name}
              </div>
            )}
          </div>
          {renderSidePanel()}
        </div>
      </div>
    </>
  );
};

ReadonlyArchiveDocumentSection.defaultProps = {
  documentName: undefined,
  onCloseModal: undefined,
};

export default ReadonlyArchiveDocumentSection;
