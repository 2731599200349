import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dateFormat from '../../common/dateFormat';
import CompletedArchiveDocument from '../../common/models/completedArchiveDocument';
import CollapserPositionVariant from '../../common/types/collapserPositionVariant';
import FileSource from '../../common/types/fileSource';
import NotificationVariant from '../../common/types/notificationVariant';
import Metadata from '../../services/capture/models/metadata';
import MetadataDetail from '../../services/capture/models/metadataDetail';
import { postArchiveData } from '../../services/document/document.service';
import DocumentTemplateResponse from '../../services/documentType/models/documentTemplateResponse';
import DocumentType from '../../services/documentType/models/documentType';
import MetadataTemplateItem from '../../services/documentType/models/metadataTemplateItem';
import { getPendingTrayItemTags } from '../../services/pendingTray/pendingTray.service';
import CurrentUserResponse from '../../services/user/models/currentUserResponse';
import formatDateTime from '../../utils/i18n-date-format';
import {
  buildMetadataRequest,
  validateMetadataRequest,
} from '../../utils/metadataUtil';
import showToaster from '../Toaster/ToasterProvider';
import CaptureSidePanel from './CaptureSidePanel';
import './PendingTraySidePanel.scss';

interface PendingTrayCaptureSidePanelProps {
  captureInfoId: string;
  pendingTrayItemId: string;
  currentUser: CurrentUserResponse;
  documentTypes: DocumentType[];
  metadata: Metadata;
  onArchiveCompleted: (complete: CompletedArchiveDocument) => void;
  setArchiveError: React.Dispatch<React.SetStateAction<boolean>>;
  setArchiveStatus: React.Dispatch<React.SetStateAction<boolean>>;
  setIsArchivingFile: React.Dispatch<React.SetStateAction<boolean>>;
  checkedDocumentIds: string[];
  fileName?: string;
  clearCheckedDocumentIds: () => void;
}

const PendingTrayCaptureSidePanel = ({
  captureInfoId,
  pendingTrayItemId,
  currentUser,
  documentTypes,
  metadata,
  onArchiveCompleted,
  setArchiveError,
  setArchiveStatus,
  setIsArchivingFile,
  checkedDocumentIds,
  fileName,
  clearCheckedDocumentIds,
}: PendingTrayCaptureSidePanelProps): ReactElement => {
  const { t } = useTranslation();
  const [selectedDocumentType, setSelectedDocumentType] = useState<string>();
  const [selectedTemplate, setSelectedTemplate] = useState<
    MetadataTemplateItem[]
  >([]);
  const [readyToArchive, setReadyToArchive] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [documentTags, setDocumentTags] = useState<string[]>([]);
  const [modifiedMetadata, setModifiedMetadata] = useState<
    MetadataDetail[] | undefined
  >([]);
  const [documentTypeValidateError, setDocumentTypeValidateError] =
    useState<string>('');

  const editingMetadata = modifiedMetadata !== undefined;

  const handleDocumentTypeChange = (
    response: DocumentTemplateResponse | undefined
  ): void => {
    if (response) {
      const documentType = documentTypes.find(
        (x) => x.name === response.documentType.name
      );
      if (documentType) setSelectedDocumentType(documentType.id);
      setSelectedTemplate(response.template);
      setDocumentTypeValidateError('');

      // If the selected document type is original, clear out metadata request.
      // Otherwise, build new metada request for the document from the original metadata against the new document type.
      if (response.documentType.name === metadata.templateName) {
        setModifiedMetadata(undefined);
      } else {
        setModifiedMetadata(metadata.data);
      }
    } else {
      setSelectedDocumentType('');
    }
  };

  const validateDataBeforeArchive = useCallback((): boolean => {
    if (!metadata) return false;

    if (!selectedDocumentType) {
      setDocumentTypeValidateError(
        t('archiveDocumentSectionComponent.validateDocumentTypeRequiredLabel')
      );
      return false;
    }
    setDocumentTypeValidateError('');

    // Only validate if the user changes the metadata
    if (
      editingMetadata &&
      !validateMetadataRequest(modifiedMetadata, selectedTemplate)
    ) {
      return false;
    }

    return true;
  }, [
    editingMetadata,
    metadata,
    modifiedMetadata,
    selectedDocumentType,
    selectedTemplate,
    t,
  ]);

  const archiveDocument = async (): Promise<void> => {
    if (
      validateDataBeforeArchive() &&
      (!pendingTrayItemId || !captureInfoId || !selectedDocumentType)
    ) {
      return;
    }

    const documentTypeName = documentTypes.find(
      (x) => x.id === selectedDocumentType
    )?.name;
    if (!documentTypeName) {
      return;
    }

    setIsArchivingFile(true);
    setArchiveStatus(false);
    setIsProcessing(true);

    const request = buildMetadataRequest(modifiedMetadata, documentTypeName);

    if (checkedDocumentIds.length) {
      checkedDocumentIds.forEach(async (itemId, index) => {
        try {
          const response = await postArchiveData(
            captureInfoId,
            itemId,
            FileSource.PendingTray,
            request
          );
          const archivedDocument: CompletedArchiveDocument = {
            file: undefined,
            archivedTime: formatDateTime(
              new Date(response.createdOn),
              'en',
              dateFormat.time
            ),
            document: response,
            metadata,
          };
          setArchiveStatus(true);
          onArchiveCompleted(archivedDocument);
        } catch (error: any) {
          setArchiveStatus(true);
          setArchiveError(true);

          showToaster(
            t('pendingTrayPage.notificationTitleError'),
            error.response?.data.detail || error.response?.data.title,
            NotificationVariant.PrimaryDanger
          );
        }

        if (index === checkedDocumentIds.length - 1) {
          setIsProcessing(false);
          setIsArchivingFile(false);
          clearCheckedDocumentIds();
        }
      });
    }
  };

  const getTags = async (id: string): Promise<void> => {
    setIsLoadingTags(true);
    const tags = await getPendingTrayItemTags(id);
    if (tags) {
      setDocumentTags(tags);
    }
    setIsLoadingTags(false);
  };

  useEffect(() => {
    setReadyToArchive(validateDataBeforeArchive());
  }, [modifiedMetadata, validateDataBeforeArchive]);

  useEffect(() => {
    if (!metadata) {
      setReadyToArchive(false);
    } else {
      setReadyToArchive(validateDataBeforeArchive());
    }
  }, [metadata, validateDataBeforeArchive]);

  useEffect(() => {
    getTags(pendingTrayItemId);
  }, [pendingTrayItemId]);

  const renderSidePanel = (): ReactElement | null => (
    <CaptureSidePanel
      onDocumentTypeChange={handleDocumentTypeChange}
      documentTypeValidateError={documentTypeValidateError}
      onMetadataChange={setModifiedMetadata}
      settings={currentUser.settings}
      documentTypes={documentTypes}
      metadata={metadata}
      collapserPosition={
        CollapserPositionVariant.WithTitleBarAndTaskBarAndPendingTray
      }
      // Set footer button in side panel
      actionItem={{
        text: `${t('sidePanel.archiveButton')} ${
          checkedDocumentIds.length ? `(${checkedDocumentIds.length})` : ''
        }`,
        onClick: archiveDocument,
        isProcessing,
        show: pendingTrayItemId !== undefined,
        disabled: !readyToArchive,
      }}
      tags={documentTags}
      isLoading={isLoadingTags}
      pendingTrayId={pendingTrayItemId}
      fileName={
        checkedDocumentIds.length > 1
          ? `${t('sidePanel.selectedLabel')} (${checkedDocumentIds.length})`
          : fileName
      }
    />
  );

  return (
    <>
      <div>
        <div className="view-container">{renderSidePanel()}</div>
      </div>
    </>
  );
};

PendingTrayCaptureSidePanel.defaultProps = {
  fileName: '',
};

export default PendingTrayCaptureSidePanel;
