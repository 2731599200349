import { ReactElement } from 'react';
import { ReactComponent as ListIcon } from '../../assets/svg/ListIcon.svg';

interface ListActionIconProps {
  active: boolean;
  color: string;
  activeColor: string;
  onClick: () => void;
}

const ListActionIcon = ({
  active,
  color,
  activeColor,
  onClick,
}: ListActionIconProps): ReactElement => (
  <button
    className="icon-wrapper"
    type="button"
    onClick={(): void => onClick()}
  >
    <ListIcon width="22px" height="18px" fill={active ? activeColor : color} />
  </button>
);

export default ListActionIcon;
