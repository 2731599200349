/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-bind */
import { MutableRefObject, ReactElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CaretIcon } from '../../assets/svg/CaretIcon.svg';
import KeyPairItem from '../../common/models/keypairItem';
import RadioDropdownItem from '../../common/models/radioDropdownItem';
import DataType from '../../common/types/dataType';
import DropdownItemRole from '../../common/types/dropdownItemRole';
import DropdownVariant from '../../common/types/dropdownVariant';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import SDCTextFieldSize from '../../common/types/sdcTextFieldSize';
import useClickOutside from '../../hooks/useClickOutside/useClickOutside';
import UserSettings from '../../services/user/models/userSettings';
import { getMaskOptions } from '../../utils/dataTypeUtil';
import RadioOption from '../RadioOption/RadioOption';
import SDCButton from '../SDCButton/SDCButton';
import SDCTextField from '../SDCTextField/SDCTextField';
import './RadioDropdown.scss';

interface RadioDropdownProps {
  /**
   * Option items. Each value must be unique. You must handle the customized value option as well.
   * Let's say, there's an option with value of 'a', and also there's a editable option with value 'a'.
   * This will cause it to break.
   */
  list: RadioDropdownItem[];
  /**
   * Initial selected value in a RadioDropdownItem from 'list'
   */
  initialSelectedValue?: string;
  /**
   * Placeholder text when initialSelectedValue is not specified
   */
  text: string;
  /**
   * If specified, the apply button is present.
   */
  onApply: (item: RadioDropdownItem, editedValue: string) => void;
  /**
   * User settings
   */
  settings: UserSettings;
  /**
   * Whether the conponent should be disabled
   */
  disabled?: boolean;
  /**
   * Optional menu descriptio header
   */
  description?: string;
  /**
   * Style variant
   */
  variant?: DropdownVariant;
}

/**
 * Inspried by
 * https://codepen.io/ayanna/pen/ObLowr
 */
const RadioDropdown = ({
  list,
  settings,
  initialSelectedValue,
  text,
  onApply,
  disabled,
  description,
  variant,
}: RadioDropdownProps): ReactElement => {
  // If the pre-selected item is custom, store its value
  const defaultTextData: { [key: string]: string } = {};
  const initialItem =
    initialSelectedValue === undefined
      ? undefined
      : list.find((x) => x.value === initialSelectedValue);
  if (initialItem && initialItem.editable) {
    defaultTextData[initialItem.name] = initialItem.value;
  }

  const groupName = 'radio-dropdown';
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null) as MutableRefObject<any>;
  const { t } = useTranslation();
  const inputRef = useRef(null) as MutableRefObject<any>;
  const [selectedItem, setSelectedItem] = useState<
    RadioDropdownItem | undefined
  >(initialItem);
  const [textFieldValues, setTextFiledValues] = useState<{
    [key: string]: string;
  }>(defaultTextData);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [shouldDropup, setShouldDropup] = useState(false);

  const toggleDropdown = (): void => {
    setIsOpen((value) => !value);
  };

  useClickOutside(menuRef, () => {
    setIsOpen(false);
  });

  const renderInput = (item: RadioDropdownItem): ReactElement | null => {
    if (!item.editable) {
      return null;
    }

    const getMaskingInfo = (): KeyPairItem => {
      if (item.dataType === DataType.DateTime) {
        return {
          Name: settings.dateTimeFormat,
          Value: settings.dateTimeFormat,
        };
      }
      if (item.dataType === DataType.Date) {
        return {
          Name: settings.dateFormat,
          Value: settings.dateFormat,
        };
      }
      if (item.dataType === DataType.YearTimespan) {
        return {
          Name: t('radioDropdown.placeholderYearTimespanValue'),
          Value: '',
        };
      }
      if (item.dataType === DataType.Decimal) {
        return {
          Name: t('radioDropdown.placeholderDecimalValue'),
          Value: '',
        };
      }
      return {
        Name: t('radioDropdown.placeholderValue'),
        Value: '',
      };
    };

    const maskingInfo = getMaskingInfo();
    const dateMaskOptions = getMaskOptions(item.dataType, maskingInfo.Value);

    const handleTextChange = (value: string): void => {
      textFieldValues[item.name] = value;
      setTextFiledValues(() => textFieldValues);
    };

    return (
      <div className="option-input-container">
        <SDCTextField
          inputRef={inputRef}
          placeholder={maskingInfo.Name}
          fieldSize={SDCTextFieldSize.Small}
          value={textFieldValues[item.name] ?? ''}
          onTextChange={handleTextChange}
          errorMessage={errorMessage}
          maskOptions={dateMaskOptions}
          hasError={errorMessage !== undefined}
          errorMessageId={item.name}
        />
      </div>
    );
  };

  const renderDropdownField = (
    item: RadioDropdownItem,
    index: number
  ): ReactElement => {
    if (item.role === DropdownItemRole.Divider) {
      return (
        <div className="separator" key={`item-${index}`}>
          {item.name}
        </div>
      );
    }

    const radioItem: KeyPairItem = {
      Name: item.name,
      Value: item.value,
    };

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
      <div className="option-item-container" key={item.value}>
        <RadioOption
          item={radioItem}
          groupName={groupName}
          selectedValue={selectedItem !== undefined ? selectedItem.value : ''}
          onClick={(): void => {
            setSelectedItem(item);
            setErrorMessage(undefined);

            if (item.editable) {
              setImmediate(() => {
                inputRef?.current?.focus();
              });
            }
          }}
        />
        {renderInput(item)}
      </div>
    );
  };

  const handleApply = (): void => {
    if (selectedItem !== undefined) {
      onApply(selectedItem, textFieldValues[selectedItem.name]);
      toggleDropdown();
    }
  };

  const renderDropdownMenuContent = (): ReactElement => (
    <>
      {description !== undefined ? (
        <div className="description">{description}</div>
      ) : null}
      <ul className="radio-dropdown-item-container">
        {list.map(renderDropdownField)}
      </ul>
      <div className="apply-container">
        <SDCButton
          variant={SDCButtonVariant.Primary}
          text={t('radioDropdown.applyButton')}
          onClick={handleApply}
        />
      </div>
    </>
  );

  const handleButtonClick = (e: any): void => {
    const halfScreen =
      Math.ceil(document.body.scrollHeight / 2) +
      Math.ceil(0.1 * document.body.scrollHeight);
    const clickedOnBottomHalf = e.pageY > halfScreen;
    setShouldDropup(clickedOnBottomHalf);

    toggleDropdown();
  };

  return (
    <div className="radio-dropdown-container">
      <div className="radio-dropdown" ref={menuRef}>
        <button
          disabled={disabled}
          className={`radio-dropdown-toggle ${variant}`}
          type="button"
          onClick={handleButtonClick}
        >
          <span>{selectedItem !== undefined ? selectedItem.name : text}</span>
          <span className="caret">
            <CaretIcon />
          </span>
        </button>
        {isOpen && (
          <div className={`radio-dropdown-menu ${shouldDropup ? 'up' : ''}`}>
            {renderDropdownMenuContent()}
          </div>
        )}
      </div>
    </div>
  );
};

export default RadioDropdown;

RadioDropdown.defaultProps = {
  disabled: false,
  initialSelectedValue: undefined,
  description: undefined,
  variant: DropdownVariant.Default,
};
