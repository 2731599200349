import KeyPairItem from '../common/models/keypairItem';
import DocumentType from '../services/documentType/models/documentType';

const getKeyPairItem = (name: string, value: string): KeyPairItem => ({
  Name: name,
  Value: value,
});

const buildDocumentTypeDropdownListItems = (
  defaultOptionName: string,
  documentTypes: DocumentType[],
  deletedState: string
): KeyPairItem[] => {
  const defaultDocumentType: KeyPairItem = {
    Name: defaultOptionName,
    Value: '',
  };
  const listItems = documentTypes.map((item: DocumentType) => {
    if (item.isDeleted)
      return getKeyPairItem(`${item.displayName} (${deletedState})`, item.name);
    return getKeyPairItem(item.displayName, item.name);
  });

  if (defaultOptionName) return [defaultDocumentType, ...listItems];

  return [...listItems];
};

export default buildDocumentTypeDropdownListItems;
