import { createContext, useContext } from 'react';
import CurrentUserResponse from '../../services/user/models/currentUserResponse';

export type CurrentUserContextProps = {
  currentUser: CurrentUserResponse;
  fetchingUserData: boolean;
};

export const CurrentUserContext = createContext<
  CurrentUserContextProps | undefined
>(undefined);

export const useCurrentUser = (): CurrentUserContextProps => {
  const context = useContext(CurrentUserContext);
  if (context === undefined) {
    throw new Error('useCurrentUser must be used within a CurrentUserProvider');
  }
  return context;
};
