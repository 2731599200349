import { getSdcClient } from '../../common/apiClients/sdcClient.instance';
import DocumentProtection from './models/documentProtection';
import DocumentTemplateResponse from './models/documentTemplateResponse';
import DocumentType from './models/documentType';

export const getAllDocumentTypes = async (
  errorHandler?: Function | undefined
): Promise<DocumentType[]> => {
  try {
    const sdcClient = getSdcClient();
    const uri: string = `./api/v1/document-types`;
    const response = await sdcClient.get<DocumentType[]>(uri);
    return response.data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    }
  }
  return [] as DocumentType[];
};

export const getActiveDocumentTypes = async (
  errorHandler?: Function | undefined
): Promise<DocumentType[]> => {
  try {
    const sdcClient = getSdcClient();
    const uri: string = `./api/v1/document-types?isDeleted=false`;
    const response = await sdcClient.get<DocumentType[]>(uri);
    return response.data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    }
  }
  return [] as DocumentType[];
};

export const getDocumentTemplate = async (
  documentTypeId: string,
  errorHandler?: Function | undefined
): Promise<DocumentTemplateResponse> => {
  try {
    const sdcClient = getSdcClient();
    const uri: string = `./api/v1/document-types/${documentTypeId}/templates/metadata`;
    const response = await sdcClient.get<DocumentTemplateResponse>(uri);
    return response.data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    }
  }
  return {} as DocumentTemplateResponse;
};

export const getDocumentProtection = async (
  errorHandler?: Function | undefined
): Promise<DocumentProtection[]> => {
  try {
    const sdcClient = getSdcClient();
    const uri: string = `./api/v1/document-types/protection`;
    const response = await sdcClient.get<DocumentProtection[]>(uri);
    return response.data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    }
  }
  return [] as DocumentProtection[];
};

export const updateDocumentProtection = async (
  request: DocumentProtection[],
  errorHandler?: Function | undefined
): Promise<DocumentProtection[]> => {
  try {
    const sdcClient = getSdcClient();
    const uri: string = `./api/v1/document-types/protection`;
    const response = await sdcClient.patch(uri, request);
    return response.data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    }
  }
  return [];
};

export default getAllDocumentTypes;
