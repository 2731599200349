import { useEffect } from 'react';

const usePreventWindowClose = (preventDefault: boolean): void => {
  const handleBeforeUnload = (event: BeforeUnloadEvent): void => {
    event.preventDefault();
    // Required in order for the default dialog to trigger
    // eslint-disable-next-line no-param-reassign
    event.returnValue = '';
  };
  useEffect(() => {
    if (preventDefault) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return (): void => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [preventDefault]);
};

export default usePreventWindowClose;
