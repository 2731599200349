import { toast } from 'react-toastify';
import NotificationVariant from '../../common/types/notificationVariant';
import ToasterContent from './ToasterContent';

const showToaster = (
  title: string,
  message: string,
  variant: NotificationVariant
): void => {
  const content = (
    <ToasterContent title={title} message={message} variant={variant} />
  );
  toast(content, {
    position: 'bottom-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: variant,
  });
};

export default showToaster;
