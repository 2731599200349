import MetadataTableItem from '../common/models/metadataTableItem';
import DataType from '../common/types/dataType';
import MetadataValue, { RowCollection, RowData } from '../common/types/types';
import MetadataDetail from '../services/capture/models/metadataDetail';
import MetadataRequest from '../services/document/models/metadataRequest';
import MetadataTemplateItem from '../services/documentType/models/metadataTemplateItem';
import UserSettings from '../services/user/models/userSettings';
import { formatValue, validateDataType } from './dataTypeUtil';

/**
 * Get the string value if the data value is a string; otherwise return an empty string.
 * @param dataValue
 * @returns
 */
export const getStringDataValue = (dataValue: MetadataValue): string =>
  typeof dataValue === 'string' ? dataValue : '';

/**
 * Creates table items from the metadata using the template.
 * @param metadataItems
 * @param metadataTemplate
 * @returns
 */
export const buildTableItemsFromTemplate = (
  metadataItems: MetadataDetail[],
  metadataTemplate: MetadataTemplateItem[] | undefined,
  settings: UserSettings
): MetadataTableItem[] => {
  if (metadataTemplate === undefined) {
    return metadataItems.map(
      (item: MetadataDetail) =>
        ({
          isMissingValue: false,
          isRequired: false,
          isHidden: item.isHidden,
          metadata: item,
        } as MetadataTableItem)
    );
  }

  return metadataTemplate.map((item: MetadataTemplateItem) => {
    const data = [...metadataItems].find(
      (x) => x.key?.toLowerCase() === item.name.toLowerCase()
    );
    let metadataValue =
      data === undefined
        ? ''
        : formatValue(getStringDataValue(data.value), data.type, settings);

    // If the key matches, but the type is not valid, populate it with an empty value.
    // This handles type mismatch like string to a date time as an example.
    const isValidValue = validateDataType(
      metadataValue,
      item.dataType,
      settings
    );
    if (!isValidValue) {
      metadataValue = '';
    }

    return {
      isMissingValue: metadataValue === '' || data?.isMissingValue === true,
      isRequired: item.isRequired,
      isHidden: item.isHidden,
      dataType: item.dataType,
      metadata: {
        key: item.name,
        value: metadataValue,
        type: item.dataType,
        displayName: item.displayName,
      },
    } as MetadataTableItem;
  });
};

const buildTableDataFromColumnTemplate = (
  rows: RowCollection,
  template: MetadataTemplateItem[],
  settings: UserSettings
): RowCollection =>
  rows.map((row: RowData): RowData => {
    const newRow: RowData = [];
    template.forEach((x) => {
      const rowValue =
        (row.find((y) => y.key === x.name)?.value as string) ?? '';
      const newItem: MetadataDetail = {
        key: x.name,
        value: rowValue ? formatValue(rowValue, x.dataType, settings) : '',
        type: x.dataType,
        displayName: x.displayName,
        isHidden: false,
      };
      newRow.push(newItem);
    });
    return newRow;
  });

/**
 * Creates a new copy of metadata from the template with data type validation.
 * @param metadataItems
 * @param metadataTemplate
 * @param settings
 */
export const buildMetadataFromTemplate = (
  metadataItems: MetadataDetail[] | undefined,
  metadataTemplate: MetadataTemplateItem[] | undefined,
  settings: UserSettings
): MetadataDetail[] => {
  if (!metadataItems) return [];

  if (metadataTemplate === undefined) {
    return [...metadataItems];
  }

  return metadataTemplate.map((item: MetadataTemplateItem) => {
    const data = [...metadataItems].find(
      (x) => x.key?.toLowerCase() === item.name.toLowerCase()
    );

    if (item.dataType === DataType.Table) {
      const tableData = !data ? ([] as RowCollection) : data.value;
      return {
        key: item.name,
        value: buildTableDataFromColumnTemplate(
          tableData as RowCollection,
          item.template,
          settings
        ),
        type: item.dataType,
        displayName: item.displayName,
        isHidden: item.isHidden,
        isValid: true,
        isMissingValue: data?.isMissingValue,
      } as MetadataDetail;
    }

    const metadataValue =
      data === undefined
        ? ''
        : formatValue(getStringDataValue(data.value), data.type, settings);

    // If the key matches, but the type is not valid, populate it with an empty value.
    // This handles type mismatch like string to a date time as an example.
    const isValidValue = validateDataType(
      metadataValue,
      item.dataType,
      settings
    );

    return {
      key: item.name,
      value: isValidValue ? metadataValue : '',
      type: item.dataType,
      displayName: item.displayName,
      isHidden: item.isHidden,
      isValid: isValidValue,
      isMissingValue: !isValidValue || data?.isMissingValue, // persist missing-value status
    } as MetadataDetail;
  });
};

/**
 * Validate if the metadata request is valid against the template
 * @param request
 * @param metadataTemplate
 * @returns
 */
export const validateMetadataRequest = (
  request: MetadataDetail[] | undefined,
  metadataTemplate: MetadataTemplateItem[]
): boolean => {
  if (!request) {
    return false;
  }

  let isValid = true;

  // Check if the metadata entry requires a value, then validate that value against the template data type.
  // If not required, no need to validate.
  metadataTemplate.forEach((item: MetadataTemplateItem) => {
    if (!isValid) {
      return;
    }

    const metadata = request.find((x) => x.key === item.name);
    // Ignore anything outside the template
    if (!metadata) {
      if (item.isRequired) {
        isValid = false;
      }

      return;
    }

    // Validate data including table data
    if (item.dataType === DataType.Table) {
      item.template.forEach((column) => {
        const rows = metadata.value as RowCollection;
        rows.forEach((row) => {
          const value =
            (row.find((x) => x.key === column.name)?.value as string) ?? '';
          if (column.isRequired && !value) {
            isValid = false;
          }
          if (isValid && !validateDataType(value, column.dataType, undefined)) {
            isValid = false;
          }
        });
      });
      return;
    }

    if (
      !validateDataType(
        getStringDataValue(metadata.value),
        item.dataType,
        undefined
      )
    ) {
      isValid = false;
    }

    if (item.isRequired && !getStringDataValue(metadata.value)) {
      isValid = false;
    }
  });

  return isValid;
};

/**
 * Build metadata request to be used with the API. Dates and date and times
 * will be converted to ISO8601 format from the user preference format.
 * @param data
 * @param documentTypeName
 * @param settings
 * @returns
 */
export const buildMetadataRequest = (
  data: MetadataDetail[] | undefined,
  documentTypeName: string
): MetadataRequest | undefined => {
  if (!data || data.length === 0) {
    return undefined;
  }

  const dictionary: { [key: string]: string | {} } = {};
  data.forEach((item: MetadataDetail) => {
    if (item.type === DataType.Table) {
      const rowCollection = item.value as RowCollection;
      const rowValues: { [key: string]: string }[] = [];
      rowCollection.forEach((row: RowData) => {
        const rowValue: { [key: string]: string } = {};
        row.forEach((col: MetadataDetail) => {
          rowValue[col.key] = col.value as string;
        });
        rowValues.push(rowValue);
      });
      dictionary[item.key] = rowValues;
    } else dictionary[item.key] = item.value;
  });

  return {
    templateName: documentTypeName,
    data: dictionary,
  } as MetadataRequest;
};

/**
 * Checks if both metadata details are equivalent
 * @param data1
 * @param data2
 * @returns
 */
export const metadataEqual = (
  data1: MetadataDetail[] | undefined,
  data2: MetadataDetail[] | undefined
): boolean => {
  if (data1 === undefined && data1 === data2) {
    return true;
  }

  if (
    data1 !== undefined &&
    data2 !== undefined &&
    data1.length !== data2?.length
  ) {
    return false;
  }

  if (data1 === undefined || data2 === undefined) {
    return false;
  }

  for (let index = 0; index < data1?.length; index += 1) {
    if (data1[index].key !== data2[index].key) {
      return false;
    }
    if (data1[index].value !== data2[index].value) {
      return false;
    }
  }

  return true;
};

/**
 * Set missing status based on the value
 * @param data
 */
export const setMissingStatus = (data: MetadataDetail[]): void => {
  data.forEach((item: MetadataDetail) => {
    // eslint-disable-next-line no-param-reassign
    item.isMissingValue = getStringDataValue(item.value) === '';
  });
};
