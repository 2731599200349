import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as AssociatedIcon } from '../../assets/svg/AssociatedIcon.svg';
import { ReactComponent as ProtectionIcon } from '../../assets/svg/ProtectionIcon.svg';
import DocumentProtectionStatus from '../../services/documentType/models/documentProtectionStatus';
import DateTimeFormatter from '../DateTime/DateTimeFormatter';
import SDCCheckbox from '../SDCCheckbox/SDCCheckbox';
import './ResultListItem.scss';

interface ResultListItemProps {
  id: string;
  title: string;
  subtitle: string;
  date: Date;
  authorName: string | undefined;
  onClick: () => void;
  onCheckChange: (value: boolean | undefined) => void;
  selected: boolean;
  checked: boolean;
  protectionStatus: DocumentProtectionStatus;
  entityIds: string[];
  mainEntityId?: string;
}

const ResultListItem = ({
  id,
  title,
  subtitle,
  date,
  authorName,
  onClick,
  onCheckChange,
  selected,
  checked,
  protectionStatus,
  entityIds,
  mainEntityId,
}: ResultListItemProps): ReactElement => {
  const { t } = useTranslation();
  const titleMaxLength = 50;

  const renderAssociatedIcon = (): ReactElement | null => {
    if (mainEntityId === undefined) return null;

    if (
      entityIds.some(
        (value) => value.toLowerCase() === mainEntityId?.toLowerCase()
      )
    )
      return null;

    return (
      <span className="protected-icon">
        <AssociatedIcon />
      </span>
    );
  };

  return (
    <div className={`list-item ${selected ? 'selected' : ''}`} data-hj-suppress>
      {title?.length > titleMaxLength ? (
        <ReactTooltip place="top" effect="solid" multiline />
      ) : null}

      <div className="checkbox-container">
        <SDCCheckbox
          title={`checkbox-${id}`}
          checked={checked}
          onCheckChange={onCheckChange}
        />
      </div>
      <div>
        <button type="button" onClick={onClick} data-tip={title}>
          <span className="item-body">
            <div
              className={`document-type ${
                subtitle.length > 0 ? 'defined' : 'undefined'
              }`}
            >
              {subtitle.length > 0
                ? subtitle
                : t('listItem.undefinedDocumentType')}
            </div>
            {title}
            {protectionStatus === DocumentProtectionStatus.Protected ? (
              <span className="protected-icon">
                <ProtectionIcon fill="#1F6BEB" />
              </span>
            ) : null}
            {renderAssociatedIcon()}
          </span>
          <span className="details">
            <div>
              <DateTimeFormatter date={date} format="DD MMM YYYY" />
            </div>
            <div className="author-name">
              {authorName ?? t('documentsViewScreen.undefinedAuthor')}
            </div>
          </span>
        </button>
      </div>
    </div>
  );
};

ResultListItem.defaultProps = {
  mainEntityId: undefined,
};

export default ResultListItem;
