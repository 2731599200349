import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as SampleDocumentIcon } from '../../assets/svg/SampleDocumentIcon.svg';
import { ReactComponent as SampleDocumentLoadingIcon } from '../../assets/svg/SampleDocumentLoadingIcon.svg';
import { ReactComponent as UploadCloudBlueMediumIcon } from '../../assets/svg/UploadCloudBlueMediumIcon.svg';
import { ReactComponent as UploadThumbnailQueuedIcon } from '../../assets/svg/UploadThumbnailQueuedIcon.svg';
import UploadFileWithStatus from '../../common/models/uploadFileWithStatus';
import FileUploadStatus from '../../common/types/fileUploadStatus';
import SDCCheckboxSize from '../../common/types/sdcCheckboxSize';
import { getUploadedDocumentPreviewImage } from '../../services/capture/capture.service';
import { getFileExtension } from '../../utils/fileUtil';
import BadgePill from '../BadgePill/BadgePill';
import SDCCheckbox from '../SDCCheckbox/SDCCheckbox';
import './UploadDocumentThumbnail.scss';

interface UploadDocumentThumbnailProps {
  fileInfo: UploadFileWithStatus;
  selectFile: React.MouseEventHandler<HTMLButtonElement>;
  isUploadListView: boolean;
  updateFilePreview: Function | undefined;
  checkboxOnClick: (selectedFile: File, checked: boolean) => void;
  checked: boolean;
}

const UploadDocumentThumbnail = ({
  fileInfo,
  selectFile,
  isUploadListView,
  updateFilePreview,
  checkboxOnClick,
  checked,
}: UploadDocumentThumbnailProps): ReactElement => {
  const { t } = useTranslation();
  const previewImageLimitCall = 5;
  const delayTimeout = 6000;
  const [getPreviewImageCounter, setGetPreviewImageCounter] =
    useState<number>(0);
  const [previewImage, setPreviewImage] = useState<string>();

  const { uploadId, file, status } = fileInfo;
  const abortControllerRefs = useRef<Array<AbortController>>([]);

  const loadingThumbnail = (): ReactElement => (
    <>
      <SampleDocumentLoadingIcon className="sample-document-loading-icon" />
      <div className="uploading-group">
        {status === FileUploadStatus.UPLOADING ? (
          <UploadCloudBlueMediumIcon className="cloud-uploading-icon" />
        ) : (
          <UploadThumbnailQueuedIcon className="cloud-uploading-icon" />
        )}
        <div
          className={`uploading-title ${
            status === FileUploadStatus.PENDING && 'queuing'
          }`}
        >
          {status === FileUploadStatus.UPLOADING
            ? t('uploadDocumentThumbnailComponent.uploadStatusUploadingLabel')
            : t('uploadDocumentThumbnailComponent.uploadStatusQueuedLabel')}
        </div>
      </div>
    </>
  );

  useEffect(() => {
    const getPreviewThumbnailImage = async (
      abortController: AbortController
    ): Promise<void> => {
      if (!fileInfo.previewImg && !previewImage && uploadId) {
        try {
          let imageContent;
          if (getPreviewImageCounter < previewImageLimitCall) {
            imageContent = await getUploadedDocumentPreviewImage(
              uploadId,
              abortController.signal
            );
          }
          // Then create a local URL for that image and set it to preview image
          const imageObjectURL = imageContent
            ? URL.createObjectURL(imageContent)
            : undefined;
          // eslint-disable-next-line no-param-reassign
          fileInfo.previewImg = imageObjectURL;
          setPreviewImage(imageObjectURL);
          if (updateFilePreview) updateFilePreview(fileInfo);
        } catch (e) {
          setGetPreviewImageCounter((c) => c + 1);
        }
      }
    };

    let timer: NodeJS.Timeout;
    if (
      status === FileUploadStatus.SUCCESS &&
      !fileInfo.previewImg &&
      uploadId &&
      getPreviewImageCounter < previewImageLimitCall
    ) {
      // Delay 6 sec. then call api get preview image only 5 times.
      timer = setTimeout(() => {
        const newAbortController = new AbortController();
        abortControllerRefs.current = [
          ...abortControllerRefs.current,
          newAbortController,
        ];
        getPreviewThumbnailImage(newAbortController);
      }, delayTimeout);
    }

    return (): void => {
      abortControllerRefs.current.forEach((value) => {
        value.abort();
      });
      clearTimeout(timer);
    };
  }, [
    uploadId,
    getPreviewImageCounter,
    status,
    fileInfo.previewImg,
    fileInfo,
    previewImage,
    updateFilePreview,
  ]);

  const loadingThumbnailList = (): ReactElement => (
    <>
      {status === FileUploadStatus.UPLOADING ? (
        <UploadCloudBlueMediumIcon className="cloud-uploading-icon" />
      ) : (
        <UploadThumbnailQueuedIcon className="cloud-uploading-icon" />
      )}
    </>
  );

  const selectFileOnCheckBox = (
    event: React.MouseEvent<HTMLInputElement>,
    selectedFile: File
  ): void => {
    const eventTarget = event.currentTarget;
    checkboxOnClick(selectedFile, eventTarget.checked);
  };

  return (
    <div
      data-hj-suppress
      className={`document-thumbnail-container ${
        isUploadListView ? 'list' : ''
      }`}
    >
      <ReactTooltip place="bottom" effect="solid" />
      <div data-tip={file?.name}>
        <Button
          className={`document-thumbnail-btn ${
            status !== FileUploadStatus.SUCCESS && 'loading'
          }`}
          onClick={selectFile}
        >
          {file ? (
            <SDCCheckbox
              title={`checkbox-${file.name}`}
              labelText=""
              checked={checked}
              onClickEvent={(e: React.MouseEvent<HTMLInputElement>): void =>
                selectFileOnCheckBox(e, file)
              }
              sdcSize={SDCCheckboxSize.Large}
            />
          ) : null}
          {!fileInfo.previewImg &&
            !isUploadListView &&
            (status !== FileUploadStatus.SUCCESS ? (
              loadingThumbnail()
            ) : (
              <SampleDocumentIcon />
            ))}
          {fileInfo.previewImg && (
            <div
              style={{ backgroundImage: `url(${fileInfo.previewImg})` }}
              className="preview-img"
            />
          )}
          <BadgePill
            text={getFileExtension(file?.name)}
            themeClass={`file-type ${getFileExtension(
              file?.name
            )?.toLowerCase()} ${!isUploadListView && 'file-type-badge'} ${
              status !== FileUploadStatus.SUCCESS && 'loading'
            }`}
          />
          <div
            className={`document-name ${
              status === FileUploadStatus.PENDING && 'queuing'
            }`}
          >
            {file?.name}
          </div>
          {isUploadListView &&
            status !== FileUploadStatus.SUCCESS &&
            loadingThumbnailList()}
        </Button>
      </div>
    </div>
  );
};
export default UploadDocumentThumbnail;
