import { ReactElement } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DataType from '../../common/types/dataType';
import MetadataDetail from '../../services/capture/models/metadataDetail';
import UserSettings from '../../services/user/models/userSettings';
import { formatValue } from '../../utils/dataTypeUtil';
import './ReadonlyDocumentMetadataTable.scss';

export interface ReadonlyDocumentMetadataTableProps {
  metadata: MetadataDetail[];
  settings: UserSettings;
}

export interface ReadonlyDocumentMetadataRowProps {
  data: MetadataDetail;
  isHidden: boolean;
  settings: UserSettings;
}

const ReadonlyMetadataRow = ({
  data,
  isHidden,
  settings,
}: ReadonlyDocumentMetadataRowProps): ReactElement => {
  const { key, value, displayName, type } = data;
  const stringValue: string = value as string;

  if (isHidden) {
    return <span />;
  }

  return (
    <Row>
      <Col md="12" className="document-metadata-key">
        <span>{displayName || key}</span>
      </Col>
      <Col md="12" className="document-metadata-value">
        <span>{formatValue(stringValue, type, settings) || '-'}</span>
      </Col>
    </Row>
  );
};

const ReadonlyDocumentMetadataTable = ({
  metadata,
  settings,
}: ReadonlyDocumentMetadataTableProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Container className="document-metadata" data-hj-suppress>
      <div className="document-info-frame">
        {metadata.length !== 0 ? (
          metadata
            .filter((x) => x.type !== DataType.Table)
            .map(
              (data, index): JSX.Element => (
                <ReadonlyMetadataRow
                  key={data.key + index.toString()}
                  data={data}
                  isHidden={data.isHidden}
                  settings={settings}
                />
              )
            )
        ) : (
          <div className="no-data">{t('sidePanel.noData')}</div>
        )}
      </div>
    </Container>
  );
};

export default ReadonlyDocumentMetadataTable;
