import { getSdcClient } from '../../common/apiClients/sdcClient.instance';
import handleApplicationError from '../../utils/errorUtil';
import AccessKey from './models/accessKey';
import AccessKeyAccess from './models/accessKeyAccess';
import CreateAccessKeyRequest from './models/createAccessKeyRequest';
import CreateAccessKeyResponse from './models/createAccessKeyResponse';
import UpdateAccessKeyExpiryRequest from './models/updateAccessKeyExpiryRequest';
import UpdateAccessKeyRequest from './models/updateAccessKeyRequest';

export const getAccessKeys = async (
  errorHandler?: any
): Promise<AccessKey[]> => {
  const sdcClient = getSdcClient();
  const uri: string = `./api/v1/access-keys`;

  try {
    const response = await sdcClient.get<AccessKey[]>(uri);
    return response.data;
  } catch (error: any) {
    handleApplicationError(error, errorHandler);
  }

  return [];
};

export const createAccessKey = async (
  request: CreateAccessKeyRequest,
  errorHandler?: any
): Promise<CreateAccessKeyResponse> => {
  const sdcClient = getSdcClient();
  const uri: string = `./api/v1/access-keys`;

  try {
    const response = await sdcClient.post<CreateAccessKeyResponse>(
      uri,
      request
    );
    return response.data;
  } catch (error: any) {
    handleApplicationError(error, errorHandler);
  }

  return {} as CreateAccessKeyResponse;
};

export const updateAccessKey = async (
  request: UpdateAccessKeyRequest,
  errorHandler?: any
): Promise<AccessKey> => {
  const sdcClient = getSdcClient();
  const uri: string = `./api/v1/access-keys/${request.accessKeyId}`;

  try {
    const response = await sdcClient.patch<AccessKey>(uri, request);
    return response.data;
  } catch (error: any) {
    handleApplicationError(error, errorHandler);
  }

  return {} as AccessKey;
};

export const updateAccessKeyExpiry = async (
  request: UpdateAccessKeyExpiryRequest,
  errorHandler?: any
): Promise<AccessKey> => {
  const sdcClient = getSdcClient();
  const uri: string = `./api/v1/access-keys/${request.accessKeyId}/expiry`;

  try {
    const response = await sdcClient.put<AccessKey>(uri, request);
    return response.data;
  } catch (error: any) {
    handleApplicationError(error, errorHandler);
  }

  return {} as AccessKey;
};

export const revokeAccessKey = async (
  id: string,
  errorHandler?: any
): Promise<void> => {
  const sdcClient = getSdcClient();
  const uri: string = `./api/v1/access-keys/${id}`;

  try {
    await sdcClient.delete(uri);
  } catch (error: any) {
    handleApplicationError(error, errorHandler);
  }
};

export const getAccessKeyDocumentTypeAccess = async (
  id: string,
  errorHandler?: any
): Promise<AccessKeyAccess> => {
  const sdcClient = getSdcClient();
  const uri: string = `./api/v1/access-keys/${id}/accesses/document-types`;

  try {
    const response = await sdcClient.get<AccessKeyAccess>(uri);
    return response.data;
  } catch (error: any) {
    handleApplicationError(error, errorHandler);
  }

  return {} as AccessKeyAccess;
};

export const updateAccessKeyDocumentTypeAccess = async (
  request: AccessKeyAccess,
  errorHandler?: any
): Promise<AccessKeyAccess> => {
  const sdcClient = getSdcClient();
  const uri: string = `./api/v1/access-keys/${request.accessKeyId}/accesses/document-types`;

  try {
    const response = await sdcClient.patch<AccessKeyAccess>(uri, request);
    return response.data;
  } catch (error: any) {
    handleApplicationError(error, errorHandler);
  }

  return {} as AccessKeyAccess;
};

export const getAccessKeyContainerAccess = async (
  id: string,
  errorHandler?: any
): Promise<AccessKeyAccess> => {
  const sdcClient = getSdcClient();
  const uri: string = `./api/v1/access-keys/${id}/accesses/containers`;

  try {
    const response = await sdcClient.get<AccessKeyAccess>(uri);
    return response.data;
  } catch (error: any) {
    handleApplicationError(error, errorHandler);
  }

  return {} as AccessKeyAccess;
};

export const updateAccessKeyContainerAccess = async (
  request: AccessKeyAccess,
  errorHandler?: any
): Promise<AccessKeyAccess> => {
  const sdcClient = getSdcClient();
  const uri: string = `./api/v1/access-keys/${request.accessKeyId}/accesses/containers`;

  try {
    const response = await sdcClient.patch<AccessKeyAccess>(uri, request);
    return response.data;
  } catch (error: any) {
    handleApplicationError(error, errorHandler);
  }

  return {} as AccessKeyAccess;
};

export const getAccessKeyPermissions = async (
  id: string,
  errorHandler?: any
): Promise<string[]> => {
  const sdcClient = getSdcClient();
  const uri: string = `./api/v1/access-keys/${id}/permissions`;

  try {
    const response = await sdcClient.get<string[]>(uri);
    return response.data;
  } catch (error: any) {
    handleApplicationError(error, errorHandler);
  }

  return {} as string[];
};

export const updateAccessKeyPermissions = async (
  id: string,
  permissions: string[],
  errorHandler?: any
): Promise<string[]> => {
  const sdcClient = getSdcClient();
  const uri: string = `./api/v1/access-keys/${id}/permissions`;

  try {
    const response = await sdcClient.put<string[]>(uri, permissions);
    return response.data;
  } catch (error: any) {
    handleApplicationError(error, errorHandler);
  }

  return {} as string[];
};
