import { getSdcClient } from '../../common/apiClients/sdcClient.instance';
import ArgumentError from '../../common/error/argumentError';
import TrashSearchOptions from '../../common/models/trashSearchOptions';
import handleApplicationError from '../../utils/errorUtil';
import { getTrashSearchParam } from '../../utils/searchOptionUtil';
import PaginationResponse from '../paginationResponse';
import TrashItem from './models/trashItem';

export const searchTrashDocuments = async (
  searchOptions: TrashSearchOptions,
  containerKey: string,
  signal?: AbortSignal,
  errorHandler?: any
): Promise<PaginationResponse<TrashItem>> => {
  const sdcClient = getSdcClient();
  const params = getTrashSearchParam(searchOptions, containerKey);
  const uri: string = `./api/v1/trash/documents`;

  try {
    const response = await sdcClient.get<PaginationResponse<TrashItem>>(uri, {
      params,
      signal,
    });
    return response.data;
  } catch (error: any) {
    handleApplicationError(error, errorHandler);
  }

  return {} as PaginationResponse<TrashItem>;
};

export const deleteTrashDocuments = async (
  documentIds: string[],
  errorHandler?: any
): Promise<void> => {
  if (!documentIds) throw new ArgumentError('documentIds');

  const sdcClient = getSdcClient();
  const idsQueryParam = documentIds.join(',');
  const url: string = `./api/v1/trash/documents?ids=${idsQueryParam}`;

  try {
    await sdcClient.delete<Blob>(url);
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }
};

export const restoreTrashDocuments = async (
  documentIds: string[],
  errorHandler?: any
): Promise<void> => {
  if (!documentIds) throw new ArgumentError('documentIds');

  const sdcClient = getSdcClient();
  const idsQueryParam = documentIds.join(',');
  const url: string = `./api/v1/trash/documents/recovery?ids=${idsQueryParam}`;

  try {
    await sdcClient.post<Blob>(url);
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }
};
