const enum ErrorCode {
  Unknown = 0,
  Forbidden = 4,
  TenantNotFound = 10,
  InvalidDocumentType = 515,
  BarcodeAlreadyExists = 605,
  PendingTrayContainerNotMatch = 803,
  DuplicatedFileNameInPendingTray = 805,
  UnauthorizedSubscription = 1001,
  ContainerKeyAlreadyExists = 1100,
  SystemContainerModification = 1102,
  InvalidContainerKeyFormat = 1105,
  InvalidContainerNameFormat = 1106,
}

export default ErrorCode;
