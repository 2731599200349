import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Accordion, Button, Toast } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowDownIcon } from '../../assets/svg/ArrowDownIcon.svg';
import { ReactComponent as DefaultCardIcon } from '../../assets/svg/DefaultCardIcon.svg';
import { ReactComponent as FailedCardIcon } from '../../assets/svg/FailedCardIcon.svg';
import { ReactComponent as QueuingCardIcon } from '../../assets/svg/QueuingCardIcon.svg';
import { ReactComponent as UploadCloudBlueSmallIcon } from '../../assets/svg/UploadCloudBlueSmallIcon.svg';
import { ReactComponent as UploadCloudWhiteSmallIcon } from '../../assets/svg/UploadCloudWhiteSmallIcon.svg';
import { ReactComponent as UploadSuccessIcon } from '../../assets/svg/UploadSuccessIcon.svg';
import { ReactComponent as UploadingCardIcon } from '../../assets/svg/UploadingCardIcon.svg';
import getFileUploadConfig from '../../common/configurations/fileUploadConfig';
import UploadCardDisplayModel from '../../common/models/uploadCardDisplayModel';
import UploadFileWithStatus from '../../common/models/uploadFileWithStatus';
import FileUploadStatus from '../../common/types/fileUploadStatus';
import FileUploadStatusCard from '../../common/types/fileUploadStatusCard';
import PendingTrayUploadResponse from '../../services/pendingTray/models/pendingTrayUploadResponse';
import PendingTrayUploadedCard from '../PendingTrayUploadedCard/PendingTrayUploadedCard';
import PendingTrayUploadingCard from '../PendingTrayUploadingCard/PendingTrayUploadingCard';
import './PendingTrayUploadingStickyDialog.scss';

interface UploadingStickyDialogProps {
  files: Array<UploadFileWithStatus>;
  setFiles: React.Dispatch<React.SetStateAction<UploadFileWithStatus[]>>;
  show: boolean;
  onClose: Function;
  setIsStickyModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  startProgressTracking: (response: PendingTrayUploadResponse) => void;
  setIsUploadFileConfirmationModalVisible: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setDuplicatedNameFiles: React.Dispatch<
    React.SetStateAction<UploadFileWithStatus[]>
  >;
}

const PendingTrayUploadingStickyDialog = ({
  files,
  setFiles,
  show,
  onClose,
  setIsStickyModalVisible,
  startProgressTracking,
  setIsUploadFileConfirmationModalVisible,
  setDuplicatedNameFiles,
}: UploadingStickyDialogProps): ReactElement => {
  const { limitFileUploadQueue } = getFileUploadConfig();
  const [accordionCollapsed, setAccordionCollapsed] = useState(false);
  const [filesUploadedSuccess, setFilesUploadedSuccess] = useState(false);
  const [filesUploadedSuccessCounter, setFilesUploadedSuccessCounter] =
    useState<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (
      files.every(
        (f) =>
          f.status === FileUploadStatus.SUCCESS ||
          f.status === FileUploadStatus.CANCELED
      )
    ) {
      setFilesUploadedSuccess(true);
      setAccordionCollapsed(true);
      timer = setTimeout(() => {
        setFilesUploadedSuccessCounter(0);
      }, 4000);
    } else {
      setFilesUploadedSuccess(false);
      setAccordionCollapsed(false);
    }
    return (): void => {
      if (timer) clearTimeout(timer);
    };
  }, [files]);

  // Main function for change status UPLOADING to SUCCESS and PENDING to UPLOADING
  const replaceStatusFiles = useCallback(
    (
      fileSet: Array<UploadFileWithStatus>,
      uploadedFileId: string,
      status: FileUploadStatus
    ): UploadFileWithStatus[] => {
      const nextUploading = fileSet.find(
        (f) => f.status === FileUploadStatus.PENDING
      );

      const maxUploading = fileSet.filter(
        (f) => f.status === FileUploadStatus.UPLOADING
      ).length;

      return fileSet.map((f) => {
        // eslint-disable-next-line no-param-reassign
        if (f.id === uploadedFileId) f.status = status;

        if (
          nextUploading &&
          f.id === nextUploading.id &&
          uploadedFileId !== nextUploading.id &&
          maxUploading < limitFileUploadQueue
        ) {
          // eslint-disable-next-line no-param-reassign
          f.status = FileUploadStatus.UPLOADING;
        }
        return f;
      });
    },
    [limitFileUploadQueue]
  );

  // Generate file props for display
  const getCardProps = useCallback(
    (cardStatus: FileUploadStatus): UploadCardDisplayModel => {
      if (cardStatus === FileUploadStatus.PENDING)
        return {
          className: FileUploadStatusCard.QUEUING,
          Icon: QueuingCardIcon,
          title: t('uploadingCardComponent.cardStatusQueue'),
        };

      if (cardStatus === FileUploadStatus.UPLOADING)
        return {
          className: FileUploadStatusCard.UPLOADING,
          Icon: UploadingCardIcon,
          title: t('uploadingCardComponent.cardStatusUploading'),
        };

      if (cardStatus === FileUploadStatus.SUCCESS)
        return {
          className: FileUploadStatusCard.SUCCESS,
          Icon: UploadSuccessIcon,
          title: t('uploadingCardComponent.cardStatusSuccess'),
        };

      if (cardStatus === FileUploadStatus.FAILED)
        return {
          className: FileUploadStatusCard.FAILED,
          Icon: FailedCardIcon,
          title: t('uploadingCardComponent.cardStatusFailed'),
        };

      return {
        className: '',
        Icon: DefaultCardIcon,
        title: 'Default',
      };
    },
    [t]
  );

  const setUploadFileStatus = useCallback(
    (uploadedFile: UploadFileWithStatus, status: FileUploadStatus): void => {
      if (status === FileUploadStatus.SUCCESS) {
        setFilesUploadedSuccessCounter((success) => success + 1);
      }
      setFiles((prevStates): UploadFileWithStatus[] =>
        replaceStatusFiles(prevStates, uploadedFile.id, status)
      );
    },
    [replaceStatusFiles, setFiles]
  );

  const checkAllFileUploadSuccess = (): ReactElement => {
    if (filesUploadedSuccess) {
      return accordionCollapsed ? (
        <UploadSuccessIcon className="collapsed" />
      ) : (
        <UploadSuccessIcon fill="#20C151" />
      );
    }
    return accordionCollapsed ? (
      <UploadCloudBlueSmallIcon />
    ) : (
      <UploadCloudWhiteSmallIcon />
    );
  };

  return (
    <>
      <Toast
        className="uploading-toast-container"
        show={show}
        onClose={(): void => onClose()}
      >
        <Accordion
          defaultActiveKey="0"
          activeKey={accordionCollapsed ? '1' : '0'}
        >
          <Accordion.Item eventKey="0">
            <Accordion.Header
              className={
                files.every(
                  (f) =>
                    f.status === FileUploadStatus.SUCCESS ||
                    f.status === FileUploadStatus.CANCELED
                )
                  ? 'success'
                  : ''
              }
              onClick={(): void => {
                if (filesUploadedSuccess) {
                  setIsStickyModalVisible(false);
                } else {
                  setAccordionCollapsed(!accordionCollapsed);
                }
              }}
            >
              <div className="upload-sticky-header">
                {checkAllFileUploadSuccess()}
                <div className="heading-title-18-bold">
                  {filesUploadedSuccess
                    ? t(
                        'uploadingStickyDialogComponent.successUploadCounterTitle',
                        {
                          successFilesCounter: filesUploadedSuccessCounter,
                        }
                      )
                    : t(
                        'uploadingStickyDialogComponent.uploadingCounterTitle',
                        {
                          uploadingCounter: files.filter(
                            (item) =>
                              item.status === FileUploadStatus.UPLOADING ||
                              item.status === FileUploadStatus.PENDING
                          ).length,
                        }
                      )}
                </div>
                {accordionCollapsed &&
                  files.some((f) => f.status === FileUploadStatus.FAILED) && (
                    <div className="has-error">
                      {t('uploadingStickyDialogComponent.uploadIncompleted')}
                    </div>
                  )}
                {accordionCollapsed ? (
                  <div
                    className={`view-collapsed-button ${
                      filesUploadedSuccess && 'success'
                    }`}
                  >
                    {filesUploadedSuccess
                      ? t('uploadingStickyDialogComponent.closeCollapsed')
                      : t('uploadingStickyDialogComponent.openCollapsed')}
                  </div>
                ) : (
                  <div className="view-collapsed-button">
                    <ArrowDownIcon fill="#fff" />
                  </div>
                )}
              </div>
            </Accordion.Header>
            <Accordion.Body>
              {files.map((file: UploadFileWithStatus) => {
                if (file.status === FileUploadStatus.UPLOADING)
                  return (
                    <PendingTrayUploadingCard
                      key={file.id}
                      fileInfo={file}
                      setUploadFileStatus={setUploadFileStatus}
                      startProgressTracking={startProgressTracking}
                      setIsUploadFileConfirmationModalVisible={
                        setIsUploadFileConfirmationModalVisible
                      }
                      getCardProps={getCardProps}
                      setDuplicatedNameFiles={setDuplicatedNameFiles}
                    />
                  );

                return (
                  <PendingTrayUploadedCard
                    key={file.id}
                    fileInfo={file}
                    getCardProps={getCardProps}
                  />
                );
              })}
              {files.some((f) => f.status === FileUploadStatus.FAILED) &&
                !files.some(
                  (f) =>
                    f.status === FileUploadStatus.UPLOADING ||
                    f.status === FileUploadStatus.PENDING
                ) && (
                  <Button
                    className="close-dialog-btn"
                    variant="link"
                    onClick={(event): void => {
                      event.preventDefault();
                      onClose();
                    }}
                  >
                    {t('uploadingStickyDialogComponent.closeButton')}
                  </Button>
                )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Toast>
    </>
  );
};

export default PendingTrayUploadingStickyDialog;
