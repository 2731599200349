import { useEffect, useMemo, useState } from 'react';
import EventListener from '../../common/providers/eventListener';
import LocationProvider from '../../common/providers/locationProvider';

const usePathName = (): [string, URLSearchParams] => {
  const eventListener = useMemo(() => new EventListener(), []);
  const location = useMemo(() => new LocationProvider(), []);
  const [path, setPath] = useState(location.pathName());
  const [searchParams, setSearchParams] = useState(
    new URLSearchParams(location.search())
  );

  useEffect(() => {
    const listenToPopstate = (): void => {
      const winPath = location.pathName();
      setPath(winPath);

      const winSearch = location.search();
      setSearchParams(new URLSearchParams(winSearch));
    };

    eventListener.add('popstate', listenToPopstate);
    return (): void => {
      eventListener.remove('popstate', listenToPopstate);
    };
  }, [eventListener, location]);

  return [path, searchParams];
};

export default usePathName;
