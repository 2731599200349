import { getSdcClient } from '../../common/apiClients/sdcClient.instance';
import handleApplicationError from '../../utils/errorUtil';
import CurrentUserResponse from './models/currentUserResponse';
import UserFullNameResponse from './models/userFullNameResponse';

const getCurrentUser = async (
  errorHandler?: Function | undefined
): Promise<CurrentUserResponse> => {
  const sdcClient = getSdcClient();
  const url: string = `./api/v1/users/current`;
  try {
    const response = await sdcClient.get<CurrentUserResponse>(url);
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }
  return {} as CurrentUserResponse;
};

export const getUserFullNames = async (
  errorHandler?: Function | undefined
): Promise<UserFullNameResponse[]> => {
  const sdcClient = getSdcClient();
  const url: string = `./api/v1/users/names`;
  try {
    const response = await sdcClient.get(url);
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }
  return [] as UserFullNameResponse[];
};

export default getCurrentUser;
