import { ReactElement } from 'react';
import GridCell, { DataGridCell } from './GridCell';
import GridCheckBox from './GridCheckBox';

export interface DataGridRow {
  id: string;
  cells: DataGridCell[];
  clickable?: boolean;
  onRowClick?: (id: string) => void;
  rowClass?: string;
  columnExtraWidth?: boolean;
}

interface GridRowCollectionProps {
  rows: DataGridRow[];
  selectable: boolean;
  values: string[];
  setSelectableStates: React.Dispatch<React.SetStateAction<string[]>>;
}

const GridRowCollection = ({
  rows,
  selectable,
  values,
  setSelectableStates,
}: GridRowCollectionProps): ReactElement => (
  <>
    {rows.map((row: DataGridRow, index: number) => (
      <div
        className={`data-row ${row.rowClass ?? ''} ${
          row.clickable ? 'clickable' : ''
        }`}
        key={row.id}
        role="button"
        onClick={(): void => {
          if (row.onRowClick !== undefined) {
            row.onRowClick(row.id);
          }
        }}
        onKeyPress={(): void => {
          if (row.onRowClick !== undefined) {
            row.onRowClick(row.id);
          }
        }}
        tabIndex={index}
      >
        {selectable ? (
          <div className="data-check">
            <GridCheckBox
              value={row.id}
              setSelectableStates={setSelectableStates}
              selectedValues={values}
            />
          </div>
        ) : (
          <div className="padded-column" />
        )}
        {row.cells.map((cell: DataGridCell) => (
          <GridCell key={cell.dataKey} cell={cell} id={row.id} />
        ))}
      </div>
    ))}
  </>
);

export default GridRowCollection;
