import {
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import SearchGuideImage from '../../assets/img/search-guide.png';
import SearchNotFoundImage from '../../assets/img/search-not-found.png';
import { ReactComponent as CloseSmallIcon } from '../../assets/svg/CloseSmallIcon.svg';
import { ReactComponent as SearchSmallIcon } from '../../assets/svg/SearchIcon.svg';
import getDefaultPageSize from '../../common/configurations/pageConfig';
import DocumentSearchOptions from '../../common/models/documentSearchOptions';
import ItemListAction from '../../common/models/itemListAction';
import KeyPairItem from '../../common/models/keypairItem';
import SDCButtonSize from '../../common/types/sdcButtonSize';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import SortItemKey from '../../common/types/sortItemKey';
import ArchivedDocumentDetail from '../../services/document/models/archivedDocumentDetail';
import DocumentType from '../../services/documentType/models/documentType';
import PaginationResponse from '../../services/paginationResponse';
import buildDocumentTypeDropdownListItems from '../../utils/documentTypeUtil';
import { getDocumentNameWithExtension } from '../../utils/fileUtil';
import LoadingSet from '../LoadingSet/LoadingSet';
import ResultListItem from '../ResultListItem/ResultListItem';
import SDCButton from '../SDCButton/SDCButton';
import SDCCheckbox from '../SDCCheckbox/SDCCheckbox';
import SearchFilter from '../SearchFilter/SearchFilter';
import SecondaryDropdown from '../SecondaryDropdown/SecondaryDropdown';
import ResultDocumentActionIcon from './ResultDocumentActionIcon';
import './ResultDocumentList.scss';

const ascending = 'ascending';
const descending = 'descending';
const defaultSearchOptions: DocumentSearchOptions = {
  text: '',
  sortBy: `${SortItemKey.Relevance}_${descending}`,
  page: 1,
  pageSize: getDefaultPageSize(),
  documentType: undefined,
  filter: undefined,
};

interface ResultDocumentListProps {
  documentResult: PaginationResponse<ArchivedDocumentDetail> | undefined;
  isFetchingDocuments: boolean;
  onItemClick: (document: ArchivedDocumentDetail) => void;
  documentTypes: DocumentType[];
  actions: ItemListAction[];
  isNoTaskBar?: boolean;
  isTags?: boolean;
  checkedDocumentIds: string[];
  setCheckedDocumentIds: React.Dispatch<React.SetStateAction<Array<string>>>;
  resultCount?: number;
  disabled?: boolean;
  collapsable?: boolean;
  /**
   * Title when collapsable
   */
  title?: string;
  onSearch: (options: DocumentSearchOptions) => void;
  mainEntityId?: string;
}

const ResultDocumentList = ({
  documentResult,
  isFetchingDocuments,
  onItemClick,
  documentTypes,
  actions,
  isNoTaskBar,
  isTags,
  checkedDocumentIds,
  setCheckedDocumentIds,
  resultCount,
  disabled,
  collapsable,
  title,
  onSearch,
  mainEntityId,
}: ResultDocumentListProps): ReactElement => {
  const { t } = useTranslation();
  // Use in next version
  // const [useCardView, setUseCardView] = useState(false);
  const allDocumentType = t('documentsViewScreen.documentTypeDropDownDefault');
  const deletedDocumentTypeState = t(
    'documentsViewScreen.deletedDocumentTypeState'
  );
  const documentTypeItems: KeyPairItem[] = buildDocumentTypeDropdownListItems(
    allDocumentType,
    documentTypes,
    deletedDocumentTypeState
  );

  const [searchOptions, setSearchOptions] = useState(defaultSearchOptions);
  const selectedAll =
    checkedDocumentIds.length > 0 &&
    documentResult !== undefined &&
    documentResult.results.every((x) => checkedDocumentIds.includes(x.id));

  const [selectedDocumentId, setSelectedDocumentId] = useState('');
  const unknownFilePlaceHolder = '...';
  const [searchControlExpanded, setSearchControlExpanded] = useState(false);
  const searchBoxRef = useRef(null) as MutableRefObject<any>;
  const sortMenuItems: KeyPairItem[] = ((): KeyPairItem[] => {
    if (isTags) {
      return [
        {
          Name: t(`documentsViewScreen.sortBy${SortItemKey.Name}ASC`),
          Value: `${SortItemKey.Name}_${ascending}`,
        },
        {
          Name: t(`documentsViewScreen.sortBy${SortItemKey.Name}DESC`),
          Value: `${SortItemKey.Name}_${descending}`,
        },
        {
          Name: t(`documentsViewScreen.sortBy${SortItemKey.Type}ASC`),
          Value: `${SortItemKey.Type}_${ascending}`,
        },
        {
          Name: t(`documentsViewScreen.sortBy${SortItemKey.Type}DESC`),
          Value: `${SortItemKey.Type}_${descending}`,
        },
        {
          Name: t(`documentsViewScreen.sortBy${SortItemKey.Date}ASC`),
          Value: `${SortItemKey.Date}_${ascending}`,
        },
        {
          Name: t(`documentsViewScreen.sortBy${SortItemKey.Date}DESC`),
          Value: `${SortItemKey.Date}_${descending}`,
        },
      ];
    }
    return [
      {
        Name: t(`documentsViewScreen.sortBy${SortItemKey.Relevance}DESC`),
        Value: `${SortItemKey.Relevance}_${descending}`,
      },
      {
        Name: t(`documentsViewScreen.sortBy${SortItemKey.Name}ASC`),
        Value: `${SortItemKey.Name}_${ascending}`,
      },
      {
        Name: t(`documentsViewScreen.sortBy${SortItemKey.Name}DESC`),
        Value: `${SortItemKey.Name}_${descending}`,
      },
      {
        Name: t(`documentsViewScreen.sortBy${SortItemKey.Type}ASC`),
        Value: `${SortItemKey.Type}_${ascending}`,
      },
      {
        Name: t(`documentsViewScreen.sortBy${SortItemKey.Type}DESC`),
        Value: `${SortItemKey.Type}_${descending}`,
      },
      {
        Name: t(`documentsViewScreen.sortBy${SortItemKey.Date}ASC`),
        Value: `${SortItemKey.Date}_${ascending}`,
      },
      {
        Name: t(`documentsViewScreen.sortBy${SortItemKey.Date}DESC`),
        Value: `${SortItemKey.Date}_${descending}`,
      },
    ];
  })();

  const [documents, setDocuments] = useState<ArchivedDocumentDetail[]>([]);

  useEffect(() => {
    if (documentResult) {
      setDocuments(documentResult.results);
      setCheckedDocumentIds([]);
    }
  }, [documentResult, documentTypes, allDocumentType, setCheckedDocumentIds]);

  const sortItemBy = (attribute: string): void => {
    const newOptions = { ...searchOptions, sortBy: attribute };
    setSearchOptions(newOptions);
    onSearch(newOptions);
  };

  const handleCheckChange = (id: string): void => {
    const foundIndex = checkedDocumentIds.indexOf(id);
    const unchecked = foundIndex === -1;
    if (unchecked) {
      setCheckedDocumentIds([...checkedDocumentIds, id]);
    } else {
      const temp = [...checkedDocumentIds];
      temp.splice(foundIndex, 1);
      setCheckedDocumentIds(temp);
    }
  };

  const handleItemClick = (document: ArchivedDocumentDetail): void => {
    setSelectedDocumentId(document.id);
    onItemClick(document);
  };

  const handleSelectAll = (checked: boolean): void => {
    // Check/uncheck all documents on the current page
    if (checked) {
      const allIds = documentResult?.results.map(
        (item: ArchivedDocumentDetail) => item.id
      );
      if (allIds) {
        setCheckedDocumentIds(allIds);
      }
    } else {
      setCheckedDocumentIds([]);
    }
  };

  const getListItemTitle = (document: ArchivedDocumentDetail): string => {
    if (document.documentName === document.fileName) {
      return document.documentName;
    }

    return (
      getDocumentNameWithExtension(document.documentName, document.fileName) ??
      unknownFilePlaceHolder
    );
  };

  const getDocumentTypeDisplayName = (documentTypeName: string): string => {
    const displayName = documentTypes.find(
      (x) => x.name === documentTypeName
    )?.displayName;
    return displayName ?? documentTypeName;
  };

  const renderItems = (): JSX.Element => {
    if (documents.length === 0) {
      return (
        <>
          <div className="result-placeholder-container" key="no-result-card">
            <img src={SearchNotFoundImage} alt="" />
            <div className="not-found-content">
              {t('documentSearchScreen.notFound')}
            </div>
          </div>
          <div
            className="simple-result-placeholder-container"
            key="no-result-text"
          >
            {t('documentSearchScreen.notFound')}
          </div>
        </>
      );
    }

    const items = [];

    items.push(
      documents?.map((document: ArchivedDocumentDetail) => (
        <ResultListItem
          key={document.documentName}
          id={document.id}
          date={document.modifiedOn}
          subtitle={getDocumentTypeDisplayName(document.documentType)}
          title={getListItemTitle(document)}
          authorName={document.createdBy}
          onClick={(): void => handleItemClick(document)}
          onCheckChange={(): void => handleCheckChange(document.id)}
          selected={document.id === selectedDocumentId}
          checked={checkedDocumentIds.includes(document.id)}
          protectionStatus={document.protection}
          entityIds={document.entityIds}
          mainEntityId={mainEntityId}
        />
      ))
    );

    return <>{items}</>;
  };

  const renderSearchResults = (): ReactElement => {
    if (resultCount === undefined) {
      if (isFetchingDocuments) {
        return <LoadingSet />;
      }

      return (
        <div className="search-placeholder-container" key="initial-placeholder">
          <img src={SearchGuideImage} alt="" />
          <div className="guide-content">{t('documentSearchScreen.guide')}</div>
        </div>
      );
    }

    return (
      <>
        <div className="view-actions">
          <div className="action-group">
            <SDCCheckbox
              aria-label="select-all"
              labelText={t('documentsViewScreen.selectAllCheckbox')}
              checked={selectedAll}
              onCheckChange={handleSelectAll}
              disabled={documentResult?.results.length === 0}
            />
            <span className="divider" />

            {actions.map((action) => {
              const { name, displayName, Icon, onAction } = action;
              return (
                <ResultDocumentActionIcon
                  key={name}
                  name={name}
                  displayName={displayName}
                  Icon={Icon}
                  onAction={(): void => onAction(checkedDocumentIds)}
                  isDisabled={checkedDocumentIds.length === 0}
                />
              );
            })}
          </div>
          <div className="control-items">
            <SecondaryDropdown
              selectedValue={
                sortMenuItems.find(
                  (sortBy) => sortBy.Value === searchOptions.sortBy
                )?.Value ?? ''
              }
              items={sortMenuItems}
              onSelect={(item: KeyPairItem): void => sortItemBy(item.Value)}
            />
          </div>
        </div>
        <div className={`items-container ${isNoTaskBar ? 'full' : ''}`}>
          {isFetchingDocuments && <LoadingSet />}
          {!isFetchingDocuments ? renderItems() : null}
        </div>
      </>
    );
  };

  const renderSearchFilter = (): ReactElement => {
    if (collapsable) {
      return (
        <div
          className={`collapsable-document-view-header ${
            searchControlExpanded ? 'visible' : ''
          }`}
        >
          <div className="title">
            <div className="start">
              <span className="display-name">{title ?? 'Untitled'}</span>
            </div>
            <div className="end">
              <SDCButton
                variant={SDCButtonVariant.Text}
                size={SDCButtonSize.Small}
                Icon={searchControlExpanded ? CloseSmallIcon : SearchSmallIcon}
                text={
                  searchControlExpanded
                    ? t('documentsViewScreen.searchButtonClose')
                    : t('documentsViewScreen.searchButton')
                }
                onClick={(): void => {
                  // Focus on the textbox if this is to expand
                  if (!searchControlExpanded) {
                    setImmediate(() => {
                      searchBoxRef?.current?.focus();
                    });
                  }

                  setSearchControlExpanded(!searchControlExpanded);
                }}
              />
            </div>
          </div>
          <div className="content">
            <SearchFilter
              inputRef={searchBoxRef}
              documentTypeItems={documentTypeItems}
              documentTypes={documentTypes}
              onSearch={(options: DocumentSearchOptions): void => {
                const newOptions = {
                  ...options,
                  sortBy: searchOptions.sortBy,
                  page: searchOptions.page,
                };
                onSearch(newOptions);
                setSearchOptions(newOptions);
              }}
              filterDisabled={disabled}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="document-view-header">
        <SearchFilter
          documentTypeItems={documentTypeItems}
          documentTypes={documentTypes}
          onSearch={(options: DocumentSearchOptions): void => {
            const newOptions = {
              ...options,
              sortBy: searchOptions.sortBy,
              page: searchOptions.page,
            };
            onSearch(newOptions);
            setSearchOptions(newOptions);
          }}
          filterDisabled={documentResult?.pageSize === 0}
        />
      </div>
    );
  };

  return (
    <div className="result-document-view-container">
      {renderSearchFilter()}
      {renderSearchResults()}
    </div>
  );
};

ResultDocumentList.defaultProps = {
  isNoTaskBar: true,
  isTags: false,
  resultCount: undefined,
  disabled: false,
  collapsable: false,
  title: undefined,
  mainEntityId: undefined,
};

export default ResultDocumentList;
