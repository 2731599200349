/* eslint-disable no-console */
import * as signalR from '@microsoft/signalr';
import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import getApiUrl from '../../common/configurations/apiUrl';
import signalRServerEventName from '../../common/constants/signalRServerEventName.constant';
import { SignalRContext } from '../../context/SignalR/SignalRContext';
import usePathName from '../../hooks/usePathName/usePathName';

interface SignalRProviderProps {
  children: ReactNode;
}

interface RequiredQueryString {
  key: string;
  value: string;
}

interface SignalRRoute {
  path: string;
  queryString: RequiredQueryString | null;
}

const enabledSignalRRoutes: SignalRRoute[] = [
  { path: '/pending-tray', queryString: null },
  {
    path: '/documents/capture',
    queryString: { key: 'type', value: 'pending-tray' },
  },
];

export const validPathToEnableSignalR = (
  validRoutes: SignalRRoute[],
  path: string,
  searchParams: URLSearchParams
): boolean => {
  const pathOnly = validRoutes.filter((x) => !x.queryString).map((x) => x.path);

  if (pathOnly.includes(path)) return true;

  const requiredQueryString = validRoutes.filter((x) => x.queryString);

  if (
    requiredQueryString.find(
      (x) =>
        x.queryString &&
        searchParams.get(x.queryString.key) === x.queryString.value &&
        path.substring(0, x.path.length) === x.path
    )
  )
    return true;

  return false;
};

const { sdcUrl } = getApiUrl();
const signalRURL = `${sdcUrl}/pending-tray-hub`;
const connection = new signalR.HubConnectionBuilder()
  .withUrl(signalRURL, {
    withCredentials: true,
  })
  .withAutomaticReconnect()
  .configureLogging(signalR.LogLevel.Information)
  .build();

const SignalRProvider = ({ children }: SignalRProviderProps): ReactElement => {
  const [path, URLSearchParams] = usePathName();
  const { state } = connection;
  const [connectionState, setConnectionState] =
    useState<signalR.HubConnectionState>(state);

  connection.onreconnecting(() => {
    console.log(connection.state);
    setConnectionState(signalR.HubConnectionState.Reconnecting);
  });

  connection.onreconnected(() => {
    console.log(connection.state);
    setConnectionState(signalR.HubConnectionState.Connected);
  });

  connection.onclose(() => {
    console.log(connection.state);
    setConnectionState(signalR.HubConnectionState.Disconnected);
  });

  useEffect(() => {
    if (validPathToEnableSignalR(enabledSignalRRoutes, path, URLSearchParams)) {
      connection
        .start()
        .catch(() => {
          // Ignore error when client cannot connect to SignalR
        })
        .then(() => {
          if (connection.state === signalR.HubConnectionState.Connected) {
            setConnectionState(signalR.HubConnectionState.Connected);
            connection.invoke(signalRServerEventName.JOIN_TENANT_GROUP);
          }
        });
    } else
      connection.stop().then(() => {
        setConnectionState(signalR.HubConnectionState.Disconnected);
      });
  }, [URLSearchParams, path]);

  const contextValue = useMemo(
    () => ({
      connection,
      connectionState,
    }),
    [connectionState]
  );

  return (
    <SignalRContext.Provider value={contextValue}>
      {children}
    </SignalRContext.Provider>
  );
};

export default SignalRProvider;
