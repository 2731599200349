import { ReactElement } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import Page from '../../../common/types/page';
import { useCurrentUser } from '../../../context/CurrentUser/CurrentUserContext';
import useApplicationLink from '../../../hooks/useApplicationLink/useApplicationLink';
import isTabletSize from '../../../utils/responsiveUtil';
import ContainerDropdown from '../../ContainerDropdown/ContainerDropdown';
import MenuOptions from '../../MenuOptions/MenuOptions';
import UserHeaderOptions from '../../UserHeaderOptions/UserHeaderOptions';
import ApplicationMenuItem from '../ApplicationMenuItem/ApplicationMenuItem';
import BrandLogo from '../BrandLogo/BrandLogo';
import './MainNavigationMenu.scss';

interface MainNavigationMenuProps {
  children?: ReactElement;
}

const MainNavigationMenu = ({
  children,
}: MainNavigationMenuProps): ReactElement => {
  const pageTitleInfo = useApplicationLink();
  const { currentUser } = useCurrentUser();

  const getMenuItem = (): ReactElement => {
    if (
      pageTitleInfo?.pageType === Page.ApplicationSpecific ||
      pageTitleInfo?.pageType === Page.Error
    ) {
      return <ApplicationMenuItem pageTitle={pageTitleInfo} />;
    }
    return <Nav />;
  };

  const getUserMenu = (): ReactElement => {
    if (currentUser && pageTitleInfo?.showUserMenu) {
      if (!isTabletSize()) {
        return (
          <UserHeaderOptions
            displayName={currentUser.name || currentUser.email}
            userRole={currentUser.roles[0]}
          />
        );
      }
      return (
        <MenuOptions
          displayName={currentUser.name}
          userRole={currentUser.roles[0]}
        />
      );
    }
    return <></>;
  };

  const getContainer = (): ReactElement => (
    <ContainerDropdown display={pageTitleInfo?.isScopeContainer} />
  );

  return (
    <Navbar className="app-header-w-side-menu" variant="dark">
      <div className="container-fluid main-navbar p-0">
        {isTabletSize() ? <BrandLogo /> : null}
        {getMenuItem()}
        <div className="options-group">
          {children}
          {getContainer()}
          {getUserMenu()}
        </div>
      </div>
    </Navbar>
  );
};

MainNavigationMenu.defaultProps = {
  children: <></>,
};

export default MainNavigationMenu;
