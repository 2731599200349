/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import { ReactElement, useEffect, useState } from 'react';
import { Popover } from 'react-bootstrap';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import ArchiveArrowNotificationIcon from '../../assets/svg/ArchiveArrowNotificationIcon.svg';
import ArchiveSuccessNotificationIcon from '../../assets/svg/ArchiveSuccessNotificationIcon.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/svg/ArrowDownIcon.svg';
import CloseIcon from '../../assets/svg/CloseIcon.svg';
import CompletedArchiveDocument from '../../common/models/completedArchiveDocument';
import CollapserPositionVariant from '../../common/types/collapserPositionVariant';
import NotificationVariant from '../../common/types/notificationVariant';
import Metadata from '../../services/capture/models/metadata';
import {
  downloadArchivedDocuments,
  getArchivedDocument,
} from '../../services/document/document.service';
import ArchivedDocumentWithMetadata from '../../services/document/models/archivedDocumentWithMetadata';
import { getDocumentNameWithExtension } from '../../utils/fileUtil';
import ArchiveDocumentSection from '../ArchiveDocumentSection/ArchiveDocumentSection';
import DocumentViewModal from '../DocumentViewModal/DocumentViewModal';
import showToaster from '../Toaster/ToasterProvider';
import './CaptureDocumentFooter.scss';

type CaptureDocumentFooterProps = {
  displayName: string;
  captureInfoId: string;
  metadata: Metadata;
  archiveCompleteFileList: CompletedArchiveDocument[];
  isShowCompletedNotification: boolean;
  isShowCompletedArchiveList: boolean;
  setShowLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const CaptureDocumentFooter = ({
  displayName,
  captureInfoId,
  metadata,
  archiveCompleteFileList,
  isShowCompletedNotification,
  isShowCompletedArchiveList,
  setShowLoading,
}: CaptureDocumentFooterProps): ReactElement => {
  const { t } = useTranslation();
  const [showCompletedNotification, setShowCompletedNotification] =
    useState(false);
  const [showCompletedArchiveList, setShowCompletedArchiveList] =
    useState(false);
  const [documentInfo, setDocumentInfo] = useState<
    ArchivedDocumentWithMetadata | undefined
  >(undefined);
  const [documentContent, setDocumentContent] = useState<File | undefined>(
    undefined
  );
  const [showDocumentCompletedModal, setShowDocumentCompletedModal] =
    useState(false);
  const errorHandler = useErrorHandler();

  useEffect(() => {
    setShowCompletedNotification(isShowCompletedNotification);
    setShowCompletedArchiveList(isShowCompletedArchiveList);
  }, [isShowCompletedArchiveList, isShowCompletedNotification]);

  const archivedListOnClick = async (
    fileInfo: CompletedArchiveDocument
  ): Promise<void> => {
    setShowLoading(true);
    try {
      const [getDocumentResponse, getDocumentContentResponse] =
        await Promise.all([
          getArchivedDocument(fileInfo.document.documentId, errorHandler),
          downloadArchivedDocuments(
            [fileInfo.document.documentId],
            errorHandler
          ),
        ]);
      setShowDocumentCompletedModal(true);
      setDocumentInfo(getDocumentResponse);
      setDocumentContent(getDocumentContentResponse);
    } catch (e) {
      showToaster(
        t('captureDocumentFooterComponent.errorLoadingFileTitle'),
        t('captureDocumentFooterComponent.errorLoadingFileMessage'),
        NotificationVariant.SoftBasic
      );
    } finally {
      setShowLoading(false);
    }
  };

  return (
    <>
      <div
        className={`complete-archive-document-footer ${
          showCompletedArchiveList && 'expanded'
        }`}
      >
        {showCompletedNotification && (
          <Popover className="archive-success-notification">
            <Popover.Body>
              <div className="complete-document-notification">
                <img
                  src={ArchiveSuccessNotificationIcon}
                  alt="ArchiveSuccessNotificationIcon"
                />
                <div className="text-start w-100">
                  <div className="header">
                    {t('captureDocumentFooterComponent.addedNotification')}
                  </div>
                  <div data-hj-suppress>
                    {
                      archiveCompleteFileList[
                        archiveCompleteFileList.length - 1
                      ].file?.name
                    }
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-link notification-close-btn"
                  onClick={(): void => {
                    setShowCompletedNotification(false);
                  }}
                >
                  <img src={CloseIcon} alt="CloseIcon" />
                </button>
                <img
                  src={ArchiveArrowNotificationIcon}
                  className="notification-arrow"
                  alt="ArchiveArrowNotificationIcon"
                />
              </div>
            </Popover.Body>
          </Popover>
        )}
        <div
          className="label-group"
          onClick={(): void => {
            setShowCompletedNotification(false);
            setShowCompletedArchiveList(!showCompletedArchiveList);
          }}
        >
          <button type="button" className="btn btn-link btn-footer-label p-0">
            {t('captureDocumentFooterComponent.archiveDocumentsLabel')}
          </button>
          <div className="complete-counter-number">
            {archiveCompleteFileList.length}
          </div>
          <div className="icon-box">
            <ArrowDownIcon
              fill="#20c151"
              className={showCompletedArchiveList ? 'flip' : ''}
            />
          </div>
        </div>

        {showCompletedArchiveList && (
          <div className="complete-document-container">
            {archiveCompleteFileList
              .slice(0)
              .reverse()
              .map((fileInfo: CompletedArchiveDocument, index: number) => (
                <div
                  className="complete-list"
                  key={`document-completed-list-${index}`}
                >
                  <div>{fileInfo.archivedTime}</div>
                  <button
                    type="button"
                    className="btn btn-link btn-success-file"
                    onClick={(): Promise<void> => archivedListOnClick(fileInfo)}
                  >
                    <span className="item-content" data-hj-suppress>
                      {fileInfo.file?.name}
                    </span>
                  </button>
                </div>
              ))}
          </div>
        )}
      </div>

      <DocumentViewModal
        show={showDocumentCompletedModal}
        onClose={(): void => setShowDocumentCompletedModal(false)}
      >
        <ArchiveDocumentSection
          selectedFile={documentContent}
          // Prioritize the document's metadata over the original metadata
          metadata={metadata}
          displayName={displayName ?? captureInfoId}
          showArchiveButton={false}
          captureInfoId={captureInfoId}
          onCloseModal={(): void => setShowDocumentCompletedModal(false)}
          onCompletedArchive={undefined}
          uploadedContents={undefined}
          enableEdit={false}
          collapserPosition={CollapserPositionVariant.WithTitleBar}
          documentName={
            getDocumentNameWithExtension(
              documentInfo?.documentName,
              documentInfo?.fileName
            ) ?? t('documentTitleBar.undefinedValue')
          }
        />
      </DocumentViewModal>
    </>
  );
};

export default CaptureDocumentFooter;
