import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ForbiddenImage from '../../assets/img/search-forbidden.png';
import CollapserPositionVariant from '../../common/types/collapserPositionVariant';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import SDCButton from '../SDCButton/SDCButton';
import AccessDescriptionSidePanel from '../SidePanel/AccessDescriptionSidePanel';
import './RestrictedArea.scss';

const RestrictedArea = (): ReactElement => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const [showDescription, setShowDescription] = useState(false);

  return (
    <div className="restricted-area-container">
      <div className="main-content">
        <div className="forbidden-content">
          <img src={ForbiddenImage} alt="" />
          <div className="title">{t('restrictedArea.title')}</div>
          <div className="subtitle">{t('restrictedArea.subtitle')}</div>
          <SDCButton
            variant={SDCButtonVariant.Link}
            onClick={(): void => {
              setShowDescription(!showDescription);
            }}
            text={t('restrictedArea.learnMore')}
          />
        </div>
      </div>
      {showDescription ? (
        <AccessDescriptionSidePanel
          userPermissions={currentUser.permissions}
          collapserPosition={CollapserPositionVariant.WithTitleBar}
          userRole={currentUser.roles[0]}
        />
      ) : null}
    </div>
  );
};

export default RestrictedArea;
