import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SubscriptionResponse from '../../services/subscription/models/subscriptionResponse';
import DateTimeFormatter from '../DateTime/DateTimeFormatter';
import './SubscriptionCard.scss';
import SubscriptionStatusBadge from './SubscriptionStatusBadge';

interface SubscriptionCardProps {
  subscription: SubscriptionResponse | undefined;
}

const SubscriptionCard = ({
  subscription,
}: SubscriptionCardProps): ReactElement => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  useEffect(() => {
    setStartDate(subscription?.startDate ?? new Date());
    setEndDate(subscription?.endDate ?? new Date());
  }, [subscription]);

  return (
    <div className="subscription-card">
      <div className="info">
        <div>{t('subscriptionScreen.planLabel')}</div>
        <span>{subscription?.planName}</span>
      </div>
      <div className="info">
        <div>{t('subscriptionScreen.companyLabel')}</div>
        <span>{subscription?.tenantName}</span>
      </div>
      <div className="info">
        <div>{t('subscriptionScreen.startDateLabel')}</div>
        <span>
          <DateTimeFormatter date={startDate} format="DD/MM/YYYY" />
        </span>
      </div>
      <div className="info">
        <div>{t('subscriptionScreen.statusLabel')}</div>
        <SubscriptionStatusBadge status={subscription?.status} />
      </div>
      <div className="info">
        <div>{t('subscriptionScreen.endDateLabel')}</div>
        <span>
          <DateTimeFormatter date={endDate} format="DD/MM/YYYY" />
        </span>
      </div>
    </div>
  );
};

export default SubscriptionCard;
