const enum NotificationVariant {
  PrimaryBasic = 'primary-basic',
  PrimaryDanger = 'primary-danger',
  PrimaryWarning = 'primary-warning',
  PrimaryInfo = 'primary-info',
  PrimarySuccess = 'primary-success',

  SecondaryBasic = 'secondary-basic',
  SecondaryDanger = 'secondary-danger',
  SecondaryWarning = 'secondary-warning',
  SecondaryInfo = 'secondary-info',
  SecondarySuccess = 'secondary-success',

  SoftBasic = 'soft-basic',
  SoftDanger = 'soft-danger',
  SoftWarning = 'soft-warning',
  SoftInfo = 'soft-info',
  SoftSuccess = 'soft-success',
}

export default NotificationVariant;
