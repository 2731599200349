import ArgumentError from '../common/error/argumentError';

const enum MetricLength {
  kilo = 3,
  mega = 6,
  giga = 9,
}

const round = (value: number): number => Math.round(value * 100) / 100;

const getStorageDisplayValue = (bytes: number): string => {
  if (`${bytes}`.includes('.')) {
    throw new ArgumentError('Only except integer');
  }

  const binaryBytes = 1024;
  const metricLength = `${bytes}`.length - 1;
  if (metricLength < MetricLength.kilo) {
    return `${bytes} bytes`;
  }
  if (metricLength >= MetricLength.kilo && metricLength < MetricLength.mega) {
    return `${round(bytes / binaryBytes)} KB`;
  }
  if (metricLength >= MetricLength.mega && metricLength < MetricLength.giga) {
    return `${round(bytes / binaryBytes / binaryBytes)} MB`;
  }
  return `${round(bytes / binaryBytes / binaryBytes / binaryBytes)} GB`;
};

export default getStorageDisplayValue;
