import { AxiosResponse } from 'axios';
import getFileUploadConfig from '../common/configurations/fileUploadConfig';
import storageKey from '../common/constants/storageKey.constants';
import UploadFileWithStatus from '../common/models/uploadFileWithStatus';
import StorageProvider from '../common/providers/storageProvider';
import FileUploadStatus from '../common/types/fileUploadStatus';
import { createUUID } from './stringUtil';

const viewableExtensions = ['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'tif'];

export const toBase64 = (file: File): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (): void => {
      if (typeof reader.result === 'string') resolve(reader.result);
      else
        throw new Error(
          `Unable to convert the file to base64. The reader result is not of the expected type. Type: ${typeof reader.result}`
        );
    };
    reader.onerror = (error): void => reject(error);
  });

export const downloadFile = (blob: Blob | File, fileName: string): void => {
  const fileUrl = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileName;
  link.click();

  window.URL.revokeObjectURL(fileUrl);
  link.remove();
};

export const getFileName = (response: AxiosResponse<Blob>): string | null => {
  try {
    const contentDisposition: string = response.headers['content-disposition'];
    const result = contentDisposition?.split(';')[1]?.trim().split('=')[1];

    return result?.replace(/"/g, '');
  } catch {
    return null;
  }
};

export const getFileExtension = (
  filename: string | undefined | null
): string | null => {
  if (!filename) {
    return null;
  }

  const lastDotIndex = filename.lastIndexOf('.');
  if (lastDotIndex !== -1) {
    const index =
      lastDotIndex < filename.length - 1 ? lastDotIndex + 1 : lastDotIndex;
    return filename.substring(index).toUpperCase();
  }
  return null;
};

export const getDocumentNameWithExtension = (
  documentName: string | undefined,
  fileName: string | undefined
): string | null => {
  if (documentName == null) {
    return null;
  }

  const extension = getFileExtension(fileName)?.toLowerCase();
  if (extension) {
    return `${documentName}.${extension}`;
  }
  return documentName;
};

export const formatFileSizeBytes = (
  bytes: number = 0,
  decimals = 2
): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const formatFileSetToHaveStatus = (
  files: Array<File>,
  currentFiles: Array<UploadFileWithStatus>,
  checkDuplicatedName: boolean
): UploadFileWithStatus[] => {
  const duplicatedNames: Array<string> = [];
  if (checkDuplicatedName) {
    const names = files
      ?.filter((x) => currentFiles?.find((y) => y.file.name === x.name))
      .map((x) => x.name);
    duplicatedNames.push(...names);
  }

  const fileSet = currentFiles ? [...currentFiles, ...files] : [...files];
  const { limitFileUploadQueue } = getFileUploadConfig();
  // Check if file status is success or fail it will not calculate in queue
  const successOrFailFiles = currentFiles
    ? currentFiles.filter(
        (f) =>
          f.status !== FileUploadStatus.UPLOADING &&
          f.status !== FileUploadStatus.PENDING
      )
    : [];
  return fileSet.map((data, index) => {
    const isFile = data instanceof File;
    let status;
    if (isFile) {
      if (duplicatedNames.find((name) => name === data.name)) {
        status = FileUploadStatus.DUPLICATED;
      } else
        status =
          index + 1 - successOrFailFiles.length <= limitFileUploadQueue
            ? FileUploadStatus.UPLOADING
            : FileUploadStatus.PENDING;
    } else {
      status = data.status;
    }
    const localStorageProvider = new StorageProvider();
    const forceUploadingFileInPendingTray = localStorageProvider.getItem(
      storageKey.FORCE_UPLOADING_FILE_IN_PENDING_TRAY
    );
    return {
      id: isFile ? createUUID() : data.id,
      file: data instanceof File ? data : data.file,
      status,
      abortController: isFile ? new AbortController() : data.abortController,
      force: forceUploadingFileInPendingTray === 'true',
    };
  });
};

export const sortFileNameByAlphabet = (
  fileA: UploadFileWithStatus,
  fileB: UploadFileWithStatus
): number => {
  const fileNameA = fileA.file.name.toUpperCase(); // ignore upper and lowercase
  const fileNameB = fileB.file.name.toUpperCase(); // ignore upper and lowercase
  if (
    fileA.status === FileUploadStatus.SUCCESS &&
    fileB.status === FileUploadStatus.SUCCESS
  ) {
    if (fileNameA < fileNameB) {
      return -1;
    }
    if (fileNameA > fileNameB) {
      return 1;
    }
  }
  // shouldn't sort file status !== success
  return 0;
};

export const isViewable = (fileName: string | undefined | null): boolean => {
  if (!fileName) {
    return false;
  }

  const extension = getFileExtension(fileName);
  if (!extension) {
    return false;
  }

  return viewableExtensions.includes(extension.toLowerCase());
};

export const convertToFormData = (oriFile: File): FormData => {
  const formData = new FormData();
  formData.append('file', oriFile);
  return formData;
};
