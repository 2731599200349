import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import accessKeyConstants from '../../common/constants/accessKey.constant';
import KeyPairItem from '../../common/models/keypairItem';
import SDCCheckboxSize from '../../common/types/sdcCheckboxSize';
import SDCCheckbox from '../SDCCheckbox/SDCCheckbox';
import './EditPermissionSidePanel.scss';

interface EditPermissionSidePanelProps {
  permissions: string[];
  onChange: (values: string[]) => void;
}

const EditPermissionSidePanel = ({
  permissions,
  onChange,
}: EditPermissionSidePanelProps): ReactElement => {
  const { t } = useTranslation();

  const handleCheckChange = (permission: string, checked: boolean): void => {
    const temp = [...permissions];

    if (checked) {
      temp.push(permission);
      onChange(temp);
      return;
    }

    const foundIndex = permissions.indexOf(permission);
    temp.splice(foundIndex, 1);
    onChange(temp);
  };

  return (
    <div className="edit-permissions-side-panel-component">
      <div className="text-field-label">
        {t('accessKeyPage.permissionLabel')}
      </div>
      {accessKeyConstants.Permissions.map((item: KeyPairItem) => (
        <div className="pb-3" key={item.Name}>
          <SDCCheckbox
            labelText={t(item.Name)}
            onCheckChange={(checked: boolean): void => {
              handleCheckChange(item.Value, checked);
            }}
            checked={permissions.includes(item.Value)}
            sdcSize={SDCCheckboxSize.Default}
          />
        </div>
      ))}
    </div>
  );
};

export default EditPermissionSidePanel;
