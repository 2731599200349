import dayjs from 'dayjs';
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import KeyPairItem from '../../common/models/keypairItem';
import ProtectionStatus from '../../common/types/protectionStatus';
import SDCButtonSize from '../../common/types/sdcButtonSize';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import SDCDropdownSize from '../../common/types/sdcDropdownSize';
import DocumentType from '../../services/documentType/models/documentType';
import TrashItem from '../../services/trash/models/trashItem';
import UserFullNameResponse from '../../services/user/models/userFullNameResponse';
import UserSettings from '../../services/user/models/userSettings';
import DataGrid, { DataGridHeaderDefinition } from '../DataGrid/DataGrid';
import { DataGridCell } from '../DataGrid/GridCell';
import { DataGridRow } from '../DataGrid/GridRowCollection';
import SDCButton from '../SDCButton/SDCButton';
import SDCDropdown from '../SDCDropdown/SDCDropdown';
import TableRowDropdownMenu from '../TableRowDropdownMenu/TableRowDropdownMenu';
import Toaster from '../Toaster/Toaster';
import './TrashTable.scss';

export interface TrashTableProps {
  trashItems: TrashItem[];
  documentTypes: DocumentType[];
  settings: UserSettings;
  users: UserFullNameResponse[];
  protection: ProtectionStatus;
  selectedUserItem: KeyPairItem;
  isLoading: boolean;
  setSelectedUserItem: Dispatch<SetStateAction<KeyPairItem>>;
  onRestore: (ids: string[]) => void;
  onDelete: (ids: string[]) => void;
  onUserFilterChange: (userId: string) => void;
  onItemSelect: (id: TrashItem) => void;
}

const TrashTable = ({
  trashItems,
  documentTypes,
  settings,
  users,
  protection,
  selectedUserItem,
  isLoading,
  setSelectedUserItem,
  onRestore,
  onDelete,
  onUserFilterChange,
  onItemSelect,
}: TrashTableProps): ReactElement => {
  const { t } = useTranslation();

  const optionRestore = 'restore';
  const optionDelete = 'delete';
  const anyUserId = 'any-user';

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedRowId, setSelectedRowId] = useState('');
  const userItems = users.map(
    (user: UserFullNameResponse) =>
      ({
        Name: user.fullName,
        Value: user.id,
      } as KeyPairItem)
  );

  // Create the default selection along with the user items
  const defaultItem = {
    Name: t('trashPage.userDropdownTitle'),
    Value: anyUserId,
  };

  const userDropdownItems = [defaultItem, ...userItems];

  const rowOptions = useMemo(() => {
    if (protection === ProtectionStatus.Protected) {
      return [
        {
          Name: t('trashPage.buttonRestoreSingle'),
          Value: optionRestore,
        },
      ];
    }
    return [
      {
        Name: t('trashPage.buttonRestoreSingle'),
        Value: optionRestore,
      },
      {
        Name: t('trashPage.buttonDeleteSingle'),
        Value: optionDelete,
      },
    ];
  }, [protection, t]);

  const handleSelectAll = (checkedAll: boolean): void => {
    if (checkedAll) {
      const allValues = trashItems.map((entry: TrashItem) => entry.id);
      setSelectedItems(allValues);
      return;
    }

    setSelectedItems([]);
  };

  const handleRowOptionSelect = (id: string, option: KeyPairItem): void => {
    if (option.Value === optionRestore) {
      onRestore([id]);
    } else if (option.Value === optionDelete) {
      onDelete([id]);
    }
  };

  const getProtectionCell = (item: TrashItem): DataGridCell => ({
    dataKey: 'trashPage.protectUntilHeader',
    value: item.protectUntil ?? '-',
    template: (
      <div className="title-content">
        <div>{dayjs(item.protectUntil).format(settings.dateFormat)}</div>
        <div>{dayjs(item.protectUntil).format('H:mm')}</div>
      </div>
    ),
  });
  const getDeleteByCell = (item: TrashItem): DataGridCell => ({
    dataKey: 'trashPage.deletedByColHeader',
    value: item.deletedBy,
    template: <div className="delete-by-col">{item.deletedBy}</div>,
  });

  const getRows = (): DataGridRow[] =>
    trashItems.map((item: TrashItem) => {
      const documentTypeDisplayName =
        documentTypes.find((x) => x.name === item.documentType)?.displayName ??
        '';
      return {
        id: item.id,
        rowClass: selectedRowId === item.id ? 'selected-row' : '',
        cells: [
          {
            dataKey: 'trashPage.documentTypeColumnHeader',
            value: documentTypeDisplayName,
            text: documentTypeDisplayName,
            template: (
              <div className="document-type">{documentTypeDisplayName}</div>
            ),
          },
          {
            dataKey: 'trashPage.nameColHeader',
            value: item.documentName,
            template: (
              <div className="title-content">
                <div>{item.documentName}</div>
                <div>{item.fileName}</div>
              </div>
            ),
            columnExtraWidth: true,
          },
          {
            dataKey: 'trashPage.deletedDateColHeader',
            value: item.modifiedOn,
            template: (
              <div className="title-content">
                <div>{dayjs(item.deletedOn).format(settings.dateFormat)}</div>
                <div>{dayjs(item.deletedOn).format('H:mm')}</div>
              </div>
            ),
          },
          protection === ProtectionStatus.Protected
            ? getProtectionCell(item)
            : getDeleteByCell(item),
          {
            columnKey: 'dropdown-menu',
            dataKey: 'dropdown-menu',
            value: '',
            template: (
              <TableRowDropdownMenu
                values={rowOptions}
                onSelect={(option: KeyPairItem): void =>
                  handleRowOptionSelect(item.id, option)
                }
              />
            ),
          },
        ],
        clickable: true,
        onRowClick: (): void => {
          onItemSelect(item);
          setSelectedRowId(item.id);
        },
      } as DataGridRow;
    });

  const headers: Array<DataGridHeaderDefinition> = [
    {
      columnKey: 'trashPage.documentTypeColHeader',
      translationKey: 'trashPage.documentTypeColHeader',
    },
    {
      columnKey: 'trashPage.nameColHeader',
      translationKey: 'trashPage.nameColHeader',
      columnExtraWidth: true,
    },
    {
      columnKey: 'trashPage.deletedDateColHeader',
      translationKey: 'trashPage.deletedDateColHeader',
    },
    {
      columnKey:
        protection === ProtectionStatus.Protected
          ? 'trashPage.protectUntilHeader'
          : 'trashPage.deletedByHeader',
      translationKey:
        protection === ProtectionStatus.Protected
          ? 'trashPage.protectUntilHeader'
          : 'trashPage.deletedByHeader',
    },
    {
      columnKey: '',
      translationKey: '',
    },
  ];

  return (
    <div className="trash-table">
      <Toaster />
      <div className="action-button-container">
        <div className="start">
          <SDCButton
            text={t('trashPage.buttonRestore')}
            onClick={(): void => {
              onRestore(selectedItems);
            }}
            variant={SDCButtonVariant.Default}
            size={SDCButtonSize.Small}
            disabled={selectedItems.length === 0}
          />
          {protection === ProtectionStatus.Unprotected && (
            <SDCButton
              text={t('trashPage.buttonDelete')}
              onClick={(): void => {
                onDelete(selectedItems);
              }}
              variant={SDCButtonVariant.Default}
              size={SDCButtonSize.Small}
              disabled={selectedItems.length === 0}
            />
          )}
        </div>
        <div className="end">
          <SDCDropdown
            values={userDropdownItems}
            onSelect={(item: KeyPairItem): void => {
              setSelectedUserItem(item);
              onUserFilterChange(item.Value);
            }}
            currentItem={selectedUserItem}
            size={SDCDropdownSize.Small}
          />
        </div>
      </div>
      <DataGrid
        headerDefinitions={headers}
        tableContent={getRows()}
        selectable
        setSelectableStates={setSelectedItems}
        sortableColumnKeys={[
          'trashPage.documentTypeColHeader',
          'trashPage.nameColHeader',
          'trashPage.deletedDateColHeader',
          protection === ProtectionStatus.Protected
            ? 'trashPage.protectUntilHeader'
            : 'trashPage.deletedByHeader',
        ]}
        onSelectAllChange={handleSelectAll}
        selectedValues={selectedItems}
        noResultsMessage={t('trashPage.noResultsMessage')}
        isLoading={isLoading}
      />
    </div>
  );
};

export default TrashTable;
