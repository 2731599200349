import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import storageKey from '../../common/constants/storageKey.constants';
import StorageProvider from '../../common/providers/storageProvider';
import Tray from '../../common/types/tray';

const usePendingTrayScope = (): [string, Dispatch<SetStateAction<string>>] => {
  const storage = useMemo(() => new StorageProvider(), []);
  const [pendingTrayScope, setPendingTrayScope] = useState<string>(
    () => storage.getItem(storageKey.PENDING_TRAY_SCOPE) ?? Tray.Private
  );

  useEffect(() => {
    const beforeUnLoad = (): void => {
      storage.setItem(storageKey.PENDING_TRAY_SCOPE, pendingTrayScope);
    };

    window.addEventListener('beforeunload', beforeUnLoad);

    return (): void => {
      window.removeEventListener('beforeunload', beforeUnLoad);
    };
  }, [pendingTrayScope, storage]);

  return [pendingTrayScope, setPendingTrayScope];
};

export default usePendingTrayScope;
