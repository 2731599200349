import { ReactElement, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import SDCDropdownSize from '../../common/types/sdcDropdownSize';
import './PaginationDropdown.scss';

interface PaginationDropdownProps {
  active: boolean;
  values: number[];
  onSelect: (value: number) => void;
  displayText: string | null;
  darkMode?: boolean;
  size?: SDCDropdownSize;
}

const PaginationDropdown = ({
  active,
  values,
  onSelect,
  displayText,
  darkMode,
  size,
}: PaginationDropdownProps): ReactElement => {
  const defaultText = '...';
  const [currentValue, setCurrentValue] = useState(defaultText);

  const selectEventHandler = (value: number): void => {
    setCurrentValue(`${value}`);
    onSelect(value);
  };

  const getKey = (value: number): string => `pagination-dropdown-item-${value}`;

  return (
    <Dropdown
      id="pagination-dropdown"
      className={`pagination-dropdown${active ? '-active' : ''}`}
    >
      <Dropdown.Toggle
        variant="secondary"
        className={`shadow-none ${darkMode ? 'pagination-dropdown-dark' : ''} ${
          size === SDCDropdownSize.Small ? 'small' : 'default'
        }`}
      >
        {displayText ?? currentValue}
      </Dropdown.Toggle>
      <Dropdown.Menu className="shadow-none">
        {values.map((value: number) => (
          <Dropdown.Item
            className="menuItem"
            key={getKey(value)}
            eventKey={value}
            onClick={(): void => selectEventHandler(value)}
          >
            {value}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

PaginationDropdown.defaultProps = {
  darkMode: false,
  size: SDCDropdownSize.Default,
};

export default PaginationDropdown;
