import { ReactElement, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../assets/svg/CloseIcon.svg';
import { ReactComponent as CloseIconDark } from '../../assets/svg/CloseIconDark.svg';
import StorageProvider from '../../common/providers/storageProvider';
import ConfirmationModalVariant from '../../common/types/confirmationModalVariant';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import SDCButton from '../SDCButton/SDCButton';
import SDCCheckbox from '../SDCCheckbox/SDCCheckbox';
import SDCLinkButton from '../SDCLinkButton/SDCLinkButton';
import './DefaultModal.scss';

interface ConfirmationModalProps {
  title: string;
  bodyText?: string;
  primaryButtonText?: string;
  dismissButtonText: string;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onAnswer: (isYes: boolean) => void;
  variant: ConfirmationModalVariant;
  children?: ReactElement;
  showRememberThisAnswer?: boolean;
  rememberKey?: string;
}

const ConfirmationModal = ({
  title,
  bodyText,
  primaryButtonText,
  dismissButtonText,
  show,
  setShow,
  onAnswer,
  variant,
  children,
  showRememberThisAnswer,
  rememberKey,
}: ConfirmationModalProps): ReactElement => {
  const storage = useMemo(() => new StorageProvider(), []);
  const [rememberSetting, setRememberSetting] = useState<boolean>(false);
  const { t } = useTranslation();
  const onClick = (answer: boolean): void => {
    if (rememberSetting && rememberKey) {
      storage.setItem(rememberKey, answer.toString());
    }
    onAnswer(answer);
    setShow(false);
  };

  const renderBodyContent = (): ReactElement =>
    bodyText ? (
      <Modal.Body>
        {bodyText}
        {showRememberThisAnswer ? (
          <div className="checkbox">
            <SDCCheckbox
              checked={rememberSetting}
              labelText={t('confirmationModal.rememberThisSettingLabel')}
              onCheckChange={(value: boolean): void => {
                setRememberSetting(value);
              }}
            />
          </div>
        ) : null}
      </Modal.Body>
    ) : (
      <></>
    );

  if (!show) return <></>;

  const getButtonVariant = (): SDCButtonVariant => {
    if (variant === ConfirmationModalVariant.PrimaryDefault) {
      return SDCButtonVariant.Primary;
    }
    if (variant === ConfirmationModalVariant.PrimaryDanger) {
      return SDCButtonVariant.Default;
    }
    if (variant === ConfirmationModalVariant.PrimaryInfo) {
      return SDCButtonVariant.Default;
    }
    if (variant === ConfirmationModalVariant.PrimaryWarning) {
      return SDCButtonVariant.Warning;
    }

    if (variant === ConfirmationModalVariant.SecondaryDefault) {
      return SDCButtonVariant.Primary;
    }
    if (variant === ConfirmationModalVariant.SecondaryDanger) {
      return SDCButtonVariant.Default;
    }
    if (variant === ConfirmationModalVariant.SecondaryInfo) {
      return SDCButtonVariant.Default;
    }
    if (variant === ConfirmationModalVariant.SecondaryWarning) {
      return SDCButtonVariant.Warning;
    }

    return SDCButtonVariant.Default;
  };

  return (
    <>
      <Modal
        show={show}
        dialogClassName={`default-modal-container ${variant}`}
        centered
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        onHide={() => onClick(false)}
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <button
            type="button"
            className="close-button"
            onClick={(): void => onClick(false)}
          >
            {variant.startsWith('secondary') ? (
              <CloseIconDark />
            ) : (
              <CloseIcon width={22} height={22} />
            )}
          </button>
        </Modal.Header>
        {children || renderBodyContent()}
        <Modal.Footer>
          <SDCLinkButton
            onClick={(): void => onClick(false)}
            text={dismissButtonText}
          />
          {primaryButtonText ? (
            <SDCButton
              text={primaryButtonText}
              variant={getButtonVariant()}
              onClick={(): void => onClick(true)}
            />
          ) : null}
        </Modal.Footer>
      </Modal>
    </>
  );
};

ConfirmationModal.defaultProps = {
  bodyText: null,
  children: undefined,
  primaryButtonText: null,
  showRememberThisAnswer: false,
  rememberKey: null,
};

export default ConfirmationModal;
