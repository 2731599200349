import { ReactElement } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as QuestionMarkIcon } from '../../assets/svg/QuestionMarkIcon.svg';
import PermissionAccessStatus from '../../common/types/permissionAccessStatus';
import SDCCheckboxSize from '../../common/types/sdcCheckboxSize';
import Container from '../../services/container/models/container';
import { AccessDetail } from '../../services/teamManagement/models/userAccessItem';
import SDCCheckbox from '../SDCCheckbox/SDCCheckbox';
import './EditContainerAccessSidePanel.scss';

interface EditContainerAccessSidePanelProps {
  containers: Container[];
  userContainers: AccessDetail[];
  selectAllContainer: boolean;
  onSelectAllContainer: (arg0: boolean) => void;
  onSetContainer: (id: string) => void;
}

const EditContainerAccessSidePanel = ({
  containers,
  userContainers,
  selectAllContainer,
  onSelectAllContainer,
  onSetContainer,
}: EditContainerAccessSidePanelProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="edit-container-access-side-panel-container">
      <Form>
        <Row>
          <Col md="12" className="title mb-1">
            <span>{t('teamManagementScreen.sidePanelContainerTitle')}</span>
            <QuestionMarkIcon />
          </Col>
          <Col md="12" className="input-box">
            <Form.Check
              type="radio"
              id="default-radio"
              name="group1"
              checked={selectAllContainer}
              onChange={(): void => onSelectAllContainer(true)}
            />
            <div>
              {t('teamManagementScreen.sidePanelContainerAll')}
              <br /> {t('teamManagementScreen.includeFutureContainer')}
            </div>
          </Col>
          <Col md="12" className="input-box">
            <Form.Check
              type="radio"
              id="default-radio"
              name="group1"
              checked={!selectAllContainer}
              onChange={(): void => onSelectAllContainer(false)}
            />
            <div>{t('teamManagementScreen.sidePanelContainerSome')}</div>
          </Col>
          <Col className="px-5">
            {containers.map((container) => (
              <Col key={container.key} md="12" className="input-box">
                <SDCCheckbox
                  sdcSize={SDCCheckboxSize.Default}
                  checked={
                    userContainers.find(
                      (con) => con.referenceId === container.key
                    )?.permission === PermissionAccessStatus.Granted
                  }
                  onCheckChange={(): void => onSetContainer(container.key)}
                  disabled={!!selectAllContainer}
                />
                <div>{container.name}</div>
              </Col>
            ))}
          </Col>
        </Row>
      </Form>

      <div className="separator" />
    </div>
  );
};

export default EditContainerAccessSidePanel;
