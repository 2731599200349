const dateFormat = {
  date: {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  } as Intl.DateTimeFormatOptions,
  datetime: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  } as Intl.DateTimeFormatOptions,
  time: {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  } as Intl.DateTimeFormatOptions,
};

export default dateFormat;
