import { AxiosError } from 'axios';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseCardIcon } from '../../assets/svg/CloseCardIcon.svg';
import ErrorDetails from '../../common/models/errorDetails';
import UploadCardDisplayModel from '../../common/models/uploadCardDisplayModel';
import UploadFileWithStatus from '../../common/models/uploadFileWithStatus';
import ErrorCode from '../../common/types/errorCode';
import FileUploadStatus from '../../common/types/fileUploadStatus';
import FileUploadStatusCard from '../../common/types/fileUploadStatusCard';
import { useTenant } from '../../context/Tenant/TenantContext';
import { postUploadContent } from '../../services/capture/capture.service';
import { UploadedContent } from '../../services/capture/models/uploadedContent';
import { formatFileSizeBytes } from '../../utils/fileUtil';
import './UploadingCard.scss';

interface UploadingCardProps {
  fileInfo: UploadFileWithStatus;
  captureInfoId: string;
  setUploadFileStatus: (uploadedFile: UploadFileWithStatus) => void;
  setUploadedContents: React.Dispatch<
    React.SetStateAction<Array<UploadedContent>>
  >;
  getCardProps: (cardStatus: FileUploadStatus) => UploadCardDisplayModel;
}

const UploadingCard = ({
  fileInfo,
  captureInfoId,
  setUploadFileStatus,
  setUploadedContents,
  getCardProps,
}: UploadingCardProps): ReactElement => {
  const { t } = useTranslation();
  const { maxFileSizeInBytes } = useTenant();

  const [progress, setProgress] = useState(0);
  const errorFileExceedMessage = t(
    'uploadingCardComponent.errorDescriptionFileSizeExceed'
  );
  const errorMessage = t('uploadingCardComponent.errorMessage');
  const errorContainerNotMatch = t(
    'uploadingCardComponent.errorDescriptionContainerNotMatch'
  );
  const checkFileSizeExceedValidation = useCallback(
    (oriFile: File): boolean => {
      if (oriFile.size > maxFileSizeInBytes) {
        return true;
      }
      return false;
    },
    [maxFileSizeInBytes]
  );

  const checkFileValidationError = useCallback(
    (oriFile: File): string => {
      if (checkFileSizeExceedValidation(oriFile)) {
        return errorFileExceedMessage;
      }
      return '';
    },
    [checkFileSizeExceedValidation, errorFileExceedMessage]
  );

  const convertToFormData = (oriFile: File): FormData => {
    const formData = new FormData();
    formData.append('file', oriFile);
    return formData;
  };

  const uploadContent = useCallback(
    async (targetFile: File): Promise<void> => {
      const formData = convertToFormData(targetFile);
      try {
        const uploadContentResponse = await postUploadContent(
          captureInfoId,
          formData,
          setProgress,
          fileInfo.abortController.signal
        );
        const uploadStatus = uploadContentResponse
          ? FileUploadStatus.SUCCESS
          : FileUploadStatus.FAILED;
        setUploadedContents((previousState) => {
          const newState = {
            name: targetFile.name,
            id: uploadContentResponse.fileId,
          };
          return previousState.concat(newState);
        });
        setTimeout(() => {
          const successFileSet = {
            id: fileInfo.id,
            uploadId: uploadContentResponse.fileId,
            previewImg: undefined,
            file: targetFile,
            status: uploadStatus,
            abortController: fileInfo.abortController,
            force: false,
          };
          setUploadFileStatus(successFileSet);
        }, 1000);
      } catch (error: any) {
        if (error.message !== 'canceled') {
          setProgress(() => 100);
          let message = errorMessage;
          const axiosError = error as AxiosError;
          const detail = axiosError.response?.data as ErrorDetails;
          if (
            detail &&
            detail.errorCode === ErrorCode.PendingTrayContainerNotMatch
          ) {
            message = errorContainerNotMatch;
          }

          const errorFileSet = {
            id: fileInfo.id,
            file: targetFile,
            status: FileUploadStatus.FAILED,
            abortController: fileInfo.abortController,
            error: message,
            force: false,
          };
          setUploadFileStatus(errorFileSet);
        }
      }
    },
    [
      captureInfoId,
      errorContainerNotMatch,
      errorMessage,
      fileInfo.abortController,
      fileInfo.id,
      setUploadFileStatus,
      setUploadedContents,
    ]
  );

  // Call API upload file.
  const uploadFile = useCallback(async () => {
    const error = checkFileValidationError(fileInfo.file);
    if (!error) {
      await uploadContent(fileInfo.file);
    } else {
      setProgress(100);
      const errorFileSet = {
        id: fileInfo.id,
        file: fileInfo.file,
        status: FileUploadStatus.FAILED,
        abortController: fileInfo.abortController,
        error,
        force: false,
      };
      setUploadFileStatus(errorFileSet);
    }
  }, [
    checkFileValidationError,
    fileInfo.abortController,
    fileInfo.file,
    fileInfo.id,
    setUploadFileStatus,
    uploadContent,
  ]);

  useEffect(() => {
    uploadFile();
  }, [uploadFile]);

  const cancelUploadFileRequest = (): void => {
    fileInfo.abortController.abort();
    setUploadFileStatus({
      id: fileInfo.id,
      file: fileInfo.file,
      status: FileUploadStatus.CANCELED,
      abortController: fileInfo.abortController,
      force: false,
    });
  };

  const fileDisplayCard = (uploadStatus: FileUploadStatus): ReactElement => {
    const { className, Icon, title }: UploadCardDisplayModel =
      getCardProps(uploadStatus);
    return (
      <div className={`uploading-card-container ${className}`}>
        <div className="file-info-wrapper">
          <Icon />
          <div className="file-info">
            {title && <span className="file-status">{title}</span>}
            &nbsp;
            <span data-hj-suppress>{fileInfo.file.name}</span>
            &nbsp;
            <span className="file-size">{`(${formatFileSizeBytes(
              fileInfo.file.size
            )})`}</span>
          </div>
          <div className="close-file-upload">
            {(className === FileUploadStatusCard.UPLOADING ||
              className === FileUploadStatusCard.QUEUING) && (
              <CloseCardIcon onClick={(): void => cancelUploadFileRequest()} />
            )}
          </div>
        </div>
        {uploadStatus === FileUploadStatus.UPLOADING && (
          <div className={`upload-progress ${className} pt-2`}>
            <ProgressBar now={progress} />
          </div>
        )}
      </div>
    );
  };

  return <>{fileDisplayCard(fileInfo.status)}</>;
};
export default UploadingCard;
