import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DocumentProtectionImage from '../../assets/img/document-protection.png';
import { ReactComponent as ProtectionLargeIcon } from '../../assets/svg/ProtectionLargeIcon.svg';
import UserPermission from '../../common/types/userPermission';
import HelpActionIcon from '../../components/ActionIcon/HelpActionIcon';
import DefaultLoading from '../../components/DefaultLoading/DefaultLoading';
import DocumentProtectionTable from '../../components/DocumentProtectionTable/DocumentProtectionTable';
import PaginationNavigator from '../../components/Pagination/PaginationNavigator';
import RestrictedArea from '../../components/RestrictedArea/RestrictedArea';
import HelpSidePanel from '../../components/SidePanel/HelpSidePanel';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import { getActiveDocumentTypes } from '../../services/documentType/documentType.service';
import DocumentType from '../../services/documentType/models/documentType';
import userHasPermission from '../../utils/userUtil';
import './DocumentProtectionPage.scss';

const DocumentProtectionPage = (): ReactElement => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSidePanel, setShowSidePanel] = useState(false);

  const renderTable = (): JSX.Element | null => {
    if (!documentTypes) {
      return null;
    }

    return (
      <DocumentProtectionTable
        documentTypes={documentTypes}
        settings={currentUser.settings}
        isLoading={isLoading}
      />
    );
  };

  useEffect(() => {
    getActiveDocumentTypes()
      .then((response) => {
        setDocumentTypes(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (!userHasPermission(currentUser, UserPermission.ConfigureSystemSettings)) {
    return (
      <div className="document-protection-page">
        <RestrictedArea />
      </div>
    );
  }

  return (
    <>
      {isLoading && <DefaultLoading />}
      <div className="document-protection-page">
        <div className="document-protection-page-container">
          <div className="document-protection-body">
            <div className="help-header">
              <HelpActionIcon
                text={t('documentProtectionPage.helpButton')}
                onClick={(): void => {
                  setShowSidePanel(true);
                }}
              />
            </div>
            <div className="hero">
              <ProtectionLargeIcon />
              <div className="content">
                <div className="title">{t('documentProtectionPage.title')}</div>
                <div className="subtitle">
                  {t('documentProtectionPage.subtitle')}
                </div>
              </div>
            </div>
            {renderTable()}
            <PaginationNavigator
              pageSize={100}
              lastPage={1}
              currentPage={1}
              totalCount={documentTypes.length}
              onPageChange={(): void => {
                // Do nothing
              }}
              descriptionTranslationKey="documentProtectionPage.paginationDescription"
            />
          </div>
          <HelpSidePanel
            title={t('documentProtectionPage.helpTitle')}
            onClose={(): void => {
              setShowSidePanel(false);
            }}
            show={showSidePanel}
            image={DocumentProtectionImage}
          >
            <div>
              <p>{t('documentProtectionPage.helpContent1')}</p>
              <p>{t('documentProtectionPage.helpContent2')}</p>
              <p>{t('documentProtectionPage.helpInstruction')}</p>
            </div>
          </HelpSidePanel>
        </div>
      </div>
    </>
  );
};

export default DocumentProtectionPage;
