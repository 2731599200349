import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import accessKeyConstants from '../../common/constants/accessKey.constant';
import lengths from '../../common/constants/lengths.constant';
import SDCTextField from '../SDCTextField/SDCTextField';
import './EditAccessKeyDisplayNameSidePanel.scss';

interface EditAccessKeyDisplayNameSidePanelProps {
  displayName: string;
  onChange: (name: string, hasError: boolean) => void;
}

const EditAccessKeyDisplayNameSidePanel = ({
  displayName,
  onChange,
}: EditAccessKeyDisplayNameSidePanelProps): ReactElement => {
  const { t } = useTranslation();
  const [error, setError] = useState<string>();

  const handleInputChange = (text: string): void => {
    const value = text.trim();
    const missing = value === '';
    if (missing) {
      setError(t('accessKeyPage.errorMissingDisplayName'));
    }
    const tooLong = value.length > accessKeyConstants.MaxDisplayNameLength;
    if (tooLong) {
      setError(t('validation.textLengthError', { count: lengths.name }));
    }

    const hasError = tooLong || missing;
    if (!hasError) {
      setError(undefined);
    }
    onChange(value, hasError);
  };

  return (
    <div className="edit-access-key-display-name-side-panel-component">
      <div className="text-field-label">
        {t('accessKeyPage.displayNameLabel')}
      </div>
      <SDCTextField
        value={displayName}
        placeholder={t('accessKeyPage.placeholderName')}
        onTextChange={handleInputChange}
        hasError={error !== undefined}
        errorMessage={error}
      />
    </div>
  );
};

export default EditAccessKeyDisplayNameSidePanel;
