import { ReactElement } from 'react';
import './FileInformationEntry.scss';

interface FileInformationEntryProps {
  labelText: string;
  valueText: string;
}

const FileInformationEntry = ({
  labelText: key,
  valueText: value,
}: FileInformationEntryProps): ReactElement => (
  <div className="file-information-entry">
    <div className="key">{key}</div>
    <div className="value" data-hj-suppress>
      {value}
    </div>
  </div>
);

export default FileInformationEntry;
