/* eslint-disable react/jsx-props-no-spreading */

import React, { ReactElement, ReactNode } from 'react';

export const contextProvider = (
  provider: React.ComponentType<any>,
  props = {}
): any[] => [provider, props];

export interface ProviderComposerProps {
  providers: Array<any>;
  children?: ReactNode;
}

const ContextProviderComposer = ({
  providers,
  children,
}: ProviderComposerProps): ReactElement => {
  const composedContextElement = providers.reduceRight(
    (composedContextProvider, currentContextProvider) => {
      const [Provider, props] = currentContextProvider;
      return <Provider {...props}>{composedContextProvider}</Provider>;
    },
    children
  );
  return composedContextElement as ReactElement;
};

export default ContextProviderComposer;
