import DataTypeItem from '../common/models/dataTypeItem';
import KeyPairItem from '../common/models/keypairItem';
import DataType from '../common/types/dataType';
import QueryOperator from '../common/types/queryOperator';

export const buildBetweenFilterString = (
  field: DataTypeItem,
  queryValue1: string,
  queryValue2: string
): string => {
  if (field.dataType === DataType.String)
    throw new Error('String is not supported for Between.');
  return `${field.value} ${QueryOperator.Between} ${queryValue1};${queryValue2}`;
};

export const buildCommonFilterString = (
  field: DataTypeItem,
  operationItem: KeyPairItem,
  queryValue: string
): string =>
  field.dataType === DataType.String
    ? `${field.value} ${operationItem.Value} '${queryValue}'`
    : `${field.value} ${operationItem.Value} ${queryValue}`;
