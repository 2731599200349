import { ReactElement, useEffect, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../assets/svg/BuildingIcon.svg';
import { ReactComponent as DocumentIcon } from '../../assets/svg/DocumentIcon.svg';
import { ReactComponent as ExitIcon } from '../../assets/svg/ExitIcon.svg';
import userConst from '../../common/constants/user.constant';
import KeyPairItem from '../../common/models/keypairItem';
import LocationProvider from '../../common/providers/locationProvider';
import { useAuth } from '../../context/Auth/AuthContext';
import { useTenant } from '../../context/Tenant/TenantContext';
import UserInitial from '../UserInitial/UserInitial';
import './UserHeaderOptions.scss';

interface UserHeaderOptionsProps {
  displayName: string;
  userRole: string;
}

const UserHeaderOptions = ({
  displayName,
  userRole,
}: UserHeaderOptionsProps): ReactElement => {
  const locationProvider = new LocationProvider();
  const { t } = useTranslation();
  const { authService } = useAuth();
  const { accountName } = useTenant();
  const [displayRole, setDisplayRole] = useState<string>();

  const logout = async (): Promise<void> => {
    const redirectUrl = await authService.logout();
    locationProvider.replace(redirectUrl);
  };

  useEffect(() => {
    const rolesTranslation: KeyPairItem[] = [
      { Value: 'Administrator', Name: t('teamManagementScreen.roleAdmin') },
      { Value: 'SuperUser', Name: t('teamManagementScreen.roleSuperUser') },
      {
        Value: 'CaptureAndView',
        Name: t('teamManagementScreen.roleCaptureAndView'),
      },
      { Value: 'View', Name: t('teamManagementScreen.roleView') },
      { Value: 'Owner', Name: t('teamManagementScreen.roleOwner') },
    ];
    const currentRole = rolesTranslation.find(
      (r: KeyPairItem) => r.Value === userRole
    );
    if (currentRole) setDisplayRole(currentRole.Name);
  }, [userRole, t]);

  return (
    <span>
      <NavDropdown
        title={
          <UserInitial
            color={userConst.color}
            displayName={displayName}
            isInvitationPending={false}
          />
        }
        className="user-header-options"
      >
        <NavDropdown.Item className="dropdown-section">
          <div data-hj-suppress>
            {displayName || t('mainMenu.loading')}
            <div className="role">{displayRole}</div>
          </div>
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item className="dropdown-section">
          <BuildingIcon />
          {accountName}
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item>
          <DocumentIcon />
          {t('mainMenu.terms')}
        </NavDropdown.Item>
        <NavDropdown.Item>
          <DocumentIcon />
          {t('mainMenu.privacy')}
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={logout}>
          <ExitIcon />
          {t('mainMenu.signOutMenuItem')}
        </NavDropdown.Item>
      </NavDropdown>
    </span>
  );
};

export default UserHeaderOptions;
