/* eslint-disable no-param-reassign */
import { ReactElement, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MetadataTableItem from '../../common/models/metadataTableItem';
import DataType from '../../common/types/dataType';
import MetadataDetail from '../../services/capture/models/metadataDetail';
import MetadataTemplateItem from '../../services/documentType/models/metadataTemplateItem';
import UserSettings from '../../services/user/models/userSettings';
import {
  getMaskOptions,
  toDate,
  toDateTime,
  validateDataType,
  validateLength,
} from '../../utils/dataTypeUtil';
import {
  buildMetadataFromTemplate,
  buildTableItemsFromTemplate,
  getStringDataValue,
} from '../../utils/metadataUtil';
import SDCTextField from '../SDCTextField/SDCTextField';
import './DocumentMetadataTable.scss';

export interface DocumentMetadataTableProps {
  metadata: MetadataDetail[];
  onMetadataItemsChange?: (data: MetadataDetail[]) => void;
  template?: MetadataTemplateItem[];
  settings: UserSettings;
  isEditMode?: boolean;
  isArchived?: boolean;
}

export interface DocumentMetadataRowProps {
  data: MetadataDetail;
  requiredText: string;
  invalidInputText: string;
  invalidLengthText: string;
  onValueChange: (value: string) => void;
  settings: UserSettings;
  isMissing: boolean;
  isRequired: boolean;
  isHidden: boolean;
  isEditMode: boolean;
  isArchived: boolean;
}

const MetadataRow = ({
  data,
  requiredText,
  invalidInputText,
  invalidLengthText,
  onValueChange,
  settings,
  isMissing,
  isRequired,
  isHidden,
  isEditMode,
  isArchived,
}: DocumentMetadataRowProps): ReactElement => {
  const { key, value, displayName, type, isMissingValue } = data;
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (text: string): void => {
    setInputValue(text);

    // For a specific data type, only trigger change event when the value is in valid format
    if (type === DataType.Date) {
      if (validateDataType(text, type, settings)) {
        onValueChange(toDate(text, settings).toISOString());
      }
    } else if (type === DataType.DateTime) {
      if (validateDataType(text, type, settings)) {
        onValueChange(toDateTime(text, settings).toISOString());
      }
    } else {
      onValueChange(text);
    }
  };

  const getErrorMessage = (): string | undefined => {
    if (isRequired && !inputValue) {
      return requiredText;
    }

    if (inputValue && !validateDataType(inputValue, data.type, settings)) {
      return invalidInputText;
    }

    if (inputValue && !validateLength(inputValue, data.type)) {
      return invalidLengthText;
    }

    return undefined;
  };

  const getPlaceholder = (): string | undefined => {
    if (data.type === DataType.Date) {
      return settings.dateFormat;
    }
    if (data.type === DataType.DateTime) {
      return settings.dateTimeFormat;
    }

    return undefined;
  };

  if (isArchived) {
    return (
      <Row>
        <Col md="12" className="document-metadata-key">
          <span>{displayName || key}</span>
        </Col>
        <Col md="12" className="document-metadata-value">
          <span>{getStringDataValue(value) || '-'}</span>
        </Col>
      </Row>
    );
  }

  if (isMissing || isMissingValue || isEditMode) {
    return (
      <Row>
        <Col md="12" className="document-metadata-key">
          <span>{displayName || key}</span>
        </Col>
        <Col md="12" className="document-metadata-input">
          <SDCTextField
            value={getStringDataValue(value)}
            onTextChange={handleInputChange}
            errorMessage={getErrorMessage()}
            errorMessageId={key}
            hasError={getErrorMessage() !== undefined}
            placeholder={getPlaceholder()}
            maskOptions={getMaskOptions(data.type, getPlaceholder())}
            disabled={isArchived}
          />
        </Col>
      </Row>
    );
  }

  if (isHidden) {
    return <span />;
  }

  return (
    <Row>
      <Col md="12" className="document-metadata-key">
        <span>{displayName || key}</span>
      </Col>
      <Col md="12" className="document-metadata-value">
        <span>{getStringDataValue(value)}</span>
      </Col>
    </Row>
  );
};

const DocumentMetadataTable = ({
  metadata,
  onMetadataItemsChange,
  template,
  settings,
  isEditMode,
  isArchived,
}: DocumentMetadataTableProps): ReactElement => {
  const { t } = useTranslation();
  const requiredText = t('validation.textMissingError');
  const [metadataTableItems, setMetadataTableItems] = useState<
    MetadataTableItem[]
  >([]);
  // Keeps track of modified metadata
  const [modifiedMetadata, setModifiedMetadata] = useState<MetadataDetail[]>(
    []
  );

  useEffect(() => {
    if (metadata.length || isEditMode) {
      const items = buildTableItemsFromTemplate(metadata, template, settings);
      if (items !== undefined) {
        setMetadataTableItems(() => items);
      }

      if (template) {
        const templatedMetadata = buildMetadataFromTemplate(
          metadata,
          template,
          settings
        );

        setModifiedMetadata(templatedMetadata);
      }
    }
  }, [metadata, template, settings, isEditMode]);

  const ensureValueIsIsoForDates = (metadataItems: MetadataDetail[]): void => {
    metadataItems.forEach((x) => {
      const stringValue = getStringDataValue(x.value);
      if (
        x.type === DataType.Date &&
        validateDataType(stringValue, x.type, settings)
      ) {
        x.value = toDate(stringValue, settings).toISOString();
      } else if (
        x.type === DataType.DateTime &&
        validateDataType(stringValue, x.type, settings)
      ) {
        x.value = toDateTime(stringValue, settings).toISOString();
      }
    });
  };

  /**
   * If any value is changed, send the new copy of the metadata to the listener.
   * Note that this is the entire metadata since it will be the new metadata for the document.
   * @param newValue
   * @param tableItem
   */
  const handleValueChange = (
    newValue: string,
    tableItem: MetadataTableItem
  ): void => {
    // Update the tracking value
    const results = modifiedMetadata.map((item: MetadataDetail) => {
      // Set new value for the one that's changed.
      // If value is not specified, defaults to an empty text.
      if (item.key === tableItem.metadata.key) {
        const data = {
          key: item.key,
          type: item.type,
          displayName: item.displayName,
          value: newValue,
          isMissingValue: item.isMissingValue,
        } as MetadataDetail;
        return data;
      }

      return item;
    });

    ensureValueIsIsoForDates(results);

    setModifiedMetadata(results);
    if (onMetadataItemsChange !== undefined) {
      onMetadataItemsChange(results);
    }
  };

  return (
    <Container className="document-metadata" data-hj-suppress>
      <div className="document-info-frame">
        {metadataTableItems.length !== 0 ? (
          metadataTableItems
            .filter((x) => x.dataType !== DataType.Table)
            .map(
              (data, index): JSX.Element => (
                <MetadataRow
                  key={data.metadata.key + index.toString()}
                  data={data.metadata}
                  isMissing={data.isMissingValue}
                  isRequired={data.isRequired}
                  isHidden={data.isHidden}
                  isEditMode={isEditMode ?? false}
                  onValueChange={(value: string): void =>
                    handleValueChange(value, data)
                  }
                  settings={settings}
                  requiredText={requiredText}
                  invalidInputText={t(
                    `validation.invalidInput${data.dataType}`
                  )}
                  invalidLengthText={t('validation.textLengthGenericError')}
                  isArchived={isArchived ?? false}
                />
              )
            )
        ) : (
          <div className="no-data">{t('sidePanel.noData')}</div>
        )}
      </div>
    </Container>
  );
};
DocumentMetadataTable.defaultProps = {
  onMetadataItemsChange: (): boolean => true,
  template: undefined,
  isEditMode: false,
  isArchived: false,
};

export default DocumentMetadataTable;
