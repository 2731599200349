import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from '../../assets/svg/DeleteIcon.svg';
import { ReactComponent as DownloadCloudSmallIcon } from '../../assets/svg/DownloadCloudSmallIcon.svg';
import getDefaultPageSize from '../../common/configurations/pageConfig';
import DocumentSearchOptions from '../../common/models/documentSearchOptions';
import CollapserPositionVariant from '../../common/types/collapserPositionVariant';
import ConfirmationModalVariant from '../../common/types/confirmationModalVariant';
import SortItemKey from '../../common/types/sortItemKey';
import UserPermission from '../../common/types/userPermission';
import ConfirmationModal from '../../components/DefaultModal/ConfirmationModal';
import PaginationNavigator from '../../components/Pagination/PaginationNavigator';
import RestrictedArea from '../../components/RestrictedArea/RestrictedArea';
import ResultDocumentList from '../../components/ResultDocumentList/ResultDocumentList';
import DocumentReadonlySidePanel from '../../components/SidePanel/DocumentReadonlySidePanel';
import { useContainer } from '../../context/Container/ContainerContext';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import Metadata from '../../services/capture/models/metadata';
import {
  deleteDocuments,
  downloadArchivedDocuments,
  searchDocuments,
} from '../../services/document/document.service';
import ArchivedDocumentDetail from '../../services/document/models/archivedDocumentDetail';
import { getAllDocumentTypes } from '../../services/documentType/documentType.service';
import DocumentType from '../../services/documentType/models/documentType';
import PaginationResponse from '../../services/paginationResponse';
import { downloadFile } from '../../utils/fileUtil';
import userHasPermission from '../../utils/userUtil';
import './DocumentSearchPage.scss';

const defaultDocumentResponse = {
  pageSize: 0,
  lastPage: 0,
  totalCount: 0,
  page: 0,
  results: [],
} as PaginationResponse<ArchivedDocumentDetail>;

const DocumentSearchPage = (): ReactElement => {
  const { t } = useTranslation();
  const errorHandler = useErrorHandler();
  const { containerKey } = useContainer();
  const { currentUser } = useCurrentUser();
  // Document search options
  const defaultPageSize = getDefaultPageSize();
  const firstPage = 1;

  const abortControllerRef = useRef<AbortController>(new AbortController());
  const [isSearchingDocument, setIsSearchingDocument] =
    useState<boolean>(false);
  const [documentResponse, setDocumentResponse] = useState<
    PaginationResponse<ArchivedDocumentDetail>
  >(defaultDocumentResponse);

  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
  const [searchOptions, setSearchOptions] = useState<DocumentSearchOptions>({
    page: firstPage,
    pageSize: defaultPageSize,
    sortBy: `${SortItemKey.Date}_descending`,
  });
  // Document view modal
  const [selectedDocument, setSelectedDocument] = React.useState<File>();
  const [selectedDocumentMetadata, setSelectedDocumentMetadata] =
    React.useState<Metadata>({
      templateName: '',
      templateGroupName: '',
      data: [],
    });
  const [document, setDocument] = useState<ArchivedDocumentDetail | undefined>(
    undefined
  );
  const [showDeletionConfirmationDialog, setShowDeletionConfirmationDialog] =
    useState(false);
  const [checkedDocumentIds, setCheckedDocumentIds] = useState<string[]>([]);

  useEffect(() => {
    async function fetchDocumentTypes(): Promise<void> {
      const response: DocumentType[] = await getAllDocumentTypes(errorHandler);
      if (response) {
        setDocumentTypes(response);
      }
    }
    fetchDocumentTypes();
  }, [errorHandler]);

  const onDownloadFiles = (documentIds: string[]): void => {
    async function downloadDocumentFiles(): Promise<void> {
      const response = await downloadArchivedDocuments(documentIds);
      downloadFile(response, response.name);
    }
    downloadDocumentFiles();
  };

  const deleteSelectedDocuments = (isConfimred: boolean): void => {
    if (!isConfimred) {
      return;
    }
    setIsSearchingDocument(true);

    setDocument(undefined);

    async function deleteDocumentsInternal(): Promise<void> {
      await deleteDocuments(checkedDocumentIds);
      setDocumentResponse((previousState) => ({
        ...previousState,
        lastPage:
          (previousState.totalCount - checkedDocumentIds.length) /
          previousState.pageSize,
        totalCount: previousState.totalCount - checkedDocumentIds.length,
        results: previousState.results.filter(
          (x) => !checkedDocumentIds.includes(x.id)
        ),
      }));
      setIsSearchingDocument(false);
    }
    deleteDocumentsInternal();
    setCheckedDocumentIds([]);
  };

  const handleSearch = (options: DocumentSearchOptions): void => {
    if (abortControllerRef && isSearchingDocument) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    setIsSearchingDocument(true);
    setSearchOptions(options);

    searchDocuments(
      options,
      containerKey,
      abortControllerRef.current.signal,
      errorHandler
    )
      .then((response) => {
        if (response) {
          setDocumentResponse(response);
        }
      })
      .finally(() => {
        setDocument(undefined);
        setIsSearchingDocument(false);
      });
  };

  const [rowAction, setRowAction] = useState([
    {
      name: 'Download',
      displayName: t('documentsViewScreen.downloadIconDisplayName'),
      Icon: DownloadCloudSmallIcon,
      onAction: onDownloadFiles.bind(this),
    },
  ]);

  useEffect(() => {
    if (!currentUser) return;

    const { permissions } = currentUser;
    if (permissions.find((p) => p === UserPermission.DeleteDocuments)) {
      setRowAction((act) => [
        ...act,
        {
          name: 'Delete',
          displayName: t('documentsViewScreen.deleteIconDisplayName'),
          Icon: DeleteIcon,
          onAction: (): void => setShowDeletionConfirmationDialog(true),
        },
      ]);
    }
  }, [currentUser, t]);

  const itemClickHandler = async (
    documentItem: ArchivedDocumentDetail
  ): Promise<void> => {
    // If select the same one, do nothing
    if (document === documentItem) {
      return;
    }

    if (documentItem.metadata) {
      setSelectedDocumentMetadata(documentItem.metadata);
    }
    setDocument(documentItem);
    setSelectedDocument(undefined);
  };

  const loadFileContent = async (): Promise<void> => {
    if (!document) return;
    setSelectedDocument(undefined);
    const fileContent = await downloadArchivedDocuments(
      [document.id],
      errorHandler
    );
    if (fileContent) {
      setSelectedDocument(fileContent);
    }
  };

  const renderResults = (): ReactElement => {
    let resultCount: number | undefined;
    if (documentResponse !== defaultDocumentResponse) {
      resultCount = documentResponse.totalCount;
    }

    return (
      <>
        <ResultDocumentList
          documentResult={documentResponse}
          onItemClick={itemClickHandler}
          documentTypes={documentTypes}
          actions={rowAction}
          isFetchingDocuments={isSearchingDocument}
          checkedDocumentIds={checkedDocumentIds}
          setCheckedDocumentIds={setCheckedDocumentIds}
          resultCount={resultCount}
          onSearch={handleSearch}
        />
        {resultCount !== undefined ? (
          <PaginationNavigator
            currentPage={searchOptions.page}
            lastPage={documentResponse.lastPage}
            totalCount={documentResponse.totalCount}
            pageSize={searchOptions.pageSize}
            onPageChange={(currentPage: number): void => {
              const newOptions = { ...searchOptions, page: currentPage };
              handleSearch(newOptions);
            }}
            descriptionTranslationKey={
              documentResponse.totalCount > searchOptions.pageSize
                ? 'documentSearchScreen.paginationDescription_moreThanPageSize'
                : 'documentSearchScreen.paginationDescription_withinPageSize'
            }
          />
        ) : null}
      </>
    );
  };

  if (!userHasPermission(currentUser, UserPermission.SearchArchivedDocuments)) {
    return (
      <div className="document-search-container">
        <RestrictedArea />
      </div>
    );
  }

  return (
    <>
      <div className="document-search-container">
        <div className="main-content">
          <div className="result-container">{renderResults()}</div>
          <DocumentReadonlySidePanel
            loadFileContent={loadFileContent}
            file={selectedDocument}
            id={document ? document.id : ''}
            document={document}
            documentTypes={documentTypes}
            metadata={selectedDocumentMetadata}
            settings={currentUser.settings}
            collapserPosition={CollapserPositionVariant.WithTitleBar}
          />
        </div>
      </div>
      <ConfirmationModal
        title={t('confirmationModal.deleteDocumentDialogTitle')}
        bodyText={t('confirmationModal.deleteDocumentDialogBody', {
          count: checkedDocumentIds.length,
        })}
        show={showDeletionConfirmationDialog}
        setShow={setShowDeletionConfirmationDialog}
        onAnswer={deleteSelectedDocuments}
        primaryButtonText={t('confirmationModal.deleteButton')}
        dismissButtonText={t('confirmationModal.cancelButton')}
        variant={ConfirmationModalVariant.SecondaryWarning}
      />
    </>
  );
};

export default DocumentSearchPage;
