import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import storageKey from '../../common/constants/storageKey.constants';
import EventListener from '../../common/providers/eventListener';
import StorageProvider from '../../common/providers/storageProvider';
import QueryStringKey from '../../common/types/queryStringKey';
import { ContainerContext } from '../../context/Container/ContainerContext';
import usePathName from '../../hooks/usePathName/usePathName';

interface ContainerProviderProps {
  children: ReactNode;
}

const ContainerProvider = ({
  children,
}: ContainerProviderProps): ReactElement => {
  const [, searchParams] = usePathName();
  const eventListener = useMemo(() => new EventListener(), []);
  const storage = useMemo(() => new StorageProvider(), []);
  const [containerKey, setContainerKey] = useState<string>(() => {
    const containerKeyFromQueryString = searchParams.get(
      QueryStringKey.ContainerKey
    );
    if (containerKeyFromQueryString) return containerKeyFromQueryString;

    const containerKeyFromStorage = storage.getItem(storageKey.CONTAINER_KEY);
    if (containerKeyFromStorage) return containerKeyFromStorage;

    return 'default-container';
  });

  useEffect(() => {
    const beforeUnLoad = (): void => {
      storage.setItem(storageKey.CONTAINER_KEY, containerKey);
    };

    eventListener.add('beforeunload', beforeUnLoad);

    return (): void => {
      eventListener.remove('beforeunload', beforeUnLoad);
    };
  }, [containerKey, eventListener, storage]);

  const contextValue = useMemo(
    () => ({ containerKey, setContainerKey }),
    [containerKey]
  );

  return (
    <ContainerContext.Provider value={contextValue}>
      {children}
    </ContainerContext.Provider>
  );
};

export default ContainerProvider;
