enum SubscriptionStatus {
  Unknown = 'Unknown',
  OnHold = 'OnHold',
  Active = 'Active',
  Expired = 'Expired',
  NotStarted = 'NotStarted',
  ExceededUsageQuota = 'ExceededUsageQuota',
}

export default SubscriptionStatus;
