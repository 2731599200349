import axios, { AxiosResponse } from 'axios';
import { ReactElement } from 'react';
import ErrorDetails from '../../common/models/errorDetails';
import LocationProvider from '../../common/providers/locationProvider';
import ErrorCode from '../../common/types/errorCode';
import HttpStatusCode from '../../common/types/httpStatusCode';
import { useAuth } from '../../context/Auth/AuthContext';
import { handledErrorCodes } from '../../utils/errorUtil';

interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
}

const ErrorFallback = ({ error }: ErrorFallbackProps): ReactElement => {
  const { authService } = useAuth();
  const locationProvider = new LocationProvider();

  const redirectToLoginPage = (): ReactElement => {
    const redirectUrl = authService.authorize();
    locationProvider.replace(redirectUrl);
    return <></>;
  };

  const redirectToErrorPage = (
    httpStatusCode?: string,
    errorCode?: string
  ): ReactElement => {
    if (errorCode)
      locationProvider.replace(`/error/${httpStatusCode}/code/${errorCode}`);
    else locationProvider.replace(`/error/${httpStatusCode}`);

    return <></>;
  };

  let handledResult: ReactElement | null = null;
  if (axios.isAxiosError(error)) {
    const response = error.response as AxiosResponse<ErrorDetails>;
    if (!response) {
      return redirectToErrorPage(ErrorCode.Unknown.toString());
    }

    switch (response.status) {
      case HttpStatusCode.Unauthorized:
        handledResult = redirectToLoginPage();
        break;

      case HttpStatusCode.NotFound:
      case HttpStatusCode.BadGateway:
      case HttpStatusCode.ServiceUnavailable:
        handledResult = redirectToErrorPage(response.status.toString());
        break;

      case HttpStatusCode.BadRequest:
      case HttpStatusCode.Forbidden:
        if (handledErrorCodes.includes(response.data.errorCode)) {
          handledResult = redirectToErrorPage(
            HttpStatusCode.Forbidden.toString(),
            response.data.errorCode.toString()
          );
        } else {
          handledResult = redirectToErrorPage(response.status.toString());
        }
        break;

      default:
        handledResult = redirectToErrorPage(ErrorCode.Unknown.toString());
        break;
    }
  }

  return handledResult ?? redirectToErrorPage(ErrorCode.Unknown.toString());
};

export default ErrorFallback;
