import React, { ReactElement, useCallback } from 'react';
import UploadFileWithStatus from '../../common/models/uploadFileWithStatus';
import FileUploadStatus from '../../common/types/fileUploadStatus';
import { sortFileNameByAlphabet } from '../../utils/fileUtil';
import UploadDocumentBox from '../UploadDocumentBox/UploadDocumentBox';
import UploadDocumentThumbnail from '../UploadDocumentThumbnail/UploadDocumentThumbnail';
import './UploadingDropzone.scss';

interface UploadingDropzoneProps {
  fileSet: Array<UploadFileWithStatus>;
  successUploadFiles: Array<UploadFileWithStatus>;
  updateFilePreviewThumbnail: Function;
  setShowDocumentViewModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedFileId: React.Dispatch<React.SetStateAction<string>>;
  openAddFilesDialog: React.MouseEventHandler<HTMLButtonElement>;
  isUploadListView: boolean;
  checkboxOnClick: (selectedFile: File, checked: boolean) => void;
  checkboxSelectedFiles: Array<File>;
}

const UploadingDropzone = ({
  fileSet,
  successUploadFiles,
  updateFilePreviewThumbnail,
  setShowDocumentViewModal,
  setSelectedFileId,
  openAddFilesDialog,
  isUploadListView,
  checkboxOnClick,
  checkboxSelectedFiles,
}: UploadingDropzoneProps): ReactElement => {
  const selectFileThumbnail = useCallback(
    (id: string): void => {
      setShowDocumentViewModal(true);
      setSelectedFileId(id);
    },
    [setSelectedFileId, setShowDocumentViewModal]
  );

  return (
    <div
      className={`uploaded-document-container ${
        isUploadListView ? 'list' : ''
      }`}
    >
      <UploadDocumentBox
        openAddFilesDialog={openAddFilesDialog}
        isUploadListView={isUploadListView}
      />
      {successUploadFiles
        .sort(sortFileNameByAlphabet)
        .map((successUploadFile: UploadFileWithStatus) => (
          <UploadDocumentThumbnail
            key={`${successUploadFile.id}_success`}
            fileInfo={successUploadFile}
            checked={checkboxSelectedFiles.includes(successUploadFile.file)}
            selectFile={(): void => {
              selectFileThumbnail(successUploadFile.id);
            }}
            isUploadListView={isUploadListView}
            updateFilePreview={(file: UploadFileWithStatus): void =>
              updateFilePreviewThumbnail(successUploadFile.id, file)
            }
            checkboxOnClick={checkboxOnClick}
          />
        ))}
      {fileSet.map(
        (fileData: UploadFileWithStatus) =>
          (fileData.status === FileUploadStatus.UPLOADING ||
            fileData.status === FileUploadStatus.PENDING) && (
            <UploadDocumentThumbnail
              key={fileData.id}
              fileInfo={fileData}
              checked={checkboxSelectedFiles.includes(fileData.file)}
              selectFile={(): void => {
                selectFileThumbnail(fileData.id);
              }}
              isUploadListView={isUploadListView}
              updateFilePreview={undefined}
              checkboxOnClick={checkboxOnClick}
            />
          )
      )}
    </div>
  );
};

export default UploadingDropzone;
