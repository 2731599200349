import {
  MutableRefObject,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import storageKey from '../../common/constants/storageKey.constants';
import ActionItem from '../../common/models/actionItem';
import StorageProvider from '../../common/providers/storageProvider';
import CollapserPositionVariant from '../../common/types/collapserPositionVariant';
import SDCButtonVariant from '../../common/types/sdcButtonVariant';
import CollapseIcon from '../ActionIcon/CollapseIcon';
import LoadingSet from '../LoadingSet/LoadingSet';
import SDCButton from '../SDCButton/SDCButton';
import './SidePanel.scss';

interface SidePanelProps {
  title?: string;
  children: ReactElement;
  footerActionItem?: ActionItem;
  showSidePanel?: boolean;
  setShowSidePanel?: React.Dispatch<React.SetStateAction<boolean>>;
  collapserPosition: CollapserPositionVariant;
  resizable?: boolean;
}

const SidePanel = ({
  title,
  children,
  footerActionItem,
  showSidePanel,
  setShowSidePanel,
  collapserPosition,
  resizable,
}: SidePanelProps): ReactElement => {
  const minWidth = 300;
  const maxWidth = 800;
  const storageProvider = useMemo(() => new StorageProvider(), []);
  const [show, setShow] = useState(true);
  const refSidePanel = useRef(null) as MutableRefObject<HTMLDivElement | null>;
  const refResizer = useRef(null) as MutableRefObject<HTMLDivElement | null>;
  const refResizerLayout = useRef(
    null
  ) as MutableRefObject<HTMLDivElement | null>;
  const isMobileOrTablet =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  const [sidePanelWidth, setSidePanelWidth] = useState(() => {
    if (isMobileOrTablet) {
      return minWidth;
    }
    const savedWidth = storageProvider.getItem(storageKey.SIDE_PANEL_WIDTH);
    return savedWidth ? parseInt(savedWidth, 10) : minWidth;
  });

  useEffect(() => {
    if (showSidePanel) setShow(true);
  }, [children, showSidePanel]);

  useEffect(() => {
    if (!resizable || isMobileOrTablet) {
      return (): void => {};
    }

    const element = refSidePanel.current;
    const layout = refResizerLayout.current;

    if (!element) {
      return (): void => {};
    }

    const styles = window.getComputedStyle(element);
    let width = parseInt(styles.width, 10);
    let xCord = 0;

    const onMouseMoveLeftResize = (event: MouseEvent): void => {
      const dx = event.clientX - xCord;
      const newWidth = width - dx;
      // Ensure the side panel doesn't get too wide or too narrow
      if (newWidth > maxWidth || newWidth < minWidth) {
        return;
      }

      width = newWidth;
      xCord = event.clientX;

      if (layout) {
        layout.style.left = `${event.clientX}px`;
      }
    };

    const onMouseUpLeftResize = (): void => {
      const widthValue = `${width}px`;
      setSidePanelWidth(width);
      storageProvider.setItem(storageKey.SIDE_PANEL_WIDTH, width.toString());
      element.style.width = widthValue;

      // When release the handle, hide the layout
      if (layout) {
        layout.style.display = 'none';
      }

      document.removeEventListener('mousemove', onMouseMoveLeftResize);
    };

    const onMouseDownLeftResize = (event: MouseEvent): void => {
      xCord = event.clientX;
      if (element) {
        element.style.right = styles.right;
        element.style.left = '';
      }
      document.addEventListener('mousemove', onMouseMoveLeftResize);
      document.addEventListener('mouseup', onMouseUpLeftResize);
      if (layout) {
        layout.style.display = 'block';
      }
    };

    const resizer = refResizer.current;
    resizer?.addEventListener('mousedown', onMouseDownLeftResize);

    return (): void => {
      resizer?.removeEventListener('mousedown', onMouseDownLeftResize);
    };
  }, [
    refSidePanel,
    refResizer,
    refResizerLayout,
    storageProvider,
    resizable,
    setSidePanelWidth,
    isMobileOrTablet,
  ]);

  const renderAction = (): ReactElement | undefined => {
    if (footerActionItem && footerActionItem.show) {
      if (footerActionItem.isProcessing) {
        return (
          <div className="footer">
            <LoadingSet size="sm" />
          </div>
        );
      }
      return (
        <div className="footer">
          <SDCButton
            className="action-item"
            variant={SDCButtonVariant.Primary}
            text={footerActionItem.text}
            onClick={footerActionItem.onClick}
            disabled={footerActionItem.disabled}
          />
        </div>
      );
    }

    return undefined;
  };

  return (
    <div
      ref={refSidePanel}
      style={{ width: show ? `${sidePanelWidth}px` : 0 }}
      className="side-panel user-select-none"
    >
      {resizable ? (
        <>
          <div ref={refResizer} className="resizer" />
          <div ref={refResizerLayout} className="resizer-layout" />
        </>
      ) : null}

      <div className="collapse-icon">
        <CollapseIcon
          onClick={(): void => {
            setShow((showSide) => !showSide);
            if (setShowSidePanel) setShowSidePanel((showSide) => !showSide);
          }}
          reverseArrow={show}
          position={collapserPosition}
        />
      </div>
      <div className="side-bar-collapser">{title}</div>
      <div
        className={`document-property-container ${
          footerActionItem && footerActionItem.show ? 'full' : ''
        }`}
      >
        {children}
      </div>
      {renderAction()}
    </div>
  );
};

SidePanel.defaultProps = {
  title: '',
  showSidePanel: false,
  setShowSidePanel: undefined,
  footerActionItem: undefined,
  resizable: false,
};

export default SidePanel;
