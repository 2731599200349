// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
import { useEffect, useRef } from 'react';

const useInterval = (callback: Function, delay: number | null): void => {
  const savedCallback = useRef<Function>();

  // Remember the latest callback.
  useEffect(() => {
    if (savedCallback) savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = (): void => {
      if (savedCallback && savedCallback.current) savedCallback.current();
    };
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return (): void => clearInterval(id);
    }
    return (): void => {};
  }, [delay]);
};

export default useInterval;
