import { AxiosError } from 'axios';
import React, { ReactElement, useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useParams } from 'react-router-dom';
import HttpStatusCode from '../../common/types/httpStatusCode';
import DefaultLoading from '../../components/DefaultLoading/DefaultLoading';
import ReadonlyArchiveDocumentSection from '../../components/ReadonlyArchiveDocumentSection/ReadonlyArchiveDocumentSection';
import {
  downloadArchivedDocuments,
  getArchivedDocument,
} from '../../services/document/document.service';
import ArchivedDocumentWithMetadata from '../../services/document/models/archivedDocumentWithMetadata';
import { getAllDocumentTypes } from '../../services/documentType/documentType.service';
import DocumentType from '../../services/documentType/models/documentType';
import './DocumentViewPage.scss';

type DocumentViewPageParams = {
  archivedObjectInfoId: string;
};

const DocumentViewPage = (): ReactElement => {
  const errorHandler = useErrorHandler();
  const { archivedObjectInfoId } = useParams<DocumentViewPageParams>();
  const [documentTypes, setDocumentTypes] = React.useState<DocumentType[]>([]);
  const [document, setDocument] = React.useState<
    ArchivedDocumentWithMetadata | undefined
  >();

  const [selectedFile, setSelectedFile] = React.useState<File | undefined>();

  const redirectToErrorPage = (error: AxiosError): void => {
    if (
      error.response?.status === HttpStatusCode.BadRequest ||
      error.response?.status === HttpStatusCode.NotFound
    ) {
      window.location.href = '/error/404';
    } else {
      window.location.href = '/error/0';
    }
  };

  useEffect(() => {
    async function fetchDocumentTypes(): Promise<void> {
      const response = await getAllDocumentTypes(errorHandler);
      if (response) {
        setDocumentTypes(response);
      }
    }
    fetchDocumentTypes();
  }, [errorHandler]);

  useEffect(() => {
    async function getDocument(): Promise<void> {
      if (archivedObjectInfoId) {
        try {
          const viewDocumentResponse: ArchivedDocumentWithMetadata =
            await getArchivedDocument(archivedObjectInfoId, errorHandler);
          if (viewDocumentResponse) {
            setDocument(viewDocumentResponse);
          }
          const archivedObject: File = await downloadArchivedDocuments([
            archivedObjectInfoId,
          ]);
          if (archivedObject) {
            setSelectedFile(archivedObject);
          }
        } catch (e) {
          const error = e as AxiosError;
          redirectToErrorPage(error);
        }
      }
    }
    getDocument();
  }, [archivedObjectInfoId, errorHandler]);

  const renderContent = (): ReactElement => {
    if (document === undefined || selectedFile === undefined) {
      return <DefaultLoading />;
    }

    return (
      <div className="document-view-page-container">
        <ReadonlyArchiveDocumentSection
          selectedFile={selectedFile}
          documentName={document.documentName}
          document={document}
          documentTypes={documentTypes}
          onCloseModal={undefined}
        />
      </div>
    );
  };

  return renderContent();
};
export default DocumentViewPage;
