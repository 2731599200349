import React, { ReactElement, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import CloseIcon from '../../assets/svg/CloseIcon.svg';
import './DocumentViewModal.scss';

interface DocumentModalProps {
  children: React.ReactNode;
  show: boolean;
  onClose: Function;
  showCloseButton?: boolean;
}

const DocumentViewModal = ({
  children,
  show,
  onClose,
  showCloseButton,
}: DocumentModalProps): ReactElement => {
  const modalRef: any = useRef(null);
  useEffect(() => {
    if (show) {
      modalRef.current.classList.add('visible');
    } else {
      modalRef.current.classList.remove('visible');
    }
  }, [show]);

  const closeModal = (): void => {
    onClose();
  };

  return (
    <div>
      <div ref={modalRef} className="document-modal">
        {showCloseButton && (
          <Button onClick={closeModal} className="close-btn">
            <img src={CloseIcon} alt="CloseIcon" />
          </Button>
        )}
        <div className="modal-wrapper">{children}</div>
      </div>
    </div>
  );
};

DocumentViewModal.defaultProps = {
  showCloseButton: false,
};

export default DocumentViewModal;
