import { ReactElement } from 'react';
import CollapseArrow from '../../assets/svg/CollapseArrow.svg';
import CollapserIcon from '../../assets/svg/CollapserIcon.svg';
import CollapserPositionVariant from '../../common/types/collapserPositionVariant';
import './CollapseIcon.scss';

interface CollapseIconProps {
  onClick: Function;
  reverseArrow: boolean;
  position: CollapserPositionVariant;
}

const CollapseIcon = ({
  onClick,
  reverseArrow,
  position,
}: CollapseIconProps): ReactElement => (
  <button
    className={`collapse-icon-container ${position}`}
    type="button"
    onClick={(): void => onClick()}
  >
    <div className="frame">
      <img className="box" src={CollapserIcon} alt="" />
      <img
        className={`arrow ${reverseArrow ? '' : 'reverse'}`}
        src={CollapseArrow}
        alt=""
      />
    </div>
  </button>
);

export default CollapseIcon;
