import { ReactElement, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import TaskBarSize from '../../common/types/taskBarSize';
import UserPermission from '../../common/types/userPermission';
import RestrictedArea from '../../components/RestrictedArea/RestrictedArea';
import SubscriptionCard from '../../components/SubscriptionCard/SubscriptionCard';
import TaskBar from '../../components/TaskBar/TaskBar';
import UsageCard from '../../components/UsageCard/UsageCard';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';
import SubscriptionResponse from '../../services/subscription/models/subscriptionResponse';
import { getCurrentSubscription } from '../../services/subscription/subscription.service';
import getStorageDisplayValue from '../../utils/conversionUtil';
import userHasPermission from '../../utils/userUtil';
import './SubscriptionPage.scss';

const SubscriptionPage = (): ReactElement => {
  const errorHandler = useErrorHandler();
  const { currentUser } = useCurrentUser();
  const { t } = useTranslation();
  const [subscription, setSubscription] = useState<
    SubscriptionResponse | undefined
  >();
  useEffect(() => {
    if (!userHasPermission(currentUser, UserPermission.BillingManagement)) {
      return;
    }
    async function getSubscription(): Promise<void> {
      const response = await getCurrentSubscription(errorHandler);
      if (response) {
        setSubscription(response);
      }
    }
    getSubscription();
  }, [errorHandler, currentUser]);

  const renderUsage = (): ReactElement => {
    if (!subscription) {
      return <></>;
    }

    return (
      <div className="usage-container">
        <UsageCard
          title={t('subscriptionScreen.storageUsageLabel')}
          usage={subscription.storageUsage}
          converter={getStorageDisplayValue}
        />
        <UsageCard
          title={t('subscriptionScreen.userUsageLabel')}
          usage={subscription.userUsage}
        />
      </div>
    );
  };

  const renderSubscription = (): ReactElement => {
    if (!subscription) {
      return <></>;
    }

    return (
      <Container fluid="xl">
        <div className="section-header">
          {t('subscriptionScreen.subscriptionTitle')}
        </div>
        <SubscriptionCard subscription={subscription} />
        <div className="section-header">
          {t('subscriptionScreen.usageTitle')}
        </div>
        {renderUsage()}
      </Container>
    );
  };

  if (!userHasPermission(currentUser, UserPermission.BillingManagement)) {
    return (
      <div className="subscription-page-container">
        <TaskBar
          headerPrefix=""
          size={TaskBarSize.Large}
          isHaveSideNavBar
          stickOnTop
        >
          <div>{t('subscriptionScreen.subscriptionTitle')}</div>
        </TaskBar>
        <RestrictedArea />
      </div>
    );
  }

  return (
    <div className="subscription-page-container">{renderSubscription()}</div>
  );
};

export default SubscriptionPage;
