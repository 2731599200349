import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckMarkSolidIcon } from '../../assets/svg/CheckMarkSolidIcon.svg';
import { ReactComponent as QRCodeSuccessIcon } from '../../assets/svg/QRCodeSuccessIcon.svg';
import PendingTrayFileProgressTracking from '../../common/types/pendingTrayFileProgressTracking';
import './PendingTrayTimeoutStatusCard.scss';

interface PendingTrayTimeoutStatusCardProps {
  id: string;
  fileName: string;
  setFileProgressTrackings: React.Dispatch<
    React.SetStateAction<Array<PendingTrayFileProgressTracking>>
  >;
}

const PendingTrayTimeoutStatusCard = ({
  id,
  fileName,
  setFileProgressTrackings,
}: PendingTrayTimeoutStatusCardProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="status-card">
      <div
        className="timeout-card fade-in"
        onAnimationEnd={(): void => {
          if (setFileProgressTrackings) {
            setFileProgressTrackings((prev) =>
              prev.map((x) => {
                if (x.id === id) {
                  // eslint-disable-next-line no-param-reassign
                  x.shouldBeRemoved = true;
                }
                return x;
              })
            );
          }
        }}
      >
        <div>
          <QRCodeSuccessIcon width={30} height={30} />
        </div>
        <div className="card-body">
          <div className="title truncate" data-hj-suppress>
            {fileName}
          </div>
        </div>
        <div className="message">
          <CheckMarkSolidIcon width={20} height={20} />{' '}
          <span className="ps-2">{t('pendingTrayPage.timeoutMessage')}</span>
        </div>
      </div>
    </div>
  );
};

export default PendingTrayTimeoutStatusCard;
