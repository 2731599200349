class HistoryProvider {
  private history: History;

  constructor() {
    this.history = window.history;
  }

  public get state(): any {
    return this.history.state;
  }

  public replaceState(
    data: any,
    unused: string,
    url?: string | URL | null
  ): void {
    this.history.replaceState(data, unused, url);
  }
}

export default HistoryProvider;
