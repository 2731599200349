import { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import UserPermission from '../../common/types/userPermission';
import DefaultLoading from '../../components/DefaultLoading/DefaultLoading';
import { useCurrentUser } from '../../context/CurrentUser/CurrentUserContext';

const DefaultPage = (): ReactElement => {
  const { currentUser, fetchingUserData } = useCurrentUser();

  if (fetchingUserData) return <DefaultLoading />;

  if (
    currentUser?.permissions?.includes(UserPermission.SearchArchivedDocuments)
  )
    return <Redirect from="/" exact to="/search" />;
  return <Redirect from="/" exact to="/team-management" />;
};

export default DefaultPage;
