import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './assets/i18n/en.json';
import DataType from './common/types/dataType';
import formatDateTime from './utils/i18n-date-format';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: (value, formatType, lng, options) => {
        // intlDateTime or intlDate
        if (formatType === DataType.DateTime) {
          const dateOptions = options ? options.formatParams.val : undefined;
          return formatDateTime(value, lng, dateOptions);
        }

        return value;
      },
    },
  });

export default i18n;
