import { createContext, useContext } from 'react';

export type TenantContextProps = {
  maxFileSizeInBytes: number;
  supportedFileTypes: string[];
  accountName: string;
};

export const TenantContext = createContext<TenantContextProps | undefined>(
  undefined
);

export const useTenant = (): TenantContextProps => {
  const context = useContext(TenantContext);
  if (context === undefined) {
    throw new Error('useTenant must be used within a TenantProvider');
  }
  return context;
};
