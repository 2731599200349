import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useErrorHandler } from 'react-error-boundary';
import LocationProvider from '../../common/providers/locationProvider';
import { useAuth } from '../../context/Auth/AuthContext';
import { CurrentUserContext } from '../../context/CurrentUser/CurrentUserContext';
import CurrentUserResponse from '../../services/user/models/currentUserResponse';
import getCurrentUser from '../../services/user/user.service';

interface CurrentUserProviderProps {
  children: ReactNode;
}

const locationProvider = new LocationProvider();

const CurrentUserProvider = ({
  children,
}: CurrentUserProviderProps): ReactElement => {
  const { authService } = useAuth();
  const handleError = useErrorHandler();
  const [fetchingUserData, setFetchingUserData] = useState(true);
  const [currentUser, setCurrentUser] = useState<CurrentUserResponse>({
    id: '',
    email: '',
    name: '',
    username: '',
    roles: [],
    permissions: [],
    settings: {
      dateFormat: '',
      dateTimeFormat: '',
    },
  });
  const UninvitedUsersErrorRoute = 'error/403/code/4';

  const getCurrentUserData = useCallback(async (): Promise<void> => {
    // do not get current user on error page
    if (locationProvider.href().indexOf(UninvitedUsersErrorRoute) > 0) {
      setFetchingUserData(false);
      return;
    }

    const currentUserResponse = await getCurrentUser(handleError);
    setCurrentUser(currentUserResponse);
    setFetchingUserData(false);
  }, [handleError]);

  useEffect(() => {
    if (authService.isPending() || currentUser?.id) return;

    if (fetchingUserData) getCurrentUserData();
  }, [authService, currentUser, getCurrentUserData, fetchingUserData]);

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        fetchingUserData,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUserProvider;
