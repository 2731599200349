import { getSdcClient } from '../../common/apiClients/sdcClient.instance';
import handleApplicationError from '../../utils/errorUtil';
import { TenantSetting } from './models/tenantSetting';

export const getTenantSetting = async (
  errorHandler?: Function | undefined
): Promise<TenantSetting> => {
  const sdcClient = getSdcClient();
  const url: string = `./api/v1/tenant/settings`;

  try {
    const response = await sdcClient.get<TenantSetting>(url);
    return response.data;
  } catch (error) {
    handleApplicationError(error, errorHandler);
  }

  return {} as TenantSetting;
};

export default getTenantSetting;
